<fuse-card class="w-full p-6 filter-info flex-col">
  <div class="flex items-center">
    <img *ngIf="contact.imageUrl == null" class="w-16 h-16 mr-6 rounded-full"
      src="https://img.icons8.com/color/128/000000/test-account.png" alt="Card cover image">
    <img *ngIf="contact.imageUrl != null" class="w-16 h-16 mr-6 border rounded-full object-cover"
      src="{{contact.imageUrl}}" alt="Card cover image">
    <div class="flex flex-col">
      <div class="text-2xl font-semibold leading-tight">{{contact.name}}</div>
      <div class="text-md leading-tight text-primary">{{contact.position}}</div>
      <div class="text-md text-secondary mt-2">{{contact.email}}</div>
    </div>
  </div>

  <div class="w-full grid grid-cols-12 text-xs text-secondary font-bold mt-4">
    <div class="col-span-6 text-left">
      <div class="mb-2">COMPANIES</div>
      <a *ngFor="let company of contact.companies" [matTooltip]="company.name" routerLink="/companies/{{company.id}}" class="inline-block">
        <img
        class="w-5 h-5 mr-1 rounded object-cover"
        *ngIf="company.imageUrl != null" 
        [src]="company.imageUrl"
        alt="Card cover image">
        <mat-icon  *ngIf="company.imageUrl == null" class="mr-1 text-primary w-5 h-5 inline-block" style="vertical-align: bottom;"
[svgIcon]="'heroicons_solid:briefcase'"></mat-icon>
      </a>
    </div>
    <div class="col-span-6 text-right">
      <div class="mb-2">AGENCIES</div>
      <a *ngFor="let agency of contact.agencies" [matTooltip]="agency.name" routerLink="/agencies/{{agency.id}}" class="inline-block">
        <img
        class="w-5 h-5 mr-1 rounded object-cover"
        *ngIf="agency.imageUrl != null" 
        [src]="agency.imageUrl"
        alt="Card cover image">
        <mat-icon  *ngIf="agency.imageUrl == null" class="mr-1 text-primary w-5 h-5 inline-block" style="vertical-align: bottom;"
[svgIcon]="'heroicons_solid:office-building'"></mat-icon>
      </a>
    </div>
  </div>
</fuse-card>