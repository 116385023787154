<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<!--Current worklist-->
<fuse-card *ngIf="viewState == viewStates.content" class="flex flex-col w-full mb-4 p-8 filter-article">
  <!--header-->
  <div class="flex items-center justify-between">
    <div class="text-2xl font-semibold leading-tight">Current Worklist Description</div>
  </div>
  <div class="mt-4 mb-4 text-sm text-center">
    These rules are currently in effect.
  </div>
  <ckeditor class="mt-4" [editor]="WorklistEditor" [disabled]="true"  [(ngModel)]="settings.worklistDescription" (ready)="onReady($event)">
  </ckeditor>
</fuse-card>

<!--Suggested worklist-->
<fuse-card *ngIf="viewState == viewStates.content" class="flex flex-col w-full mb-4 p-8 filter-article">
  <!--header-->
  <div class="flex items-center justify-between">
    <div class="text-2xl font-semibold leading-tight">Suggested Worklist Description</div>
    <div class="-mr-3">
      <button mat-flat-button [color]="'primary'" (click)="editWorklistSettings()">
        <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
        <span class="ml-2 mr-1">Save</span>
      </button>
    </div>
  </div>
  <div class="mt-4 mb-4 text-sm text-center">
    Specify the desired rules for a worklist route below and hit save. When implemented by the software team, your worklist rules will move from "Update Pending" status to "No Updates Pending".
  </div>
  <div class="text-center">
    <small>
      <span class="text-primary" *ngIf="settings.worklistUpdatePending">
        Update Pending...
      </span>
      <span class="text-secondary" *ngIf="!settings.worklistUpdatePending">
        No Updates Pending
      </span>
    </small>
  </div>
  <ckeditor class="mt-4" [editor]="SuggestedWorklistEditor" [(ngModel)]="settings.worklistSuggestedDescription" (ready)="onReady($event)">
  </ckeditor>
</fuse-card>