import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AdministratorDto, CompanyDto, EditCompanyDto, MatchedLeadsProductDto } from 'app/models/dtos';
import { PermissionGroupsLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  // styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  public id: string = '0';
  company: CompanyDto = null;
  breadcrumbs: GenericBreadcrumb[] = [
    new GenericBreadcrumb('Companies', '/companies')
  ];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  //lookups
  permissionGroup = PermissionGroupsLookup.COMPANY;

  async ngOnInit() {
    //update title
    this.setTitle('Company | Admin');

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';

    await this.permissionsService.setContent(PermissionGroupsLookup.COMPANY, this.id);
    this.getCompany();
  }

  //api
  getCompany(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}`)
      .subscribe((result: CompanyDto) => {
        this.company = result;
        this.viewState = ViewState.content;
        this.setTitle(this.company.name);
      });
  }

  didClickAddMlp() {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts`, null)
      .subscribe((result: MatchedLeadsProductDto) => {
        this._router.navigateByUrl(`/companies/${this.company.id}/matchedLeadsProducts/${result.id}`);
      });
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | Admin`);
  }

  //tabs
  tabClick(tabEvent: MatTabChangeEvent) {
    switch (tabEvent.tab.textLabel) {
      case 'Dashboard':
        this.getDashboard(); 
        break;
        case 'Leads':
          this.getLeads(); 
          break;
      case 'Contacts':
        // this.getContacts(); 
        break;
        case 'Permissions':
          this.getPermissions(); 
          break;
      default:
        break;
    }
  }

  

  getDashboard(): void {
    this.getLeads();
  }

  getLeads(): void {

  }

  getContacts(): void {

  }

  getPermissions(): void {

  }

  //callbacks
  didEditCompany(company: CompanyDto) {
    this.company = company;
  }
}
