import { ExternalJobPositionDto } from "./externalJobPosition.dto";

export class EditExternalJobPositionDto {
    radius: number;
    salary: string;

    static fromPosition(position: ExternalJobPositionDto) : EditExternalJobPositionDto {
        var dto = new EditExternalJobPositionDto();
        dto.radius = position.radius;
        dto.salary = position.salary;

        return dto;
    }
}