<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="Documents" fallbackIcon="heroicons_solid:document-text"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <app-documents-list documentsRoute="/v1/documents"></app-documents-list>
  </div>

</div>