import { BaseLookup } from './base.lookup'

export class HiringStatesLookup extends BaseLookup {

    abbreviation: string;

    constructor(id: string, abbreviation: string, name: string,) {
        super(id, name);
        this.abbreviation = abbreviation;
    }

    // Values
    static readonly ALL = new HiringStatesLookup('0', '', 'All States');
    static readonly ALABAMA = new HiringStatesLookup('1', 'AL', 'Alabama');
    // static readonly ALASKA = new HiringStatesLookup('2', 'AK', 'Alaska');
    // static readonly AMERICAN_SAMOA = new HiringStatesLookup('3', 'AS', 'American Samoa');
    static readonly ARIZONA = new HiringStatesLookup('4', 'AZ', 'Arizona');
    static readonly ARKANSAS = new HiringStatesLookup('5', 'AR', 'Arkansas');
    static readonly CALIFORNIA = new HiringStatesLookup('6', 'CA', 'California');
    static readonly COLORADO = new HiringStatesLookup('7', 'CO', 'Colorado');
    static readonly CONNECTICUT = new HiringStatesLookup('8', 'CT', 'Connecticut');
    static readonly DELAWARE = new HiringStatesLookup('9', 'DE', 'Delaware');
    static readonly DISTRICT_OF_COLUMBIA = new HiringStatesLookup('10', 'DC', 'District of Columbia');
    // static readonly FEDERATED_SATES_OF_MICRONESIA = new HiringStatesLookup('11', 'FM', 'Federated States Of Micronesia');
    static readonly FLORIDA = new HiringStatesLookup('12', 'FL', 'Florida');
    static readonly GEORGIA = new HiringStatesLookup('13', 'GA', 'Georgia');
    // static readonly GUAM = new HiringStatesLookup('14', 'GU', 'Guam');
    // static readonly HAWAII = new HiringStatesLookup('15', 'HI', 'Hawaii');
    static readonly IDAHO = new HiringStatesLookup('16', 'ID', 'Idaho');
    static readonly ILLINOIS = new HiringStatesLookup('17', 'IL', 'Illinois');
    static readonly INDIANA = new HiringStatesLookup('18', 'IN', 'Indiana');
    static readonly IOWA = new HiringStatesLookup('19', 'IA', 'Iowa');
    static readonly KANSAS = new HiringStatesLookup('20', 'KS', 'Kansas');
    static readonly KENTUCKY = new HiringStatesLookup('21', 'KY', 'Kentucky');
    static readonly LOUISIANA = new HiringStatesLookup('22', 'LA', 'Louisiana');
    static readonly MAINE = new HiringStatesLookup('23', 'ME', 'Maine');
    // static readonly MARSHALL_ISLANDS = new HiringStatesLookup('24', 'MH', 'Marshall Islands');
    static readonly MARYLAND = new HiringStatesLookup('25', 'MD', 'Maryland');
    static readonly MASSACHUSETTS = new HiringStatesLookup('26', 'MA', 'Massachusetts');
    static readonly MICHIGAN = new HiringStatesLookup('27', 'MI', 'Michigan');
    static readonly MINNESOTA = new HiringStatesLookup('28', 'MN', 'Minnesota');
    static readonly MISSISSIPPI = new HiringStatesLookup('29', 'MS', 'Mississippi');
    static readonly MISSOURI = new HiringStatesLookup('30', 'MO', 'Missouri');
    static readonly MONTANA = new HiringStatesLookup('31', 'MT', 'Montana');
    static readonly NEBRASKA = new HiringStatesLookup('32', 'NE', 'Nebraska');
    static readonly NEVADA = new HiringStatesLookup('33', 'NV', 'Nevada');
    static readonly NEW_HAMPSHIRE = new HiringStatesLookup('34', 'NH', 'New Hampshire');
    static readonly NEW_JERSEY = new HiringStatesLookup('35', 'NJ', 'New Jersey');
    static readonly NEW_MEXICO = new HiringStatesLookup('36', 'NM', 'New Mexico');
    static readonly NEW_YORK = new HiringStatesLookup('37', 'NY', 'New York');
    static readonly NORTH_CAROLINA = new HiringStatesLookup('38', 'NC', 'North Carolina');
    static readonly NORTH_DAKOTA = new HiringStatesLookup('39', 'ND', 'North Dakota');
    // static readonly NORTHERN_MARIANA_ISLANDS = new HiringStatesLookup('40', 'MP', 'Northern Mariana Islands');
    static readonly OHIO = new HiringStatesLookup('41', 'OH', 'Ohio');
    static readonly OKLAHOMA = new HiringStatesLookup('42', 'OK', 'Oklahoma');
    static readonly OREGON = new HiringStatesLookup('43', 'OR', 'Oregon');
    // static readonly PALAU = new HiringStatesLookup('44', 'PW', 'Palau');
    static readonly PENNSYLVANIA = new HiringStatesLookup('45', 'PA', 'Pennsylvania');
    // static readonly PUERTO_RICO = new HiringStatesLookup('46', 'PR', 'Puerto Rico');
    static readonly RHODE_ISLAND = new HiringStatesLookup('47', 'RI', 'Rhode Island');
    static readonly SOUTH_CAROLINA = new HiringStatesLookup('48', 'SC', 'South Carolina');
    static readonly SOUTH_DAKOTA = new HiringStatesLookup('49', 'SD', 'South Dakota');
    static readonly TENNESSEE = new HiringStatesLookup('50', 'TN', 'Tennessee');
    static readonly TEXAS = new HiringStatesLookup('51', 'TX', 'Texas');
    static readonly UTAH = new HiringStatesLookup('52', 'UT', 'Utah');
    static readonly VERMONT = new HiringStatesLookup('53', 'VT', 'Vermont');
    // static readonly VIRGIN_ISLANDS = new HiringStatesLookup('54', 'VI', 'Virgin Islands');
    static readonly VIRGINIA = new HiringStatesLookup('55', 'VA', 'Virginia');
    static readonly WASHINGTON = new HiringStatesLookup('56', 'WA', 'Washington');
    static readonly WEST_VIRGINIA = new HiringStatesLookup('57', 'WV', 'West Virginia');
    static readonly WISCONSIN = new HiringStatesLookup('58', 'WI', 'Wisconsin');
    static readonly WYOMING = new HiringStatesLookup('59', 'WY', 'Wyoming');

    static override readonly values = [
        HiringStatesLookup.ALL,
        HiringStatesLookup.ALABAMA,
        // HiringStatesLookup.ALASKA,
        // HiringStatesLookup.AMERICAN_SAMOA,
        HiringStatesLookup.ARIZONA,
        HiringStatesLookup.ARKANSAS,
        HiringStatesLookup.CALIFORNIA,
        HiringStatesLookup.COLORADO,
        HiringStatesLookup.CONNECTICUT,
        HiringStatesLookup.DELAWARE,
        HiringStatesLookup.DISTRICT_OF_COLUMBIA,
        // HiringStatesLookup.FEDERATED_SATES_OF_MICRONESIA,
        HiringStatesLookup.FLORIDA,
        HiringStatesLookup.GEORGIA,
        // HiringStatesLookup.GUAM,
        // HiringStatesLookup.HAWAII,
        HiringStatesLookup.IDAHO,
        HiringStatesLookup.ILLINOIS,
        HiringStatesLookup.INDIANA,
        HiringStatesLookup.IOWA,
        HiringStatesLookup.KANSAS,
        HiringStatesLookup.KENTUCKY,
        HiringStatesLookup.LOUISIANA,
        HiringStatesLookup.MAINE,
        // HiringStatesLookup.MARSHALL_ISLANDS,
        HiringStatesLookup.MARYLAND,
        HiringStatesLookup.MASSACHUSETTS,
        HiringStatesLookup.MICHIGAN,
        HiringStatesLookup.MINNESOTA,
        HiringStatesLookup.MISSISSIPPI,
        HiringStatesLookup.MISSOURI,
        HiringStatesLookup.MONTANA,
        HiringStatesLookup.NEBRASKA,
        HiringStatesLookup.NEVADA,
        HiringStatesLookup.NEW_HAMPSHIRE,
        HiringStatesLookup.NEW_JERSEY,
        HiringStatesLookup.NEW_MEXICO,
        HiringStatesLookup.NEW_YORK,
        HiringStatesLookup.NORTH_CAROLINA,
        HiringStatesLookup.NORTH_DAKOTA,
        // HiringStatesLookup.NORTHERN_MARIANA_ISLANDS,
        HiringStatesLookup.OHIO,
        HiringStatesLookup.OKLAHOMA,
        HiringStatesLookup.OREGON,
        // HiringStatesLookup.PALAU,
        HiringStatesLookup.PENNSYLVANIA,
        // HiringStatesLookup.PUERTO_RICO,
        HiringStatesLookup.RHODE_ISLAND,
        HiringStatesLookup.SOUTH_CAROLINA,
        HiringStatesLookup.SOUTH_DAKOTA,
        HiringStatesLookup.TENNESSEE,
        HiringStatesLookup.TEXAS,
        HiringStatesLookup.UTAH,
        HiringStatesLookup.VERMONT,
        // HiringStatesLookup.VIRGIN_ISLANDS,
        HiringStatesLookup.VIRGINIA,
        HiringStatesLookup.WASHINGTON,
        HiringStatesLookup.WEST_VIRGINIA,
        HiringStatesLookup.WISCONSIN,
        HiringStatesLookup.WYOMING
    ];

    static override fromId(id: string) : HiringStatesLookup {
        return this.values.find(v => v.id == id);
    }
}