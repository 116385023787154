import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { DocumentDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import moment from 'moment';
import * as mime from 'mime';
import { PermissionsService } from 'app/services/permissions/permissions.service';

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  // styleUrls: ['./documents-list.component.css']
})
export class DocumentsListComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  //inputs
  @Input() documentsRoute: string;
  @Input() companyId: string;
  @Input() permissionsService: PermissionsService;

  //view children
  @ViewChild('documentsTable', { read: MatSort, static: false }) documentsTableMatSort: MatSort;

  //vars
  documents: DocumentDto[] = []
  filteredDocuments: DocumentDto[] = []
  searchText: string = '';
  documentsDataSource: MatTableDataSource<DocumentDto> = new MatTableDataSource();
  documentsTableColumns: string[] = ['name', 'company', 'type', 'created', 'size'];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addDocumentViewState = ViewState.content;

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    this.getDocuments();
  }

  //api
  getDocuments(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}${this.documentsRoute}`)
      .subscribe((result: DocumentDto[]) => {
        this.documents = result;
        this.filteredDocuments = this.documents;
        this.viewState = ViewState.content;
        this.documentsDataSource = new MatTableDataSource(this.filteredDocuments);
        this.updateSorting(this.filteredDocuments);
      });
  }

  seachTextDidChange(text: string) {
    this.filteredDocuments = this.documents.filter(r =>
      r.name.toLowerCase().includes(text.toLowerCase())
      || r.company.name.toLowerCase().includes(text.toLowerCase())
      || r.documentType.name.toLowerCase().includes(text.toLowerCase())
      || r.mimeType.toLowerCase().includes(text.toLowerCase())
    )
    this.updateSorting(this.filteredDocuments);
  }

  updateSorting(documents: DocumentDto[]) {
    this.documentsDataSource = new MatTableDataSource(documents);
    this.documentsDataSource.sort = this.documentsTableMatSort;
    this.documentsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
      return item[property];
   };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  fileIconType(mimeType: string): string {
    return mime.getExtension(mimeType);
  }

  formattedDocSize(size: number): string {
    if (size > 1000000) {
      return `${(size / 1000000).toFixed(2)} MB`
    }
    else if (size > 1000) {
      return `${(size / 1000).toFixed(2)} KB`
    }
    else {
      return `${size.toFixed(2)} bytes`
    }
  }
}
