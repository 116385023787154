import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { JobPostingDto } from 'app/models/dtos';
import { PermissionGroupsLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { JobBoardPostingModalComponent } from '../job-board-posting-modal/job-board-posting-modal.component';

@Component({
  selector: 'app-job-board-postings',
  templateUrl: './job-board-postings.component.html',
  // styleUrls: ['./tenstreet-jobs.component.css']
})
export class JobBoardPostingsComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, public permissionsService: PermissionsService, public dialog: MatDialog) { }

  //view children
  @ViewChild('jobPostingsTable', { read: MatSort, static: false }) jobPostingsTableMatSort: MatSort;

  //vars
  jobPostings: JobPostingDto[] = []
  filteredTenstreetJobPostings: JobPostingDto[] = []
  searchText: string = '';
  jobPostingsDataSource: MatTableDataSource<JobPostingDto> = new MatTableDataSource();
  jobPostingsTableColumns: string[] = ['read', 'service', 'referencId', 'company'];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  async ngOnInit() {
    this.titleService.setTitle('Job Board Postings | Admin');

    await this.permissionsService.setContent(PermissionGroupsLookup.GLOBAL, '');
    this.getTenstreetJobPostings();
  }

  //api
  getTenstreetJobPostings(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/jobBoards`)
      .subscribe((result: JobPostingDto[]) => {
        this.jobPostings = result;
        this.filteredTenstreetJobPostings = this.jobPostings;
        this.viewState = ViewState.content;
        this.jobPostingsDataSource = new MatTableDataSource(this.filteredTenstreetJobPostings);
        this.updateSorting(this.filteredTenstreetJobPostings);
      });
  }

  seachTextDidChange(text: string) {
    this.filteredTenstreetJobPostings = this.jobPostings.filter(r =>
      r.referenceId.toLowerCase().includes(text.toLowerCase())
    )
    this.updateSorting(this.filteredTenstreetJobPostings);
  }

  updateSorting(jobPostings: JobPostingDto[]) {
    this.jobPostingsDataSource = new MatTableDataSource(jobPostings);
    this.jobPostingsDataSource.sort = this.jobPostingsTableMatSort;
    this.jobPostingsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
      return item[property];
   };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  didClickJobPosting(jobPosting: JobPostingDto) {
    const dialogRef = this.dialog.open(JobBoardPostingModalComponent, {
      data: jobPosting,
    });

    dialogRef.afterClosed().subscribe(result => {
     
    });
  }
}
