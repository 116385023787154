<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [breadcrumbs]="breadcrumbs" [terminal]="company?.name ?? ''" [imageUrl]="company?.imageUrl"
    fallbackIcon="heroicons_solid:briefcase" imgRenderMode="object-contain"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-2 mt-6">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- content -->
    <div *ngIf="viewState == viewStates.content" class="grid grid-cols-12">
      <div class="col-span-12">
        <!--tabs-->
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabClick($event)">
          <!--Dashboard-->
          <mat-tab label="Dashboard">
            <div class="grid grid-cols-12 gap-3">
              <div class="hidden md:block md:col-span-7 lg:col-span-9" *ngIf="permissionsService.canAdmin()">
                <div class="font-bold text-xl">Company Dashboard</div>
              </div>
              <div class="col-span-12 md:col-span-5 lg:col-span-3" *ngIf="permissionsService.canAdmin()">
                <button mat-flat-button color="primary" class="w-full"
                [swal]="{ title: 'Add Matched Leads Product', confirmButtonText: 'Add', icon: 'info', confirmButtonColor: '#EF4444', showCancelButton: true }"
        (confirm)="didClickAddMlp()">
                  <mat-icon class="icon-size-5" [svgIcon]="'add'"></mat-icon>
                  Add Matched Leads Product
                </button>
              </div>
              <div class="col-span-12">
                <app-dashboard-analytics applicationRoute="v1/companies/{{company.id}}/matchedLeadsProducts"
                  [permissionsService]="permissionsService">
                </app-dashboard-analytics>
              </div>
            </div>

          </mat-tab>

          <!--Info-->
          <mat-tab label="Info">
            <ng-template matTabContent>
              <app-company-info [company]="company" (didEditCompany)="didEditCompany($event)"></app-company-info>
            </ng-template>
          </mat-tab>

          <!--Leads-->
          <mat-tab label="Leads">
            <ng-template matTabContent>
              <app-leads-analytics applicationRoute="v1/companies/{{id}}/applications"
                [permissionsService]="permissionsService"></app-leads-analytics>
            </ng-template>
          </mat-tab>

          <!--Contacts-->
          <mat-tab label="Contacts">
            <ng-template matTabContent>
              <app-company-contacts [company]="company" (didEditCompany)="didEditCompany($event)">
              </app-company-contacts>
            </ng-template>
          </mat-tab>

          <!--Documents-->
          <mat-tab label="Documents">
            <ng-template matTabContent>
              <app-documents-list documentsRoute="/v1/companies/{{company.id}}/documents" [companyId]="company.id" [permissionsService]="permissionsService">
              </app-documents-list>
            </ng-template>
          </mat-tab>

          <!--Worklist-->
          <mat-tab label="Worklist" *ngIf="permissionsService.canManage()">
            <ng-template matTabContent>
              <app-company-worklist [company]="company"></app-company-worklist>
            </ng-template>
          </mat-tab>

          <!--Permissions-->
          <mat-tab label="Permissions" *ngIf="permissionsService.canAdmin()">
            <ng-template matTabContent>
              <app-manage-permissions [objectId]="company.id" [group]="permissionGroup"
                permissionsRoute="v1/companies/{{company.id}}/permissions"></app-manage-permissions>
            </ng-template>
          </mat-tab>

          <!--Logs-->
          <mat-tab label="Logs" *ngIf="permissionsService.canAdmin()">
            <ng-template matTabContent>
              <app-log-analytics logRoute="v1/companies/{{company.id}}/logs"></app-log-analytics>
            </ng-template>
          </mat-tab>

          <!--Notifications-->
          <mat-tab label="Notifications" *ngIf="permissionsService.canAdmin()">
            <ng-template matTabContent>
              <app-log-notifications logRoute="v1/companies/{{company.id}}/logs/notifications"></app-log-notifications>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

  </div>

</div>