<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<div *ngIf="viewState == viewStates.content" class="grid grid-cols-12">
  <div class="col-span-12 md:col-span-6 lg:col-span-4 p-2">
    <fuse-card class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
      <div class="text-small text-center text-secondary mb-4">
        <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
        </mat-icon>
        <b>Product Info</b>
      </div>

      <div class="form-group">
        <input type="file" style="display: none;" id="resource-image" accept="image/*" (change)="editImage($event)"
          #image>
      </div>
      <img *ngIf="listing?.heroImage == null" class="object-contain w-32 h-32 ml-auto mr-auto cursor-pointer"
        src="https://img.icons8.com/color/256/000000/add-image.png" (click)="imageElement.nativeElement.click()" [disabled]="!permissionsService.canManage()" />
      <img *ngIf="listing?.heroImage != null"
        class="object-cover w-full h-32 ml-auto mr-auto border rounded cursor-pointer" src="{{listing.heroImage}}"
        alt="Card cover image" (click)="imageElement.nativeElement.click()" [disabled]="!permissionsService.canManage()">

      <div class="text-center text-small text-secondary mt-2" *ngIf="permissionsService.canManage()">Click hero image to change</div>
      <div>
        <div class="flex-auto mt-4">
          <!--name-->
          <mat-form-field class="w-full">
            <mat-label>Name</mat-label>
            <input matInput type="text" [(ngModel)]="listing.name" [autocomplete]="'off'"
              placeholder="Name" [disabled]="!permissionsService.canManage()">
            <mat-icon class="icon-size-5" matSuffix [svgIcon]="'attach_money'"></mat-icon>
          </mat-form-field>

          <!--notes-->
          <mat-form-field class="w-full">
            <mat-label>Notes</mat-label>
            <textarea [rows]="3" matInput [(ngModel)]="listing.notes" [autocomplete]="'off'" placeholder="Product Notes" [disabled]="!permissionsService.canManage()"></textarea>
            <mat-icon class="icon-size-5" matSuffix [svgIcon]="'edit_note'"></mat-icon>
          </mat-form-field>
          
        </div>
        <button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="editListing()" [disabled]="!permissionsService.canManage()">
          <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
          <span class="ml-2 mr-1">Save</span>
        </button>
        <div class="mt-4 text-small text-center text-secondary">Updated
          {{moment(listing.timestampUpdated).fromNow()}}</div>
      </div>

      <!--delete-->
      <div class="border rounded p-3 mt-4 border-rose-500" *ngIf="permissionsService.canAdmin()">
        <div class="text-warn"><b>Danger Zone</b></div>
        <div><small>These actions are destructive. Proceed with care.</small></div>
        <button class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
          [swal]="{ title: 'Delete Matched Leads Product', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
          (confirm)="deleteProduct()">

          Delete
        </button>
      </div>
    </fuse-card>
  </div>

  <div class="col-span-12 md:col-span-6 lg:col-span-8 p-2">
    <!--Social-->
    <!--Profile Details-->
    <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
      <!--header-->
      <div class="flex items-center justify-between">
        <div class="text-2xl font-semibold leading-tight">Social Media</div>
        <div class="-mr-3">
          <button mat-flat-button [color]="'primary'" (click)="editListing()" [disabled]="!permissionsService.canManage()">
            <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span class="ml-2 mr-1">Save</span>
          </button>
        </div>
      </div>

      <div class="mt-4 mb-4 text-sm text-center">
        Add handles and IDs from social media to make the listing more engaging.
      </div>

      <div class="grid grid-cols-12 gap-3 text-center mt-2">
        <!--tiktok-->
        <div class="col-span-12 md:col-span-6">
          <img class="w-12 h-12 ml-auto mr-auto" src="https://img.icons8.com/color/100/null/tiktok--v1.png" />
          <div class="text-sm font-bold mb-2">TikTok</div>
          <mat-form-field class="w-full">
            <input matInput [(ngModel)]="listing.tiktokHandle" [autocomplete]="'off'" placeholder="TikTok Handle" [disabled]="!permissionsService.canManage()">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'alternate_email'"></mat-icon>
          </mat-form-field>
        </div>

        <!--facebook-->
        <div class="col-span-12 md:col-span-6">
          <img class="w-12 h-12 ml-auto mr-auto" src="https://img.icons8.com/color/100/null/facebook.png" />
          <div class="text-sm font-bold mb-2">Facebook</div>
          <mat-form-field class="w-full">
            <input matInput [(ngModel)]="listing.facebookHandle" [autocomplete]="'off'" placeholder="Facebook Handle" [disabled]="!permissionsService.canManage()">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'alternate_email'"></mat-icon>
          </mat-form-field>
        </div>

        <!--twitter-->
        <div class="col-span-12 md:col-span-4">
          <img class="w-12 h-12 ml-auto mr-auto" src="https://img.icons8.com/color/100/000000/twitter-squared.png" />
          <div class="text-sm font-bold mb-2">Twitter</div>
          <mat-form-field class="w-full">
            <input matInput [(ngModel)]="listing.twitterHandle" [autocomplete]="'off'" placeholder="Twitter Handle" [disabled]="!permissionsService.canManage()">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'alternate_email'"></mat-icon>
          </mat-form-field>
        </div>

        <!--instagram-->
        <div class="col-span-12 md:col-span-4">
          <img class="w-12 h-12 ml-auto mr-auto" src="https://img.icons8.com/color/100/000000/instagram.png" />
          <div class="text-sm font-bold mb-2">Instagram</div>
          <mat-form-field class="w-full">
            <input matInput [(ngModel)]="listing.instagramHandle" [autocomplete]="'off'" placeholder="Instagram Handle" [disabled]="!permissionsService.canManage()">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'alternate_email'"></mat-icon>
          </mat-form-field>
        </div>

        <!--youtube-->
        <div class="col-span-12 md:col-span-4">
          <img class="w-12 h-12 ml-auto mr-auto" src="https://img.icons8.com/color/100/000000/youtube.png" />
          <div class="text-sm font-bold mb-2">Youtube Playlist ID</div>
          <mat-form-field class="w-full">
            <input matInput [(ngModel)]="listing.youtubePlaylistId" [autocomplete]="'off'"
              placeholder="Youtube Playlist ID" [disabled]="!permissionsService.canManage()">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'code'"></mat-icon>
          </mat-form-field>
        </div>
      </div>

      <!-- default -->
      <div class="mt-4 text-sm text-center font-bold">
        Default Social Media
      </div>
      <div class="text-sm text-center mb-4">
        Set the default social media account to be displayed on the account. Defaults to facebook.
      </div>
      <mat-form-field appearance="fill" class="col-span-12">
        <mat-select [(value)]="listing.defaultSocial" [disabled]="!permissionsService.canManage()">
          <mat-option *ngFor="let social of socialMedias" [value]="social.id">{{social.name}}</mat-option>
        </mat-select>
      </mat-form-field>


    </fuse-card>

    <!--Biography-->
    <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article" hidden>
      <!--header-->
      <div class="flex items-center justify-between">
        <div class="text-2xl font-semibold leading-tight">Biography</div>
        <div class="-mr-3">
          <button mat-flat-button [color]="'primary'" (click)="editListing()" [disabled]="!permissionsService.canManage()">
            <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span class="ml-2 mr-1">Save</span>
          </button>
        </div>
      </div>
      <div class="mt-4 mb-4 text-sm text-center">
        This description will be shown to prospective applicants on the corresponding microsite to lend credability for
        why this client is one of the top employers for the particular type of microsite.
      </div>
      <ckeditor class="mt-2" [editor]="BiographyEditor" [(ngModel)]="listing.biography" (ready)="onReady($event)" [disabled]="!permissionsService.canManage()">
      </ckeditor>
    </fuse-card>

    <!--Profile Details-->
    <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
      <!--header-->
      <div class="flex items-center justify-between">
        <div class="text-2xl font-semibold leading-tight">Profile Details</div>
        <div class="-mr-3">
          <button mat-flat-button [color]="'primary'" (click)="editListing()" [disabled]="!permissionsService.canManage()">
            <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span class="ml-2 mr-1">Save</span>
          </button>
        </div>
      </div>
      <div class="mt-4 mb-4 text-sm text-center">
        This description can be used on landing pages or microsites.
      </div>
      <ckeditor class="mt-4" [editor]="ProfileEditor" [(ngModel)]="listing.profileDetails" (ready)="onReady($event)" [disabled]="!permissionsService.canManage()">
      </ckeditor>
    </fuse-card>
  </div>
</div>