import { Route } from '@angular/router';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { AccountComponent } from './modules/admin/accounts/account/account.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { DashboardComponent } from './modules/admin/dashboard/dashboard.component';
import { LeadsComponent } from './modules/admin/leads/leads/leads.component';
import { AgenciesComponent } from './modules/admin/agencies/agencies/agencies.component';
import { CompaniesComponent } from './modules/admin/companies/companies/companies.component';
import { CollectivesComponent } from './modules/admin/collectives/collectives/collectives.component';
import { StaffComponent } from './modules/admin/staff/staff/staff.component';
import { CandidatesComponent } from './modules/admin/candidates/candidates/candidates.component';
import { LogComponent } from './modules/admin/log/log/log.component';
import { SettingsComponent } from './modules/admin/settings/settings/settings.component';
import { DocumentsComponent } from './modules/admin/documents/documents/documents.component';
import { AgencyComponent } from './modules/admin/agencies/agency/agency.component';
import { CompanyComponent } from './modules/admin/companies/company/company.component';
import { CollectiveComponent } from './modules/admin/collectives/collective/collective.component';
import { LeadComponent } from './modules/admin/leads/lead/lead.component';
import { AddCompanyComponent } from './modules/admin/companies/add-company/add-company.component';
import { StaffMemberComponent } from './modules/admin/staff/staff-member/staff-member.component';
import { CandidateComponent } from './modules/admin/candidates/candidate/candidate.component';
import { ProposalComponent } from './modules/admin/proposals/proposal/proposal.component';
import { DocumentComponent } from './modules/admin/documents/document/document.component';
import { ContactsComponent } from './modules/admin/contacts/contacts/contacts.component';
import { ContactComponent } from './modules/admin/contacts/contact/contact.component';
import { AddContactComponent } from './modules/admin/contacts/add-contact/add-contact.component';
import { AddAgencyComponent } from './modules/admin/agencies/add-agency/add-agency.component';
import { AddCollectiveComponent } from './modules/admin/collectives/add-collective/add-collective.component';
import { AddDocumentComponent } from './modules/admin/documents/add-document/add-document.component';
import { AddStaffComponent } from './modules/admin/staff/add-staff/add-staff.component';
import { MatchedLeadsProductComponent } from './modules/admin/products/matched-leads-product/matched-leads-product.component';
import { JobComponent } from './modules/admin/jobs/job/job.component';
import { ProposalsComponent } from './modules/admin/proposals/proposals/proposals.component';
import { CampaignsComponent } from './modules/admin/ads/campaigns/campaigns.component';
import { AdComponent } from './modules/admin/ads/ad/ad.component';
import { AdsListComponent } from './modules/admin/ads/ads-list/ads-list.component';
import { CampaignComponent } from './modules/admin/ads/campaign/campaign.component';
import { AdsComponent } from './modules/admin/ads/ads/ads.component';
import { MatchedLeadsProductUpdateStatusComponent } from './modules/admin/products/matched-leads-product-update-status/matched-leads-product-update-status.component';
import { ProposalJobComponent } from './modules/admin/proposals/proposal-job/proposal-job.component';
import { ReportComponent } from './modules/admin/reports/report/report.component';
import { JobBoardPostingsComponent } from './modules/admin/job-boards/job-board-postings/job-board-postings.component';
import { JobBoardSourcesComponent } from './modules/admin/job-boards/job-board-sources/job-board-sources.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    // {
    //     path: '',
    //     canActivate: [NoAuthGuard],
    //     canActivateChild: [NoAuthGuard],
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
    //         { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
    //         { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
    //         { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
    //         { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) }
    //     ]
    // },

    // Auth routes for authenticated users
    // {
    //     path: '',
    //     canActivate: [AuthGuard],
    //     canActivateChild: [AuthGuard],
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
    //         { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) }
    //     ]
    // },

    // Landing routes
    // {
    //     path: '',
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
    //     ]
    // },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'account',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/accounts/accounts.module').then(m => m.AccountsModule),
                component: AccountComponent
            },
            {
                path: 'dashboard',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/dashboard/dashboard.module').then(m => m.DashboardModule),
                component: DashboardComponent
            },
            {
                path: 'leads',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/leads/leads.module').then(m => m.LeadsModule),
                component: LeadsComponent
            },
            {
                path: 'leads/:id',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/leads/leads.module').then(m => m.LeadsModule),
                component: LeadComponent
            },
            {
                path: 'agencies',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                loadChildren: () => import('./modules/admin/agencies/agencies.module').then(m => m.AgenciesModule),
                component: AgenciesComponent
            },
            {
                path: 'agencies/:id',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/agencies/agencies.module').then(m => m.AgenciesModule),
                component: AgencyComponent
            },
            {
                path: 'add-agency',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/agencies/agencies.module').then(m => m.AgenciesModule),
                component: AddAgencyComponent
            },
            {
                path: 'companies',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/companies/companies.module').then(m => m.CompaniesModule),
                component: CompaniesComponent
            },
            {
                path: 'companies/:id',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/companies/companies.module').then(m => m.CompaniesModule),
                component: CompanyComponent
            },
            {
                path: 'companies/:id/matchedLeadsProducts/:productId/proposals/:proposalId',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/proposals/proposals.module').then(m => m.ProposalsModule),
                component: ProposalComponent
            },
            {
                path: 'companies/:id/matchedLeadsProducts/:productId/reports/:reportId',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/reports/reports.module').then(m => m.ReportsModule),
                component: ReportComponent
            },
            {
                path: 'companies/:id/matchedLeadsProducts/:productId/proposals/:proposalId/jobs/:jobId',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/proposals/proposals.module').then(m => m.ProposalsModule),
                component: ProposalJobComponent
            },
            {
                path: 'companies/:id/matchedLeadsProducts/:productId',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/products/products.module').then(m => m.ProductsModule),
                component: MatchedLeadsProductComponent
            },
            {
                path: 'companies/:id/matchedLeadsProducts/:productId/status',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/products/products.module').then(m => m.ProductsModule),
                component: MatchedLeadsProductUpdateStatusComponent
            },
            {
                path: 'companies/:id/matchedLeadsProducts/:productId/jobs/:jobId',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/jobs/jobs.module').then(m => m.JobsModule),
                component: JobComponent
            },
            {
                path: 'add-company',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/companies/companies.module').then(m => m.CompaniesModule),
                component: AddCompanyComponent
            },
            {
                path: 'collectives',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/collectives/collectives.module').then(m => m.CollectivesModule),
                component: CollectivesComponent
            },
            {
                path: 'collectives/:id',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/collectives/collectives.module').then(m => m.CollectivesModule),
                component: CollectiveComponent
            },
            {
                path: 'add-collective',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/collectives/collectives.module').then(m => m.CollectivesModule),
                component: AddCollectiveComponent
            },
            {
                path: 'proposals',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/proposals/proposals.module').then(m => m.ProposalsModule),
                component: ProposalsComponent
            },
            {
                path: 'documents',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/documents/documents.module').then(m => m.DocumentsModule),
                component: DocumentsComponent
            },
            {
                path: 'companies/:id/documents/:documentId',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/documents/documents.module').then(m => m.DocumentsModule),
                component: DocumentComponent
            },
            {
                path: 'companies/:id/add-document',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/documents/documents.module').then(m => m.DocumentsModule),
                component: AddDocumentComponent
            },
            {
                path: 'campaigns',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/ads/ads.module').then(m => m.AdsModule),
                component: CampaignsComponent
            },
            {
                path: 'campaigns/:id',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/ads/ads.module').then(m => m.AdsModule),
                component: CampaignComponent
            },
            {
                path: 'campaigns/:id/ads/:adId',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/ads/ads.module').then(m => m.AdsModule),
                component: AdComponent
            },
            {
                path: 'ads',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/ads/ads.module').then(m => m.AdsModule),
                component: AdsComponent
            },
            {
                path: 'ads/:adId',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/ads/ads.module').then(m => m.AdsModule),
                component: AdComponent
            },
            {
                path: 'staff',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/staff/staff.module').then(m => m.StaffModule),
                component: StaffComponent
            },
            {
                path: 'staff/:id',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/staff/staff.module').then(m => m.StaffModule),
                component: StaffMemberComponent
            },
            {
                path: 'add-staff',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/staff/staff.module').then(m => m.StaffModule),
                component: AddStaffComponent
            },
            {
                path: 'candidates',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/candidates/candidates.module').then(m => m.CandidatesModule),
                component: CandidatesComponent
            },
            {
                path: 'candidates/:id',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/candidates/candidates.module').then(m => m.CandidatesModule),
                component: CandidateComponent
            },
            {
                path: 'contacts',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/contacts/contacts.module').then(m => m.ContactsModule),
                component: ContactsComponent
            },
            {
                path: 'contacts/:contactId',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/contacts/contacts.module').then(m => m.ContactsModule),
                component: ContactComponent
            },
            {
                path: 'add-contact',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/contacts/contacts.module').then(m => m.ContactsModule),
                component: AddContactComponent
            },
            {
                path: 'log',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/log/log.module').then(m => m.LogModule),
                component: LogComponent
            },
            {
                path: 'settings',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/settings/settings.module').then(m => m.SettingsModule),
                component: SettingsComponent
            },
            {
                path: 'jobBoards',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/job-boards/job-boards.module').then(m => m.JobBoardsModule),
                component: JobBoardPostingsComponent
            },
            {
                path: 'jobBoardSources',
                pathMatch: 'full',
                loadChildren: () => import('./modules/admin/job-boards/job-boards.module').then(m => m.JobBoardsModule),
                component: JobBoardSourcesComponent
            },
        ]
    }
];
