export class ProductRenewalTypes {

    id!: string
    name!: string
    checked: boolean = false;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    // Values
    static readonly RENEWED = new ProductRenewalTypes('1', 'Renewed');
    static readonly NOT_RENEWED = new ProductRenewalTypes('2', 'Not Renewed');

    static readonly values = [
        ProductRenewalTypes.RENEWED,
        ProductRenewalTypes.NOT_RENEWED
    ]
}