import { IdentifiableDto } from "./identifiable.dto";
import { PermissionDto } from "./permission.dto";

export class AdministratorDto extends IdentifiableDto {
    email!: string;
    name!: string;
    phone: string;
    timestampCreated!: Date;
    timestampUpdated!: Date;
    imageUrl: string;
    position: string;
    canLogin: boolean;

    permissions: PermissionDto[] = [];
}