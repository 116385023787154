import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AddMatchedLeadsProductEmailRecipientDto, CompanyDto, MatchedLeadsProductDto, MatchedLeadsProductEmailGroupDto, MatchedLeadsProductEmailRecipientDto, MatchedLeadsProductEmailSettingsDto } from 'app/models/dtos';
import { MatchedLeadsProductEmailFormatsLookup, MatchedLeadsProductEmailModesLookup } from 'app/models/lookups';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-matched-leads-product-email',
  templateUrl: './matched-leads-product-email.component.html',
  // styleUrls: ['./matched-lead-product-email.component.css']
})
export class MatchedLeadsProductEmailComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  //inputs
  @Input() company: CompanyDto;
  @Input() product: MatchedLeadsProductDto;

  //outputs
  @Output() didEditProduct: EventEmitter<MatchedLeadsProductDto> = new EventEmitter();

  //vars
  groups: MatchedLeadsProductEmailGroupDto[] = [];

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  ngOnInit() {
    this.getGroups();
  }

  //api
  getGroups() {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/email/groups`)
      .subscribe((result: MatchedLeadsProductEmailGroupDto[]) => {
        this.groups = result;
        this.viewState = ViewState.content;
      });
  }

  addGroup() {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/email/groups`, null)
      .subscribe((result: MatchedLeadsProductEmailGroupDto) => {
        this.groups.push(result);
        this.viewState = ViewState.content;
      });
  }

  didDeleteGroup(group: MatchedLeadsProductEmailGroupDto) {
    this.groups = this.groups.filter(g => g.id !== group.id);
  }
}
