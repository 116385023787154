<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<div *ngIf="viewState == viewStates.content">
<!--Matrix: Product Weights-->
<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
  <div class="text-2xl font-semibold leading-tight">Facebook Marketing Token</div>
  <div class="text-secondary text-md mt-1">
    Last updated: 
    <span *ngIf="settingsMeta.timestampFacebookMarketingUpdated != null">{{moment(settingsMeta.timestampFacebookMarketingUpdated).fromNow()}}</span>
    <span *ngIf="settingsMeta.timestampFacebookMarketingUpdated == null">N/A</span>
  </div>
  <div class="mt-4">
    The facebook marketing token allows for the programmatic administration of facebook-based advertisements in accordance with business goals. This token expires after ~60 days, so it will need to be updated periodically.
  </div>
  <mat-form-field class="w-full mt-4">
    <input type="text" matInput [(ngModel)]="settings.facebookMarketingToken" [autocomplete]="'off'" placeholder="e.g. ex84jfur...">
    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'code'"></mat-icon>
  </mat-form-field>
</fuse-card>

<button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="editSettings()"
[disabled]="!settingsIsValid()">
<mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
<span class="ml-2 mr-1">Save</span>
</button>
</div>

