import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { ContactDto } from 'app/models/dtos';
import { PermissionGroupsLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import moment from 'moment';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  // styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, public permissionsService: PermissionsService) { }


  //vars
  contacts: ContactDto[] = []
  searchText: string = '';
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addContactViewState = ViewState.content;

  async ngOnInit() {
    this.titleService.setTitle('Contacts | Admin');

    await this.permissionsService.setContent(PermissionGroupsLookup.GLOBAL, '');
    this.getContacts();
  }

  //api
  getContacts(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/contacts`)
      .subscribe((result: ContactDto[]) => {
        this.contacts = result;
        this.viewState = ViewState.content;
      });
  }

  filteredContacts(): ContactDto[] {
    const lowerSearch = this.searchText.toLowerCase();

    return this.contacts.filter(r =>
      (r.name ?? '').toLowerCase().includes(lowerSearch)
      || (r.email ?? '').toLowerCase().includes(lowerSearch)
      || (r.phone ?? '').toLowerCase().includes(lowerSearch)
      || (r.position ?? '').toLowerCase().includes(lowerSearch)
      || r.agencies.find(a => a.name.toLowerCase().includes(lowerSearch))
      || r.companies.find(c => c.name.toLowerCase().includes(lowerSearch))
    );
  }
}
