import { HiringCityDto } from "./hiringCity.dto";
import { IdentifiableDto } from "./identifiable.dto";
import { HiringStateDto, HiringZipDto } from "./lookup.dto";

export class ExternalJobPositionDto extends IdentifiableDto {
    //core
    salary!: string;
    timestampPosted!: Date;
    timestampCreated!: Date;
    timestampUpdated!: Date;
    referenceNumber!: string;
    xmlHash!: string;
    radius: number;
    city!: string;
    country!: string;
    state!: string;
    zip!: string;

    //location
    hiringState: HiringStateDto;
    hiringCity: HiringCityDto;
    hiringZip: HiringZipDto;
}