<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<div *ngIf="viewState == viewStates.content">
<!--Matrix: Product Weights-->
<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
  <div class="text-2xl font-semibold leading-tight">Matrix: Product Weights</div>
  <div class="text-secondary text-md mt-1">
    Truck Drivers USA - Expected CPL</div>
  <div class="mt-4">
    When leads match this product, this will indicate how valuable this product should be in the ranking. The
    number should be about what you would expect the CDLLife Cost Per Lead to be (NOT the client's gross CPL).
    Clients will NOT receive leads if they are below this CPL (assuming you have not altered their default lead
    weighting of 1). If you lower this number, the CPLs should lower and clients will get more leads (until the
    program is consistently maxed out).
  </div>
  <mat-form-field class="w-full mt-4">
    <input type="number" min="0" matInput [(ngModel)]="settings.matrixProductWeights" [autocomplete]="'off'" placeholder="e.g. 2.5">
    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:scale'"></mat-icon>
  </mat-form-field>
</fuse-card>

<!--Max Sends per Application-->
<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
  <div class="text-2xl font-semibold leading-tight">Max Sends per Application</div>
  <div class="text-secondary text-md mt-1">
    Truck Drivers USA - Max Sends per Application</div>
  <div class="mt-4">
    Each application that is received will be sent to a maximum of this many clients. Clients with the worst
    realtime performance (highest Priority Score) are sent the next eligible lead. A client's Priority Score is
    calculated as the current period's CPSL for the product divided multiplied by its Lead Weighting for that
    product (default=1) and then divided by the overall product weight. If this value is below 1.0, the client
    will not be allowed to get this lead.
  </div>
  <mat-form-field class="w-full mt-4">
    <input type="number" min="0" matInput [(ngModel)]="settings.maxSendsPerApplication" [autocomplete]="'off'" placeholder="e.g. 10">
    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:chevron-double-right'"></mat-icon>
  </mat-form-field>
</fuse-card>

<!--Microsite - Pocketing Spend-->
<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
  <div class="text-2xl font-semibold leading-tight">Microsite - Pocketing Spend</div>
  <div class="text-secondary text-md mt-1">Truck Drivers USA - Pocketing Spend</div>
  <div class="mt-4">
    Should we allow pocketing spend on this product line?
  </div>
  <div class="mt-4">
    <ng-switchery onLabel="Allow Pocketing Spend" [(ngModel)]="settings.pocketingSpend"
  name="Allow Pocketing Spend"></ng-switchery>
  </div>
</fuse-card>

<!--Microsite Ad Allocations-->
<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
  <div class="text-2xl font-semibold leading-tight">Microsite Ad Allocations</div>
  <div class="text-secondary text-md mt-1">Truck Drivers USA - Allocation to Facebook</div>
  <div class="mt-4">
    If a client has a $1,000 adspend at 50% allocation, then we will spend $500 on ad networks. However, you can
    tune it with this config. Instead of spending $500, you could set this config to 50%, so we would only spend
    $250. This changes it for every company on this product when the next allocation runs (every morning).

    Also, it will only affect the spend for the days it's set at that number. So if you set it at 50% for 5-days
    and then 100% for 5-days, once it got switched to 100% we do not make up for spend we did not make while it
    was 50%. We simply spend 100% each day it is now 100%.

    This does not affect any reporting, lead matrix sorting, or anything else in the platform. It will only
    affect the actual number we tell ad networks to spend.
  </div>
  <mat-form-field class="w-full mt-4">
    <input type="number" min="0" max="100" matInput [(ngModel)]="settings.percentageAllocation" [autocomplete]="'off'" placeholder="e.g. 50">
    <!-- <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:percentage'"></mat-icon> -->
    <span class="ml-4 mr-2 text-secondary">%</span>
  </mat-form-field>
</fuse-card>

<!--FB Min Spend per Campaign-->
<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
  <div class="text-2xl font-semibold leading-tight">FB Min Spend per Campaign</div>
  <div class="text-secondary text-md mt-1">
    Truck Drivers USA - FB Min Spend per Campaign</div>
  <div class="mt-4">
    You must spend at least $1 per day per adset or Facebook will generate at "Budget Too Low" error when trying to set spend. So if you add 15 adsets, you're saying that campaign must spent at least $15.
  </div>
  <mat-form-field class="w-full mt-4">
    <input type="number" min="0" matInput [(ngModel)]="settings.minDailySpendCampaign" (ngModelChange)="minDailySpendInCentsDidChange()" [autocomplete]="'off'" placeholder="e.g. 10">
    <mat-icon class="icon-size-5" matSuffix svgIcon="attach_money"></mat-icon>
  </mat-form-field>
</fuse-card>

<button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="editSettings()"
[disabled]="!settingsIsValid()">
<mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
<span class="ml-2 mr-1">Save</span>
</button>
</div>

