import { ContactDto } from "./contact.dto";
import { IdentifiableDto } from "./identifiable.dto";
import { MatchedLeadsProductEmailFormatDto, MatchedLeadsProductEmailModeDto } from "./lookup.dto";

export class MatchedLeadsProductEmailGroupDto extends IdentifiableDto {
    name: string;
    timestampCreated!: Date;
    timestampUpdated!: Date;
    emailMode: MatchedLeadsProductEmailModeDto;
    emailFormat: MatchedLeadsProductEmailFormatDto;
    batchLeads: boolean;
}