<fuse-card class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
  <!--header-->
  <div class="flex items-center justify-between">
    <div class="text-2xl font-semibold leading-tight">Proposal Period</div>
    <div class="-mr-3">
      <!-- <div class="text-primary font-bold">Date Range</div> -->
      <button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="editProposal()"
                  [disabled]="!proposalIsValid()">
                  <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
                  <span class="ml-2 mr-1">Save</span>
                </button>
    </div>
  </div>

  <!--loading-->
  <div *ngIf="viewState == viewStates.loading">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
  </div>

  <div *ngIf="viewState == viewStates.content">
    <!-- date range -->
    <!-- <mat-form-field appearance="fill" class="w-full mt-4">
      <mat-select [(value)]="selectedRange" (valueChange)="didChangeDateRange()">
        <mat-option *ngFor="let range of dateRanges" [value]="range.id">{{range.name}}</mat-option>
      </mat-select>
    </mat-form-field> -->

    <!--cpl-->
    <div class="text-center">
      <div class="text-xl font-bold">MatchedLeads</div>
      <div class="text-secondary">Estimated CPLs are generated based on historical performance over the past 30 days or
        the target CPL.</div>
    </div>
    <div class="grid grid-cols-12 gap-3">
      <div class="col-span-12 md:col-span-4">
        <mat-form-field class="w-full mt-4">
          <mat-label>Target CPL</mat-label>
            <input matInput type="number" min="0" step="0.01" [(ngModel)]="proposal.targetCpl"
              (ngModelChange)="targetCplInCentsDidChange()" [autocomplete]="'off'"
              placeholder="Target CPL">
          <mat-icon matSuffix [svgIcon]="'attach_money'"></mat-icon>
        </mat-form-field>
      </div>
      <div class="col-span-12 md:col-span-4">
        <mat-form-field class="w-full mt-4">
          <mat-label>Budget Allocation (max rec. $0.00)</mat-label>
            <input matInput type="number" min="0" step="0.01" [(ngModel)]="proposal.spend"
              (ngModelChange)="spendInCentsDidChange()" [autocomplete]="'off'"
              placeholder="Budget Allocation">
          <mat-icon matSuffix [svgIcon]="'attach_money'"></mat-icon>
        </mat-form-field>
      </div>
      <div class="col-span-12 md:col-span-4">
        <mat-form-field class="w-full mt-4">
          <mat-label>Max Leads Found</mat-label>
          <input type="number" min="0" matInput [(ngModel)]="proposal.maxLeads" [autocomplete]="'off'"
            placeholder="e.g. 10">
          <mat-icon matSuffix [svgIcon]="'manage_search'"></mat-icon>
        </mat-form-field>
      </div>

      <div class="col-span-12 md:col-span-4 mb-4">
        <div class="text-md font-bold">Estimated CPL</div>
        <div class="text-sm">
          {{estimatedCpl() | currency:'USD'}}
        </div>
      </div>
      <div class="col-span-12 md:col-span-4 mb-4">
        <div class="text-md font-bold">Max Recommended Budget</div>
        <div class="text-sm">
          {{maxRecommendedBudget() | currency:'USD'}}
        </div>
      </div>
      <div class="col-span-12 md:col-span-4 mb-4">
        <div class="text-md font-bold">Estimated No. of Leads</div>
        <div class="text-sm">
          {{estimatedNumberOfLeads() | number:'1.0-0'}}
        </div>
      </div>
    </div>

    <button mat-flat-button [color]="'primary'" class="w-full" (click)="getMaxLeads()">
      <mat-icon [svgIcon]="'calculate'"></mat-icon>
      <span class="ml-2 mr-1">Recalculate Max Leads After Criteria Change</span>
    </button>
  </div>

</fuse-card>


<!--print preview-->
<app-proposal-preview [proposal]="proposal"></app-proposal-preview>