import { CollectiveDto } from "./collective.dto";

export class EditCollectiveDto {
    name!: string;

    static fromCollective(collective: CollectiveDto) : EditCollectiveDto {
        var edit = new EditCollectiveDto();
        edit.name = collective.name;

        return edit;
    }
}