export class ProductSliceTypes {
    id!: string;
    name!: string;
    propName!: string;

    constructor(id: string, name: string, propName: string) {
        this.id = id;
        this.name = name;
        this.propName = propName;
    }

    // Values
    static readonly STATUS = new ProductSliceTypes('1', 'Status', 'productStatus');
    static readonly FUTURE_RENEWAL = new ProductSliceTypes('2', 'Future Renewal', 'futureSpendPeriod');
    static readonly SALES_REP = new ProductSliceTypes('3', 'Sales Rep', 'company');
    static readonly OPS_REP = new ProductSliceTypes('4', 'Ops Rep', 'company');

    static readonly values = [
        ProductSliceTypes.STATUS,
        ProductSliceTypes.FUTURE_RENEWAL,
        ProductSliceTypes.SALES_REP,
        ProductSliceTypes.OPS_REP,
    ]
}