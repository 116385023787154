import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CompanyDto, ContactDto } from 'app/models/dtos';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-contact-companies',
  templateUrl: './contact-companies.component.html',
  // styleUrls: ['./contact-companies.component.css']
})
export class ContactCompaniesComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  //inputs
  @Input() contact: ContactDto;

  //vars
  companies: CompanyDto[] = []
  allCompanies: CompanyDto[] = []
  searchText: string = '';

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  ngOnInit() {
    this.getCompanies();
  }

  getCompanies(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/contacts/${this.contact.id}/companies`)
      .subscribe((result: CompanyDto[]) => {
        this.companies = result;
        this.viewState = ViewState.content;
      });
  }

  filteredCompanies() : CompanyDto[] {
    return this.companies.filter(r =>
      r.name.toLowerCase().includes(this.searchText.toLowerCase())
      || (r.agency?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.salesRep?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.salesRep?.email ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.opsRep?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.opsRep?.email ?? '').toLowerCase().includes(this.searchText.toLowerCase())
    );
  }
}
