import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { BannedDomainDto } from 'app/models/dtos';
import { EditLeadGenSettingsDto } from 'app/models/dtos/editLeadGenSettings.dto';
import { LeadGenSettingsDto } from 'app/models/dtos/leadGenSettings.dto';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  // styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    
  }
}
