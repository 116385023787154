import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AgencyDto, ContactDto, EditAgencyDto } from 'app/models/dtos';
import { PermissionGroupsLookup } from 'app/models/lookups';
import { ContactCardPortraitOption } from 'app/modules/admin/cards/contact-card-portrait/contact-card-portrait.component';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agency-contacts',
  templateUrl: './agency-contacts.component.html',
  // styleUrls: ['./agency-contacts.component.css']
})
export class AgencyContactsComponent implements OnInit {
  constructor(private http: HttpClient, public permissionsService: PermissionsService) { }

  //inputs
  @Input() agency: AgencyDto;

  //outputs
  @Output() didEditAgency: EventEmitter<AgencyDto> = new EventEmitter();

  //vars
  //vars
  contacts: ContactDto[] = [];
  allContacts: ContactDto[] = [];
  searchText: string = '';
  contactsTableColumns: string[] = ['name'];
  moment = moment;
  addSearchText: string = '';
  contactToAdd: ContactDto = null;

  selectedContact: ContactDto;
  selectedRepContactId: string;
  selectedBillingContactId: string;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addViewState = ViewState.initial;
  addNewViewState = ViewState.initial;
  viewContactViewState = ViewState.initial;

  async ngOnInit() {
    await this.permissionsService.setContent(PermissionGroupsLookup.AGENCY, this.agency.id);
    this.getContacts();

    this.selectedRepContactId = this.agency.repContact?.id;
  }

  //api
  getContacts(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/agencies/${this.agency.id}/contacts`)
      .subscribe((result: ContactDto[]) => {
        this.contacts = result;
        this.viewState = ViewState.content;
      });
  }

  editAgency(): void {
    this.viewState = ViewState.loading;

    const editDto = EditAgencyDto.fromAgency(this.agency, this.selectedRepContactId);
    this.http
      .put(`${environment.services_tdusa_admin}/v1/agencies/${this.agency.id}`, editDto)
      .subscribe((result: AgencyDto) => {
        this.agency = result;
        this.viewState = ViewState.content;
        this.didEditAgency.emit(this.agency);
      });
  }

  editRepContact(): void {
    this.viewState = ViewState.loading;

    const editDto = EditAgencyDto.fromAgency(this.agency, this.selectedRepContactId);
    this.http
      .put(`${environment.services_tdusa_admin}/v1/agencies/${this.agency.id}/repContact`, editDto)
      .subscribe((result: AgencyDto) => {
        this.agency.repContact = result.repContact;
        this.viewState = ViewState.content;
      });
  }

  getAvailableContacts(): void {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/agencies/${this.agency.id}/contacts/available`)
      .subscribe((result: ContactDto[]) => {
        this.allContacts = result;
      });
  }

  removeContact(contact: ContactDto): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/agencies/${this.agency.id}/contacts/${contact.id}`)
      .subscribe(() => {
        this.contacts = this.contacts.filter(c => c.id != contact.id);
        this.viewState = ViewState.content;
      });
  }

  addContact(contact: ContactDto): void {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/agencies/${this.agency.id}/contacts/${contact.id}`, null)
      .subscribe(() => {
        this.contacts.push(contact);
        this.allContacts = this.allContacts.filter(c => c.id != contact.id);

        //reset add state
        this.contactToAdd = null;
        this.addViewState = ViewState.initial;
        this.addSearchText = "";

        this.viewState = ViewState.content;
      });
  }

  deleteContact(contact: ContactDto): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/agencies/${this.agency.id}/contacts/${contact.id}`)
      .subscribe(() => {
        this.contacts = this.contacts.filter(c => c.id != contact.id);
        this.allContacts.push(contact);
        this.searchText = '';
        this.viewState = ViewState.content;
      });
  }

  availableContacts(): ContactDto[] {
    return this.allContacts.filter(c => this.contacts.find(ec => ec.id === c.id) == null)
  }

  //search
  seachTextDidChange(text: string) {

  }

  filteredContacts(): ContactDto[] {
    return this.contacts.filter(r =>
      (r.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.email ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.phone ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.position ?? '').toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  filteredAvailableContacts(): ContactDto[] {
    return this.allContacts.filter(r =>
      (r.name ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.email ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.phone ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.position ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
    );
  }

  //contacts
  optionsForContact(): ContactCardPortraitOption[] {
    if (this.permissionsService.canManage()) {
      return [
        new ContactCardPortraitOption('1', 'Set as agency rep', false),
        new ContactCardPortraitOption('2', 'Remove as agency contact', true),
      ];
    }
    else {
      return [];
    }
  }

  optionsForRepContact(): ContactCardPortraitOption[] {
    if (this.permissionsService.canManage()) {
      return [
        new ContactCardPortraitOption('1', 'Remove as rep contact', true),
      ];
    }
    else {
      return [];
    }
  }

  didSelectContactOption(option: ContactCardPortraitOption, contact: ContactDto) {
    switch (option.id) {
      case '1':
        return this.setAgencyRepContact(contact);
      case '2':
        return this.confirmRemoveContact(contact);
      default:
        break;
    }
  }

  didSelectRepContactOption(option: ContactCardPortraitOption, contact: ContactDto) {
    switch (option.id) {
      case '1':
        return this.setAgencyRepContact(null);
      default:
        break;
    }
  }

  setAgencyRepContact(contact: ContactDto) {
    this.selectedRepContactId = contact?.id;
    this.editRepContact();
  }

  setAgencyBillingContact(contact: ContactDto) {
    this.selectedBillingContactId = contact?.id;
    this.editAgency();
  }

  confirmRemoveContact(contact: ContactDto) {
    Swal.fire({
      title: 'Remove Contact',
      confirmButtonText: 'Remove',
      icon: 'error',
      confirmButtonColor: '#EF4444',
      showCancelButton: true,

    }).then((result) => {
      if (result.value) {
        this.removeContact(contact);
      }
    });
  }

  beginAddExistingContact() {
    this.addViewState = ViewState.content;
    this.getAvailableContacts();
  }

  didCreateContact(contact: ContactDto) {
    this.contacts.push(contact);
    this.addNewViewState = this.viewStates.initial;
  }

  cancelAddNewContact() {
    this.addNewViewState = this.viewStates.initial;
  }

  didSelectViewContact(contact: ContactDto) {
    this.selectedContact = contact;
    
    //add delay for map re-render
    setTimeout(() => {
      this.viewContactViewState = this.viewStates.content;

      //scroll the job details into view
      setTimeout(() => {
        document.getElementById("contact-form").scrollIntoView({
          behavior: 'smooth'
        });
      }, 250);
    }, 500);
  }
}
