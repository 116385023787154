<fuse-card class="p-8 grid grid-cols-12">
  <div class="col-span-12">
    <div class="text-4xl mb-0"><b>Companies</b></div>
    <div>Manage all companies associated with this collective</div>
  </div>
  <div class="w-full col-span-12">
    <div *ngIf="addViewState == viewStates.initial" class="flex items-center mt-4 sm:mt-0 md:mt-4">
      <!-- Search -->
      <div class="flex-auto">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [(ngModel)]="searchText" [autocomplete]="'off'" [placeholder]="'Filter companies'">
        </mat-form-field>
      </div>
      <!-- Add company button -->
      <button class="ml-4" mat-flat-button [color]="'primary'" (click)="addViewState = viewStates.content">
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="ml-2 mr-1">Add</span>
      </button>
    </div>

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto "></mat-spinner>
    </div>

    <!--add-->
    <div *ngIf="addViewState == viewStates.content" class="border rounded p-4 mt-4 border-primary-500">
      <div class="grid grid-cols-2">
        <b class="text-primary col-span-1">Add Company</b>
        <button class="col-span-1 text-right text-warn" (click)="addViewState = viewStates.initial">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div><small>Search for a company below to add them</small></div>
      <!-- Search -->
      <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50 mt-2">
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
        <input matInput [(ngModel)]="addSearchText" [autocomplete]="'off'" [placeholder]="'Search companies to add'">
      </mat-form-field>

      <!--list-->
      <div *ngIf="companyToAdd == null" class="max-h-32 overflow-y-auto">
        <button *ngFor="let company of filteredAvailableCompanies()"
          class="flex items-center py-4 cursor-pointer border-b w-full" (click)="companyToAdd = company">
          <div class="flex flex-0 items-center justify-center w-10 h-10 rounded overflow-hidden">
            <img *ngIf="company.imageUrl" class="object-cover w-full h-full" [src]="company.imageUrl"
              alt="Contact avatar" />
            <mat-icon *ngIf="company.imageUrl == null" class="mr-3 text-primary" style="vertical-align: bottom;"
              [svgIcon]="'heroicons_solid:briefcase'"></mat-icon>
          </div>
          <div class="min-w-0 ml-2 text-left">
            <div class="font-medium leading-5 truncate">{{company.name}}</div>
            <div class="leading-5 truncate text-secondary">{{company.agency?.name}}</div>
          </div>
        </button>
      </div>


      <!--selected-->
      <div class="flex items-center mt-4 sm:mt-0 md:mt-4" *ngIf="companyToAdd != null">
        <div class="flex-auto">
          <div class="flex items-center py-4 cursor-pointer border-b">
            <div class="flex flex-0 items-center justify-center w-10 h-10 rounded overflow-hidden">
              <img *ngIf="companyToAdd.imageUrl" class="object-cover w-full h-full" [src]="companyToAdd.imageUrl"
                alt="Contact avatar" />
              <mat-icon *ngIf="companyToAdd.imageUrl == null" class="mr-3 text-primary" style="vertical-align: bottom;"
                [svgIcon]="'heroicons_solid:briefcase'"></mat-icon>
            </div>
            <div class="min-w-0 ml-2 text-left">
              <div class="font-medium leading-5 truncate">{{companyToAdd.name}}</div>
              <div class="leading-5 truncate text-secondary">{{companyToAdd.agency?.name}}</div>
            </div>
          </div>
        </div>
        <!--Clear company-->
        <button class="ml-4" mat-flat-button (click)="companyToAdd = null">
          <mat-icon [svgIcon]="'close'"></mat-icon>
        </button>
        <!-- Add company button -->
        <button class="ml-4" mat-flat-button [color]="'primary'" (click)="addCompany(companyToAdd)">
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="ml-2 mr-1">Add</span>
        </button>
      </div>
      <!-- <button class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
        [swal]="{ title: 'Delete Collective', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
        (confirm)="deleteCollective()">

        Delete
      </button> -->
    </div>

    <!-- no content -->
    <div *ngIf="viewState == viewStates.content && filteredCompanies().length === 0" class="mt-8 text-center">
      <app-generic-no-content title="No Companies Found"
        subtitle="No company associations were found for this collective. If search, please update your search criteria."
        imageUrl="https://img.icons8.com/color/256/000000/briefcase.png" class="ml-auto mr-auto mt-5">
      </app-generic-no-content>
    </div>

    <!--content-->
    <div *ngIf="viewState == viewStates.content && filteredCompanies().length > 0">
      <a *ngFor="let company of filteredCompanies()" class="flex items-center py-4 cursor-pointer border-b"
        routerLink="/companies/{{company.id}}">
        <!--Clear company-->
        <button mat-flat-button class="mr-2"
          [swal]="{ title: 'Remove Company', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
          (confirm)="deleteCompany(company)">
          <mat-icon [svgIcon]="'close'"></mat-icon>
        </button>
        <div class="flex flex-0 items-center justify-center w-10 h-10 rounded overflow-hidden">
          <img *ngIf="company.imageUrl" class="object-cover w-full h-full" [src]="company.imageUrl"
            alt="Contact avatar" />
          <mat-icon *ngIf="company.imageUrl == null" class="mr-3 text-primary" style="vertical-align: bottom;"
            [svgIcon]="'heroicons_solid:briefcase'"></mat-icon>

        </div>
        <div class="min-w-0 ml-4">
          <div class="font-medium leading-5 truncate">{{company.name}}</div>
          <div class="leading-5 truncate text-secondary">{{company.agency?.name}}</div>
        </div>
      </a>
    </div>
  </div>

</fuse-card>