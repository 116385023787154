import { Component, Input, OnInit } from '@angular/core';
import { ContactDto } from 'app/models/dtos';

@Component({
  selector: 'app-contact-card-landscape',
  templateUrl: './contact-card-landscape.component.html',
  // styleUrls: ['./contact-card-landscape.component.css']
})
export class ContactCardLandscapeComponent implements OnInit {
  constructor() { }

  //input
  @Input() contact: ContactDto;

  ngOnInit() {
  }

}
