import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ViewState } from 'app/models/app';
import { JobPostingSourceDto } from 'app/models/dtos';
import { JobBoardTypesLookup, PermissionGroupsLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import moment from 'moment';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-job-board-sources',
  templateUrl: './job-board-sources.component.html',
  // styleUrls: ['./job-board-sources.component.css']
})
export class JobBoardSourcesComponent implements OnInit {
  constructor(private http: HttpClient, private titleService: Title, public permissionsService: PermissionsService) { }

  //vars
  jobSources: JobPostingSourceDto[] = [];
  searchText: string = '';
  moment = moment;
  breadcrumbs: GenericBreadcrumb[] = [
    new GenericBreadcrumb('Job Boards', '/jobBoards')
  ];
  selectedSource: JobPostingSourceDto;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  selectedViewState = ViewState.content;

  //types
  feedTypes: JobBoardTypesLookup[] = structuredClone(JobBoardTypesLookup.availableValues);
  rawFeedTypes = JobBoardTypesLookup;

  async ngOnInit() {
    this.titleService.setTitle('Job Board Sources | Admin');

    await this.permissionsService.setContent(PermissionGroupsLookup.GLOBAL, '');
    this.getJobBoardSources();
  }

  //api
  getJobBoardSources(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/jobBoardSources`)
      .subscribe((result: JobPostingSourceDto[]) => {
        this.jobSources = result;
        this.viewState = ViewState.content;
      });
  }

  addSource(source: JobPostingSourceDto) {
    this.selectedSource = source;
    this.selectedViewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/jobBoardSources`, source)
      .subscribe((result: JobPostingSourceDto) => {
        source.id = result.id;
        this.selectedViewState = ViewState.content;
      });
  }

  editSource(source: JobPostingSourceDto) {
    this.selectedSource = source;
    this.selectedViewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/jobBoardSources/${source.id}`, source)
      .subscribe((result: JobPostingSourceDto) => {
        this.selectedViewState = ViewState.content;
      });
  }

  deleteSource(source: JobPostingSourceDto) {
    this.selectedSource = source;
    this.selectedViewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/jobBoardSources/${source.id}`)
      .subscribe((result: JobPostingSourceDto) => {
        this.jobSources = this.jobSources.filter(s => s.id != source.id);
      });
  }

  filteredJobSources() : JobPostingSourceDto[] {
    //return early if no search
    if((this.searchText ?? '').trim().length == 0) { return this.jobSources; }

    const q = this.searchText.toLowerCase();
    return this.jobSources.filter(r =>
      r.name.toLowerCase().includes(q)
      || (r.jobPostingType?.name ?? '').toLowerCase().includes(q)
    )
  }

  isValidSource(source: JobPostingSourceDto) : boolean {
    return (source.name ?? '').length > 0
    && (source.feedUrl ?? '').length > 0
  }

  didClickAddSource() {
    //early return so as to only ever present 1 new box
    if (this.jobSources.length > 0) {
      if (this.jobSources[0].id == '') {
        return;
      }
    }

    //create new source
    const newSource = new JobPostingSourceDto();
    newSource.id = '';
    newSource.name = '';
    newSource.feedUrl = '';
    newSource.jobPostingType = JobBoardTypesLookup.availableValues[0];

    this.jobSources.splice(0, 0, newSource);
  }

  cancelAddSource() {
    this.jobSources = this.jobSources.filter(s => s.id.length > 0);
  }
}
