import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AddCompanyDto, AdministratorDto, AgencyDto, CollectiveDto, CompanyDto, EditCompanyDto, FileResponseDto } from 'app/models/dtos';
import { GenericBreadcrumb } from 'app/modules/admin/common/generic-breadcrumbs/genericBreadcrumb';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  // styleUrls: ['./add-company.component.css']
})
export class AddCompanyComponent implements OnInit {
  constructor(private http: HttpClient, private titleService: Title, private _router: Router) { }

  //outputs
  @Output() didAddCompany: EventEmitter<CompanyDto> = new EventEmitter();

  //vars
  company: AddCompanyDto = new AddCompanyDto();
  agencies: AgencyDto[] = [];
  filteredAgencies: AgencyDto[] = [];
  administrators: AdministratorDto[] = [];
  collectives: CollectiveDto[] = [];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;

  //file upload
  @ViewChild('image') imageElement: ElementRef;

  ngOnInit() {
    this.titleService.setTitle(`Add Company | Admin`);

    this.getAgencies();
    this.getReps();
    this.getCollectives();
  }

  //api
  getAgencies(): void {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/agencies`)
      .subscribe((result: AgencyDto[]) => {
        this.agencies = result;
        this.filteredAgencies = this.agencies;
      });
  }

  getReps() {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/administrators`)
      .subscribe((result: AdministratorDto[]) => {
        this.administrators = result;
      });
  }

  getCollectives(): void {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/collectives`)
      .subscribe((result: CollectiveDto[]) => {
        this.collectives = result;
      });
  }

  addCompany(): void {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies`, this.company)
      .subscribe((result: CompanyDto) => {
        this._router.navigateByUrl(`/companies/${result.id}`);
      });
  }

  async uploadFile(file: File) : Promise<FileResponseDto> {
    //package form data
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    //upload
    return (await firstValueFrom(this.http.post(`${environment.services_tdusa_admin}/v1/files`, formData))) as FileResponseDto;
  }

  async editImage(e: Event) {
    //get file
    const file: File = (<HTMLInputElement>e.target).files.item(0);

    //upload
    this.viewState = ViewState.loading;
    const uploadedFile = await this.uploadFile(file);
    this.company.imageUrl = uploadedFile.src;
    this.viewState = ViewState.content;
  }

  //validations
  companyIsValid() : Boolean {
    return this.isValidString(this.company.name);
  }

  isValidString(test: string) : Boolean {
    if(test == null) { return false; }
    return test.trim() != '';
  }
}