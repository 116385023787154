import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AdministratorDto } from 'app/models/dtos';
import { PermissionGroupsLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import moment from 'moment';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  // styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, public permissionsService: PermissionsService) { }

  //view children
  @ViewChild('administratorsTable', { read: MatSort, static: false }) administratorsTableMatSort: MatSort;

  //vars
  administrators: AdministratorDto[] = []
  filteredAdministrators: AdministratorDto[] = []
  searchText: string = '';
  administratorsDataSource: MatTableDataSource<AdministratorDto> = new MatTableDataSource();
  administratorsTableColumns: string[] = ['name', 'email', 'phone'];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addAdministratorViewState = ViewState.content;

  async ngOnInit() {
    this.titleService.setTitle('Administrators | Admin');

    await this.permissionsService.setContent(PermissionGroupsLookup.GLOBAL, '');
    this.getAdministrators();
  }

  //api
  getAdministrators(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/administrators`)
      .subscribe((result: AdministratorDto[]) => {
        this.administrators = result;
        this.filteredAdministrators = this.administrators;
        this.viewState = ViewState.content;
        this.administratorsDataSource = new MatTableDataSource(this.filteredAdministrators);
        this.updateSorting(this.filteredAdministrators);
      });
  }

  seachTextDidChange(text: string) {
    this.filteredAdministrators = this.administrators.filter(r =>
      r.name.toLowerCase().includes(text.toLowerCase())
      || (r.email ?? '').toLowerCase().includes(text.toLowerCase())
      || (r.phone ?? '').toLowerCase().includes(text.toLowerCase())
    )
    this.updateSorting(this.filteredAdministrators);
  }

  updateSorting(administrators: AdministratorDto[]) {
    this.administratorsDataSource = new MatTableDataSource(administrators);
    this.administratorsDataSource.sort = this.administratorsTableMatSort;
    this.administratorsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
      return item[property];
   };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
