export class SystemServiceTypes {

    id!: string
    name!: string;
    icon: string;
    status!: string;
    isWorkingProperly: boolean = false;
    link: string;
    successText: string;
    failureText: string;

    constructor(id: string, name: string, icon: string, link: string, successText: string, failureText: string) {
        this.id = id;
        this.name = name;
        this.icon = icon;
        this.link = link;
        this.successText = successText;
        this.failureText = failureText;
    }

    // Values
    static readonly FB_TOKEN = new SystemServiceTypes('1', 'Sent Leads', 'facebook', '/settings', 'The Facebook Marketing token is valid and connecting to Facebook.', 'The Facebook Marketing token is likely expired. Please investigate via the system log or click here to go to the settings and update the token.');
    static readonly SET_SPEND = new SystemServiceTypes('2', 'Set Daily Spend', 'monetization_on', '/log', 'Daily spend ran successfully today.', 'There was an error in the daily Facebook automated spend. Click here to investigate via the system log.');
    static readonly LEAD_SEND = new SystemServiceTypes('3', 'Lead Sending', 'send', '/log', 'Lead sending is operating normally.', 'Lead sending has stopped. Contact a TDUSA developer for investigation.');
    static readonly SUSPISCIOUS_APPS = new SystemServiceTypes('5', 'Suspiscious Applications', 'dangerous', '/leads', 'There are no suspicious applications to review at this time.', 'There are suspiscious apps to review. Click here to visit the leads tab and review.');
    static readonly TENSTREET_JOBS = new SystemServiceTypes('6', 'Tenstreet Jobs', 'play_circle_filled', '/jobBoards', 'There are no new unworked Tenstreet Job Postings at this time.', 'There are new, unworked Tenstreet Job Postings for you to review.');
    // static readonly MATCHES = new ApplicationViewTypes('6', 'Matches');

    static readonly values = [
        SystemServiceTypes.FB_TOKEN,
        SystemServiceTypes.SET_SPEND,
        SystemServiceTypes.LEAD_SEND,
        SystemServiceTypes.SUSPISCIOUS_APPS,
        SystemServiceTypes.TENSTREET_JOBS
    ];
}