<!--list-->
<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<!-- no content -->
<div *ngIf="viewState == viewStates.content && filteredLeads.length === 0" class="mt-0">
  <app-generic-no-content title="No Leads Found"
    subtitle="No leads were found please refresh or update your search criteria."
    imageUrl="https://img.icons8.com/color/256/000000/clipboard.png" class="ml-auto mr-auto mt-5">
  </app-generic-no-content>
</div>

<!-- content -->
<div [hidden]="viewState != viewStates.content || filteredLeads.length === 0" class="w-full mt-0">
  <fuse-card class="w-full p-4">
    <div class="w-full overflow-x-auto mx-6">
      <table class="w-full bg-transparent" mat-table matSort [dataSource]="leadsDataSource" [trackBy]="trackByFn"
        #leadsTable>

        <!-- company -->
        <ng-container matColumnDef="company">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Company
          </th>
          <td mat-cell *matCellDef="let lead">
            <img src="{{lead.company.imageUrl}}" class="w-4 h-4 rounded inline-block mr-2" style="vertical-align: middle;" />
            <span class="whitespace-nowrap">
              <a routerLink="/companies/{{lead.company.id}}">{{lead.company.name}}</a>
            </span>
          </td>
        </ng-container>

        <!-- timestamp -->
        <ng-container matColumnDef="created">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Created
          </th>
          <td mat-cell *matCellDef="let lead">
            <span class="whitespace-nowrap">
              {{moment(lead.timestampCreated).format('lll')}}
            </span>
          </td>
        </ng-container>

        <!-- type -->
        <ng-container matColumnDef="type">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Type
          </th>
          <td mat-cell *matCellDef="let lead">
            <span class="whitespace-nowrap">
              {{lead.leadType.name}}
            </span>
          </td>
        </ng-container>

        <!-- sends -->
        <ng-container matColumnDef="sends">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Sends
          </th>
          <td mat-cell *matCellDef="let lead">
            <span class="whitespace-nowrap">
              <img *ngFor="let send of lead.sends" class="w-8 h-8 rounded object-contain inline-block" [src]="send.service.imageUrl"
                    alt="Card cover image" [matTooltip]="send.service.name">
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="leadsTableColumns"></tr>
        <tr class="order-row h-16" mat-row *matRowDef="let row; columns: leadsTableColumns;"></tr>
        <!-- <tr
              class="h-16 border-0"
              mat-footer-row
              *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
      </table>
    </div>
  </fuse-card>
</div>