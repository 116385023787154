<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [breadcrumbs]="breadcrumbs" [terminal]="ad?.name ?? ''"
    fallbackIcon="heroicons_solid:newspaper"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <div *ngIf="viewState == viewStates.content">
      
    </div>
  </div>
</div>