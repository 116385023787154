import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CompanyDto, EditExternalJobDto, ExternalJobDto, MatchedLeadsProductDto } from 'app/models/dtos';
import { PermissionGroupsLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  // styleUrls: ['./job.component.css']
})
export class JobComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  public companyId: string = '0';
  public productId: string = '0';
  public jobId: string = '0';

  company: CompanyDto;
  product: MatchedLeadsProductDto;
  job: ExternalJobDto;
  breadcrumbs() : GenericBreadcrumb[] {
    return [
      new GenericBreadcrumb('Companies', '/companies'),
      new GenericBreadcrumb(`${this.company?.name ?? 'Company'}`, `/companies/${this.companyId}`),
      new GenericBreadcrumb(`${this.product?.name ?? 'Product'}`, `/companies/${this.companyId}/matchedLeadsProducts/${this.productId}`)
    ];
  }
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  infoViewState = ViewState.loading;

  async ngOnInit() {
    //update title
    this.titleService.setTitle('Job | Admin');

    //parse query params
    this.companyId = this.route.snapshot.paramMap.get('id') ?? '0';
    this.productId = this.route.snapshot.paramMap.get('productId') ?? '0';
    this.jobId = this.route.snapshot.paramMap.get('jobId') ?? '0';

    await this.permissionsService.setContent(PermissionGroupsLookup.COMPANY, this.companyId);
    this.getCompany();
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | Admin`);
  }

  //api
  getCompany(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}`)
      .subscribe((result: CompanyDto) => {
        this.company = result;
        this.getProduct();
      });
  }

  getProduct() {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}`)
      .subscribe((result: MatchedLeadsProductDto) => {
        this.product = result;
        this.getJob();
      });
  }

  getJob(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}/jobs/${this.jobId}`)
      .subscribe((result: ExternalJobDto) => {
        this.job = result;
        this.infoViewState = ViewState.content;
        this.viewState = ViewState.content;
        this.setTitle(this.job.title);
      });
  }

  editJob(): void {
    this.infoViewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}/jobs/${this.jobId}`, EditExternalJobDto.fromJob(this.job))
      .subscribe((result: ExternalJobDto) => {
        this.job = result;
        this.infoViewState = ViewState.content;
        this.setTitle(this.job.title);
      });
  }

  deleteJob(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}/jobs/${this.jobId}`)
      .subscribe(() => {
        this._router.navigateByUrl('/jobs')
      });
  }

}
