<!--filter-->
<div class="flex items-center mt-4">
  <!-- Search -->
  <div class="flex-auto">
    <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
      <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
      <input matInput [(ngModel)]="searchText" [autocomplete]="'off'"
         placeholder="Filter leads...">
    </mat-form-field>
  </div>
</div>

<fuse-card class="w-full p-4 mt-5" style="max-height: 780px;">
  <!--loading-->
  <div *ngIf="viewState == viewStates.loading" class="w-full">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
  </div>

  <!-- no content -->
  <div *ngIf="viewState == viewStates.content && filteredApplications().length === 0" class="p-4 w-full">
    <app-generic-no-content title="No Applications Found"
      subtitle="No applications were found please refresh or update your search criteria."
      imageUrl="https://img.icons8.com/color/256/000000/clipboard.png" class="ml-auto mr-auto mt-5">
    </app-generic-no-content>
  </div>

  <!--content-->
  <div
    class="w-full overflow-x-auto mx-6">
    <cdk-virtual-scroll-viewport tvsItemSize class="wrapper mat-elevation-z2">
      <table class="w-full bg-transparent" mat-table matSort [dataSource]="applicationsDataSource"
        [trackBy]="trackByFn" #applicationsTable>

         <!--status-->
         <ng-container matColumnDef="status">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Status
          </th>
          <td mat-cell *matCellDef="let application">
            <mat-icon *ngIf="application.blocked" class="text-sm text-warn" style="vertical-align: middle;" svgIcon="heroicons_solid:x-circle"></mat-icon>
            <mat-icon *ngIf="!application.blocked" class="text-sm text-green-600" style="vertical-align: middle;" svgIcon="heroicons_solid:check-circle"></mat-icon>
          </td>
        </ng-container>

        <!--id-->
        <ng-container matColumnDef="id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            #
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              #{{application.id}}
            </small>
          </td>
        </ng-container>

        <!-- timestamp -->
        <ng-container matColumnDef="created">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Created
          </th>
          <td mat-cell *matCellDef="let application">
            <a routerLink="/leads/{{application.id}}"
              class="pr-6 font-bold text-sm text-secondary whitespace-nowrap">
              <span class="pr-6 whitespace-nowrap">
                {{moment(application.timestampCreated).format('lll')}}
              </span>
            </a>
          </td>
        </ng-container>

        <!-- First -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            First
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{application.firstName}}
            </small>
          </td>
        </ng-container>

        <!-- Last -->
        <ng-container matColumnDef="lastName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Last
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{application.lastName}}
            </small>
          </td>
        </ng-container>

        <!--email-->
        <ng-container matColumnDef="email">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Email
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 font-medium whitespace-nowrap">
              {{application.email}}
            </small>
          </td>
        </ng-container>

        <!--phone-->
        <ng-container matColumnDef="phone">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Phone
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{application.phone}}
            </small>
          </td>
        </ng-container>

        <!-- DOB -->
        <ng-container matColumnDef="dob">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            DOB
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 font-medium whitespace-nowrap">
              {{application.dobYear}}-{{application.dobMonth}}-{{application.dobDay}}
            </small>
          </td>
        </ng-container>

        <!--city-->
        <ng-container matColumnDef="city">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            City
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{application.hiringZip.city}}
            </small>
          </td>
        </ng-container>

        <!--zip-->
        <ng-container matColumnDef="zip">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Zip
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{application.hiringZip.name}}
            </small>
          </td>
        </ng-container>

        <!--state-->
        <ng-container matColumnDef="state">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            State
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{application.hiringState.abbreviation}}
            </small>
          </td>
        </ng-container>

        <!-- CDL -->
        <ng-container matColumnDef="cdl">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            CDL
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{serializedLookups(application.cdlClasses)}}
            </small>
          </td>
        </ng-container>

        <!--experience-->
        <ng-container matColumnDef="experience">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Experience
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{application.experienceType.name}}
            </small>
          </td>
        </ng-container>

        <!--accidents-->
        <ng-container matColumnDef="accidents">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Acc.
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{application.accidents}}
            </small>
          </td>
        </ng-container>

        <!--violations-->
        <ng-container matColumnDef="violations">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Viol.
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{application.violations}}
            </small>
          </td>
        </ng-container>

        <!--endorsements-->
        <ng-container matColumnDef="endorsements">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Endorsements
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="whitespace-nowrap"
              *ngFor="let endorsement of application.endorsements; let i = index">
              <span *ngIf="i != 0">, </span>
              <span [matTooltip]="endorsement.name">{{endorsement.abbreviation}}</span>
            </small>
          </td>
        </ng-container>

        <!--driver type-->
        <ng-container matColumnDef="driverType">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Driver Type
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{application.driverType.name}}
            </small>
          </td>
        </ng-container>

        <!--Interest OO or LP-->
        <ng-container matColumnDef="interestOOorLP">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            InterestOOorLP
          </th>
          <td mat-cell *matCellDef="let application">
            <small *ngIf="application.interestInOoLp != null" class="pr-6 whitespace-nowrap">
              <span *ngIf="application.interestInOoLp">YES</span>
              <span *ngIf="!application.interestInOoLp">NO</span>
            </small>
          </td>
        </ng-container>

        <!--Power unit or own authority-->
        <ng-container matColumnDef="hasOwnAuthority">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Power Unit or Own Auth
          </th>
          <td mat-cell *matCellDef="let application">
            <small *ngIf="application.hasOwnAuthority != null" class="pr-6 whitespace-nowrap">
              <span *ngIf="application.hasOwnAuthority">Own Authority</span>
              <span *ngIf="!application.hasOwnAuthority">Power Unit</span>
            </small>
          </td>
        </ng-container>

        <!--solo or team-->
        <ng-container matColumnDef="teamDriver">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Solo or Team
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              <span *ngIf="application.teamDriver">Team</span>
              <span *ngIf="!application.teamDriver">Solo</span>
            </small>
          </td>
        </ng-container>

        <!--Interest in team-->
        <ng-container matColumnDef="interestInTeamDriving">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Interest Team Driver
          </th>
          <td mat-cell *matCellDef="let application">
            <small *ngIf="application.interestInTeamDriving != null" class="pr-6 whitespace-nowrap">
              <span *ngIf="application.interestInTeamDriving">YES</span>
              <span *ngIf="!application.interestInTeamDriving">NO</span>
            </small>
          </td>
        </ng-container>

        <!--freight-->
        <ng-container matColumnDef="freight">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Freight
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{application.freightType.name}}
            </small>
          </td>
        </ng-container>

        <!-- interested freight -->
        <ng-container matColumnDef="interestedFreightTypes">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Interested Freight
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{serializedLookups(application.interestedFreightTypes)}}
            </small>
          </td>
        </ng-container>

        <!--military-->
        <ng-container matColumnDef="militaryExperience">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Military Exp
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              <span *ngIf="application.militaryExperience">YES</span>
              <span *ngIf="!application.militaryExperience">NO</span>
            </small>
          </td>
        </ng-container>

        <!--contact time-->
        <ng-container matColumnDef="applicationContactTimeId">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Best Contact Time
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{application.applicationContactTime.name}}
            </small>
          </td>
        </ng-container>

        <!--matched companies-->
        <ng-container matColumnDef="matchedCompanies">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Matched Companies
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="whitespace-nowrap" *ngFor="let lead of application.leads; let i = index">
              <a routerLink="/companies/{{lead.company.id}}" class="mr-3" [matTooltip]="lead.company.name">
                <img class="inline rounded" style="width: 24px;" *ngIf="lead.company.imageUrl != null"
                  [src]="lead.company.imageUrl" />
                <mat-icon *ngIf="lead.company.imageUrl == null" class="text-primary"
                  style="vertical-align: bottom;" [svgIcon]="'heroicons_solid:briefcase'"></mat-icon>
              </a>
            </small>
          </td>
        </ng-container>

        <!--utm source-->
        <ng-container matColumnDef="utmSource">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            UTM Source
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{application.utmSource}}
            </small>
          </td>
        </ng-container>

        <!--utm medium-->
        <ng-container matColumnDef="utmMedium">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            UTM Medium
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{application.utmMedium}}
            </small>
          </td>
        </ng-container>

        <!--utm source-->
        <ng-container matColumnDef="utmCampaign">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            UTM Campaign
          </th>
          <td mat-cell *matCellDef="let application">
            <small class="pr-6 whitespace-nowrap">
              {{application.utmCampaign}}
            </small>
          </td>
        </ng-container>

        <!-- Footer -->
        <!-- <ng-container matColumnDef="recentOrdersTableFooter">
            <td
                class="py-6 px-0 border-0"
                mat-footer-cell
                *matFooterCellDef
                colspan="6">
                <button mat-stroked-button>See all applications</button>
            </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="applicationsTableColumns; sticky: true" class="bg-white"></tr>
        <tr class="order-row h-16" mat-row *matRowDef="let row; columns: applicationsTableColumns;"></tr>
        <!-- <tr
            class="h-16 border-0"
            mat-footer-row
            *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
</fuse-card>