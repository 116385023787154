<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="Add Staff"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <fuse-card *ngIf="viewState == viewStates.content" class="flex flex-col max-w-xl ml-auto mr-auto filter-article pt-5 pb-5 pl-8 pr-8">


      <div>
        <div class="text-small text-center text-secondary mb-4">
          <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
          </mat-icon>
          <b>Staff Info</b>
        </div>
        <div class="form-group">
          <input type="file" style="display: none;" id="resource-image" accept="image/*" (change)="editImage($event)"
            #image>
        </div>
        <img *ngIf="staff?.imageUrl == null" class="object-contain w-32 h-32 ml-auto mr-auto cursor-pointer"
          src="https://img.icons8.com/color/256/000000/add-image.png" (click)="imageElement.nativeElement.click()" />
        <img *ngIf="staff?.imageUrl != null"
          class="object-cover rounded w-32 h-32 ml-auto mr-auto border rounded-full cursor-pointer"
          src="{{staff.imageUrl}}" alt="Card cover image" (click)="imageElement.nativeElement.click()">

        <div class="text-center text-small text-secondary mt-2">Tap image to change</div>
        <div>
          <div class="flex-auto">
            <!--name-->
            <mat-form-field class="w-full">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="staff.name" [autocomplete]="'off'" placeholder="Staff Name">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </mat-form-field>
            <!--email-->
            <mat-form-field class="w-full">
              <mat-label>Email</mat-label>
              <input type="email" matInput [(ngModel)]="staff.email" [autocomplete]="'off'"
                placeholder="Staff Email">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'alternate_email'"></mat-icon>
            </mat-form-field>
            <!--phone-->
            <mat-form-field class="w-full">
              <mat-label>Phone</mat-label>
              <input matInput [(ngModel)]="staff.phone" [autocomplete]="'off'" placeholder="Staff Phone">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
            </mat-form-field>
            <!--position-->
            <mat-form-field class="w-full">
              <mat-label>Position</mat-label>
              <input matInput [(ngModel)]="staff.position" [autocomplete]="'off'" placeholder="Staff Position">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:briefcase'"></mat-icon>
            </mat-form-field>
          </div>

        </div>
      </div>

      <button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="addStaff()"
        [disabled]="!staffIsValid()">
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="ml-2 mr-1">Add</span>
      </button>
    </fuse-card>


  </div>
</div>