<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [breadcrumbs]="breadcrumbs" [terminal]="campaign?.name ?? ''" fallbackIcon="campaign">
  </app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <div *ngIf="viewState == viewStates.content">
      <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12">
          <div class="relative flex flex-col flex-auto p-6 pr-3 pb-3 bg-card rounded-2xl shadow overflow-hidden">
            <div class="absolute bottom-0 right-0 w-24 h-24 -m-6">
              <mat-icon class="icon-size-24 opacity-25 text-green-500 dark:text-green-400"
                [svgIcon]="'heroicons_outline:currency-dollar'"></mat-icon>
            </div>
            <div class="flex items-center">
              <div class="flex flex-col">
                <div class="text-lg font-medium tracking-tight leading-6 truncate"
                  style="position: absolute; top: 32px; left: 24px;">Campaign Summary</div>
                <!-- <div class="text-green-600 font-medium text-sm">
                  Status: Active
                </div> -->
              </div>
              <div class="ml-auto -mt-2 flex flex-row">
                <!-- date range -->
                <div>
                  <mat-form-field appearance="fill" style="min-width: 150px;">
                    <mat-select [(value)]="selectedRange" (valueChange)="getAds()">
                      <mat-option *ngFor="let range of dateRanges" [value]="range.id">{{range.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="selectedRange === dateRangesRaw.CUSTOM.id" class="ml-2">
                  <mat-form-field appearance="fill" class="w-full" style="min-width: 200px;">
                    <mat-date-range-input [rangePicker]="picker">
                      <input matStartDate placeholder="Start date" [(ngModel)]="customRange.startDate">
                      <input matEndDate placeholder="End date" [(ngModel)]="customRange.endDate"
                        (ngModelChange)="customRangeDatePickerDidChange()">
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
                </div>


                <!-- <button
                        mat-icon-button
                        [matMenuTriggerFor]="previousStatementMenu">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:dots-vertical'"></mat-icon>
                    </button>
                    <mat-menu #previousStatementMenu="matMenu">
                        <button mat-menu-item>
                            <span class="flex items-center">
                                <mat-icon
                                    class="icon-size-5 mr-3"
                                    [svgIcon]="'heroicons_solid:credit-card'"></mat-icon>
                                <span>View statement</span>
                            </span>
                        </button>
                        <button mat-menu-item>
                            <span class="flex items-center">
                                <mat-icon
                                    class="icon-size-5 mr-3"
                                    [svgIcon]="'heroicons_solid:cash'"></mat-icon>
                                <span>Spending breakdown</span>
                            </span>
                        </button>
                        
                    </mat-menu> -->
              </div>
            </div>
            <!--loading-->
            <div *ngIf="adsViewState == viewStates.loading">
              <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
            </div>
            <div *ngIf="adsViewState == viewStates.content" class="flex flex-row flex-wrap mt-4 -mx-6">
              <div class="flex flex-col mx-6 my-3">
                <div class="text-sm font-medium leading-none text-secondary">Total Spent</div>
                <div class="mt-2 font-medium text-3xl leading-none">{{totalSpent() | currency:'USD'}}</div>
              </div>
              <div class="flex flex-col mx-6 my-3">
                <div class="text-sm font-medium leading-none text-secondary">Daily Spend Scheduled</div>
                <div class="mt-2 font-medium text-3xl leading-none">{{dailySpendScheduled() | currency:'USD'}}</div>
              </div>
              <div class="flex flex-col mx-6 my-3">
                <div class="text-sm font-medium leading-none text-secondary">Next 30-Day Spend</div>
                <div class="mt-2 font-medium text-3xl leading-none">{{ (dailySpendScheduled()*30) | currency:'USD'}}
                </div>
              </div>
              <div class="flex flex-col mx-6 my-3">
                <div class="text-sm font-medium leading-none text-secondary">Gross Pocketed Spend</div>
                <div class="mt-2 font-medium text-3xl leading-none">{{(campaign.grossPocketedSpend/100) | currency:'USD'}}</div>
              </div>
              <div class="flex flex-col mx-6 my-3">
                <div class="text-sm font-medium leading-none text-secondary">Add Backs</div>
                <div class="mt-2 font-medium text-3xl leading-none">{{(campaign.addBacks/100) | currency:'USD'}}</div>
              </div>
              <div class="flex flex-col mx-6 my-3">
                <div class="text-sm font-medium leading-none text-secondary">Net Pocketed Spend</div>
                <div class="mt-2 font-medium text-3xl leading-none">{{((campaign.grossPocketedSpend-campaign.addBacks)/100) | currency:'USD'}}</div>
              </div>
            </div>

          </div>


          <!-- Total Spent (in date range above): $18,331.38

Total Daily FB Spend Scheduled: $1,285.10

Projected Next 30-Day FB Spend: $38,553

Gross Pocketed Spend: $13,449.53

Add Backs: $1,393.31

Net Pocketed Spend:  -->
        </div>

        <!-- charts -->
        <div class="col-span-12 grid grid-cols-12 w-full gap-3 bg-card shadow rounded">
          <div class="col-span-12 sm:col-span-6 lg:col-span-7">
            <div *ngIf="chartAppsOverTime != null" class="flex flex-col flex-auto rounded-2xl overflow-hidden p-6"
              style="min-height: 450px;">
              <div class="flex items-start justify-between">
                <div class="text-lg font-medium tracking-tight leading-6 truncate">
                  {{applicationViewTypeFromId(selectedViewType).name}} Over Time</div>
              </div>
              <div class="flex flex-col flex-auto mt-6 h-44">
                <apx-chart class="flex flex-auto items-center justify-center w-full h-full"
                  [chart]="chartAppsOverTime.chart" [colors]="chartAppsOverTime.colors"
                  [labels]="chartAppsOverTime.labels" [plotOptions]="chartAppsOverTime.plotOptions"
                  [series]="chartAppsOverTime.series" [states]="chartAppsOverTime.states"
                  [tooltip]="chartAppsOverTime.tooltip" [xaxis]="chartAppsOverTime.xaxis"></apx-chart>
              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-5">
            <div *ngIf="chartUtmSources != null" class="flex flex-col flex-auto rounded-2xl overflow-hidden p-6"
              style="min-height: 450px;">
              <div class="flex items-start justify-between">
                <div class="text-lg font-medium tracking-tight leading-6 truncate">Total Percentage</div>
                <div class="ml-2">
                  <button class="h-6 min-h-6 px-2 rounded-full bg-hover" mat-button
                    [matMenuTriggerFor]="conversionMenu">
                    <span class="font-medium text-sm text-secondary">{{selectedSliceType.name}}</span>
                  </button>
                  <mat-menu #conversionMenu="matMenu">
                    <button mat-menu-item *ngFor="let type of applicationSliceTypes" (click)="selectSliceType(type)">
                      <span *ngIf="type.id == selectedSliceType.id">
                        <mat-icon>check</mat-icon>
                      </span>
                      <span>{{type.name}}</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
              <div class="flex flex-col flex-auto mt-6 h-44">
                <apx-chart class="flex flex-auto items-center justify-center w-full h-full"
                  [chart]="chartUtmSources.chart" [colors]="chartUtmSources.colors" [labels]="chartUtmSources.labels"
                  [plotOptions]="chartUtmSources.plotOptions" [series]="chartUtmSources.series"
                  [states]="chartUtmSources.states" [tooltip]="chartUtmSources.tooltip"></apx-chart>
              </div>
            </div>
          </div>
        </div>

        <!--info-->
        <div class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 mb-4">
          <!--basic info-->
          <fuse-card class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
            <!--loading-->
            <div *ngIf="infoViewState == viewStates.loading">
              <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
            </div>

            <div *ngIf="infoViewState == viewStates.content">
              <div class="text-small text-center text-secondary mb-4">
                <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix
                  [svgIcon]="'heroicons_solid:pencil-alt'">
                </mat-icon>
                <b>Campaign Info</b>
              </div>

              <div>
                <div class="flex-auto">
                  <!--name-->
                  <mat-form-field class="w-full">
                    <mat-label>Name</mat-label>
                    <input matInput [(ngModel)]="campaign.name" [autocomplete]="'off'" placeholder="Campaign Name"
                      [disabled]="campaign.locked">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                  </mat-form-field>
                  <!--external ID-->
                  <mat-form-field class="w-full">
                    <mat-label>External ID</mat-label>
                    <input matInput [(ngModel)]="campaign.externalId" [autocomplete]="'off'" placeholder="External ID"
                      [disabled]="campaign.locked">
                    <a matSuffix href="{{campaign.externalId}}" target="_blank">
                      <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:external-link'"></mat-icon>
                    </a>

                  </mat-form-field>
                </div>
                <button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="editCampaign()"
                  [disabled]="campaign.locked">
                  <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
                  <span class="ml-2 mr-1">Save</span>
                </button>
                <div class="mt-4 text-small text-center text-secondary">Updated
                  {{moment(campaign.timestampUpdated).fromNow()}}</div>
              </div>

              <!--delete-->
              <div class="border rounded p-3 mt-4 border-rose-500">
                <div class="text-warn"><b>Danger Zone</b></div>
                <div><small>These actions are destructive. Proceed with care.</small></div>
                <button class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
                  [swal]="{ title: 'Delete Campaign', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
                  (confirm)="deleteCampaign()" [disabled]="campaign.locked">

                  Delete
                </button>
              </div>
            </div>
          </fuse-card>
        </div>
        <!--ads list-->
        <div class="col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-9">
          <fuse-card class="p-4 flex flex-col">
            <div class="text-4xl mb-0"><b>Campaign Ads</b></div>
            <div>View all ads associated with this campaigns</div>

            <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
              <!-- Search -->
              <div class="flex-auto">
                <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
                  <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                  <input matInput [(ngModel)]="searchText" [autocomplete]="'off'"
                    (ngModelChange)="seachTextDidChange(searchText)" [placeholder]="'Filter ads'">
                </mat-form-field>
              </div>
              <!-- Add ad button -->
              <!-- <a routerLink="/add-ad">
                <button class="ml-4" mat-flat-button [color]="'primary'">
                  <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                  <span class="ml-2 mr-1">Add</span>
                </button>
              </a> -->
            </div>


            <!--list-->
            <!--loading-->
            <div *ngIf="viewState == viewStates.loading">
              <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
            </div>

            <!-- no content -->
            <div *ngIf="viewState == viewStates.content && filteredAds.length === 0" class="mt-8">
              <app-generic-no-content title="No Ads Found"
                subtitle="No ads were found please refresh or update your search criteria."
                imageUrl="https://img.icons8.com/color/256/000000/headline.png" class="ml-auto mr-auto mt-5">
              </app-generic-no-content>
            </div>

            <!-- content -->
            <div [hidden]="viewState != viewStates.content || filteredAds.length === 0" class="w-full mt-8">
              <fuse-card class="w-full p-4">
                <div class="w-full overflow-x-auto mx-6">
                  <table class="w-full bg-transparent text-center" mat-table matSort [dataSource]="adsDataSource"
                    [trackBy]="trackByFn" #adsTable>

                    <!-- Name -->
                    <ng-container matColumnDef="name">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        Name
                      </th>
                      <td mat-cell *matCellDef="let ad" class="text-left">
                        <a href="{{ad.externalUrl}}" target="_blank"
                          matTooltip="View this {{ad.adType.name}} on the web">
                          <mat-icon class="mr-3 text-primary" style="vertical-align: bottom;"
                            [svgIcon]="'heroicons_outline:external-link'"></mat-icon>
                        </a>
                        <a routerLink="/ads/{{ad.id}}"
                          class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">

                          <b class="text-slate-600">
                            <span *ngIf="ad.adType.id == adTypes.FACEBOOK_CAMPAIGN.id">
                              <span
                                *ngIf="ad.fbCampaign != null && ad.fbCampaign?.status === 'ACTIVE'">{{ad.name}}</span>
                              <span *ngIf="ad.fbCampaign == null || ad.fbCampaign?.status === 'PAUSED'"
                                class="text-secondary">{{ad.name}}</span>
                            </span>
                          </b>
                        </a>
                      </td>
                    </ng-container>

                    <!-- Daily Budget -->
                    <ng-container matColumnDef="dailyBudget">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        Daily Budget
                      </th>
                      <td mat-cell *matCellDef="let ad">
                        <span class="pr-6 font-medium whitespace-nowrap" matTooltip="Daily Budget">
                          <span *ngIf="ad.adType.id == adTypes.FACEBOOK_CAMPAIGN.id">
                            <span *ngIf="ad.fbCampaign != null && ad.fbCampaign?.status === 'ACTIVE'">{{
                              (ad.fbCampaign.daily_budget/100) |
                              currency:'USD'}}</span>
                            <span *ngIf="ad.fbCampaign == null || ad.fbCampaign?.status === 'PAUSED'"
                              class="text-secondary">$0</span>
                          </span>
                        </span>
                      </td>
                    </ng-container>

                    <!-- Ad Spend in Range -->
                    <ng-container matColumnDef="adSpendInRange">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        Ad Spend in Range
                      </th>
                      <td mat-cell *matCellDef="let ad">
                        <span class="pr-6 font-medium whitespace-nowrap" matTooltip="Ad Spend in Range">
                          <span *ngIf="ad.adType.id == adTypes.FACEBOOK_CAMPAIGN.id">
                            <span *ngIf="ad.fbCampaign?.insights != null">{{ (ad.fbCampaign.insights.data[0].spend) |
                              currency:'USD'}}</span>
                            <span *ngIf="ad.fbCampaign?.insights == null" class="text-secondary">$0</span>
                          </span>
                        </span>
                      </td>
                    </ng-container>

                    <!-- Applications-->
                    <ng-container matColumnDef="applicationsCount">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        Applications
                      </th>
                      <td mat-cell *matCellDef="let ad">
                        <span class="pr-6 font-medium whitespace-nowrap" matTooltip="Applications">
                          {{ad.applicationsCount}}
                        </span>
                      </td>
                    </ng-container>

                    <!-- Companies -->
                    <ng-container matColumnDef="companiesCount">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        Companies
                      </th>
                      <td mat-cell *matCellDef="let ad">
                        <button *ngIf="!ad.showCompanies || ad.companies.length == 0" class="pr-6 font-medium whitespace-nowrap" matTooltip="Companies" (click)="ad.showCompanies = true">
                          {{ad.companiesCount}}
                        </button>
                        <div class="flex flex-col" *ngIf="ad.showCompanies">
                          <a routerLink="/companies/{{company.id}}" *ngFor="let company of ad.companies">{{company.name}}</a>
                        </div>
                      </td>
                    </ng-container>

                    <!-- Products -->
                    <ng-container matColumnDef="productsCount">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        Products
                      </th>
                      <td mat-cell *matCellDef="let ad">
                        <span class="pr-6 font-medium whitespace-nowrap" matTooltip="Companies">
                          {{ad.productsCount}}
                        </span>
                      </td>
                    </ng-container>

                    <!-- Cost per Application -->
                    <ng-container matColumnDef="costPerApp">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        Cost Per App
                      </th>
                      <td mat-cell *matCellDef="let ad">
                        <span class="pr-6 font-medium whitespace-nowrap" matTooltip="Cost Per App">
                          <span *ngIf="ad.adType.id == adTypes.FACEBOOK_CAMPAIGN.id">
                            <span *ngIf="ad.fbCampaign?.insights != null">{{ ad.costPerApp | currency:'USD'}}</span>
                            <span *ngIf="ad.fbCampaign?.insights == null" class="text-secondary">$0</span>
                          </span>
                        </span>
                      </td>
                    </ng-container>

                    <!-- Leads per App -->
                    <ng-container matColumnDef="leadsPerApp">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        Leads Per App
                      </th>
                      <td mat-cell *matCellDef="let ad">
                        <span class="pr-6 font-medium whitespace-nowrap" matTooltip="Leads Per App">
                          {{ad.leadsPerApp | number:'1.2-2' }}
                        </span>
                      </td>
                    </ng-container>

                    <!-- Expected CPL -->
                    <ng-container matColumnDef="expectedCpl">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        Expected CPL
                      </th>
                      <td mat-cell *matCellDef="let ad">
                        <span class="pr-6 font-medium whitespace-nowrap" matTooltip="Expected CPS">
                          {{ad.expectedCpl | currency:'USD'}}
                        </span>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="adsTableColumns"></tr>
                    <tr class="order-row h-16" mat-row *matRowDef="let row; columns: adsTableColumns;"></tr>
                    <!-- <tr
                              class="h-16 border-0"
                              mat-footer-row
                              *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
                  </table>
                </div>
              </fuse-card>
            </div>
          </fuse-card>
        </div>
      </div>
    </div>
  </div>
</div>