import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AddAdministratorDto, AdministratorDto, FileResponseDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  // styleUrls: ['./add-staff.component.css']
})
export class AddStaffComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

//vars
staff: AddAdministratorDto = new AddAdministratorDto();

//view states
viewStates = ViewState;
viewState = ViewState.content;

//file upload
@ViewChild('image') imageElement: ElementRef;

  ngOnInit() {
    this.titleService.setTitle(`Add Staff | Admin`);
  }


  //api
  addStaff(): void {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/administrators`, this.staff)
      .subscribe((result: AdministratorDto) => {
        this._router.navigateByUrl(`/staff/${result.id}`);
      });
  }

  async uploadFile(file: File) : Promise<FileResponseDto> {
    //package form data
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    //upload
    return (await firstValueFrom(this.http.post(`${environment.services_tdusa_admin}/v1/files`, formData))) as FileResponseDto;
  }

  async editImage(e: Event) {
    //get file
    const file: File = (<HTMLInputElement>e.target).files.item(0);

    //upload
    this.viewState = ViewState.loading;
    const uploadedFile = await this.uploadFile(file);
    this.staff.imageUrl = uploadedFile.src;
    this.viewState = ViewState.content;
  }

  //validations
  staffIsValid() : Boolean {
    return this.isValidString(this.staff.name);
  }

  isValidString(test: string) : Boolean {
    if(test == null) { return false; }
    return test.trim() != '';
  }
}
