import { BaseLookup } from "./base.lookup";

export class WebhookMethodTypesLookup extends BaseLookup {
  // Values
  static readonly GET = new WebhookMethodTypesLookup('0', 'GET');
  static readonly POST = new WebhookMethodTypesLookup('1', 'POST');
  static readonly PUT = new WebhookMethodTypesLookup('2', 'PUT');
  static readonly PATCH = new WebhookMethodTypesLookup('3', 'PATCH');
  static readonly DELETE = new WebhookMethodTypesLookup('4', 'DELETE');
  static readonly HEAD = new WebhookMethodTypesLookup('5', 'HEAD');

  static readonly values = [
    WebhookMethodTypesLookup.GET,
    WebhookMethodTypesLookup.POST,
    WebhookMethodTypesLookup.PUT,
    WebhookMethodTypesLookup.PATCH,
    WebhookMethodTypesLookup.DELETE,
    WebhookMethodTypesLookup.HEAD
  ]
}