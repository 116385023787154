import { IdentifiableDto } from "./identifiable.dto";
import { JobPostingTypeDto } from "./lookup.dto";

export class JobPostingSourceDto extends IdentifiableDto {
    name: string;
    feedUrl: string;
    timestampCreated!: Date;
    timestampUpdated!: Date;

    //relations
    jobPostingType: JobPostingTypeDto;
}