import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from 'environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ColorPickerModule } from 'ngx-color-picker';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatSelectModule } from '@angular/material/select';
import { NgSwitcheryModule } from 'angular-switchery-ios';
import { FileIconsModule } from 'ngx-file-icons';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './interceptors/timeout.interceptor';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        //authentication
        AuthModule.forRoot({
            domain: environment.auth0_domain,
            clientId: environment.auth0_client_id,
            audience: environment.auth0_audience,
            cacheLocation: 'localstorage',
            useRefreshTokens: false,
            httpInterceptor: {
                allowedList: [
                    `${environment.services_tdusa_admin}/v1/*`
                ],
            }
        }),

        //material
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),

        //color picker
        ColorPickerModule,

        //sweet alert modals
        SweetAlert2Module.forRoot(),

        //ios style switches
        NgSwitcheryModule,

        //file icons
        FileIconsModule,

        //mapbox
        NgxMapboxGLModule.withConfig({
            accessToken: environment.mapbox.accessToken, // Optional, can also be set per map (accessToken input of mgl-map)
        })
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        HttpClientModule,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        },
        [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
        [{ provide: DEFAULT_TIMEOUT, useValue: 10*(60)*1000 }] //minutes * 60s * 1000ms
    ]
})
export class AppModule {
}
