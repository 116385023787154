import { ProposalDto } from "./proposal.dto";

export class EditProposalDto {
    name!: string;
    includeProductSummary: boolean;
    generateGrossQuote: boolean;
    changeFromDefault: boolean;
    quotePercentage: number;

    maxLeads: number;
    spendInCents: number;
    targetCplInCents: number;

    static fromProposal(proposal: ProposalDto) : EditProposalDto {
        var dto = new EditProposalDto();
        dto.name = proposal.name;
        dto.includeProductSummary = proposal.includeProductSummary;
        dto.generateGrossQuote = proposal.generateGrossQuote;
        dto.changeFromDefault = proposal.changeFromDefault;
        dto.quotePercentage = proposal.quotePercentage;

        //generation
        dto.maxLeads = proposal.maxLeads;
        dto.spendInCents = proposal.spendInCents;
        dto.targetCplInCents = proposal.targetCplInCents;

        return dto;
    }
}