import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { DateRanges } from 'app/models/app/dateRanges';
import { AdCampaignDto, AdDto, EditAdCampaignDto } from 'app/models/dtos';
import { AdTypesLookup } from 'app/models/lookups';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  // styleUrls: ['./campaign.component.css']
})
export class CampaignComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  //view children
  @ViewChild('adsTable', { read: MatSort, static: false }) adsTableMatSort: MatSort;

  public id: string = '0';
  campaign: AdCampaignDto = null;
  ads: AdDto[] = [];
  breadcrumbs: GenericBreadcrumb[] = [
    new GenericBreadcrumb('Campaigns', '/campaigns')
  ];
  moment = moment;
  filteredAds: AdDto[] = []
  searchText: string = '';
  adsDataSource: MatTableDataSource<AdDto> = new MatTableDataSource();
  adsTableColumns: string[] = ['name', 'dailyBudget', 'adSpendInRange', 'applicationsCount', 'companiesCount', 'productsCount', 'costPerApp', 'leadsPerApp', 'expectedCpl'];
  customRange: any = {
    startDate: moment().subtract(7, 'days').toDate(),
    endDate: moment().toDate()
  };
  selectedRange = DateRanges.THIS_MONTH.id;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  infoViewState = ViewState.loading;
  adsViewState = ViewState.loading;

  //type lists
  dateRanges: DateRanges[] = structuredClone(DateRanges.adCampaignValues);

  //raw types
  dateRangesRaw = DateRanges;
  adTypes = AdTypesLookup;


  ngOnInit() {
    //update title
    this.setTitle('Campaign');

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';

    this.getCampaign();
    this.getAds();
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | Admin`);
  }

  //api
  getCampaign(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/campaigns/${this.id}`, {
        params: this.applicationsQueryString()
      })
      .subscribe((result: AdCampaignDto) => {
        this.campaign = result;
        this.viewState = ViewState.content;
        this.infoViewState = ViewState.content;
        this.setTitle(this.campaign.name);
      });
  }

  editCampaign(): void {
    this.infoViewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/campaigns/${this.id}`, EditAdCampaignDto.fromCampaign(this.campaign))
      .subscribe((result: AdCampaignDto) => {
        this.campaign = result;
        this.infoViewState = ViewState.content;
        this.setTitle(this.campaign.name);
      });
  }

  deleteCampaign(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/campaigns/${this.id}`)
      .subscribe(() => {
        this._router.navigateByUrl('/campaigns')
      });
  }

  getAds(): void {
    this.adsViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/campaigns/${this.id}/ads`, {
        params: this.applicationsQueryString()
      })
      .subscribe((result: AdDto[]) => {
        this.ads = this.generateSortableFields(result);

        //TEST
        this.ads.forEach(ad => {
          ad.costPerLead = 0;//ad.adSpendInRange / (3 + Math.random() * 5);
        });

        this.filteredAds = this.ads;
        this.adsViewState = ViewState.content;
        this.adsDataSource = new MatTableDataSource(this.filteredAds);
        this.updateSorting(this.filteredAds);
      });
  }

  applicationsQueryString(): any {
    var range = this.dateRanges.find(range => range.id == this.selectedRange);

    if (range.id == '7') {
      //custom
      return {
        startDate: moment(this.customRange.startDate).startOf('date').toISOString(),
        endDate: moment(this.customRange.endDate).endOf('date').toISOString()
      };
    }
    else {
      return range.params;
    }
  }

  generateSortableFields(ads: AdDto[]): AdDto[] {
    return ads.map(a => {
      a.dailyBudget = this.dailyBudgetForAd(a);
      a.adSpendInRange = this.adSpendInRange(a);
      a.costPerApp = this.costPerApp(a);
      a.expectedCpl = this.expectedCpl(a);

      return a;
    });
  }

  dailyBudgetForAd(ad: AdDto): number {
    try {
      if (ad.adType.id == this.adTypes.FACEBOOK_CAMPAIGN.id && ad.fbCampaign?.status === 'ACTIVE') {
        return (Number.parseFloat(ad.fbCampaign?.daily_budget ?? '0')) / 100;
      }
    } catch (error) { }

    return 0;
  }

  adSpendInRange(ad: AdDto): number {
    try {
      if (ad.adType.id == this.adTypes.FACEBOOK_CAMPAIGN.id) {
        return Number.parseFloat(ad.fbCampaign?.insights?.data[0]?.spend ?? '0');
      }
    } catch (error) { }

    return 0;
  }

  costPerApp(ad: AdDto): number {
    try {
      if (ad.applicationsCount == 0) { return 0; }
      return this.adSpendInRange(ad) / ad.applicationsCount;
    } catch (error) { return 0; }
  }

  expectedCpl(ad: AdDto) : number {
    try {
      if (ad.leadsPerApp == 0) { return 0; }
      return this.costPerApp(ad) / ad.leadsPerApp;
    } catch (error) { return 0; }
  }

  seachTextDidChange(text: string) {
    this.filteredAds = this.ads.filter(r =>
      r.name.toLowerCase().includes(text.toLowerCase())
    )
    this.updateSorting(this.filteredAds);
  }

  updateSorting(ads: AdDto[]) {
    this.adsDataSource = new MatTableDataSource(ads);
    this.adsDataSource.sort = this.adsTableMatSort;
    this.adsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
      return item[property];
    };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  //totals
  totalSpent(): number {
    return this.ads.map(ad => ad.adSpendInRange)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  dailySpendScheduled(): number {
    return this.ads.map(ad => ad.dailyBudget)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  randomNumber(range: number): number {
    return Math.random() * range;
  }

  customRangeDatePickerDidChange() {
    if (this.customRange.startDate == null) { return; }
    if (this.customRange.endDate == null) { return; }

    this.getAds();
  }
}
