<fuse-card id="contact-form" class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
  <!--loading-->
  <div *ngIf="infoViewState == viewStates.loading">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
  </div>

  <div *ngIf="infoViewState == viewStates.content">
    <div class="text-small text-center text-secondary mb-4">
      <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
      </mat-icon>
      <b>Contact Info</b>
    </div>
    <div class="form-group">
      <input type="file" style="display: none;" id="resource-image" accept="image/*"
        (change)="editImage($event)" #image [disabled]="!permissionsService.canManage()">
    </div>
    <img *ngIf="contact?.imageUrl == null" class="object-contain w-32 h-32 ml-auto mr-auto cursor-pointer"
      src="https://img.icons8.com/color/256/000000/add-image.png"
      (click)="imageElement.nativeElement.click()" [disabled]="!permissionsService.canManage()" />
    <img *ngIf="contact?.imageUrl != null"
      class="object-cover rounded-full w-32 h-32 ml-auto mr-auto border rounded-full cursor-pointer"
      src="{{contact.imageUrl}}" alt="Card cover image" (click)="imageElement.nativeElement.click()" [disabled]="!permissionsService.canManage()">

    <div class="text-center text-small text-secondary mt-2" *ngIf="permissionsService.canManage()">Tap image to change</div>
    <div>
       <!-- can login -->
       <div class="mt-4 mb-4 font-bold text-center" *ngIf="permissionsService.canAdmin()">
        <ng-switchery onLabel="Can login to client portal" [(ngModel)]="contact.canLogin"
          name="Can login to client portal"
          [matTooltip]="'Enabling a contact to login to the client portal will generate a temporary password and send them an email with a link to the client portal. The user will have basic read-access to the companies and agencies they are associated with.'">
        </ng-switchery>
      </div>
      <div class="flex-auto grid grid-cols-12 gap-3">
       
        <!--name-->
        <mat-form-field class="w-full col-span-12 md:col-span-6">
          <mat-label>Name</mat-label>
          <input matInput [(ngModel)]="contact.name" [autocomplete]="'off'" placeholder="Contact Name" [disabled]="!permissionsService.canManage()">
          <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
        </mat-form-field>
        <!--email-->
        <mat-form-field class="w-full col-span-12 md:col-span-6">
          <mat-label>Email</mat-label>
          <input type="email" matInput [(ngModel)]="contact.email" [autocomplete]="'off'" [disabled]="!permissionsService.canManage()"
            placeholder="Contact Email">
          <mat-icon class="icon-size-5" matSuffix [svgIcon]="'alternate_email'"></mat-icon>
        </mat-form-field>
        <!--phone-->
        <mat-form-field class="w-full col-span-12 md:col-span-6">
          <mat-label>Phone</mat-label>
          <input matInput [(ngModel)]="contact.phone" [autocomplete]="'off'" placeholder="Contact Phone" [disabled]="!permissionsService.canManage()">
          <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
        </mat-form-field>
        <!--position-->
        <mat-form-field class="w-full col-span-12 md:col-span-6">
          <mat-label>Position</mat-label>
          <input matInput [(ngModel)]="contact.position" [autocomplete]="'off'" placeholder="Contact Position" [disabled]="!permissionsService.canManage()">
          <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:briefcase'"></mat-icon>
        </mat-form-field>
        <!--notes-->
        <mat-form-field class="w-full col-span-12">
          <mat-label>Notes</mat-label>
          <textarea matInput rows="5" [(ngModel)]="contact.notes" [autocomplete]="'off'" [disabled]="!permissionsService.canManage()"
            placeholder="Contact Notes"></textarea>
          <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:annotation'"></mat-icon>
        </mat-form-field>
      </div>
      <button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="editContact()" [disabled]="!permissionsService.canManage()">
        <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
        <span class="ml-2 mr-1">Save</span>
      </button>
      <div class="mt-4 text-small text-center text-secondary">Updated
        {{moment(contact.timestampUpdated).fromNow()}}</div>
    </div>
  </div>

  <!--delete-->
  <div class="border rounded p-3 mt-4 border-rose-500" *ngIf="permissionsService.canAdmin()">
    <div class="text-warn"><b>Danger Zone</b></div>
    <div><small>These actions are destructive. Proceed with care.</small></div>
    <button class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
      [swal]="{ title: 'Delete Contact', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
      (confirm)="deleteContact()">

      Delete
    </button>
  </div>
</fuse-card>