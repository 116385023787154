import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { ApplicationDto, LogEntryDto, LogEntryTypeDto, ServiceDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import moment from 'moment';
import * as mime from 'mime';
import { LogEntryStatesLookup, LogEntryTypesLookup, ServicesLookup } from 'app/models/lookups';
import { DateRanges } from 'app/models/app/dateRanges';
import { LogEntryComponent } from '../log-entry/log-entry.component';
import { MatDialog } from '@angular/material/dialog';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

@Component({
  selector: 'app-log-analytics',
  templateUrl: './log-analytics.component.html',
  styleUrls: ['./log-analytics.component.css']
})
export class LogAnalyticsComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, public dialog: MatDialog) { }

  //inputs
  @Input() logRoute: string;

  //view children
  @ViewChild('logsTable', { read: MatSort, static: false }) logsTableMatSort: MatSort;

  //vars
  logs: LogEntryDto[] = []
  _filteredLogs: LogEntryDto[];
  searchText: string = '';
  customRange: any = {
    startDate: moment().subtract(7, 'days').toDate(),
    endDate: moment().toDate()
  }
  logsDataSource: TableVirtualScrollDataSource<LogEntryDto> = new TableVirtualScrollDataSource([]);
  logsTableColumns: string[] = ['state', 'id', 'type', 'generation', 'narrative', 'target', 'created'];
  moment = moment;
  selectedRange = DateRanges.THIS_WEEK.id;
  selectedEntryState = LogEntryStatesLookup.ALL.id;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addLogViewState = ViewState.content;

  //type lists
  entryTypes = structuredClone(LogEntryTypesLookup.values);
  entryStates = structuredClone(LogEntryStatesLookup.values);
  services = structuredClone(ServicesLookup.values);
  dateRanges: DateRanges[] = structuredClone(DateRanges.values);

  rawEntryTypes = LogEntryTypesLookup;
  dateRangesRaw = DateRanges;

  ngOnInit() {


  }

  ngAfterViewInit() {
    this.getLogs();
  }

  //api
  getLogs(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/${this.logRoute ?? 'v1/logs'}`, {
        params: this.logsQueryString()
      })
      .subscribe((result: LogEntryDto[]) => {
        this.logs = result;
        this.viewState = ViewState.content;
        this.invalidateStats()
      });
  }

  logsQueryString(): any {
    var range = this.dateRanges.find(range => range.id == this.selectedRange);

    if (range.id == '7') {
      //custom
      return {
        startDate: moment(this.customRange.startDate).startOf('date').toISOString(),
        endDate: moment(this.customRange.endDate).endOf('date').toISOString()
      };
    }
    else {
      return range.params;
    }
  }

  seachTextDidChange(text: string) {
    this.invalidateStats();
  }

  updateSorting(logs: LogEntryDto[]) {
    this.logsDataSource = new TableVirtualScrollDataSource(logs);
    this.logsDataSource.sort = this.logsTableMatSort;
    this.logsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
      return item[property];
    };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  filteredLogs(): LogEntryDto[] {
    //if no cache hit, calculate based on free text and filters in place
    if (this._filteredLogs == null) {
      this._filteredLogs = this.logs;
      var uniquefiedEntries = this.typeFilteredEntries(this.uniqueifyEntries(this.logs));
      uniquefiedEntries = this.serviceFilteredEntries(uniquefiedEntries);
      

      if (this.searchText.trim() == '') {
        this._filteredLogs = uniquefiedEntries;
        // console.log(`empty: ${this._filteredLogs}`);
      }
      else {
        this._filteredLogs = uniquefiedEntries
          .filter(r =>
            r.narrative.toLowerCase().includes(this.searchText.toLowerCase())
            || (r.logEntryType?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
            || (r.generatingAdministrator?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
            || (r.generatingService?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
            || (r.agency?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
            || (r.company?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
            || (r.collective?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
            || (r.administrator?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
            || (r.contact?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
            || (r.proposal?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
          );
        // console.log(`filtered: ${this._filteredLogs}`);
      }
    }
    return this._filteredLogs;
  }

  uniqueifyEntries(entries: LogEntryDto[]): LogEntryDto[] {
    switch (this.selectedEntryState) {
      case LogEntryStatesLookup.ALL.id:
        return entries;
      case LogEntryStatesLookup.UNRESOLVED.id:
        return entries.filter(e => e.needsResolution && e.resolvingAdministrator == null);
      case LogEntryStatesLookup.RESOLVED.id:
        return entries.filter(e => e.needsResolution && e.resolvingAdministrator != null);
      default:
        return entries;
    }
  }

  typeFilteredEntries(apps: LogEntryDto[]): LogEntryDto[] {
    var filtApps = apps;

    //experience types
    if (this.entryTypes.filter(et => et.checked).length > 0) {
      filtApps = filtApps.filter(a => {
        return this.entryTypes.filter(et => et.checked)
          .find(et => et.id == a.logEntryType.id) != undefined;
      });
    }

    return filtApps;
  }

  serviceFilteredEntries(apps: LogEntryDto[]): LogEntryDto[] {
    var filtApps = apps;

    //experience types
    if (this.services.filter(et => et.checked).length > 0) {
      filtApps = filtApps.filter(a => {
        return this.services.filter(et => et.checked)
          .find(et => et.id == a.generatingService?.id) != undefined;
      });
    }

    return filtApps;
  }

  //stats & filters
  invalidateStats() {
    this._filteredLogs = null;
    this.updateSorting(this.filteredLogs());
  }

  entriesToal(): number {
    return this.filteredLogs()
      .length;
  }

  entryTypeTotal(type: LogEntryTypeDto): number {
    return this.filteredLogs()
      .filter(a => a.logEntryType.id == type.id)
      .length;
  }

  servicesToal(): number {
    return this.filteredLogs()
      .filter(a => a.generatingService != null)
      .length;
  }

  serviceTypeTotal(type: ServiceDto): number {
    return this.filteredLogs()
      .filter(a => a.generatingService != null)
      .filter(a => a.generatingService.id == type.id)
      .length;
  }

  //items
  didSelectLogEntry(logEntry: LogEntryDto) {
    const dialogRef = this.dialog.open(LogEntryComponent, {
      data: logEntry,
    });

    dialogRef.afterClosed().subscribe(result => {
      const targetLogEntry: LogEntryDto = result.data;
      switch (result.action) {
        case 'resolve':
          const resolvedLogItem = this.logs.find(l => l.id == targetLogEntry.id);
          resolvedLogItem.resolvingAdministrator = targetLogEntry.resolvingAdministrator;
          resolvedLogItem.resolutionNarrative = targetLogEntry.resolutionNarrative;
          resolvedLogItem.timestampResolved = targetLogEntry.timestampResolved;

          break;
        case 'unresolve':
          const unresovledLogItem = this.logs.find(l => l.id == targetLogEntry.id);
          unresovledLogItem.resolvingAdministrator = null;
          unresovledLogItem.resolutionNarrative = null;
          unresovledLogItem.timestampResolved = null;
          break;
        case 'delete':
          this.logs = this.logs.filter(l => l.id != targetLogEntry.id);
          break;
        default:
          break;
      }
      this.invalidateStats();
    });
  }

  customRangeDatePickerDidChange() {
    if(this.customRange.startDate == null) { return; }
    if(this.customRange.endDate == null) { return; }

    this.getLogs();
  }
}
