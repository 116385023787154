import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CompanyDto, ExternalJobDto, MatchedLeadsProductDto, MatchedLeadsProductJobsDto, XmlFeedCheckResults } from 'app/models/dtos';
import { TdusaLogicalJob } from 'app/models/integrations/tdusa-xml/models';
import { environment } from 'environments/environment';
import { MatDrawer } from '@angular/material/sidenav';
import { AddExternalJobDto } from 'app/models/dtos/addExternalJob.dto';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { IncomingPhoneNumberInstance } from 'twilio/lib/rest/api/v2010/account/incomingPhoneNumber';
import { ProductStatusTypesLookup } from 'app/models/lookups';
import { timeout } from 'rxjs';
import { ExternalJobFeedTypesLookup } from 'app/models/lookups/externalJobFeedTypes.lookup';
import { EditJobModalComponent, EditJobModalComponentInput } from './edit-job-modal/edit-job-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-matched-leads-product-jobs',
  templateUrl: './matched-leads-product-jobs.component.html',
  // styleUrls: ['./matched-leads-product-jobs.component.css']
})
export class MatchedLeadsProductJobsComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService, public dialog: MatDialog) { }

  //inputs
  @Input() company: CompanyDto;
  @Input() product: MatchedLeadsProductDto;

  //vars
  jobsInfo: MatchedLeadsProductJobsDto;
  xmlFeedCheckResult: XmlFeedCheckResults;
  proposedXmlFeed: string;
  checkedJobs: ExternalJobDto[] = [];
  jobs: ExternalJobDto[] = [];
  selectedJob: ExternalJobDto;
  searchText: string = '';

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;
  twilioViewState = ViewState.content;
  xmlFeedCheckViewState = ViewState.initial;
  jobsViewState = ViewState.initial;
  jobDetailsViewState = ViewState.initial;

  //types
  feedTypes: ExternalJobFeedTypesLookup[] = structuredClone(ExternalJobFeedTypesLookup.values);
  rawFeedTypes = ExternalJobFeedTypesLookup;


  ngOnInit() {
    this.getJobsInfo();
    this.getJobs();
  }


  //api
  getJobsInfo() {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/jobs-settings`)
      .subscribe((result: MatchedLeadsProductJobsDto) => {
        this.jobsInfo = result;
        this.proposedXmlFeed = this.jobsInfo.xmlFeed;
        this.viewState = ViewState.content;
      });
  }

  editJobsInfo() {
    this.viewState = ViewState.loading;

    this.jobsInfo.xmlFeed = this.proposedXmlFeed;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/jobs-settings`, this.jobsInfo)
      .subscribe((result: MatchedLeadsProductJobsDto) => {
        this.jobsInfo = result;
        this.viewState = ViewState.content;
      });
  }

  getJobs() {
    this.jobsViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/jobs`)
      .subscribe((result: ExternalJobDto[]) => {
        this.jobs = result;
        console.log(result);
        this.jobsViewState = ViewState.content;
      });
  }

  checkXmlFeed() {
    this.xmlFeedCheckViewState = ViewState.loading;
    this.jobsViewState = ViewState.loading;
    this.selectedJob = null;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/xml/check`)
      .subscribe((result: XmlFeedCheckResults) => {
        this.xmlFeedCheckResult = result;
        this.checkedJobs = this.xmlFeedCheckResult.jobs;
        console.log(result);
        this.xmlFeedCheckViewState = ViewState.content;
        this.jobsViewState = ViewState.content;
      });
  }

  importXmlFeed() {
    this.jobsViewState = ViewState.loading;
    this.selectedJob = null;
    this.scrollToElement('active-jobs');

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/xml/import`)
      .subscribe((result: ExternalJobDto[]) => {
        this.jobs = result;

        console.log(result);
        this.jobsViewState = ViewState.content;
        this.scrollToElement('active-jobs');
      });
  }

  addJob() {
    this.jobsViewState = ViewState.loading;

    //create dto
    var dto = new AddExternalJobDto()
    dto.title = `New Job`;

    //add job
    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/jobs`, dto)
      .subscribe((result: ExternalJobDto) => {
        this._router.navigateByUrl(`/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/jobs/${result.id}`);
      });
  }

  requestNumber() {
    this.twilioViewState = ViewState.loading;

    //add job
    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/requestNumber`, null)
      .subscribe((result: IncomingPhoneNumberInstance) => {
        this.jobsInfo.twilioNumber = result.phoneNumber;
        this.twilioViewState = ViewState.content;
      });
  }

  releaseNumber() {
    this.twilioViewState = ViewState.loading;

    //release number
    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/releaseNumber`)
      .subscribe(() => {
        this.jobsInfo.twilioNumber = null;
        this.twilioViewState = ViewState.content;
      });
  }

  syncJobs() {
    this.jobsViewState = ViewState.loading;

    //release number
    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/sync-jobs`)
      .pipe(
        timeout(9000000)
      )
      .subscribe(() => {
        this.jobsViewState = ViewState.content;
      });
  }

  //job management
  filteredJobs(): ExternalJobDto[] {
    const q = this.searchText.toLowerCase();
    return this.jobs.filter(j => j.title.toLowerCase().includes(q)
      || (j.notes ?? '').toLowerCase().includes(q));
  }

  filteredCheckedJobs(): ExternalJobDto[] {
    return this.checkedJobs;
  }

  didClickViewJob(event: any, job: ExternalJobDto) {
    this.jobDetailsViewState = ViewState.loading;
    this.selectedJob = job;

    //add delay for map re-render
    setTimeout(() => {
      this.jobDetailsViewState = ViewState.content;

      //scroll the job details into view
      this.scrollToElement('job-details');
    }, 500);

  }

  scrollToElement(elementName: string) {
    //scroll the job details into view
    setTimeout(() => {
      document.getElementById(elementName).scrollIntoView({
        behavior: 'smooth'
      });
    }, 250);
  }

  didClickEditJob(event: any, job: ExternalJobDto) {
    this._router.navigateByUrl(`/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/jobs/${job.id}`);
  }

  didClickAddJob() {
    this.addJob();
  }

  isXmlMode(): boolean {
    return this.jobsInfo?.xmlFeed != null && this.jobsInfo?.xmlFeed != ''
  }

  productIsActive(): boolean {
    if (this.product.currentSpendPeriod == null) { return false; }
    if (this.product.productStatus?.productStatusType == null) { return false; }

    return this.product.productStatus.productStatusType.id === ProductStatusTypesLookup.ACTIVE.id
      || this.product.productStatus.productStatusType.id === ProductStatusTypesLookup.RENEWAL_REQUIRED.id
  }

  //fallback job
  recommendDefineFallbackJob(): boolean {
    return this.jobsInfo.externalJobFeedTypeId == this.rawFeedTypes.TDUSA_XML_LEGACY.id
      || this.jobsInfo.externalJobFeedTypeId == this.rawFeedTypes.APPCAST.id;
  }
  showFallbackJob() {
    //build input
    const input = new EditJobModalComponentInput();
    input.job = this.jobsInfo.externalJobFeedFallbackJob;
    input.title = "Fallback Job Definition";
    input.subtitle = "Define values for job feed entries to fallback on if they are missing.";

    //show modal
    const dialogRef = this.dialog.open(EditJobModalComponent, {
      data: input,
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
