import { BaseLookup } from "./base.lookup";

export class JobBoardTypesLookup extends BaseLookup {

    imageUrl: string;

    constructor(id: string, name: string, imageUrl: string) {
        super(id, name)
        this.imageUrl = imageUrl;
    }

    // Values
    static readonly TENSTREET = new JobBoardTypesLookup('1', 'Tenstreet', '');
    static readonly EBE = new JobBoardTypesLookup('2', 'EBE', '');
    static readonly ACQUIRE_ROI = new JobBoardTypesLookup('3', 'Acquire ROI', '');

    static availableValues = [
        JobBoardTypesLookup.EBE
    ]
}