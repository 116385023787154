<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [breadcrumbs]="breadcrumbs" [terminal]="collective?.name ?? ''"
    fallbackIcon="heroicons_solid:collection"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- content -->
    <div *ngIf="viewState == viewStates.content" class="grid grid-cols-12">
      <div class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 mb-4 p-2">
        <!--basic info-->
        <fuse-card class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
          <!--loading-->
          <div *ngIf="infoViewState == viewStates.loading">
            <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
          </div>

          <div *ngIf="infoViewState == viewStates.content">
            <div class="text-small text-center text-secondary mb-4">
              <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
              </mat-icon>
              <b>Collective Info</b>
            </div>

            <div>
              <div class="flex-auto">
                <!--name-->
                <mat-form-field class="w-full">
                  <mat-label>Name</mat-label>
                  <input matInput [(ngModel)]="collective.name" [autocomplete]="'off'" placeholder="Collective Name">
                  <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>
              </div>
              <button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="editCollective()">
                <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
                <span class="ml-2 mr-1">Save</span>
              </button>
              <div class="mt-4 text-small text-center text-secondary">Updated
                {{moment(collective.timestampUpdated).fromNow()}}</div>
            </div>

            <!--delete-->
            <div class="border rounded p-3 mt-4 border-rose-500">
              <div class="text-warn"><b>Danger Zone</b></div>
              <div><small>These actions are destructive. Proceed with care.</small></div>
              <button class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
                [swal]="{ title: 'Delete Collective', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
                (confirm)="deleteCollective()">

                Delete
              </button>
            </div>
          </div>
        </fuse-card>
      </div>

      <div class="col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-9 p-2">
        <!--tabs-->
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabClick($event)">

          <!--Companies-->
          <mat-tab label="Companies">
            <app-collective-companies [collective]="collective" *ngIf="collective != null"></app-collective-companies>
          </mat-tab>

          <!--Logs-->
          <!-- <mat-tab label="Logs">
            <app-log-analytics logRoute="v1/collectives/{{collective.id}}/logs"></app-log-analytics>
          </mat-tab> -->
          <!--Notifications-->
          <!-- <mat-tab label="Notifications">
            <app-log-notifications logRoute="v1/collectives/{{collective.id}}/logs/notifications"></app-log-notifications>
          </mat-tab> -->
        </mat-tab-group>
      </div>
    </div>

  </div>

</div>