import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AddReportDto, CompanyDto, MatchedLeadsProductDto, ProductSpendPeriodDto, ReportDto } from 'app/models/dtos';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import moment from 'moment';

@Component({
  selector: 'app-matched-leads-product-reports',
  templateUrl: './matched-leads-product-reports.component.html',
  // styleUrls: ['./matched-leads-product-reports.component.css']
})
export class MatchedLeadsProductReportsComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  //inputs
  @Input() company: CompanyDto;
  @Input() product: MatchedLeadsProductDto;

  //view children
  @ViewChild('reportsTable', { read: MatSort, static: false }) reportsTableMatSort: MatSort;

  //vars
  reports: ReportDto[] = []
  filteredReports: ReportDto[] = []
  searchText: string = '';
  reportsDataSource: MatTableDataSource<ReportDto> = new MatTableDataSource();
  reportsTableColumns: string[] = ['name', 'product', 'company', 'created',];
  moment = moment;
  reportToAdd: AddReportDto = new AddReportDto();
  periods: ProductSpendPeriodDto[] = [];

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addReportViewState = ViewState.initial;

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.getReports();
    this.getSpendPeriods();
  }

  //api
  getReports(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/reports`)
      .subscribe((result: ReportDto[]) => {
        this.reports = result;
        this.filteredReports = this.reports;
        this.viewState = ViewState.content;
        this.reportsDataSource = new MatTableDataSource(this.filteredReports);
        this.updateSorting(this.filteredReports);
      });
  }

  getSpendPeriods(): void {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/periods-spend-only`)
      .subscribe((result: ProductSpendPeriodDto[]) => {
        this.periods = result;
      });
  }

  addReport() {
    this.addReportViewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/reports`, this.reportToAdd)
      .subscribe((result: ReportDto) => {
        this._router.navigateByUrl(`/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/reports/${result.id}`);
      });
  }

  //filtering / sorting
  seachTextDidChange(text: string) {
    this.filteredReports = this.reports.filter(r =>
      r.name.toLowerCase().includes(text.toLowerCase())
      || (r.matchedLeadsProduct?.name ?? '').toLowerCase().includes(text.toLowerCase())
    )
    this.updateSorting(this.filteredReports);
  }

  updateSorting(reports: ReportDto[]) {
    this.reportsDataSource = new MatTableDataSource(reports);
    this.reportsDataSource.sort = this.reportsTableMatSort;
    this.reportsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
      return item[property];
    };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  //add report
  didClickAddReport() {
    this.reportToAdd = new AddReportDto();
    this.reportToAdd.name = `${moment().format("yyyy-MM-DD")} ${this.product.name}`;
    if (this.periods.length > 0) {
      this.reportToAdd.spendPeriodId = this.periods[0].id;
      this.reportToAdd.name = `${moment(this.periods[0].timestampStart).format("yyyy-MM-DD")} to ${moment(this.periods[0].timestampEnd).format("yyyy-MM-DD")}`;
    }

    this.addReportViewState = ViewState.content;
  }

  isValidReport(reportToAdd: AddReportDto) {
    return reportToAdd.name != null
      && reportToAdd.name.trim() != ''
      && reportToAdd.spendPeriodId != null;
  }

  didClickDismissAddReport() {
    this.addReportViewState = ViewState.initial;
  }

  didClickConfirmAddReport() {
    this.addReport();
  }
}
