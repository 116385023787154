<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="Settings" fallbackIcon="heroicons_solid:adjustments"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--tabs-->
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Lead Generation">
        <app-settings-lead-gen></app-settings-lead-gen>
      </mat-tab>
      <mat-tab label="Banned Domains">
        <app-settings-banned-domains></app-settings-banned-domains>
      </mat-tab>
      <mat-tab label="Banned Candidates">
        <app-settings-banned-candidates></app-settings-banned-candidates>
      </mat-tab>
      <mat-tab label="Scripts">
        <app-settings-scripts></app-settings-scripts>
      </mat-tab>
      <mat-tab label="Advertisement">
        <app-settings-advertising></app-settings-advertising>
      </mat-tab>
    </mat-tab-group>

  </div>

</div>