import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { DateRanges } from 'app/models/app/dateRanges';
import { EditProposalDto, ProposalDto } from 'app/models/dtos';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-proposal-info',
  templateUrl: './proposal-info.component.html',
  // styleUrls: ['./proposal-info.component.css']
})
export class ProposalInfoComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  //inputs
  @Input() proposal: ProposalDto;

  //outputs
  @Output() didEditProposal: EventEmitter<ProposalDto> = new EventEmitter();

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;
  moment = moment;


  ngOnInit() {
  }

  //api
  editProposal(): void {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.proposal.matchedLeadsProduct.company.id}/matchedLeadsProducts/${this.proposal.matchedLeadsProduct.id}/proposals/${this.proposal.id}`, EditProposalDto.fromProposal(this.proposal))
      .subscribe((result: ProposalDto) => {
        this.proposal = result;
        this.viewState = ViewState.content;
        this.didEditProposal.emit(this.proposal);
      });
  }

  deleteProposal(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.proposal.matchedLeadsProduct.company.id}/matchedLeadsProducts/${this.proposal.matchedLeadsProduct.id}/proposals/${this.proposal.id}`)
      .subscribe(() => {
        this._router.navigateByUrl('/proposals')
      });
  }
}
