import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CollectiveDto, CompanyDto, EditCollectiveDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-collective',
  templateUrl: './collective.component.html',
  // styleUrls: ['./collective.component.css']
})
export class CollectiveComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  public id: string = '0';
  collective: CollectiveDto = null;
  breadcrumbs: GenericBreadcrumb[] = [
    new GenericBreadcrumb('Collectives', '/collectives')
  ];
  moment = moment;
  companies: CompanyDto[] = [];
  rules: any[] = [];

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  infoViewState = ViewState.loading;
  companiesViewState = ViewState.loading;
  rulesViewState = ViewState.loading;

  ngOnInit() {
    //update title
    this.titleService.setTitle('Collective | Admin');

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';

    this.getCollective();
    this.getCompanies();
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | Admin`);
  }

  //api
  getCollective(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/collectives/${this.id}`)
      .subscribe((result: CollectiveDto) => {
        this.collective = result;
        this.viewState = ViewState.content;
        this.infoViewState = ViewState.content;
        this.setTitle(this.collective.name);
      });
  }

  editCollective(): void {
    this.infoViewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/collectives/${this.id}`, EditCollectiveDto.fromCollective(this.collective))
      .subscribe((result: CollectiveDto) => {
        this.collective = result;
        this.infoViewState = ViewState.content;
        this.setTitle(this.collective.name);
      });
  }

  deleteCollective(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/collectives/${this.id}`)
      .subscribe(() => {
        this._router.navigateByUrl('/collectives')
      });
  }

  getCompanies(): void {
    this.companiesViewState = ViewState.loading;

    setTimeout(() => {
      this.companiesViewState = ViewState.content;
    }, 1000);
  }

  getWorklistRules(): void {
    this.rulesViewState = ViewState.loading;

    setTimeout(() => {
      this.rulesViewState = ViewState.content;
    }, 1000);
  }

  //tabs
  tabClick(tabEvent: MatTabChangeEvent) {
    switch (tabEvent.tab.textLabel) {
      case 'Companies':
        this.getCompanies();
        break;
      case 'Rules':
        this.getWorklistRules();
        break;
      default:
        break;
    }
  }
}
