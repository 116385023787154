import { CompanyDto } from "./company.dto";
import { DocumentDto } from "./document.dto";
import { ExternalJobDto } from "./externalJob.dto";
import { IdentifiableDto } from "./identifiable.dto";
import { MatchedLeadsProductDto } from "./matchedLeadsProduct.dto";

export class ProposalDto extends IdentifiableDto {
   timestampCreated!: Date;
   timestampUpdated!: Date;
   name!: string;
   includeProductSummary: boolean;
   generateGrossQuote: boolean;
   changeFromDefault: boolean;
   quotePercentage: number;

   //generation
   maxLeads: number;
   spendInCents: number;
   targetCplInCents: number;

   //relations
   matchedLeadsProduct: MatchedLeadsProductDto;
   externalJob!: ExternalJobDto;

   //internal
   spend: number;
   targetCpl: number;
}