<div class="grid grid-cols-12 gap-3">
  <div class="col-span-12 text-center mb-2">
    <div class="text-xl font-bold">Select Location and Radius</div>
    <div class="">Click the mouse to set radius center-point</div>
  </div>
  <div class="col-span-12">
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>City Search</mat-label>
      <mat-icon matPrefix svgIcon="search"></mat-icon>
      <input type="text" placeholder="Search for a city..." aria-label="City Search" matInput [(ngModel)]="searchText"
        [autocomplete]="'off'" (ngModelChange)="seachTextDidChange(searchText)">
    </mat-form-field>
    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!--content-->
    <div class="grid grid-cols-12 gap-3" *ngIf="viewState == viewStates.content" style="max-height: 120px;
    overflow-y: scroll;">
      <div *ngFor="let result of results" class="col-span-12" style="border: 1px #f0f0f0 solid;
      padding: 8px;
      border-radius: 5px;
      cursor: pointer;"
      (click)="didSelectResult(result)">
        <div>{{result.name}}</div>
      </div>
    </div>

  </div>

  <div class="col-span-12">
    <mgl-map [style]="'mapbox://styles/mapbox/streets-v11'" [style.width]="'100%'" [style.height]="'400px'"
      [style.borderRadius]="'16px'" [zoom]="3" [center]="[-95.7, 37]" (mapCreate)="map = $event"
      (mapLoad)="onMapLoad($event)" [attributionControl]="true" [preserveDrawingBuffer]="true"
      (mapClick)="onMapClick($event)">
      <mgl-marker [lngLat]="leadRadius.lngLat" [draggable]="true" (markerDragEnd)="onMarkerDragEnd($event)">
      </mgl-marker>
    </mgl-map>
  </div>

  <div class="col-span-6" *ngIf="leadRadius != null">
    <div class="mr-2 font-bold">Location</div>
    <div *ngIf="leadRadius.name != null">{{leadRadius.name}}</div>
    <div>{{leadRadius.lngLat.lat}}, {{leadRadius.lngLat.lng}}</div>
  </div>

  <div class="col-span-6" *ngIf="leadRadius != null">
    <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full">
      <mat-label>Radius (mi.)</mat-label>
      <input type="number" matInput [(ngModel)]="leadRadius.radius" (ngModelChange)="updateMapRadius()"
        [autocomplete]="'off'" [placeholder]="'50'" />
    </mat-form-field>
  </div>


  <div class="col-span-12">
    <hr class="my-2" />
  </div>
  <div class="col-span-12 md:col-span-6">
    <button *ngIf="leadRadius == null" mat-stroked-button class="w-full mt-2" color="warn" (click)="didClickDismiss()">
      <mat-icon class="mr-2" svgIcon="delete_outline"></mat-icon>
      Dismiss
    </button>
    <button *ngIf="leadRadius != null" mat-stroked-button class="w-full mt-2" color="warn"
      (click)="didClickClearRadius()">
      <mat-icon class="mr-2" svgIcon="delete_outline"></mat-icon>
      Clear Radius
    </button>
  </div>
  <div class="col-span-12 md:col-span-6">
    <button mat-stroked-button class="w-full mt-2" color="primary" (click)="didClickDismiss('confirm')"
      [disabled]="leadRadius == null">
      <mat-icon class="mr-2" svgIcon="location_searching"></mat-icon>
      Confirm Radius
    </button>
  </div>
</div>