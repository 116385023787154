<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<!--content-->
<div *ngIf="viewState == viewStates.content" class="grid grid-cols-12 gap-3 text-center" style="min-width: 480px;">
  <div class="col-span-12">
    <div>
      <img class="w-8 h-8 inline" src="{{jobPosting.jobPostingType.imageUrl}}" /> 
      <span class="ml-2 text-2xl">{{jobPosting.jobPostingType.name}} Job Posting</span>
    </div>
    <div class="font-bold text-primary text-xl">#{{jobPosting.referenceId}}</div>
    <div>{{moment(jobPosting.timestampCreated).format('lll')}}</div>
  </div>

  <div class="col-span-12">
    <hr class="mt-0 mb-0" />
  </div>

  <!--narrative-->
  <div class="col-span-12">
    <div class="text-sm text-primary font-bold">Job Posting Info</div>
    <div style="white-space: pre-wrap; max-height: 350px; overflow: scroll; text-align: left;">
      {{jobPostingSerialized()}}
    </div>
  </div>

  <!--resolution-->
  <div class="col-span-12">
    <hr class="mt-0 mb-3" />
    <div *ngIf="!jobPosting.read" class="text-sm font-bold">Status: Unworked</div>
    <div *ngIf="jobPosting.read" class="text-sm text-primary font-bold">
      <span>Status: Worked</span>
    </div>

    <!-- <button *ngIf="jobPosting.resolvingAdministrator != null" mat-flat-button class="w-full mt-3" (click)="markResolved()">
      Update Resolution
    </button> -->
    <button mat-stroked-button color="primary" class="w-full mt-3" (click)="toggleResolved()">
      <span *ngIf="!jobPosting.read" class="text-sm font-bold">Mark as Worked</span>
      <span *ngIf="jobPosting.read" class="text-sm font-bold">Mark as Unworked</span>
    </button>
  </div>

  <!--actions-->
  <div class="col-span-12">
    <hr class="mt-2 mb-2" />
  </div>

  <div class="col-span-12">
    <button mat-flat-button color="primary" class="w-full" (click)="didClickDismiss()">Dismiss</button>
  </div>
  <!-- <div class="col-span-6">
    <button mat-flat-button color="warn" class="w-full" (click)="deleteLogEntry()">Delete</button>
  </div> -->
</div>