import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AdDto, CompanyDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-ads-list',
  templateUrl: './ads-list.component.html',
  // styleUrls: ['./ads.component.css']
})
export class AdsListComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  //inputs
  @Input() adsRoute: string;
  @Input() company: CompanyDto;

  //view children
  @ViewChild('adsTable', { read: MatSort, static: false }) adsTableMatSort: MatSort;

  //vars
  ads: AdDto[] = []
  filteredAds: AdDto[] = []
  searchText: string = '';
  adsDataSource: MatTableDataSource<AdDto> = new MatTableDataSource();
  adsTableColumns: string[] = ['name'];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addAdViewState = ViewState.content;

  ngOnInit() {
    this.titleService.setTitle('Ads | Admin');

  }

  ngAfterViewInit() {
    this.getAds();
  }

  //api
  getAds(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}${this.adsRoute}`)
      .subscribe((result: AdDto[]) => {
        this.ads = result;
        this.filteredAds = this.ads;
        this.viewState = ViewState.content;
        this.adsDataSource = new MatTableDataSource(this.filteredAds);
        this.updateSorting(this.filteredAds);
      });
  }

  seachTextDidChange(text: string) {
    this.filteredAds = this.ads.filter(r =>
      r.name.toLowerCase().includes(text.toLowerCase())
    )
    this.updateSorting(this.filteredAds);
  }

  updateSorting(ads: AdDto[]) {
    this.adsDataSource = new MatTableDataSource(ads);
    this.adsDataSource.sort = this.adsTableMatSort;
    this.adsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
      return item[property];
   };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

}
