import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CollectiveDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import moment from 'moment';

@Component({
  selector: 'app-collectives',
  templateUrl: './collectives.component.html',
  // styleUrls: ['./collectives.component.css']
})
export class CollectivesComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  //view children
  @ViewChild('collectivesTable', { read: MatSort, static: false }) collectivesTableMatSort: MatSort;

  //vars
  collectives: CollectiveDto[] = []
  filteredCollectives: CollectiveDto[] = []
  searchText: string = '';
  collectivesDataSource: MatTableDataSource<CollectiveDto> = new MatTableDataSource();
  collectivesTableColumns: string[] = ['name', 'companies'];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addCollectiveViewState = ViewState.content;

  ngOnInit() {
    this.titleService.setTitle('Collectives | Admin');

  }

  ngAfterViewInit() {
    this.getCollectives();
  }

  //api
  getCollectives(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/collectives`)
      .subscribe((result: CollectiveDto[]) => {
        this.collectives = result;
        this.filteredCollectives = this.collectives;
        this.viewState = ViewState.content;
        this.collectivesDataSource = new MatTableDataSource(this.filteredCollectives);
        this.updateSorting(this.filteredCollectives);
      });
  }

  seachTextDidChange(text: string) {
    this.filteredCollectives = this.collectives.filter(r =>
      r.name.toLowerCase().includes(text.toLowerCase())
    )
    this.updateSorting(this.filteredCollectives);
  }

  updateSorting(collectives: CollectiveDto[]) {
    this.collectivesDataSource = new MatTableDataSource(collectives);
    this.collectivesDataSource.sort = this.collectivesTableMatSort;
    this.collectivesDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
      return item[property];
   };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

}
