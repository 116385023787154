<div class="flex items-center mt-4 sm:mt-0 md:mt-4">
  <!-- Search -->
  <div class="flex-auto">
    <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
      <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
      <input matInput [(ngModel)]="searchText" [autocomplete]="'off'" (ngModelChange)="seachTextDidChange(searchText)"
        [placeholder]="'Filter reports'">
    </mat-form-field>
  </div>
  <!-- Add report button -->
  <button
    *ngIf="product != null && addReportViewState == viewStates.initial"
    class="ml-4" mat-flat-button [color]="'primary'" (click)="didClickAddReport()">
    <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
    <span class="ml-2 mr-1">Add</span>
  </button>
</div>


<!--Add Report-->
<fuse-card *ngIf="addReportViewState != viewStates.initial" class="flex flex-col w-full mb-4 p-8 filter-article mt-4">
  <div class="text-2xl font-semibold leading-tight">Generate Report</div>
  <div class="text-secondary text-md mt-1">Provide a name and period for your new report below</div>

  <!--loading-->
  <div *ngIf="addReportViewState == viewStates.loading">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
  </div>

  <!--content-->
  <div *ngIf="addReportViewState == viewStates.content" class="mt-4">
    <div class="grid grid-cols-12 gap-3">
      <!--name-->
      <div class="col-span-12 md:col-span-6">
        <mat-form-field class="w-full">
          <mat-label>Report Name</mat-label>
          <input matInput [(ngModel)]="reportToAdd.name" [autocomplete]="'off'"
            placeholder="e.g. 2022-01-01 Company Name">
          <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:presentation-c'"></mat-icon>
        </mat-form-field>
      </div>
      <!--period-->
      <div class="col-span-12 md:col-span-6">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Period</mat-label>
          <mat-select [(value)]="reportToAdd.spendPeriodId">
            <mat-option *ngFor="let period of periods" [value]="period.id">
              <span>{{moment(period.timestampStart).format('LL')}}</span>
              <span> - </span>
              <span>{{moment(period.timestampEnd).format('LL')}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-3">
      <div class="col-span-12 md:col-span-6">
        <button class="w-full" mat-flat-button [color]="'warn'" (click)="didClickDismissAddReport()">
          <span class="ml-2 mr-1">Cancel</span>
        </button>
      </div>
      <div class="col-span-12 md:col-span-6">
        <button class="w-full" mat-flat-button [color]="'primary'" (click)="didClickConfirmAddReport()"
          [disabled]="!isValidReport(reportToAdd)">
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="ml-2 mr-1">Generate</span>
        </button>
      </div>
    </div>
  </div>
</fuse-card>


<!--list-->
<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<!-- no content -->
<div *ngIf="viewState == viewStates.content && filteredReports.length === 0" class="mt-8">
  <app-generic-no-content title="No Reports Found"
    subtitle="No reports were found please refresh or update your search criteria."
    imageUrl="https://img.icons8.com/color/256/null/accounting.png" class="ml-auto mr-auto mt-5">
  </app-generic-no-content>
</div>

<!-- content -->
<div [hidden]="viewState != viewStates.content || filteredReports.length === 0" class="w-full mt-8">
  <fuse-card class="w-full p-4">
    <div class="w-full overflow-x-auto mx-6">
      <table class="w-full bg-transparent" mat-table matSort [dataSource]="reportsDataSource" [trackBy]="trackByFn"
        #reportsTable>

        <!-- Name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Name
          </th>
          <td mat-cell *matCellDef="let report">
            <a routerLink="/companies/{{company?.id ?? report.matchedLeadsProduct?.company?.id}}/matchedLeadsProducts/{{product?.id ?? report.matchedLeadsProduct?.id}}/reports/{{report.id}}"
              class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
              <mat-icon class="mr-3 text-primary" style="vertical-align: bottom;"
                [svgIcon]="'heroicons_solid:document-report'"></mat-icon>
              <b class="text-slate-600">{{report.name}}</b>
            </a>
          </td>
        </ng-container>

        <!-- Product -->
        <ng-container matColumnDef="product">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Product
          </th>
          <td mat-cell *matCellDef="let report">
            <span class="whitespace-nowrap">
              {{report.matchedLeadsProduct.name}}
            </span>
          </td>
        </ng-container>

        <!-- company -->
        <ng-container matColumnDef="company">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Company
          </th>
          <td mat-cell *matCellDef="let report">
            <img src="{{report.matchedLeadsProduct.company.imageUrl}}" class="w-4 h-4 rounded inline-block"
              style="vertical-align: middle;" />
            <span class="whitespace-nowrap">
              {{report.matchedLeadsProduct.company.name}}
            </span>
          </td>
        </ng-container>

        <!-- timestamp -->
        <ng-container matColumnDef="created">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Created
          </th>
          <td mat-cell *matCellDef="let report">
            <span class="whitespace-nowrap">
              {{moment(report.timestampCreated).format('lll')}}
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="reportsTableColumns"></tr>
        <tr class="order-row h-16" mat-row *matRowDef="let row; columns: reportsTableColumns;"></tr>
        <!-- <tr
              class="h-16 border-0"
              mat-footer-row
              *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
      </table>
    </div>
  </fuse-card>
</div>