import { MatchedLeadsProductDto } from "./matchedLeadsProduct.dto";

export class EditMatchedLeadsProductDto {
    targetCplInCents: number;

    static fromProduct(product: MatchedLeadsProductDto) : EditMatchedLeadsProductDto {
        const dto = new EditMatchedLeadsProductDto();
        dto.targetCplInCents = product.targetCplInCents;

        return dto;
    }
}