<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article text-sm">
  <!--header-->
  <div class="flex items-center justify-between">
    <div class="text-2xl font-semibold leading-tight">Add New Recipient</div>
    <div class="-mr-3">
      <button mat-flat-button [color]="'primary'" [disabled]="!addRecipientIsValid()"
        (click)="addNotificationRequest()">
        <mat-icon [svgIcon]="'add'"></mat-icon>
        <span class="ml-2 mr-1">Add</span>
      </button>
    </div>
  </div>

  <!--loading-->
  <div *ngIf="addLogViewState == viewStates.loading">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
  </div>

  <div *ngIf="addLogViewState == viewStates.content">
    <div class="grid grid-cols-12 gap-3 mt-4">
      <!--  notification type  -->
      <div class="col-span-12 md:col-span-4">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label class="text-primary text-sm font-bold">Notification Type</mat-label>
          <mat-select [(value)]="addNotificationRecipient.logNotificationTypeId">
            <mat-option *ngFor="let type of notificationTypes" [value]="type.id">{{type.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- entry type -->
      <div class="col-span-12 md:col-span-4">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label class="text-primary text-sm font-bold">Entry Type</mat-label>
          <mat-select [(value)]="addNotificationRecipient.logEntryTypeId">
            <mat-option *ngFor="let type of entryTypes" [value]="type.id">{{type.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- recipient -->
      <div class="col-span-12 md:col-span-4">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label class="text-primary text-sm font-bold">Recipient</mat-label>
          <mat-select [(value)]="addNotificationRecipient.receivingAdministratorId">
            <mat-option *ngFor="let administrator of administrators" [value]="administrator.id">{{administrator.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</fuse-card>

<!--Registrants-->
<fuse-card class="mb-4 p-8 grid grid-cols-12 gap-3">
  <div class="text-2xl font-semibold col-span-12">Notification Requests</div>

  <!--requests-->
  <div class="col-span-12 w-full overflow-x-auto">
    <!-- Search -->
    <div class="flex-auto">
      <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
        <input matInput [(ngModel)]="searchText" [autocomplete]="'off'" (ngModelChange)="seachTextDidChange(searchText)"
          [placeholder]="'Filter log requests'">
      </mat-form-field>
    </div>

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- no content -->
    <div *ngIf="viewState == viewStates.content && filteredLogs().length === 0" class="mt-8">
      <app-generic-no-content title="No Recipients Found"
        subtitle="No recipients were found please refresh or update your search criteria."
        imageUrl="https://img.icons8.com/color/256/000000/conference-background-selected.png"
        class="ml-auto mr-auto mt-5"></app-generic-no-content>
    </div>

    <table class="w-full bg-transparent" mat-table matSort [dataSource]="logsDataSource" [trackBy]="trackByFn"
      [hidden]="viewState != viewStates.content || filteredLogs().length === 0" #logsTable>

      <!--id-->
      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          #
        </th>
        <td mat-cell *matCellDef="let log">
          <small class="pr-6 whitespace-nowrap">
            #{{log.id}}
          </small>
        </td>
      </ng-container>

      <!--type-->
      <ng-container matColumnDef="type">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          Type
        </th>
        <td mat-cell *matCellDef="let log">
          <span class="whitespace-nowrap">
            <mat-chip-list>
              <mat-chip *ngIf="log.logEntryType.id == rawEntryTypes.ERROR.id"
                style="background-color: #dc3545; color: white;"><small>{{log.logEntryType.name}}</small></mat-chip>
              <mat-chip *ngIf="log.logEntryType.id == rawEntryTypes.WARNING.id"
                style="background-color: #ffc107; color: white;"><small>{{log.logEntryType.name}}</small></mat-chip>
              <mat-chip *ngIf="log.logEntryType.id == rawEntryTypes.INFO.id"
                style="background-color: #6c757d; color: white;"><small>{{log.logEntryType.name}}</small></mat-chip>
              <mat-chip *ngIf="log.logEntryType.id == rawEntryTypes.USERS.id"
                style="background-color: #007bff; color: white;"><small>{{log.logEntryType.name}}</small></mat-chip>
            </mat-chip-list>
          </span>
        </td>
      </ng-container>

      <!--receiving-->
      <ng-container matColumnDef="receiving">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          To be received by...
        </th>
        <td mat-cell *matCellDef="let log">
          <small class="pr-6 whitespace-nowrap">
            <!--admin-->
            <span *ngIf="log.receivingAdministrator != null">
              <mat-icon *ngIf="log.receivingAdministrator.imageUrl == null" class="w-4 h-4 mr-3 text-primary"
                style="vertical-align: bottom;" [svgIcon]="'heroicons_outline:identification'"></mat-icon>
              <img *ngIf="log.receivingAdministrator.imageUrl != null" src="{{log.receivingAdministrator.imageUrl}}"
                class="w-4 h-4 mr-3 rounded-full object-cover inline-block" style="vertical-align: middle;" />
              <span class="whitespace-nowrap">
                <a routerLink="/staff/{{log.receivingAdministrator.id}}">{{log.receivingAdministrator.name}}</a>
              </span>
            </span>
            <!--service-->
            <span *ngIf="log.generatingService != null">{{log.generatingService.name}}</span>
          </small>
        </td>
      </ng-container>

      <!--type-->
      <ng-container matColumnDef="via">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          Via
        </th>
        <td mat-cell *matCellDef="let log">
          <span class="whitespace-nowrap">
            <small>{{log.logNotificationType.name}}</small>
          </span>
        </td>
      </ng-container>

      <!-- delete -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          Delete
        </th>
        <td mat-cell *matCellDef="let log">
          <span class="whitespace-nowrap">
            <button class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
              [swal]="{ title: 'Delete Notification Request?', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
              (confirm)="deleteNotificationRequest(log)">
              Delete
            </button>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="logsTableColumns"></tr>
      <tr class="order-row h-16" mat-row *matRowDef="let row; columns: logsTableColumns;"></tr>
      <!-- <tr
        class="h-16 border-0"
        mat-footer-row
        *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
    </table>
  </div>
</fuse-card>