<div class="w-full grid grid-cols-12 gap-3">

  <!--Stas & Filters-->
  <fuse-card class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 mb-4 p-4 grid grid-cols-12"
    style="height: fit-content;">
    <div class="text-2xl col-span-12"><b>Stats & Filters</b></div>

    <!-- states -->
    <mat-form-field appearance="fill" class="col-span-12 mt-1">
      <mat-select [(value)]="selectedEntryState" (valueChange)="invalidateStats()">
        <mat-option *ngFor="let state of entryStates" [value]="state.id">{{state.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- date range -->
    <mat-form-field appearance="fill" class="col-span-12">
      <mat-select [(value)]="selectedRange" (valueChange)="getLogs()">
        <mat-option *ngFor="let range of dateRanges" [value]="range.id">{{range.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="col-span-12" *ngIf="selectedRange === dateRangesRaw.CUSTOM.id">
      <mat-form-field appearance="fill" class="w-full">
        <mat-label><small>Custom Date Range*</small></mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" [(ngModel)]="customRange.startDate">
          <input matEndDate placeholder="End date" [(ngModel)]="customRange.endDate"
            (ngModelChange)="customRangeDatePickerDidChange()">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <!-- states -->
    <!-- <mat-form-field appearance="fill" class="col-span-12">
  <mat-select [(value)]="selectedHiringState" (valueChange)="invalidateStats()">
    <mat-option *ngFor="let state of hiringStates" [value]="state.id">{{state.name}}</mat-option>
  </mat-select>
</mat-form-field> -->

    <!--entries-->
    <div class="text-primary col-span-12 mt-2 mb-2"><b>Log Events</b>
    </div>
    <div class="col-span-8">Total</div>
    <div class="col-span-4 text-right pb-1">{{entriesToal()}}</div>
    <div class="col-span-8">Service Events</div>
    <div class="col-span-4 text-right pb-1">{{servicesToal()}}</div>
    <div class="col-span-12">
      <hr class="my-2" />
    </div>

    <!--additional-->
    <!-- <div class="col-span-8">% Max Sends Used</div>
<div class="col-span-4 text-right pb-1">{{percentMaxSends()}}</div>
<div class="col-span-8">CDL Holders</div>
<div class="col-span-4 text-right pb-1">{{cdlHolders()}}</div>
<div class="col-span-8">States</div>
<div class="col-span-4 text-right pb-1">{{states()}}</div>
<div class="col-span-12">
  <hr class="my-2" />
</div> -->

    <!--entry types-->
    <div class="text-primary col-span-12 mt-2 mb-2"><b>Entry Types</b></div>
    <div class="col-span-12 grid grid-cols-12" *ngFor="let type of entryTypes">
      <div class="col-span-8">
        <ng-switchery onLabel="{{type.name}}" [(ngModel)]="type.checked" (ngModelChange)="invalidateStats()"
          name="{{type.id}}-{{type.name}}" size="switchery-xs"></ng-switchery>
      </div>
      <div class="col-span-4 text-right pb-1">{{entryTypeTotal(type)}}</div>
    </div>
    <div class="col-span-12 my-2"></div>

    <!--services types-->
    <div class="text-primary col-span-12 mt-2 mb-2"><b>Services</b></div>
    <div class="col-span-12 grid grid-cols-12" *ngFor="let service of services">
      <div class="col-span-8">
        <ng-switchery onLabel="{{service.name}}" [(ngModel)]="service.checked" (ngModelChange)="invalidateStats()"
          name="{{service.id}}-{{service.name}}" size="switchery-xs"></ng-switchery>
      </div>
      <div class="col-span-4 text-right pb-1">{{serviceTypeTotal(service)}}</div>
    </div>
    <div class="col-span-12 my-2"></div>


    <div class="col-span-12 my-2"></div>

  </fuse-card>

  <div class="col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-9">
    <div class="flex items-center sm:mt-4 md:mt-0">
      <!-- Search -->
      <div class="flex-auto">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [(ngModel)]="searchText" [autocomplete]="'off'"
            (ngModelChange)="seachTextDidChange(searchText)" [placeholder]="'Filter log'">
        </mat-form-field>
      </div>
      <!-- Add button -->
      <!-- <a routerLink="/add">
        <button class="ml-4" mat-flat-button [color]="'primary'">
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="ml-2 mr-1">Add</span>
        </button>
      </a> -->
    </div>

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- no content -->
    <div *ngIf="viewState == viewStates.content && filteredLogs().length === 0" class="mt-4">
      <app-generic-no-content title="No Logs Found"
        subtitle="No logs were found please refresh or update your search criteria."
        imageUrl="https://img.icons8.com/color/256/000000/clipboard.png" class="ml-auto mr-auto mt-5">
      </app-generic-no-content>
    </div>

    <!-- content -->
    <div class="w-full mt-4">
      <fuse-card class="w-full p-4" >
        <div class="w-full overflow-x-auto mx-6">
          <cdk-virtual-scroll-viewport tvsItemSize class="wrapper mat-elevation-z2">
            <table class="w-full bg-transparent" mat-table matSort [dataSource]="logsDataSource" [trackBy]="trackByFn"
            #logsTable>

            <!--state (resolved)-->
            <ng-container matColumnDef="state">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                State
              </th>
              <td mat-cell *matCellDef="let log">
                <small class="pr-6 whitespace-nowrap">
                  <span *ngIf="!log.needsResolution">-</span>
                  <span *ngIf="log.needsResolution">
                    <mat-icon *ngIf="log.timestampResolved == null" svgIcon="check_box_outline_blank"
                      class="icon-size-5"></mat-icon>
                    <mat-icon *ngIf="log.timestampResolved != null" svgIcon="check_box"
                      class="icon-size-5 text-emerald-500"></mat-icon>
                  </span>
                </small>
              </td>
            </ng-container>

            <!--id-->
            <ng-container matColumnDef="id">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                #
              </th>
              <td mat-cell *matCellDef="let log">
                <small class="pr-6 whitespace-nowrap" (click)="didSelectLogEntry(log)" style="cursor: pointer;">
                  #{{log.id}}
                </small>
              </td>
            </ng-container>

            <!--type-->
            <ng-container matColumnDef="type">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Type
              </th>
              <td mat-cell *matCellDef="let log">
                <span class="whitespace-nowrap">
                  <small class="font-bold">
                    <span *ngIf="log.logEntryType.id == rawEntryTypes.ERROR.id"
                      style="color: #dc3545;"><small>{{log.logEntryType.name.toUpperCase()}}</small></span>
                    <span *ngIf="log.logEntryType.id == rawEntryTypes.WARNING.id"
                      style="color: #ffc107;"><small>{{log.logEntryType.name.toUpperCase()}}</small></span>
                    <span *ngIf="log.logEntryType.id == rawEntryTypes.INFO.id"
                      style="color: #6c757d;"><small>{{log.logEntryType.name.toUpperCase()}}</small></span>
                    <span *ngIf="log.logEntryType.id == rawEntryTypes.USERS.id"
                      style="color: #007bff;"><small>{{log.logEntryType.name.toUpperCase()}}</small></span>
                  </small>
                </span>
              </td>
            </ng-container>

            <!--generation-->
            <ng-container matColumnDef="generation">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Generated by
              </th>
              <td mat-cell *matCellDef="let log">
                <small class="pr-6 whitespace-nowrap">
                  <!--admin-->
                  <span *ngIf="log.generatingAdministrator != null">
                    <mat-icon *ngIf="log.generatingAdministrator.imageUrl == null" class="w-4 h-4 mr-3 text-primary"
                      style="vertical-align: bottom;" [svgIcon]="'heroicons_outline:identification'"></mat-icon>
                    <img *ngIf="log.generatingAdministrator.imageUrl != null"
                      src="{{log.generatingAdministrator.imageUrl}}"
                      class="w-4 h-4 mr-3 rounded-full object-cover inline-block" style="vertical-align: middle;" />
                    <span class="whitespace-nowrap">
                      <a routerLink="/staff/{{log.generatingAdministrator.id}}">{{log.generatingAdministrator.name}}</a>
                    </span>
                  </span>
                  <!--service-->
                  <span *ngIf="log.generatingService != null">
                    <img *ngIf="log.generatingService.imageUrl != null" src="{{log.generatingService.imageUrl}}"
                      class="w-4 h-4 mr-3 rounded object-cover inline-block" style="vertical-align: middle;" />
                    <span>{{log.generatingService.name}}</span>
                  </span>

                </small>
              </td>
            </ng-container>

            <!--narrative-->
            <ng-container matColumnDef="narrative">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Narrative
              </th>
              <td mat-cell *matCellDef="let log">
                <small class="pr-6 whitespace-nowrap">
                  {{(log.narrative ?? '').slice(0, 50)}}
                </small>
              </td>
            </ng-container>

            <!--target-->
            <ng-container matColumnDef="target">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Target
              </th>
              <td mat-cell *matCellDef="let log">
                <small class="pr-6 whitespace-nowrap">
                  <!--admin-->
                  <span *ngIf="log.administrator != null">
                    <mat-icon *ngIf="log.administrator.imageUrl == null" class="w-4 h-4 mr-3 text-primary"
                      style="vertical-align: bottom;" [svgIcon]="'heroicons_outline:identification'"></mat-icon>
                    <img *ngIf="log.administrator.imageUrl != null" src="{{log.administrator.imageUrl}}"
                      class="w-4 h-4 mr-3 rounded-full object-cover inline-block" style="vertical-align: middle;" />
                    <span class="whitespace-nowrap">
                      <a routerLink="/staff/{{log.administrator.id}}">{{log.administrator.name}}</a>
                    </span>
                  </span>
                  <!--agency-->
                  <span *ngIf="log.agency != null">
                    <mat-icon *ngIf="log.agency.imageUrl == null" class="w-4 h-4 mr-3 text-primary"
                      style="vertical-align: bottom;" [svgIcon]="'heroicons_solid:office-building'"></mat-icon>
                    <img *ngIf="log.agency.imageUrl != null" src="{{log.agency.imageUrl}}"
                      class="w-4 h-4 mr-3 rounded object-cover inline-block" style="vertical-align: middle;" />
                    <span class="whitespace-nowrap">
                      <a routerLink="/agencies/{{log.agency.id}}">{{log.agency.name}}</a>
                    </span>
                  </span>
                  <!--collective-->
                  <span *ngIf="log.collective != null">
                    <mat-icon class="w-4 h-4 mr-3 text-primary" style="vertical-align: bottom;"
                      [svgIcon]="'heroicons_solid:collection'"></mat-icon>
                    <span class="whitespace-nowrap">
                      <a routerLink="/collectives/{{log.collective.id}}">{{log.collective.name}}</a>
                    </span>
                  </span>
                  <!--company-->
                  <span *ngIf="log.company != null">
                    <mat-icon *ngIf="log.company.imageUrl == null" class="w-4 h-4 mr-3 text-primary"
                      style="vertical-align: bottom;" [svgIcon]="'heroicons_solid:briefcase'"></mat-icon>
                    <img *ngIf="log.company.imageUrl != null" src="{{log.company.imageUrl}}"
                      class="w-4 h-4 mr-3 rounded object-cover inline-block" style="vertical-align: middle;" />
                    <span class="whitespace-nowrap">
                      <a routerLink="/companies/{{log.company.id}}">{{log.company.name}}</a>
                    </span>
                  </span>
                  <!--contacts-->
                  <span *ngIf="log.contact != null">
                    <mat-icon *ngIf="log.contact.imageUrl == null" class="w-4 h-4 mr-3 text-primary"
                      style="vertical-align: bottom;" [svgIcon]="'contacts'"></mat-icon>
                    <img *ngIf="log.contact.imageUrl != null" src="{{log.contact.imageUrl}}"
                      class="w-4 h-4 mr-3 rounded-full object-cover inline-block" style="vertical-align: middle;" />
                    <span class="whitespace-nowrap">
                      <a routerLink="/contacts/{{log.contact.id}}">{{log.contact.name}}</a>
                    </span>
                  </span>
                  <!--proposal-->
                  <span *ngIf="log.proposal != null">
                    <mat-icon class="w-4 h-4 mr-3 text-primary" style="vertical-align: bottom;"
                      [svgIcon]="'heroicons_solid:presentation-chart-bar'"></mat-icon>
                    <span class="whitespace-nowrap">
                      <a
                        routerLink="/matchedLeadsProducts/{{log.proposal.matchedLeadsProduct.id}}/proposals/{{log.proposal.id}}">{{log.proposal.name}}</a>
                    </span>
                  </span>
                  <!--service-->
                  <span *ngIf="log.service != null">
                    <img *ngIf="log.service.imageUrl != null" src="{{log.service.imageUrl}}"
                      class="w-4 h-4 mr-3 rounded object-cover inline-block" style="vertical-align: middle;" />
                    <span>{{log.service.name}}</span>
                  </span>
                </small>
              </td>
            </ng-container>

            <!-- timestamp -->
            <ng-container matColumnDef="created">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Created
              </th>
              <td mat-cell *matCellDef="let log">
                <span class="whitespace-nowrap">
                  <small>
                    {{moment(log.timestampCreated).format('lll')}}
                  </small>
                </span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="logsTableColumns; sticky: true" class="bg-white"></tr>
            <tr class="order-row h-16" mat-row *matRowDef="let row; columns: logsTableColumns;"></tr>
            <!-- <tr
              class="h-16 border-0"
              mat-footer-row
              *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
          </table>
          </cdk-virtual-scroll-viewport>
          
        </div>
      </fuse-card>
    </div>

  </div>
</div>