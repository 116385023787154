import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AddMatchedLeadsProductEmailRecipientDto, CompanyDto, MatchedLeadsProductDto, MatchedLeadsProductEmailGroupDto, MatchedLeadsProductEmailRecipientDto, MatchedLeadsProductEmailSettingsDto } from 'app/models/dtos';
import { MatchedLeadsProductEmailFormatsLookup, MatchedLeadsProductEmailModesLookup } from 'app/models/lookups';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-matched-leads-product-email-group',
  templateUrl: './matched-leads-product-email-group.component.html',
  // styleUrls: ['./matched-leads-product-email-group.component.css']
})
export class MatchedLeadsProductEmailGroupComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  //inputs
  @Input() company: CompanyDto;
  @Input() product: MatchedLeadsProductDto;
  @Input() group: MatchedLeadsProductEmailGroupDto;

  //outputs
  @Output() didDeleteGroup: EventEmitter<MatchedLeadsProductEmailGroupDto> = new EventEmitter();

  //view children
  @ViewChild('recipientsTable', { read: MatSort, static: false }) recipientsTableMatSort: MatSort;

  //form controls
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  //vars

  recipients: MatchedLeadsProductEmailRecipientDto[] = [];
  searchText: string = '';
  addRecipientText: string = '';
  recipientsDataSource: MatTableDataSource<MatchedLeadsProductEmailRecipientDto> = new MatTableDataSource();
  recipientsTableColumns: string[] = ['email', 'delete'];
  moment = moment;

  emailModes = structuredClone(MatchedLeadsProductEmailModesLookup.values);
  emailFormats = structuredClone(MatchedLeadsProductEmailFormatsLookup.values);

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;
  recipientsViewState = ViewState.loading;
  addRecipientViewState = ViewState.content;

  ngOnInit() {
    this.getRecipients();
  }

  //api
  editGroup() {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/email/groups/${this.group.id}`, MatchedLeadsProductEmailSettingsDto.fromGroup(this.group))
      .subscribe((result: MatchedLeadsProductEmailGroupDto) => {
        this.group = result;
        this.viewState = ViewState.content;
      });
  }

  deleteGroup() {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/email/groups/${this.group.id}`)
      .subscribe(() => {
        this.didDeleteGroup?.emit(this.group);
      });
  }

  getRecipients(): void {
    this.recipientsViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/email/groups/${this.group.id}/recipients`)
      .subscribe((result: MatchedLeadsProductEmailRecipientDto[]) => {
        this.recipients = result;
        this.updateSorting(this.filteredRecipients());
        this.recipientsViewState = ViewState.content;
      });
  }

  addRecipient(): void {
    this.recipientsViewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/email/groups/${this.group.id}/recipients`, new AddMatchedLeadsProductEmailRecipientDto(this.addRecipientText))
      .subscribe((result: MatchedLeadsProductEmailRecipientDto) => {
        this.recipients.push(result);
        this.updateSorting(this.filteredRecipients());
        this.addRecipientText = '';
        this.recipientsViewState = ViewState.content;
      }, (error: Error) => {

      });
  }

  deleteRecipient(recipient: MatchedLeadsProductEmailRecipientDto): void {
    this.recipientsViewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/email/groups/${this.group.id}/recipients/${recipient.id}`)
      .subscribe(() => {
        this.recipients = this.recipients.filter(d => d.id != recipient.id);
        this.updateSorting(this.filteredRecipients());
        this.recipientsViewState = ViewState.content;
      }, (error: Error) => {

      });
  }

  seachTextDidChange(text: string) {
    this.updateSorting(this.filteredRecipients());
  }

  filteredRecipients(): MatchedLeadsProductEmailRecipientDto[] {
    return this.recipients.filter(r =>
      r.email.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  updateSorting(recipients: MatchedLeadsProductEmailRecipientDto[]) {
    this.recipientsDataSource = new MatTableDataSource(recipients);
    this.recipientsDataSource.sort = this.recipientsTableMatSort;
    this.recipientsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
      return item[property];
    };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  isValidGroup(group: MatchedLeadsProductEmailGroupDto) {
    return (group.name ?? '').trim() != '';
  }
}
