<fuse-card class="flex flex-col p-8">
  <div class="flex flex-col w-full filter-article text-sm">
    <!--header-->
    <div class="flex items-center justify-between">
      <div class="text-2xl font-semibold leading-tight">{{webhook.name ?? 'Email Webhook'}}</div>
      <div class="-mr-3">
        <button mat-stroked-button [color]="'primary'" [disabled]="!isValidWebhook(webhook)"
          [swal]="{ title: 'Test Webhook', text:'This will send a request to your webhook url below. You will not receive any confirmation or response in this panel.', confirmButtonText: 'Confirm', icon: 'info', confirmButtonColor: '#EF4444', showCancelButton: true }"
          (confirm)="testWebhook()">
          <mat-icon svgIcon="send"></mat-icon>
          <span class="ml-2 mr-1">Test</span>
        </button>
        <button class="ml-2" mat-flat-button [color]="'primary'" (click)="editWebhook()"
          [disabled]="!isValidWebhook(webhook)">
          <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
          <span class="ml-2 mr-1">Save</span>
        </button>
        <button class="ml-2" mat-flat-button [color]="'warn'"
          [swal]="{ title: 'Delete Webhook', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
          (confirm)="deleteWebhook()">
          <mat-icon [svgIcon]="'delete'"></mat-icon>
          <span class="ml-2 mr-1">Delete</span>
        </button>
      </div>
    </div>

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <div *ngIf="viewState == viewStates.content">
      <div class="grid grid-cols-12 gap-3 mt-4">
        <div class="col-span-12">
          <!--name-->
          <mat-form-field class="w-full">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="webhook.name" [autocomplete]="'off'" placeholder="Webhook Name">
            <mat-icon class="icon-size-5" matSuffix [svgIcon]="'text_fields'"></mat-icon>
          </mat-form-field>
        </div>
        <div class="col-span-12 md:col-span-3">
          <!--method-->
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Method</mat-label>
            <mat-select [(value)]="webhook.method">
              <mat-option *ngFor="let type of webhookMethodTypes" [value]="type.name">{{type.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <div class="col-span-12 md:col-span-4">
          <mat-form-field class="w-full">
            <mat-label>Custom Date Format</mat-label>
            <input matInput [(ngModel)]="webhook.dateFormat" [autocomplete]="'off'" placeholder="default: MM/dd/yyyy">
            <mat-icon class="icon-size-5" matSuffix svgIcon="date_range"></mat-icon>
          </mat-form-field>
        </div> -->
        <div class="col-span-12 md:col-span-9">
          <!--url-->
          <mat-form-field class="w-full">
            <mat-label>URL</mat-label>
            <input matInput [(ngModel)]="webhook.url" [autocomplete]="'off'" placeholder="Webhook URL">
            <mat-icon class="icon-size-5" matSuffix svgIcon="link"></mat-icon>
          </mat-form-field>
        </div>

      </div>

      <!--Request Body-->
      <div class="text-lg"><b>Request Body</b></div>
      <!--list-->
      <!--loading-->
      <div *ngIf="webhookFieldsViewState == viewStates.loading">
        <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
      </div>

      <!-- no content -->
      <div *ngIf="webhookFieldsViewState == viewStates.content && webhookFields.length === 0" class="mt-8 text-center">
        <app-generic-no-content title="No Fields Found"
          subtitle="No webhook body fields were found. Use the button below to create one."
          imageUrl="https://img.icons8.com/color/256/000000/form.png" class="ml-auto mr-auto mt-5">
        </app-generic-no-content>
        <button mat-flat-button color="primary" class="mt-3" (click)="addField()">
          <mat-icon class="icon-size-5" [svgIcon]="'add'"></mat-icon>
          Add Webhook Field
        </button>
      </div>

      <!-- content -->
      <div *ngIf="webhookFieldsViewState == viewStates.content && webhookFields.length > 0" class="w-full mt-8">
        <mat-accordion>
          <mat-expansion-panel *ngFor="let field of webhookFields" (opened)="panelOpenState = true"
            (closed)="panelOpenState = false" class="mb-0">
            <!--header-->
            <mat-expansion-panel-header>
              <mat-panel-title class="font-bold" style="max-width: 180px;">{{field.key}}</mat-panel-title>
              <mat-panel-description>
                <span *ngIf="field.fieldName != 'custom'">[Field] {{fieldTypeForField(field.fieldName).name}}</span>
                <span *ngIf="field.fieldName == 'custom'">[Custom] {{field.customValue}}</span>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <!--loading-->
            <div *ngIf="field.viewState == viewStates.loading">
              <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
            </div>

            <!--content-->
            <div *ngIf="field.viewState == viewStates.content || field.viewState == null"
              class="grid grid-cols-12 gap-3">
              <!--key-->
              <div class="col-span-12 md:col-span-4">
                <mat-form-field class="w-full">
                  <mat-label>Key</mat-label>
                  <input matInput [(ngModel)]="field.key" [autocomplete]="'off'" placeholder="Key">
                </mat-form-field>
              </div>

              <!--fieldName-->
              <div class="col-span-12 md:col-span-4">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Value</mat-label>
                  <mat-select [(value)]="field.fieldName">
                    <mat-option *ngFor="let type of webhookFieldTypes" [value]="type.field">{{type.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!--custom-->
              <div class="col-span-12 md:col-span-4">
                <mat-label>Custom</mat-label>
                <mat-form-field class="w-full">
                  <input matInput [(ngModel)]="field.customValue" [autocomplete]="'off'" placeholder="Custom Value"
                    [disabled]="field.fieldName != 'custom'">
                </mat-form-field>
              </div>

              <!--actions-->
              <div class="col-span-12 md:col-span-6">
                <button mat-flat-button color="warn" class="w-full"
                  [swal]="{ title: 'Delete Field?', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
                  (confirm)="deleteField(field)">
                  <mat-icon class="icon-size-5" [svgIcon]="'delete'"></mat-icon>
                  Delete
                </button>
              </div>
              <div class="col-span-12 md:col-span-6">
                <button mat-flat-button color="primary" class="w-full" (click)="editField(field)">
                  <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:save'"></mat-icon>
                  Save
                </button>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>


        <button mat-flat-button color="primary" class="mt-8 w-full" (click)="addField()">
          <mat-icon class="icon-size-5" [svgIcon]="'add'"></mat-icon>
          Add Webhook Field
        </button>

      </div>
    </div>
  </div>


</fuse-card>