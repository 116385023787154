<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<!--content-->
<div *ngIf="viewState == viewStates.content" class="grid grid-cols-12 gap-3">
  <div class="col-span-12 md:col-span-6 lg:col-span-4 mb-4 p-2">
    <!--basic info-->
    <fuse-card class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
      <div class="text-small text-center text-secondary mb-4">
        <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
        </mat-icon>
        <b>Proposal Info</b>
      </div>
      <div>
        <div class="flex-auto">
          <!-- product summary -->
          <div class="mt-4 mb-4 font-bold text-center">
            <ng-switchery onLabel="Include product summary" [(ngModel)]="proposal.includeProductSummary"
              name="Include product summary"></ng-switchery>
          </div>
          <!--name-->
          <mat-form-field class="w-full">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="proposal.name" [autocomplete]="'off'" placeholder="Proposal Name" >
            <mat-icon class="icon-size-5" matSuffix [svgIcon]="'edit'"></mat-icon>
          </mat-form-field>
          
        </div>
        <button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="editProposal()" >
          <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
          <span class="ml-2 mr-1">Save</span>
        </button>
        <div class="mt-4 text-small text-center text-secondary">Updated
          {{moment(proposal.timestampUpdated).fromNow()}}</div>
      </div>

      <!--delete-->
      <div class="border rounded p-3 mt-4 border-rose-500" >
        <div class="text-warn"><b>Danger Zone</b></div>
        <div><small>These actions are destructive. Proceed with care.</small></div>
        <button class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
          [swal]="{ title: 'Delete Proposal', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
          (confirm)="deleteProposal()">

          Delete
        </button>
      </div>
    </fuse-card>
  </div>

  <div class="col-span-12 md:col-span-6 lg:col-span-8 p-2">
    <!--Gross Quote-->
    <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
      <!--header-->
      <div class="flex items-center justify-between">
        <div class="text-2xl font-semibold leading-tight">Gross Quote</div>
        <div class="-mr-3">
          <button mat-flat-button [color]="'primary'" (click)="editProposal()" >
            <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span class="ml-2 mr-1">Save</span>
          </button>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-3 text-left">
        <div class="col-span-12">
          <!-- generate -->
          <div class="mt-4 mb-4 font-bold">
            <ng-switchery onLabel="Generate Gross Quote" [(ngModel)]="proposal.generateGrossQuote" 
              name="Generate Gross Quote"></ng-switchery>
          </div>
        </div>
        <div *ngIf="proposal.generateGrossQuote" class="col-span-12">
          <!-- generate -->
          <div class="mt-4 mb-4 font-bold">
            <ng-switchery onLabel="Change Gross Quote Percentage from 15%" [(ngModel)]="proposal.changeFromDefault" 
              name="Change Gross Quote Percentage from 15%"></ng-switchery>
          </div>
        </div>
        <div *ngIf="proposal.generateGrossQuote && proposal.changeFromDefault" class="col-span-12">
          <mat-form-field class="w-full mt-4">
            <mat-label>Gross Quote Percentage</mat-label>
            <input type="number" min="0" max="100" matInput [(ngModel)]="proposal.quotePercentage" 
              [autocomplete]="'off'" placeholder="e.g. 10">
            <span class="ml-4 mr-2 text-secondary">%</span>
          </mat-form-field>
        </div>
      </div>
    </fuse-card>
  </div>
</div>