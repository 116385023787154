import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CompanyDto, EditMatchedLeadsProductDto, FileResponseDto, MatchedLeadsProductDto, MatchedLeadsProductListingDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import * as DocumentEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { SocialMediaLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { MyUploadAdapter } from '../../../../../adapters/my-upload-adapter';

@Component({
  selector: 'app-matched-leads-product-listing',
  templateUrl: './matched-leads-product-listing.component.html',
  styleUrls: ['./matched-leads-product-listing.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MatchedLeadsProductListingComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  //inputs
  @Input() company: CompanyDto;
  @Input() product: MatchedLeadsProductDto;

  //outputs
  @Output() didEditProduct: EventEmitter<MatchedLeadsProductDto> = new EventEmitter();

  //vars
  listing: MatchedLeadsProductListingDto;
  moment = moment;

  //lookups
  socialMedias = structuredClone(SocialMediaLookup.values);

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;

  //file upload
  @ViewChild('image') imageElement: ElementRef;

  //editors
  public BiographyEditor: DocumentEditor = DocumentEditor;
  public ProfileEditor: DocumentEditor = DocumentEditor;

  ngOnInit() {
    this.getListing();
  }


  //api
  getListing() {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/listing`)
      .subscribe((result: MatchedLeadsProductListingDto) => {
        this.listing = result;
        this.viewState = ViewState.content;
        this.didEditProduct.emit(this.product);
      });
  }

  editListing(): void {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/listing`, this.listing)
      .subscribe((result: MatchedLeadsProductListingDto) => {
        this.listing = result;
        this.viewState = ViewState.content;
        this.didEditProduct.emit(this.product);
      });
  }

  deleteProduct(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}`)
      .subscribe(() => {
        this._router.navigateByUrl(`/companies/${this.company.id}`);
      });
  }

  async uploadFile(file: File): Promise<FileResponseDto> {
    //package form data
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    //upload
    return (await firstValueFrom(this.http.post(`${environment.services_tdusa_admin}/v1/files`, formData))) as FileResponseDto;
  }

  async editImage(e: Event) {
    //get file
    const file: File = (<HTMLInputElement>e.target).files.item(0);

    //upload
    this.viewState = ViewState.loading;
    const uploadedFile = await this.uploadFile(file);
    this.listing.heroImage = uploadedFile.src;
    this.editListing();
  }

  //document editor
  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );

    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader, this.http);
    };
  }
}
