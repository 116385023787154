import { Component, Input, OnInit } from '@angular/core';
import { ExternalJobDto, ExternalJobPositionDto } from 'app/models/dtos';
import { AnySourceData, Map } from 'mapbox-gl';
import { stripHtml } from 'string-strip-html';
import { TdusaMapElements } from '../tdusa-map-elements';

@Component({
  selector: 'app-external-jobs-map',
  templateUrl: './external-jobs-map.component.html',
  styleUrls: ['./external-jobs-map.component.css']
})
export class ExternalJobsMapComponent implements OnInit {
  constructor() { }

  //inputs
  @Input() job: ExternalJobDto;
  @Input() mapHeight: string = '200px';
  @Input() mapZoom: number = 1;
  @Input() scrollZoom: boolean = false;
  @Input() dragPan: boolean = false;

  //vars
  map: Map;
  hasLoadedZipsSource: boolean = false;
  hasLoadedCitiesSource: boolean = false;

  ngOnInit() {
  }

  onMapLoad(event) {
    this.map = event;

    //map render
    this.map.addSource(TdusaMapElements.statesSourceTag, TdusaMapElements.statesMapSource());
    this.map.addLayer(TdusaMapElements.stateFillsLayer());
    this.updateMapZips();
    this.updateMapCities();
    this.updateMapActiveStates();
  }

  updateMapZips() {
    //remove old source
    if (this.hasLoadedZipsSource) {
      this.map.removeLayer(TdusaMapElements.zipsLayerTag);
      this.map.removeSource(TdusaMapElements.zipsSourceTag);
    }

    this.map.addSource(TdusaMapElements.zipsSourceTag, this.zipsMapSource(this.job.positions.filter(p => p.hiringZip != null)));
    this.map.addLayer(TdusaMapElements.zipsLayer());

    this.hasLoadedZipsSource = true;

    //set bounding box
    // const minLat = Math.min(...this.job.hiringAreas.map(a => a.hiringZip?.lat));
    // const maxLat =  Math.max(...this.job.hiringAreas.map(a => a.hiringZip.lat));
    // const minLng = Math.min(...this.job.hiringAreas.map(a => a.hiringZip.lng));
    // const maxLng =  Math.max(...this.job.hiringAreas.map(a => a.hiringZip.lng));
    // const bounds: mapboxgl.LngLatBoundsLike = [
    //   [minLng, minLat], // southwestern corner of the bounds
    //   [maxLng, maxLat] // northeastern corner of the bounds
    //   ]
    // this.map.fitBounds(bounds,  {
    //   animate: false,
    //   padding: 20
    // });
  }

  updateMapCities() {
    //remove old source
    if (this.hasLoadedCitiesSource) {
      this.map.removeLayer(TdusaMapElements.citiesLayerTag);
      this.map.removeSource(TdusaMapElements.citiesSourceTag);
    }


    this.map.addSource(TdusaMapElements.citiesSourceTag, this.citiesMapSource(this.job.positions.filter(p => p.hiringCity != null)));
    this.map.addLayer(TdusaMapElements.citiesLayer());

    this.hasLoadedCitiesSource = true;
  }

  updateMapActiveStates() {
    this.map.setFilter(TdusaMapElements.statesFillsLayerTag, this.activeStatesFilter());
  }

  //sources
  zipsMapSource(positions: ExternalJobPositionDto[]): AnySourceData {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: positions.map(z => TdusaMapElements.circleMarkerForHiringZip(z.hiringZip, z.radius))
      }
    }
  }

  citiesMapSource(positions: ExternalJobPositionDto[]): AnySourceData {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: positions.map(c => TdusaMapElements.circleMarkerForHiringCity(c.hiringCity, c.radius))
      }
    }
  }

  activeStatesFilter() {
    var x: any[] = ['any'];

    this.job.positions.filter(p => p.hiringZip == null && p.hiringCity == null)
      .forEach(s => {
        x.push(['==', 'postal', s.hiringState.abbreviation]);
      })

    return ['all', ['!=', 'postal', 'HI'], ['!=', 'postal', 'AK'], x];
  }
}
