<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [breadcrumbs]="breadcrumbs()" [terminal]="product?.name ?? 'Matched Leads Product'"
    [imageUrl]="company?.imageUrl" fallbackIcon="account_tree" imgRenderMode="object-contain"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!--tabs-->
    <mat-tab-group animationDuration="0ms" *ngIf="viewState == viewStates.content">
      <!--Dashboard-->
      <mat-tab label="Dashboard">
        <ng-template matTabContent>
          <app-matched-leads-product-dashboard [company]="company" [product]="product">
          </app-matched-leads-product-dashboard>
        </ng-template>
      </mat-tab>

      <!--Overview-->
      <mat-tab label="Overview">
        <ng-template matTabContent>
          <app-matched-leads-product-listing [company]="company" [product]="product"
            (didEditProduct)="didEditProduct($event)"></app-matched-leads-product-listing>
        </ng-template>
      </mat-tab>

      <!--Leads-->
      <mat-tab label="Leads">
        <ng-template matTabContent>
          <app-leads-analytics applicationRoute="v1/companies/{{id}}/matchedLeadsProducts/{{productId}}/applications"
            [permissionsService]="permissionsService"></app-leads-analytics>
        </ng-template>
      </mat-tab>

      <!--ATS-->
      <mat-tab label="ATS" *ngIf="permissionsService.canManage()">
        <ng-template matTabContent>
          <app-matched-leads-product-integrations [company]="company" [product]="product"
            (didEditProduct)="didEditProduct($event)"></app-matched-leads-product-integrations>
        </ng-template>
      </mat-tab>

      <!--Email-->
      <mat-tab label="Email" *ngIf="permissionsService.canManage()">
        <ng-template matTabContent>
          <app-matched-leads-product-email [company]="company" [product]="product"></app-matched-leads-product-email>
        </ng-template>
      </mat-tab>

      <!--Jobs-->
      <mat-tab label="Jobs">
        <ng-template matTabContent>
          <app-matched-leads-product-jobs [company]="company" [product]="product"></app-matched-leads-product-jobs>
        </ng-template>
      </mat-tab>

      <!--Worklist-->
      <mat-tab label="Worklist">
        <ng-template matTabContent>
          <app-matched-leads-product-worklist [company]="company" [product]="product"></app-matched-leads-product-worklist>
        </ng-template>
      </mat-tab>

      <!--Proposals-->
      <mat-tab label="Proposals">
        <ng-template matTabContent>
          <app-proposals-list proposalsRoute="/v1/companies/{{company.id}}/matchedLeadsProducts/{{product.id}}/proposals" [company]="company"
            [product]="product"
            [permissionsService]="permissionsService">
          </app-proposals-list>
        </ng-template>
      </mat-tab>

      <!--Reports-->
      <mat-tab label="Reports">
        <ng-template matTabContent>
          <app-matched-leads-product-reports [company]="company" [product]="product"></app-matched-leads-product-reports>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>