import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AddMatchedLeadsProductHiringAreaDto, AddMatchedLeadsProductHiringCitiesDto, AddMatchedLeadsProductHiringZips, CompanyDto, EditExternalJobPositionDto, EditMappableRadiusDto, EditMatchedLeadsProductHiringArea, EditMatchedLeadsProductHiringStates, ExternalJobDto, ExternalJobPositionDto, HiringCityDto, HiringStateDto, IdListDto, MatchedLeadsProductDto, MatchedLeadsProductHiringAreaDto, MatchedLeadsProductHiringCityDto, MatchedLeadsProductHiringZipDto } from 'app/models/dtos';
import { HiringStatesLookup } from 'app/models/lookups/hiringStates.lookup';
import { TdusaMapElements } from 'app/modules/admin/maps/tdusa-map-elements';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import mapboxgl, { AnySourceData, Map } from 'mapbox-gl';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

@Component({
  selector: 'app-job-criteria-area',
  templateUrl: './job-criteria-area.component.html',
  styleUrls: ['./job-criteria-area.component.css']
})
export class JobCriteriaAreaComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  //inputs
  @Input() job: ExternalJobDto;

  //view children
  @ViewChild('zipsScrollViewport', {static: false}) zipsScrollViewport: CdkVirtualScrollViewport;
  @ViewChild('citiesScrollViewport', {static: false}) citiesScrollViewport: CdkVirtualScrollViewport;

  public companyId: string;
  public productId: string;
  public jobId: string;
  public proposalId: string;

  //vars
  map: Map;
  stateSearchText: string = '';
  zipSearchText: string = '';
  citySearchText: string = '';
  areasSearchText: string = '';
  rawZipsToAdd: string = '';
  rawZipsRadiusToAdd: number = 50;
  rawCitiesToAdd: string = '';
  rawCitiesRadiusToAdd: number = 50;
  hasLoadedZipsSource: boolean = false;
  hasLoadedCitiesSource: boolean = false;
  hasLoadedAreasSource: boolean = false;
  selectedTab: string = 'States';
  cityHiringState = HiringStatesLookup.ALL.id;
  zipHiringState = HiringStatesLookup.ALL.id;

  //view states
  viewStates = ViewState;
  statesViewState = ViewState.content;
  zipsViewState = ViewState.content;
  addZipsViewState = ViewState.initial;
  citiesViewState = ViewState.content;
  addCitiesViewState = ViewState.initial;
  areasViewState = ViewState.content;
  addAreasViewState = ViewState.initial;

  //values
  states: HiringStatesLookup[] = structuredClone(HiringStatesLookup.values).filter(s => s.id != "0");
  hiringStates: HiringStatesLookup[] = structuredClone(HiringStatesLookup.values);
  zips: ExternalJobPositionDto[] = [];
  cities: ExternalJobPositionDto[] = [];
  areas: MatchedLeadsProductHiringAreaDto[] = [];

  //maps
  areaMarkers: mapboxgl.Marker[] = [];

  //tables
  zipsDataSource: TableVirtualScrollDataSource<ExternalJobPositionDto> = new TableVirtualScrollDataSource([]);
  citiesDataSource: TableVirtualScrollDataSource<ExternalJobPositionDto> = new TableVirtualScrollDataSource([]);

  columns(): string[] {
    return [
      'main',
    ];
  }

  ngOnInit() {

    //parse query params
    this.companyId = this.route.snapshot.paramMap.get('id');
    this.productId = this.route.snapshot.paramMap.get('productId');
    this.jobId = this.route.snapshot.paramMap.get('jobId');
    this.proposalId = this.route.snapshot.paramMap.get('proposalId');
  }

  //tabs
  tabClick(tabEvent: MatTabChangeEvent) {
    this.selectedTab = tabEvent.tab.textLabel;

    if(this.selectedTab == 'Zip Codes') {
      this.zipsScrollViewport.checkViewportSize();
    }
    else if(this.selectedTab == 'Cities') {
      this.citiesScrollViewport.checkViewportSize();
    }
  }

  //api
  getStates() {
    this.statesViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}${this.proposalId ? `/proposals/${this.proposalId}` : ''}/jobs/${this.jobId ?? this.job.id}/states`)
      .subscribe((result: ExternalJobPositionDto[]) => {
        this.states.forEach(s => s.checked = result.find(r => r.hiringState.id == s.id) != null);
        this.updateMapActiveStates();
        this.statesViewState = ViewState.content;
      });
  }

  editStates() {
    // this.statesViewState = ViewState.loading;

    //create dto
    const dto = new EditMatchedLeadsProductHiringStates();
    dto.ids = this.activeStates().map(s => s.id);

    //edit
    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}${this.proposalId ? `/proposals/${this.proposalId}` : ''}/jobs/${this.jobId ?? this.job.id}/states`, dto)
      .subscribe(() => {
        // this.statesViewState = ViewState.content;
      });
  }

  getZips() {
    this.zipsViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}${this.proposalId ? `/proposals/${this.proposalId}` : ''}/jobs/${this.jobId ?? this.job.id}/zips`)
      .subscribe((result: ExternalJobPositionDto[]) => {
        this.zips = result;
        // for (let index = 0; index < 2000; index++) {
        //   this.zips.push(this.zips[0]);
        // }
        this.zipsViewState = ViewState.content;
        this.updateZipSorting(this.zips);
        this.updateMapZips();
      });
  }

  addZipCodes(codes: string[]) {
    this.zipsViewState = ViewState.loading;
    this.addZipsViewState = ViewState.initial;

    //create dto
    const dto = new AddMatchedLeadsProductHiringZips();
    dto.zips = codes;
    dto.radius = this.rawZipsRadiusToAdd;

    //add
    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}${this.proposalId ? `/proposals/${this.proposalId}` : ''}/jobs/${this.jobId ?? this.job.id}/zips`, dto)
      .subscribe((results: ExternalJobPositionDto[]) => {
        this.zips = this.zips.concat(results);
        this.zipsViewState = ViewState.content;
        this.rawZipsToAdd = '';
        this.updateMapZips();
        this.updateZipSorting(this.zips);
      }, (error: any) => {
        this.zipsViewState = ViewState.content;
        this.addZipsViewState = ViewState.content;
      });
  }

  editZip(position: ExternalJobPositionDto) {
    //make request
    this.editPosition(position, position => {
      this.updateMapZips();
    });
  }

  deleteZipCode(position: ExternalJobPositionDto) {
    this.deletePosition(position, () => {
      this.zips = this.zips.filter(z => z.id != position.id);
      this.updateMapZips();
      this.updateZipSorting(this.filteredZips());
    });
  }

  deleteZipCodes() {
    this.deletePositions(this.filteredZips(), () => {
      this.zips = this.zips.filter(z => this.filteredZips().find(fz => fz.id == z.id) == null);
      this.zipSearchText = '';
      this.zipHiringState = HiringStatesLookup.ALL.id;

      this.updateMapZips();
      this.updateZipSorting(this.filteredZips());
    });
  }

  getCities() {
    this.citiesViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}${this.proposalId ? `/proposals/${this.proposalId}` : ''}/jobs/${this.jobId ?? this.job.id}/cities`)
      .subscribe((result: ExternalJobPositionDto[]) => {
        this.cities = result;
        // for (let index = 0; index < 2000; index++) {
        //   this.cities.push(this.cities[0]);
        // }
        this.citiesViewState = ViewState.content;
        this.updateCitySorting(this.cities);
        this.updateMapCities();
      });
  }

  addCities(cities: string[]) {
    //ui
    this.citiesViewState = ViewState.loading;
    this.addCitiesViewState = ViewState.initial;

    //build dto
    const dto = new AddMatchedLeadsProductHiringCitiesDto();
    dto.radius = this.rawCitiesRadiusToAdd;
    dto.cities = cities;

    //make request
    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}${this.proposalId ? `/proposals/${this.proposalId}` : ''}/jobs/${this.jobId ?? this.job.id}/cities`, dto)
      .subscribe((result: ExternalJobPositionDto[]) => {
        this.cities = this.cities.concat(result);
        this.updateMapCities();
        this.rawCitiesToAdd = '';
        this.citiesViewState = ViewState.content;
        this.updateCitySorting(this.cities);
      }, error => {
        this.citiesViewState = ViewState.content;
        this.addCitiesViewState = ViewState.content;
      });
  }

  editCity(position: ExternalJobPositionDto) {
    //make request
    this.editPosition(position, position => {
      this.updateMapCities();
    });
  }

  deleteCity(position: ExternalJobPositionDto) {
    this.deletePosition(position, () => {
      this.cities = this.cities.filter(c => c.id != position.id);
      this.updateMapCities();
      this.updateCitySorting(this.filteredCities());
    });
  }

  deleteCities() {
    this.deletePositions(this.filteredCities(), () => {
      this.cities = this.cities.filter(c => this.filteredCities().find(fc => fc.id == c.id) == null);
      this.citySearchText = '';
      this.cityHiringState = HiringStatesLookup.ALL.id;
      
      this.updateMapCities();
      this.updateCitySorting(this.filteredCities());
    });
  }

  getAreas() {
    this.areasViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}${this.proposalId ? `/proposals/${this.proposalId}` : ''}/jobs/${this.jobId ?? this.job.id}/areas`)
      .subscribe((result: MatchedLeadsProductHiringAreaDto[]) => {
        this.areas = result;
        this.areasViewState = ViewState.content;

        this.updateMapAreas();
      });
  }

  addArea(point: mapboxgl.LngLat) {
    //ui
    this.areasViewState = ViewState.loading;

    //build dto
    const dto = new AddMatchedLeadsProductHiringAreaDto();
    dto.radius = 50;
    dto.lat = point.lat;
    dto.lng = point.lng;

    //make request
    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}${this.proposalId ? `/proposals/${this.proposalId}` : ''}/jobs/${this.jobId ?? this.job.id}/areas`, dto)
      .subscribe((result: MatchedLeadsProductHiringAreaDto) => {
        //add areas
        this.areas.push(result);

        //update map
        this.updateMapAreas();

        //update UI
        this.areasViewState = ViewState.content;
      });
  }

  editArea(area: MatchedLeadsProductHiringAreaDto, point: mapboxgl.LngLat) {
    //ui
    this.areasViewState = ViewState.loading;

    //build dto
    const dto = new EditMatchedLeadsProductHiringArea();
    dto.radius = area.radius;
    dto.lat = point?.lat ?? area.lat;
    dto.lng = point?.lng ?? area.lng;

    //make request
    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}${this.proposalId ? `/proposals/${this.proposalId}` : ''}/jobs/${this.jobId ?? this.job.id}/areas/${area.id}`, dto)
      .subscribe((result: MatchedLeadsProductHiringAreaDto) => {
        //update area
        area.name = result.name;
        area.lat = result.lat;
        area.lng = result.lng;

        //update map
        this.updateMapAreas();

        //update UI
        this.areasViewState = ViewState.content;
      });
  }

  deleteArea(area: MatchedLeadsProductHiringAreaDto) {
    //edit
    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}${this.proposalId ? `/proposals/${this.proposalId}` : ''}/jobs/${this.jobId ?? this.job.id}/areas/${area.id}`)
      .subscribe(() => {
        this.areas = this.areas.filter(a => a.id != area.id);
        this.updateMapAreas();
      });
  }

  editPosition(position: ExternalJobPositionDto, callback: (result: ExternalJobPositionDto) => void) {
    //make request
    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}${this.proposalId ? `/proposals/${this.proposalId}` : ''}/jobs/${this.jobId ?? this.job.id}/positions/${position.id}`, EditExternalJobPositionDto.fromPosition(position))
      .subscribe((result: ExternalJobPositionDto) => {
        callback(result);
      });
  }

  deletePosition(position: ExternalJobPositionDto, callback: () => void) {
    //edit
    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}${this.proposalId ? `/proposals/${this.proposalId}` : ''}/jobs/${this.jobId ?? this.job.id}/positions/${position.id}`)
      .subscribe(() => {
        callback();
      });
  }

  deletePositions(positions: ExternalJobPositionDto[], callback: () => void) {
    const idsList = new IdListDto();
    idsList.ids = positions.map(p => p.id);

    //edit
    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}${this.proposalId ? `/proposals/${this.proposalId}` : ''}/jobs/${this.jobId ?? this.job.id}/positions`, idsList)
      .subscribe(() => {
        callback();
      });
  }

  //management
  updateZipSorting(zips: ExternalJobPositionDto[]) {
    this.zipsDataSource = new TableVirtualScrollDataSource(zips);
  }

  zipSearchTextDidChange(searchText: string) {
    this.updateZipSorting(this.filteredZips());
  }

  updateCitySorting(cities: ExternalJobPositionDto[]) {
    this.citiesDataSource = new TableVirtualScrollDataSource(cities);
  }

  citySearchTextDidChange(searchText: string) {
    this.updateCitySorting(this.filteredCities());
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  filteredStates(): HiringStatesLookup[] {
    return this.states.filter(s =>
      s.name.toLowerCase().includes(this.stateSearchText.toLowerCase())
      || s.abbreviation.toLowerCase().includes(this.stateSearchText.toLowerCase())
    );
  }

  activeStates(): HiringStatesLookup[] {
    return this.states.filter(s => s.checked);
  }

  allStatesSelected(): Boolean {
    return this.states.filter(s => !s.checked).length == 0;
  }

  filteredZips(): ExternalJobPositionDto[] {
    var zips = this.zips;

    //state filter
    if(this.zipHiringState != '0') {
      zips = zips.filter(c => c.hiringState.id == this.zipHiringState);
    }
    
    //free text filter
    zips = zips.filter(s =>
      s.hiringZip.name.toLowerCase().includes(this.zipSearchText.toLowerCase())
      || s.hiringZip.city.toLowerCase().includes(this.zipSearchText.toLowerCase())
      || s.hiringZip.county.toLowerCase().includes(this.zipSearchText.toLowerCase())
    );

    return zips;
  }

  filteredCities(): ExternalJobPositionDto[] {
    var cities = this.cities;

    //state filter
    if(this.cityHiringState != '0') {
      cities = cities.filter(c => c.hiringState.id == this.cityHiringState);
    }
    
    //free text filter
    cities = cities.filter(s =>
      s.hiringCity.name.toLowerCase().includes(this.citySearchText.toLowerCase())
    );

    return cities;
  }

  filteredAreas(): MatchedLeadsProductHiringAreaDto[] {
    return this.areas.filter(s =>
      s.name.toLowerCase().includes(this.areasSearchText.toLowerCase())
    );
  }

  //actions
  didCheckState(state: HiringStateDto) {
    this.updateMapActiveStates();
    this.editStates();
  }

  didClickSelectAll() {
    const allSelected = this.allStatesSelected();
    this.states.forEach(s => s.checked = !allSelected)
    this.updateMapActiveStates();
    this.editStates();
  }

  didClickAddZips() {
    this.addZipsViewState = ViewState.content;
  }

  didClickAddRawZips(rawZips: string) {
    const zips = rawZips.split('\n');
    this.addZipCodes(zips);
  }

  didClickDeleteZip(zip: ExternalJobPositionDto) {
    this.deleteZipCode(zip);
  }

  didClickAddCities() {
    this.addCitiesViewState = ViewState.content;
  }

  didClickAddRawCities(rawCities: string) {
    const cities = rawCities.split('\n');
    this.addCities(cities);
  }

  didClickDeleteCity(city: ExternalJobPositionDto) {
    this.deleteCity(city);
  }

  didClickAddArea() {
    this.addArea(this.map.getCenter());
  }

  didClickDeleteArea(area: MatchedLeadsProductHiringAreaDto) {
    this.deleteArea(area);
  }

  //map
  onMapLoad(event) {
    // event.target.resize();
    this.map = event;

    this.setupBaseMap();
    this.getStates();
    this.getZips();
    this.getCities();
    // this.getAreas();
  }

  setupBaseMap() {
    this.map.addSource(TdusaMapElements.statesSourceTag, TdusaMapElements.statesMapSource());
    this.map.addLayer(TdusaMapElements.stateFillsLayer());
    this.map.addLayer(TdusaMapElements.stateBordersLayer());
    this.map.addLayer(TdusaMapElements.stateFillsHoverLayer());
    this.map.addLayer(TdusaMapElements.stateFillsActiveLayer());
  }

  onMouseMove(e: mapboxgl.MapMouseEvent) {
    var state = this.activeState(e);

    if (state != null) {
      this.map.setFilter(TdusaMapElements.statesFillsHoverLayerTag, ['==', 'postal', state]);
    } else {
      this.map.setFilter(TdusaMapElements.statesFillsHoverLayerTag, ['any']);
    }
  }

  onMapClick(e: mapboxgl.MapMouseEvent) {
    var clickedState = this.activeState(e);
    const state = this.states.find(s => s.abbreviation == clickedState);

    if (clickedState == null || state == null) { return; }

    //update state selection
    this.states = this.states.map(s => {
      if (s.abbreviation == clickedState) {
        s.checked = !s.checked;
      }
      return s;
    })

    this.updateMapActiveStates();
    this.editStates();
  }

  //sources
  zipsMapSource(hiringZips: ExternalJobPositionDto[]): AnySourceData {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: hiringZips.map(z => TdusaMapElements.circleMarkerForHiringZip(z.hiringZip, z.radius))
      }
    }
  }

  citiesMapSource(hiringCities: ExternalJobPositionDto[]): AnySourceData {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: hiringCities.map(c => TdusaMapElements.circleMarkerForHiringCity(c.hiringCity, c.radius))
      }
    }
  }

  areasMapSource(hiringAreas: MatchedLeadsProductHiringAreaDto[]): AnySourceData {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: hiringAreas.map(a => TdusaMapElements.circleMarkerForHiringArea(a))
      }
    }
  }

  //layers
  activeState(e: mapboxgl.MapMouseEvent): string {
    var features = this.map.queryRenderedFeatures(e.point, {
      layers: [TdusaMapElements.statesFillsLayerTag],
    });

    if (features.length == 0) { return null; }
    return features[0].properties.postal;
  }

  updateMapActiveStates() {
    this.map.setFilter(TdusaMapElements.statesFillsActiveLayerTag, this.activeStatesFilter());
  }

  updateMapZips() {
    //remove old source
    if (this.hasLoadedZipsSource) {
      this.map.removeLayer(TdusaMapElements.zipsLayerTag);
      this.map.removeSource(TdusaMapElements.zipsSourceTag);
    }

    this.map.addSource(TdusaMapElements.zipsSourceTag, this.zipsMapSource(this.zips));
    this.map.addLayer(TdusaMapElements.zipsLayer());

    this.hasLoadedZipsSource = true;
  }

  updateMapCities() {
    //remove old source
    if (this.hasLoadedCitiesSource) {
      this.map.removeLayer(TdusaMapElements.citiesLayerTag);
      this.map.removeSource(TdusaMapElements.citiesSourceTag);
    }

    this.map.addSource(TdusaMapElements.citiesSourceTag, this.citiesMapSource(this.cities));
    this.map.addLayer(TdusaMapElements.citiesLayer());

    this.hasLoadedCitiesSource = true;
  }

  updateMapAreas() {
    //remove old source
    if (this.hasLoadedAreasSource) {
      this.map.removeLayer(TdusaMapElements.areasLayerTag);
      this.map.removeSource(TdusaMapElements.areasSourceTag);
      this.areaMarkers.forEach(a => a.remove());
      this.areaMarkers = [];
    }

    this.map.addSource(TdusaMapElements.areasSourceTag, this.areasMapSource(this.areas));
    this.map.addLayer(TdusaMapElements.areasLayer());
    this.areaMarkers = this.areas.map(a => this.draggableMarkerForHiringArea(a));
    this.areaMarkers.forEach(a => a.addTo(this.map));

    this.hasLoadedAreasSource = true;
  }

  activeStatesFilter() {
    var x: any[] = ['any'];

    this.states.filter(s => s.checked)
      .forEach(s => {
        x.push(['==', 'postal', s.abbreviation]);
      })

    return ['all', ['!=', 'postal', 'HI'], ['!=', 'postal', 'AK'], x];
  }


  draggableMarkerForHiringArea(hiringArea: MatchedLeadsProductHiringAreaDto): mapboxgl.Marker {
    const marker = new mapboxgl.Marker({
      draggable: true,
    })
      .setLngLat([hiringArea.lng, hiringArea.lat]);

    const onDragEnd = () => {
      const lngLat = marker.getLngLat();
      this.editArea(hiringArea, lngLat);
    }

    marker.on('dragend', onDragEnd);

    return marker;
  }
}
