import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { PieChartDataPair } from 'app/models/app/charts/pieChartDataPair';
import { ProductRenewalTypes } from 'app/models/app/productRenewalTypes';
import { ProductSliceTypes } from 'app/models/app/productSliceTypes';
import { AdministratorDto, CompanyDto, MatchedLeadsProductDto, ProductSpendPeriodCalc, ProductSpendPeriodDto, ProductStatusDto } from 'app/models/dtos';
import { LeadGenSettingsDto } from 'app/models/dtos/leadGenSettings.dto';
import { PermissionGroupsLookup, ProductStatusTypesLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import { Map } from 'mapbox-gl';
import moment from 'moment';
import { ApexAxisChartSeries, ApexOptions } from 'ng-apexcharts';

@Component({
  selector: 'app-dashboard-analytics',
  templateUrl: './dashboard-analytics.component.html',
  // styleUrls: ['./dashboard-analytics.component.css']
})
export class DashboardAnalyticsComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  //inputs
  @Input() applicationRoute: string;
  @Input() permissionsService: PermissionsService;

  //view children
  @ViewChild('productsTable', { read: MatSort, static: false }) productsTableMatSort: MatSort;

  //vars
  settings: LeadGenSettingsDto;
  products: MatchedLeadsProductDto[] = []
  _filteredCompanyPerformances: MatchedLeadsProductDto[] = null;
  searchText: string = '';
  productsDataSource: MatTableDataSource<MatchedLeadsProductDto> = new MatTableDataSource();
  productsTableColumns(): string[] {
    if (this.permissionsService?.canAdmin() ?? false) {
      return [
        'name',
        'company',
        'agency',
        'status',
        'futureRenewal',
        'spend',
        'spent',
        'remaining',
        'allocatedPercentage',
        'lastDailyAllocatedSpend',
        'sentLeads',
        'sentPattern',
        'costPerSentLead',
        'targetCpl',
        'priorityScore',
        'addBacks',
        'sendsPerLead',
      ]
    }
    else if (this.permissionsService?.canManage() ?? false) {
      return [
        'name',
        'company',
        'agency',
        'status',
        'futureRenewal',
        'periodAdSpend',
        'amountSpent',
        'spendRemaining',
        'lastDailyAllocatedSpend',
        'sentLeads',
        'sentPattern',
        'costPerSentLead'
      ]
    }
    else {
      return [
        'name',
        'company',
        'agency',
        'status',
        'futureRenewal',
        'sentLeads',
        'sentPattern',
        'costPerSentLead'
      ]
    }
  }
  administrators: AdministratorDto[] = []
  map: Map;
  moment = moment;
  selectedSalesRepId: string;
  selectedOpsRepId: string;
  selectedSliceType: ProductSliceTypes = ProductSliceTypes.STATUS;

  // selectedStatusType: string = CompanyStatusTypesLookup.ALL.id;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  //type lists
  statusTypes = structuredClone(ProductStatusTypesLookup.performanceFilterValues);
  renewedType = ProductRenewalTypes.RENEWED;
  notRenewedType = ProductRenewalTypes.NOT_RENEWED;
  rawStatusTypes = ProductStatusTypesLookup;

  //charts
  applicationSliceTypes: ProductSliceTypes[] = structuredClone(ProductSliceTypes.values);
  // chartAppsOverTime: ApexOptions;
  chartTypeSlices: ApexOptions;
  chartSpendSlices: ApexOptions;
  _slicePieData: PieChartDataPair[];
  _sliceChartData: ApexAxisChartSeries;

  ngOnInit() {
    this.getReps();
  }

  async ngAfterViewInit() {
    this.statusTypes.find(st => st.id === ProductStatusTypesLookup.ACTIVE.id).checked = true;
    this.statusTypes.find(st => st.id === ProductStatusTypesLookup.RENEWAL_REQUIRED.id).checked = true;

    await this.permissionsService.setContent(PermissionGroupsLookup.GLOBAL, '');
    if (this.permissionsService?.canAdmin() ?? false) {
      this.getSettings();
    }
    else {
      this.getProducts();
    }
  }

  //map
  onMapLoad(event) {

  }

  //api
  getReps() {
    // this.repsViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/administrators`)
      .subscribe((result: AdministratorDto[]) => {
        this.administrators = result;
        // this.repsViewState = ViewState.content;
      });
  }

  getSettings(): void {

    this.http
      .get(`${environment.services_tdusa_admin}/v1/settings`)
      .subscribe((result: LeadGenSettingsDto) => {
        this.settings = result;
        this.getProducts();
      });
  }

  getProducts(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/${this.applicationRoute}`)
      .subscribe((result: MatchedLeadsProductDto[]) => {
        this.products = this.generateSortableFields(result);
        this._filteredCompanyPerformances = null;
        this.viewState = ViewState.content;
        this.productsDataSource = new MatTableDataSource(this.filteredCompanyPerformances());
        this.updateSorting(this.filteredCompanyPerformances());
        this.invalidateCharts();
      });
  }

  generateSortableFields(products: MatchedLeadsProductDto[]): MatchedLeadsProductDto[] {
    return products.map(p => {
      p.sentLeads = this.sendLeadsForProduct(p);
      p.costPerSentLead = this.costPerSentLeadForProduct(p);
      p.priorityScore = this.priorityScoreForProduct(p);
      p.allocatedPercentage = p.currentSpendPeriod?.allocationPercentage ?? 0;
      p.spend = p.currentSpendPeriod?.spendInCents ?? 0;
      p.spent = p.currentSpendPeriod?.spentInCents ?? 0;
      p.remaining = p.spend - p.spent;

      return p;
    });
  }

  sendLeadsForProduct(product: MatchedLeadsProductDto): number {
    return product.currentSpendPeriod?.sentLeads ?? 0;
  }

  costPerSentLeadForProduct(product: MatchedLeadsProductDto): number {
    if (product.currentSpendPeriod == null) { return Infinity; }
    return product.currentSpendPeriod.spendInCents / product.currentSpendPeriod.sentLeads;
  }

  priorityScoreForProduct(product: MatchedLeadsProductDto): number {
    if (product.currentSpendPeriod == null) { return 0; }

    const targetCpsl = product.targetCplInCents;
    const cpsl = product.currentSpendPeriod.spendInCents / product.currentSpendPeriod.sentLeads;

    return cpsl / targetCpsl;
  }

  filteredCompanyPerformances(): MatchedLeadsProductDto[] {
    //if no cache hit, calculate based on free text and filters in place
    if (this._filteredCompanyPerformances == null) {
      var tfPerformances = this.typeFilteredPerformances(this.products);

      if (this.searchText.trim() == '') {
        this._filteredCompanyPerformances = tfPerformances;
        console.log(`empty: ${this._filteredCompanyPerformances}`);
      }
      else {
        const lowerSearchText = this.searchText.toLowerCase();
        this._filteredCompanyPerformances = tfPerformances
          .filter(r =>
            r.company.name.toLowerCase().includes(lowerSearchText)
            || (r.name?.toLowerCase() ?? '').toLowerCase().includes(lowerSearchText)
            || (r.notes?.toLowerCase() ?? '').toLowerCase().includes(lowerSearchText)
            || (r.company.agency?.name?.toLowerCase() ?? '').toLowerCase().includes(lowerSearchText)
            || (r.productStatus?.productStatusType?.name?.toLowerCase() ?? '').toLowerCase().includes(lowerSearchText)
            || (r.currentSpendPeriod?.freeTrial ? 'free trial' : '').includes(lowerSearchText)
          );
        console.log(`filtered: ${this._filteredCompanyPerformances}`);
      }
    }
    return this._filteredCompanyPerformances;
  }

  typeFilteredPerformances(performances: MatchedLeadsProductDto[]): MatchedLeadsProductDto[] {
    var filtPerformances = performances;

    //ops rep
    if (this.selectedOpsRepId != undefined && this.selectedOpsRepId != '0') {
      filtPerformances = filtPerformances.filter(a => a.company.opsRep?.id == this.selectedOpsRepId);
    }

    //sales rep
    if (this.selectedSalesRepId != undefined && this.selectedSalesRepId != '0') {
      filtPerformances = filtPerformances.filter(a => a.company.salesRep?.id == this.selectedSalesRepId);
    }

    //status types
    if (this.statusTypes.filter(st => st.checked).length > 0) {
      filtPerformances = filtPerformances.filter(a => {
        return this.statusTypes.filter(dt => dt.checked)
          .find(dt => dt.id == a.productStatus?.productStatusType?.id) != undefined;
      });
    }

    //renewal types
    if (this.renewedType.checked && !this.notRenewedType.checked) {
      filtPerformances = filtPerformances.filter(a => a.futureSpendPeriod != null);
    }
    else if (!this.renewedType.checked && this.notRenewedType.checked) {
      filtPerformances = filtPerformances.filter(a => a.futureSpendPeriod == null);
    }

    return filtPerformances;
  }

  updateSorting(products: MatchedLeadsProductDto[]) {
    this.productsDataSource = new MatTableDataSource(products);
    this.productsDataSource.sort = this.productsTableMatSort;
    this.productsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
      return item[property];
    };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  seachTextDidChange(text: string) {
    this.invalidateStats();
  }

  invalidateStats() {
    this._filteredCompanyPerformances = null;
    this.updateSorting(this.filteredCompanyPerformances());
    this.invalidateCharts();
  }

  statusTypeTotal(type: ProductStatusTypesLookup): number {
    return this.filteredCompanyPerformances()
      .filter(a => a.productStatus?.productStatusType?.id == type.id)
      .length;
  }

  renewalTypeTotal(type: ProductRenewalTypes): number {
    switch (type.id) {
      case ProductRenewalTypes.RENEWED.id:
        return this.filteredCompanyPerformances()
          .filter(a => a.futureSpendPeriod != null)
          .length;
      case ProductRenewalTypes.NOT_RENEWED.id:
        return this.filteredCompanyPerformances()
          .filter(a => a.futureSpendPeriod == null)
          .length;
      default:
        return 0;
    }
  }

  sentLeadsTotal(): number {
    return this.filteredCompanyPerformances()
      .map(l => l.sentLeads)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  periodAdSpendTotalRaw(): number {
    return this.filteredCompanyPerformances()
      .filter(c => c.currentSpendPeriod != null)
      .map(c => c.currentSpendPeriod)
      .map(l => (l.spendInCents ?? 0) / 100)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  periodAdSpendTotal(): string {
    var total = this.periodAdSpendTotalRaw();
    return `$${total.toFixed(2)}`;
  }

  periodAdSpendTrialRaw(): number {
    return this.filteredCompanyPerformances()
      .filter(c => c.currentSpendPeriod != null)
      .map(c => c.currentSpendPeriod)
      .filter(c => c.freeTrial)
      .map(l => l.spendInCents / 100)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  periodAdSpendTrial(): string {
    const total = this.periodAdSpendTrialRaw();
    return `$${total.toFixed(2)}`;
  }

  periodAdSpendAllocRaw(): number {
    return this.filteredCompanyPerformances()
      .filter(c => c.currentSpendPeriod != null)
      .map(c => c.currentSpendPeriod)
      .filter(c => !c.freeTrial)
      .map(l => (l.spendInCents / 100) * (l.allocationPercentage / 100) * (this.settings.percentageAllocation / 100))
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  periodAdSpendAlloc(): string {
    const total = this.periodAdSpendTotalRaw() - this.periodAdSpendTrialRaw();
    return `$${total.toFixed(2)}`;
  }

  periodAmountSpentTotal(): string {
    var total = this.filteredCompanyPerformances()
      .filter(c => c.currentSpendPeriod != null)
      .map(c => c.currentSpendPeriod)
      .map(l => l.spentInCents / 100)
      .reduce((partialSum, a) => partialSum + a, 0);
    return `$${total.toFixed(2)}`;
  }

  periodAmountSpendRemainingTotal(): string {
    var total = this.filteredCompanyPerformances()
      .filter(c => c.currentSpendPeriod != null)
      .map(c => c.currentSpendPeriod)
      .map(l => (l.spendInCents - l.spentInCents) / 100)
      .reduce((partialSum, a) => partialSum + a, 0);
    return `$${total.toFixed(2)}`;
  }

  //chart
  invalidateCharts() {
    //invalidate local caches
    this._slicePieData = null;
    this._sliceChartData = null;

    //rebuild charts
    this.buildCharts();
  }

  buildCharts() {
    this.chartSpendSlices = {
      series: this.sliceSpendChartData().map(t => t.value),
      chart: {
        width: '100%',
        height: 400,
        type: 'donut',
      },
      labels: this.sliceSpendChartData().map(t => t.label),
      legend: {
        position: 'bottom',
        offsetY: 40,
        show: false
      }
    };

    this.chartTypeSlices = {
      series: this.sliceChartData(this.selectedSliceType).map(t => t.value),
      chart: {
        width: '400px',
        height: 400,
        type: 'donut',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            width: '100%',
          }
        }
      ],
      labels: this.sliceChartData(this.selectedSliceType).map(t => t.label),
      // legend: {
      //   position: 'bottom',
      //   offsetY: -25,
      //   show: false
      // }
    };
  }

  selectSliceType(type: ProductSliceTypes) {
    this.selectedSliceType = type;
    this.invalidateCharts();
  }

  sliceChartData(type: ProductSliceTypes): PieChartDataPair[] {
    //check cache
    if (this._slicePieData != null) { return this._slicePieData; }

    var pairs = {};
    this.filteredCompanyPerformances().forEach(a => {
      var value = a[`${type.propName}`];
      switch (type.propName) {
        case 'productStatus':
          value = (value as ProductStatusDto)?.productStatusType?.name ?? 'Unknown';
          break;
        case 'futureSpendPeriod':
          value = (value == null) ? false : true;
          break;
        case 'company':
          if (type.id === '3') {
            value = (value as CompanyDto)?.salesRep?.name ?? 'Unknown';
          }
          if (type.id === '4') {
            value = (value as CompanyDto)?.opsRep?.name ?? 'Unknown';
          }
        // case 'driverType':
        //   value = (value as LookupDto).name;
        //   break;
        default: break;
      }

      //clean up bool values
      if (typeof value == "boolean") {
        value = value ? 'Yes' : 'No';
      }

      if (pairs[value] != null) {
        pairs[value].value = pairs[value].value + 1;
      }
      else {
        pairs[value] = new PieChartDataPair(value, 1);
      }
    });

    return Object.values(pairs);
  }

  sliceSpendChartData(): PieChartDataPair[] {
    //check cache
    if (this._slicePieData != null) { return this._slicePieData; }

    var pairs = {
      // 'adSpend': new PieChartDataPair('Ad Spend', 0),
      'amountSpent': new PieChartDataPair('Amount Spent', 0),
      'spendRemaining': new PieChartDataPair('Spend Remaining', 0)
    };
    this.filteredCompanyPerformances().forEach(a => {

      if (a.currentSpendPeriod?.spendInCents != null && a.currentSpendPeriod.spentInCents != null) {
        pairs['spendRemaining'].value = pairs['spendRemaining'].value + (a.currentSpendPeriod.spendInCents / 100) - (a.currentSpendPeriod.spentInCents / 100);
        pairs['amountSpent'].value = pairs['amountSpent'].value + (a.currentSpendPeriod.spentInCents / 100);
      }
    });

    return Object.values(pairs);
  }


  periodAdSpend(): number {
    return this.filteredCompanyPerformances()
      .map(a => (a.currentSpendPeriod?.spendInCents ?? 0) / 100)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  periodAdSpent(): number {
    return this.filteredCompanyPerformances()
      .map(a => (a.currentSpendPeriod?.spentInCents ?? 0) / 100)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  periodSpendRemaining(): number {
    return this.periodAdSpend() - this.periodAdSpent();
  }

  //math
  sendsPerLead(period: ProductSpendPeriodDto): number {
    return ProductSpendPeriodCalc.sendsPerLead(period);
  }

  costPerSentLead(period: ProductSpendPeriodDto): number {
    return ProductSpendPeriodCalc.costPerSentLead(period);
  }

  productSuccessColorClass(product: MatchedLeadsProductDto): string {
    if (product?.currentSpendPeriod == null) { return "bg-white"; }

    const difference = this.productSuccessDifferential(product);
    if (difference >= 0) {
      return 'bg-emerald-200';
    }
    else if (difference < 0 && difference > -20) {
      return 'bg-yellow-200';
    }
    else {
      return 'bg-rose-200';
    }
  }

  productSuccessDifferential(product: MatchedLeadsProductDto): number {
    if (product?.currentSpendPeriod == null) { return 0; }

    const targetLeadsForPeriod = product.currentSpendPeriod.spendInCents / product.targetCplInCents;
    const percentPeriodComplete = Math.max(0, this.daysElapsedInPeriod(product.currentSpendPeriod) / this.daysInPeriod(product.currentSpendPeriod) * 100);
    const percentLeadsFullfilled = (product.currentSpendPeriod.sentLeads + product.currentSpendPeriod.calls) / targetLeadsForPeriod * 100;

    return percentLeadsFullfilled - percentPeriodComplete;
  }

  daysInPeriod(period: ProductSpendPeriodDto): number {
    const periodStart = moment(period.timestampStart).startOf('day');
    const periodEnd = moment(period.timestampEnd).startOf('day');

    const rawDaysInPeriod = periodStart.diff(periodEnd, 'days');
    const positiveDaysInPeriod = Math.abs(rawDaysInPeriod);

    //if there is just one day in the period, we still need to return 1
    const minAdjustedDaysInPeriod = positiveDaysInPeriod + 1;

    return minAdjustedDaysInPeriod;
  }

  daysElapsedInPeriod(period: ProductSpendPeriodDto): number {
    const periodStart = moment(period.timestampStart).startOf('day');
    const periodEnd = moment().startOf('day');

    const rawDaysInPeriod = periodStart.diff(periodEnd, 'days');
    const positiveDaysInPeriod = Math.abs(rawDaysInPeriod);

    return positiveDaysInPeriod;
  }
}
