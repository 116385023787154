<div class="grid grid-cols-12">
  <div class="col-span-12 md:col-span-6 lg:col-span-4 p-2">
    <fuse-card class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
      <!--loading-->
      <div *ngIf="infoViewState == viewStates.loading">
        <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
      </div>

      <div *ngIf="infoViewState == viewStates.content">
        <div class="text-small text-center text-secondary mb-4">
          <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
          </mat-icon>
          <b>Company Info</b>
        </div>

        <div class="form-group">
          <input type="file" style="display: none;" id="resource-image" accept="image/*" (change)="editImage($event)"
            #image>
        </div>
        <img *ngIf="company?.imageUrl == null" class="object-contain w-32 h-32 ml-auto mr-auto cursor-pointer"
          src="https://img.icons8.com/color/256/000000/add-image.png" (click)="imageElement.nativeElement.click()" />
        <img *ngIf="company?.imageUrl != null"
          class="object-contain w-32 h-32 ml-auto mr-auto border rounded cursor-pointer" src="{{company.imageUrl}}"
          alt="Card cover image" (click)="imageElement.nativeElement.click()">

        <div class="text-center text-small text-secondary mt-2">Click image to change</div>
        <div>
          <div class="flex-auto">
            <!--name-->
            <mat-form-field class="w-full">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="company.name" [autocomplete]="'off'" placeholder="Company Name" [disabled]="!permissionsService.canManage()">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </mat-form-field>
            <!--phone-->
            <mat-form-field class="w-full">
              <mat-label>Phone</mat-label>
              <input matInput [(ngModel)]="company.phone" [autocomplete]="'off'" placeholder="Company Phone" [disabled]="!permissionsService.canManage()">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
            </mat-form-field>
            <!--notes-->
            <mat-form-field class="w-full">
              <mat-label>Notes</mat-label>
              <textarea [rows]="3" matInput [(ngModel)]="company.notes" [autocomplete]="'off'" placeholder="Company Notes" [disabled]="!permissionsService.canManage()"></textarea>
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'edit_note'"></mat-icon>
            </mat-form-field>
          </div>
          <button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="editCompany()" [disabled]="!permissionsService.canManage()">
            <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span class="ml-2 mr-1">Save</span>
          </button>
          <div class="mt-4 text-small text-center text-secondary">Updated
            {{moment(company.timestampUpdated).fromNow()}}</div>
        </div>

        <!--delete-->
        <div class="border rounded p-3 mt-4 border-rose-500" *ngIf="permissionsService.canAdmin()">
          <div class="text-warn"><b>Danger Zone</b></div>
          <div><small>These actions are destructive. Proceed with care.</small></div>
          <button class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
            [swal]="{ title: 'Delete Company', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
            (confirm)="deleteCompany()">

            Delete
          </button>
        </div>
      </div>

    </fuse-card>
  </div>
  <div class="col-span-12 md:col-span-6 lg:col-span-8 p-2">

    <fuse-card class="pt-5 pb-5 pl-8 pr-8 w-full grid grid-cols-12 gap-3">
      <div class="text-2xl col-span-12 mb-2"><b>Staff Assignments</b></div>
      <div class="col-span-12 md:col-span-6">
        <div class="text-md text-accent"><b>Sales Rep</b></div>
        <mat-form-field appearance="fill" class="w-full mt-1">
          <mat-select [(value)]="selectedSalesRepId" (valueChange)="editSalesRep()" [disabled]="!permissionsService.canAdmin()">
            <mat-option [value]="undefined">Unassigned</mat-option>
            <mat-option *ngFor="let administrator of administrators" [value]="administrator.id">{{administrator.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-span-12 md:col-span-6">
        <div class="text-md text-accent"><b>Ops Rep</b></div>
        <mat-form-field appearance="fill" class="w-full mt-1">
          <mat-select [(value)]="selectedOpsRepId" (valueChange)="editOpsRep()" [disabled]="!permissionsService.canAdmin()">
            <mat-option [value]="undefined">Unassigned</mat-option>
            <mat-option *ngFor="let administrator of administrators" [value]="administrator.id">{{administrator.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </fuse-card>

    <fuse-card class="pt-5 pb-5 pl-8 pr-8 w-full grid grid-cols-12 gap-3 mt-5">
      <div class="text-2xl col-span-12 mb-2"><b>Associations</b></div>
      <div class="col-span-12">
        <div class="text-md text-accent mb-2"><b>Agency</b></div>
        <div *ngIf="company.agency == null"
          class="flex flex-col items-center justify-center w-full rounded-lg border-2 border-gray-300 border-dashed text-accent bg-default"
          style="min-height: 90px;">
          <div>No company association</div>
        </div>
        <app-agency-card *ngIf="company.agency != null" [agency]="company.agency"></app-agency-card>
      </div>
      <!-- <div class="col-span-12 md:col-span-6">
        <div class="text-md text-accent mb-2"><b>Collective</b></div>
        <div *ngIf="company.collective == null"
          class="flex flex-col items-center justify-center w-full rounded-lg border-2 border-gray-300 border-dashed text-accent bg-default"
          style="min-height: 90px;">
          <div>No collective association</div>
        </div>
        <app-collective-card *ngIf="company.collective != null" [collective]="company.collective"></app-collective-card>
      </div> -->
    </fuse-card>
  </div>
</div> 