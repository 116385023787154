import { BaseLookup } from "./base.lookup";

export class AdTypesLookup extends BaseLookup {

  // Values
  static readonly FACEBOOK_CAMPAIGN = new AdTypesLookup('1', 'Facebook Campaign');
  static readonly FACEBOOK_AD = new AdTypesLookup('2', 'Facebook Ad');
  static readonly GOOGLE_AD = new AdTypesLookup('3', 'Google Ad');

  static readonly values = [
    AdTypesLookup.FACEBOOK_CAMPAIGN,
    AdTypesLookup.FACEBOOK_AD,
    AdTypesLookup.GOOGLE_AD,
  ]
}