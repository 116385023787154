<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<fuse-card *ngIf="viewState == viewStates.content"
  class="flex flex-col ml-auto mr-auto filter-article pt-5 pb-5 pl-8 pr-8">
  <div>
    <div class="text-small text-center text-secondary mb-4">
      <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
      </mat-icon>
      <b>Contact Info</b>
    </div>
    <div class="form-group">
      <input type="file" style="display: none;" id="resource-image" accept="image/*" (change)="editImage($event)"
        #image>
    </div>
    <img *ngIf="contact?.imageUrl == null" class="object-contain w-32 h-32 ml-auto mr-auto cursor-pointer"
      src="https://img.icons8.com/color/256/000000/add-image.png" (click)="imageElement.nativeElement.click()" />
    <img *ngIf="contact?.imageUrl != null"
      class="object-cover rounded w-32 h-32 ml-auto mr-auto border rounded-full cursor-pointer"
      src="{{contact.imageUrl}}" alt="Card cover image" (click)="imageElement.nativeElement.click()">

    <div class="text-center text-small text-secondary mt-2">Tap image to change</div>
    <div>
      <div class="grid grid-cols-12 gap-3">
        <!--name-->
        <mat-form-field class="w-full col-span-12 md:col-span-6">
          <mat-label>Name</mat-label>
          <input matInput [(ngModel)]="contact.name" [autocomplete]="'off'" placeholder="Contact Name">
          <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
        </mat-form-field>
        <!--email-->
        <mat-form-field class="w-full col-span-12 md:col-span-6">
          <mat-label>Email</mat-label>
          <input type="email" matInput [(ngModel)]="contact.email" [autocomplete]="'off'" placeholder="Contact Email">
          <mat-icon class="icon-size-5" matSuffix [svgIcon]="'alternate_email'"></mat-icon>
        </mat-form-field>
        <!--phone-->
        <mat-form-field class="w-full col-span-12 md:col-span-6">
          <mat-label>Phone</mat-label>
          <input matInput [(ngModel)]="contact.phone" [autocomplete]="'off'" placeholder="Contact Phone">
          <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
        </mat-form-field>
        <!--position-->
        <mat-form-field class="w-full col-span-12 md:col-span-6">
          <mat-label>Position</mat-label>
          <input matInput [(ngModel)]="contact.position" [autocomplete]="'off'" placeholder="Contact Position">
          <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:briefcase'"></mat-icon>
        </mat-form-field>
        <!--notes-->
        <mat-form-field class="w-full col-span-12">
          <mat-label>Notes</mat-label>
          <textarea matInput rows="5" [(ngModel)]="contact.notes" [autocomplete]="'off'"
            placeholder="Contact Npyes"></textarea>
          <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:annotation'"></mat-icon>
        </mat-form-field>
      </div>

    </div>
  </div>

  <button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="addContact()" [disabled]="!contactIsValid()">
    <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
    <span class="ml-2 mr-1">Add</span>
  </button>
</fuse-card>