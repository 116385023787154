import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CompanyDto, EditMatchedLeadsProductDto, ExternalJobDto, MatchedLeadsProductDto, MatchedLeadsProductListingDto, ProductSpendPeriodCalc, ProductSpendPeriodDto } from 'app/models/dtos';
import { AddProductSpendPeriodDto } from 'app/models/dtos/addProductSpendPeriod.dto';
import { EditProductSpendPeriodDto } from 'app/models/dtos/editProductSpendPeriod.dto';
import { ProductStatusTypesLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-matched-leads-product-dashboard',
  templateUrl: './matched-leads-product-dashboard.component.html',
  // styleUrls: ['./matched-leads-product-advertising.component.css']
})
export class MatchedLeadsProductDashboardComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  //inputs
  @Input() company: CompanyDto;
  @Input() product: MatchedLeadsProductDto;

  //view children
  @ViewChild('jobsTable', { read: MatSort, static: false }) jobsTableMatSort: MatSort;

  //vars
  jobs: ExternalJobDto[] = []
  searchText: string = '';
  jobsDataSource: MatTableDataSource<ExternalJobDto> = new MatTableDataSource();
  jobsTableColumns: string[] = ['job', 'currentAllocation', 'futureAllocation'];
  moment = moment;

  //view states
  viewStates = ViewState
  currentPeriodViewState = ViewState.content;
  futurePeriodViewState = ViewState.content;
  viewState = ViewState.content;
  allocationsViewState = ViewState.content;

  //lookups
  rawStatusTypes = ProductStatusTypesLookup;

  ngOnInit() {
    this.processTempVars();
    this.getJobs();
  }

  processTempVars() {
    this.product.targetCpl = (this.product.targetCplInCents / 100);
    if (this.product.currentSpendPeriod != null) {
      this.product.currentSpendPeriod.spend = (this.product.currentSpendPeriod?.spendInCents / 100);
    }
    if (this.product.futureSpendPeriod != null) {
      this.product.futureSpendPeriod.spend = (this.product.futureSpendPeriod?.spendInCents / 100);
    }
  }

  //api
  editProduct(): void {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}`, EditMatchedLeadsProductDto.fromProduct(this.product))
      .subscribe((result: MatchedLeadsProductDto) => {
        this.product = result;
        this.processTempVars();
        this.viewState = ViewState.content;
        // this.didEditProduct.emit(this.product);
      });
  }

  getJobs(): void {
    this.allocationsViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/allocations`)
      .subscribe((result: ExternalJobDto[]) => {
        this.jobs = result;
        this.allocationsViewState = ViewState.content;
        this.jobsDataSource = new MatTableDataSource(this.jobs);
        this.updateSorting(this.jobs);
      });
  }

  editCurrentPeriod() {
    this.currentPeriodViewState = this.viewStates.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/currentSpendPeriod`, EditProductSpendPeriodDto.fromSpendPeriod(this.product.currentSpendPeriod))
      .subscribe((result: ProductSpendPeriodDto) => {
        this.product.currentSpendPeriod = result;
        this.processTempVars();
        this.currentPeriodViewState = ViewState.content;
      });
  }

  createCurrentPeriod() {
    this.currentPeriodViewState = this.viewStates.loading;

    //add dto
    const addPeriodDto = new AddProductSpendPeriodDto();
    addPeriodDto.spendInCents = 0;
    addPeriodDto.allocationPercentage = 50;
    addPeriodDto.timestampStart = new Date();
    addPeriodDto.timestampEnd = moment().add(1, 'month').subtract(1, 'day').toDate();

    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/currentSpendPeriod`, addPeriodDto)
      .subscribe((result: ProductSpendPeriodDto) => {
        this.product.currentSpendPeriod = result;
        this.processTempVars();
        this.currentPeriodViewState = ViewState.content;
      });
  }

  suspendCurrentPeriod() {
    this.currentPeriodViewState = this.viewStates.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/currentSpendPeriod`)
      .subscribe(() => {
        this.product.currentSpendPeriod = null;
        this.currentPeriodViewState = ViewState.content;
      });
  }

  editFuturePeriod() {
    this.futurePeriodViewState = this.viewStates.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/futureSpendPeriod`, EditProductSpendPeriodDto.fromSpendPeriod(this.product.futureSpendPeriod))
      .subscribe((result: ProductSpendPeriodDto) => {
        this.product.futureSpendPeriod = result;
        this.processTempVars();
        this.futurePeriodViewState = ViewState.content;
      });
  }

  createFuturePeriod() {
    this.futurePeriodViewState = this.viewStates.loading;

    //add dto
    const addPeriodDto = new AddProductSpendPeriodDto();
    addPeriodDto.spendInCents = this.product.currentSpendPeriod?.spendInCents ?? 0;
    addPeriodDto.allocationPercentage = this.product.currentSpendPeriod?.allocationPercentage ?? 50;
    addPeriodDto.timestampStart = new Date();
    addPeriodDto.timestampEnd = moment().add(1, 'month').subtract(1, 'day').toDate();

    if(this.product.currentSpendPeriod) {
      addPeriodDto.timestampStart = moment(this.product.currentSpendPeriod.timestampEnd).add(1, 'day').toDate();
      addPeriodDto.timestampEnd = moment(addPeriodDto.timestampStart).endOf('month').toDate();
    }

    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/futureSpendPeriod`, addPeriodDto)
      .subscribe((result: ProductSpendPeriodDto) => {
        this.product.futureSpendPeriod = result;
        this.processTempVars();
        this.futurePeriodViewState = ViewState.content;
      });
  }

  suspendFuturePeriod() {
    this.futurePeriodViewState = this.viewStates.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/futureSpendPeriod`)
      .subscribe(() => {
        this.product.futureSpendPeriod = null;
        this.futurePeriodViewState = ViewState.content;
      });
  }

  //actions
  splitCurrentEventy() {

  }

  splitFutureEventy() {

  }

  //validators
  periodIsValid(period: ProductSpendPeriodDto): Boolean {
    return true;
  }

  targetCplDidChange() {
    this.product.targetCplInCents = Math.floor((this.product.targetCpl * 100));

    try {
      if (`${this.product.targetCpl}`.split(".")[1].length > 2) {
        setTimeout(() => {
          this.product.targetCpl = (this.product.targetCplInCents / 100);
        }, 100);
      }
    } catch (error) {

    }
  }

  spendInCentsDidChange(period: ProductSpendPeriodDto) {
    period.spendInCents = Math.floor((period.spend * 100));

    try {
      if (`${period.spend}`.split(".")[1].length > 2) {
        setTimeout(() => {
          period.spend = (period.spendInCents / 100);
        }, 100);
      }
    } catch (error) {

    }
  }

  allocationDidChange(period: ProductSpendPeriodDto) {
    setTimeout(() => {
      if (period.allocationPercentage > 100) {
        period.allocationPercentage = 100;
      }
      else if (period.allocationPercentage < 0) {
        period.allocationPercentage = 0;
      }
      else {
        period.allocationPercentage = Math.floor(period.allocationPercentage);
      }

    }, 100);

  }

  currentAllocationSpend(period: ProductSpendPeriodDto): string {
    return (period.spend * (period.allocationPercentage / 100)).toFixed(2);
  }

  currentRemainingSpend(period: ProductSpendPeriodDto): string {
    return ((period.spendInCents - period.spentInCents) / 100).toFixed(2);
  }

  //allocations
  updateSorting(jobs: ExternalJobDto[]) {
    this.jobsDataSource = new MatTableDataSource(jobs);
    this.jobsDataSource.sort = this.jobsTableMatSort;
    this.jobsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
      return item[property];
    };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  splitAllocation(period: ProductSpendPeriodDto): string {
    if (period == null) { return 'N/A'; }
    return `$${(period.spendInCents * (period.allocationPercentage / 100) / 100 / this.jobs.length).toFixed(2)}`;
  }

  splitAllocationPercentage() {
    return `${(1 / this.jobs.length * 100).toFixed(2)}`;
  }

  //math
  sendsPerLead(): number {
    return ProductSpendPeriodCalc.sendsPerLead(this.product.currentSpendPeriod);
  }

  costPerSentLead(): number {
    return ProductSpendPeriodCalc.costPerSentLead(this.product.currentSpendPeriod);
  }

  callsPlusSentLeads(): number {
    return ProductSpendPeriodCalc.callsPlusSentLeads(this.product.currentSpendPeriod);
  }

  percentChosenPerMatched(): number {
    return ProductSpendPeriodCalc.percentChosenPerMatched(this.product.currentSpendPeriod);
  }

  estimatedLeadsRemaining(): number {
    return Math.floor(this.product.currentSpendPeriod.spendInCents / this.product.targetCplInCents) - this.product.currentSpendPeriod.sentLeads;
  }
}
