import { ProductSpendPeriodDto } from "./productSpendPeriod.dto";

export class EditProductSpendPeriodDto {
    timestampStart!: Date;
    timestampEnd!: Date;
    spendInCents: number;
    allocationPercentage: number;
    freeTrial: boolean;

    static fromSpendPeriod(spendPeriod: ProductSpendPeriodDto) : EditProductSpendPeriodDto {
        var dto = new EditProductSpendPeriodDto();
        dto.timestampStart = spendPeriod.timestampStart;
        dto.timestampEnd = spendPeriod.timestampEnd;
        dto.spendInCents = spendPeriod.spendInCents;
        dto.allocationPercentage = spendPeriod.allocationPercentage;
        dto.freeTrial = spendPeriod.freeTrial;

        return dto;
    }
}