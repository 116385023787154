import { BaseLookup } from "./base.lookup";

export class EndorsementTypesLookup extends BaseLookup {

    abbreviation: string;

    constructor(id: string, name: string, abbreviation: string) {
        super(id, name);
        this.abbreviation = abbreviation;
    }

    // Values
    static readonly HAZARDOUS_MATERIALS = new EndorsementTypesLookup('1', 'H - Hazardous Materials', 'H');
    static readonly TANKER_VEHICLES = new EndorsementTypesLookup('2', 'N - Tanker Vehicles', 'N');
    static readonly DOUBLES_OR_TRIPLES = new EndorsementTypesLookup('3', 'T - Doubles or Triples', 'T');
    static readonly PASSENGERS = new EndorsementTypesLookup('4', 'P - Passengers', 'P');
    static readonly SCHOOL_BUS = new EndorsementTypesLookup('5', 'S - School Bus', 'S');
    static readonly COMBO_FOR_HAZMAT_AND_TANK = new EndorsementTypesLookup('6', 'X - Combo for Hazmat and Tank Vehicles', 'X');
    static readonly TWIC_CARD = new EndorsementTypesLookup('7', 'TWIC Card', 'TWIC');

    static readonly values = [
        EndorsementTypesLookup.HAZARDOUS_MATERIALS,
        EndorsementTypesLookup.TANKER_VEHICLES,
        EndorsementTypesLookup.DOUBLES_OR_TRIPLES,
        EndorsementTypesLookup.PASSENGERS,
        EndorsementTypesLookup.SCHOOL_BUS,
        EndorsementTypesLookup.COMBO_FOR_HAZMAT_AND_TANK,
        EndorsementTypesLookup.TWIC_CARD
    ];

    static override fromId(id: string) : EndorsementTypesLookup {
        return this.values.find(v => v.id == id);
    }
}