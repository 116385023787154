import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ApplicationDto } from 'app/models/dtos';

@Component({
  selector: 'app-lead-status',
  templateUrl: './lead-status.component.html',
  // styleUrls: ['./lead-status.component.css']
})
export class LeadStatusComponent implements AfterViewInit {
  constructor() { }

  //inputs
  @Input() application: ApplicationDto;

  //view child
  @ViewChild('stepper', { static: false }) private stepper: MatStepper;

  ngAfterViewInit(): void {
    this.skipToCurrentStatus();
  }

  skipToCurrentStatus() {
    for (var i = 0; i < this.numberOfInitialNextSkips(this.application); i++) {
      this.stepper.next();
    }
  }

  numberOfInitialNextSkips(application: ApplicationDto): number {
    if (application.blocked) { return 1; }

    if (application.leads.length == 0) { return 0; } //app
    else if (application.leads.filter(l => l.sends.length > 0).length > 0) { return 2; } //sent
    else if (application.leads.length > 0) { return 1; } //matched

    return 0;
  }
}
