// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    auth0_domain: 'tdusa-dev.us.auth0.com',
    auth0_client_id: 'P3XVYBKkvEHJGlWGwP1TrFx3pUP0UU7P',
    auth0_audience: "https://admin-services.tdusadev.com",
    auth0_logout_redirect: "https://admin.tdusadev.com",
    services_tdusa_admin: "https://admin-services.tdusadev.com",
    mapbox: {
        accessToken: 'pk.eyJ1IjoidGR1c2EiLCJhIjoiY2t3bm5zYWczMm41YzJvcWJucm5uNXpjYSJ9.GImKkE1fTUe9x7pmiUqgiw'
      }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
