import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AddProposalDto, CompanyDto, MatchedLeadsProductDto, ProposalDto } from 'app/models/dtos';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import moment from 'moment';

@Component({
  selector: 'app-proposals-list',
  templateUrl: './proposals-list.component.html',
  // styleUrls: ['./proposals.component.css']
})
export class ProposalsListComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  //inputs
  //inputs
  @Input() proposalsRoute: string;
  @Input() company: CompanyDto;
  @Input() product: MatchedLeadsProductDto;
  @Input() permissionsService: PermissionsService;

  //view children
  @ViewChild('proposalsTable', { read: MatSort, static: false }) proposalsTableMatSort: MatSort;

  //vars
  proposals: ProposalDto[] = []
  filteredProposals: ProposalDto[] = []
  searchText: string = '';
  proposalsDataSource: MatTableDataSource<ProposalDto> = new MatTableDataSource();
  proposalsTableColumns: string[] = ['name', 'product', 'company', 'created', 'actions'];
  moment = moment;
  proposalToAdd: AddProposalDto = new AddProposalDto();

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addProposalViewState = ViewState.initial;

  ngOnInit() {
    this.titleService.setTitle('Proposals | Admin');

  }

  ngAfterViewInit() {
    this.getProposals();
  }

  //api
  getProposals(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}${this.proposalsRoute}`)
      .subscribe((result: ProposalDto[]) => {
        this.refreshProposals(result);
      });
  }

  addProposal() {
    this.addProposalViewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}${this.proposalsRoute}`, this.proposalToAdd)
      .subscribe((result: ProposalDto) => {
        this._router.navigateByUrl(`/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/proposals/${result.id}`);
      });
  }

  duplicateProposal(proposal: ProposalDto) {
    this.addProposalViewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/proposals/${proposal.id}/duplicate`, this.proposalToAdd)
      .subscribe((result: ProposalDto) => {
        this._router.navigateByUrl(`/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/proposals/${result.id}`);
      });
  }

  deleteProposal(proposal: ProposalDto): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${proposal.matchedLeadsProduct.company.id}/matchedLeadsProducts/${proposal.matchedLeadsProduct.id}/proposals/${proposal.id}`)
      .subscribe(() => {
        this.proposals = this.proposals.filter(p => p.id != proposal.id);
        this.refreshProposals(this.proposals);
        this.viewState = ViewState.content;
      });
  }

  //filtering / sorting
  refreshProposals(proposals: ProposalDto[]) {
    this.proposals = proposals;
    this.filteredProposals = this.proposals;
    this.viewState = ViewState.content;
    this.proposalsDataSource = new MatTableDataSource(this.filteredProposals);
    this.updateSorting(this.filteredProposals);
  }

  seachTextDidChange(text: string) {
    this.filteredProposals = this.proposals.filter(r =>
      r.name.toLowerCase().includes(text.toLowerCase())
      || (r.matchedLeadsProduct?.name ?? '').toLowerCase().includes(text.toLowerCase())
    )
    this.updateSorting(this.filteredProposals);
  }

  updateSorting(proposals: ProposalDto[]) {
    this.proposalsDataSource = new MatTableDataSource(proposals);
    this.proposalsDataSource.sort = this.proposalsTableMatSort;
    this.proposalsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
      return item[property];
    };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  //add proposal
  didClickAddProposal() {
    this.proposalToAdd = new AddProposalDto();
    this.proposalToAdd.name = `${moment().format("yyyy-MM-DD")} ${this.product.name}`;

    this.addProposalViewState = ViewState.content;
  }

  isValidProposal(addProposal: AddProposalDto) {
    return addProposal.name != null
      && addProposal.name.trim() != '';
  }

  didClickDismissAddProposal() {
    this.addProposalViewState = ViewState.initial;
  }

  didClickConfirmAddProposal() {
    this.addProposal();
  }
}
