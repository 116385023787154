import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CompanyDto, MatchedLeadsProductDto, ProposalDto } from 'app/models/dtos';
import { PermissionGroupsLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-proposal',
  templateUrl: './proposal.component.html',
  // styleUrls: ['./proposal.component.css']
})
export class ProposalComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  public id: string = '0';
  public productId: string = '0';
  public proposalId: string = '0';
  proposal: ProposalDto;
  company: CompanyDto;
  product: MatchedLeadsProductDto;
  breadcrumbs(): GenericBreadcrumb[] {
    return [
      new GenericBreadcrumb(`${this.company?.name ?? 'Company'}`, `/companies/${this.id}`),
      new GenericBreadcrumb(`${this.product?.name ?? 'Product'}`, `/companies/${this.id}/matchedLeadsProducts/${this.productId}`)
    ];
  }

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  async ngOnInit() {
    //update title
    this.titleService.setTitle('Proposal | Admin');

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';
    this.productId = this.route.snapshot.paramMap.get('productId') ?? '0';
    this.proposalId = this.route.snapshot.paramMap.get('proposalId') ?? '0';

    await this.permissionsService.setContent(PermissionGroupsLookup.COMPANY, this.id);
    this.getCompany();
    this.getProduct();
    this.getProposal();
  }

  //api
  getCompany(): void {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}`)
      .subscribe((result: CompanyDto) => {
        this.company = result;
      });
  }

  getProduct(): void {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}/matchedLeadsProducts/${this.productId}`)
      .subscribe((result: MatchedLeadsProductDto) => {
        this.product = result;
      });
  }

  getProposal(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}/matchedLeadsProducts/${this.productId}/proposals/${this.proposalId}`)
      .subscribe((result: ProposalDto) => {
        this.proposal = result;
        this.viewState = ViewState.content;

        //update title
        this.titleService.setTitle(`${this.proposal.name ?? 'Proposal'} | Admin`);
      });
  }

  //callbacks
  didEditProposal(proposal: ProposalDto) {
    this.proposal = proposal;
  }
}
