import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CollectiveDto, CompanyDto } from 'app/models/dtos';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-collective-companies',
  templateUrl: './collective-companies.component.html',
  // styleUrls: ['./collective-companies.component.css']
})
export class CollectiveCompaniesComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  //inputs
  @Input() collective: CollectiveDto;

  //vars
  companies: CompanyDto[] = []
  allCompanies: CompanyDto[] = []
  searchText: string = '';
  addSearchText: string = '';
  companyToAdd: CompanyDto = null;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addViewState = ViewState.initial;

  ngOnInit() {
    this.getCompanies();
    this.getAvailableCompanies();
  }

  getCompanies(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/collectives/${this.collective.id}/companies`)
      .subscribe((result: CompanyDto[]) => {
        this.companies = result;
        this.viewState = ViewState.content;
      });
  }

  getAvailableCompanies(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/collectives/${this.collective.id}/companies/available`)
      .subscribe((result: CompanyDto[]) => {
        this.allCompanies = result;
        this.viewState = ViewState.content;
      });
  }

  addCompany(company: CompanyDto): void {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/collectives/${this.collective.id}/companies/${company.id}`, null)
      .subscribe(() => {
        this.companies.push(company);
        this.allCompanies = this.allCompanies.filter(c => c.id != company.id);
        
        //reset add state
        this.companyToAdd = null;
        this.addViewState = ViewState.initial;
        this.addSearchText = "";

        this.viewState = ViewState.content;
      });
  }

  deleteCompany(company: CompanyDto): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/collectives/${this.collective.id}/companies/${company.id}`)
      .subscribe(() => {
        this.companies = this.companies.filter(c => c.id != company.id);
        this.allCompanies.push(company);
        this.searchText = '';
        this.viewState = ViewState.content;
      });
  }

  availableCompanies() : CompanyDto[] {
    return this.allCompanies.filter(c => this.companies.find(ec => ec.id === c.id) == null)
  }

  filteredCompanies() : CompanyDto[] {
    return this.companies.filter(r =>
      r.name.toLowerCase().includes(this.searchText.toLowerCase())
      || (r.agency?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.salesRep?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.salesRep?.email ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.opsRep?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.opsRep?.email ?? '').toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  filteredAvailableCompanies() : CompanyDto[] {
    return this.availableCompanies().filter(r =>
      r.name.toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.agency?.name ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.salesRep?.name ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.salesRep?.email ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.opsRep?.name ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.opsRep?.email ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
    );
  }
}
