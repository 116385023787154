import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyDto, ContactDto } from 'app/models/dtos';

@Component({
  selector: 'app-contact-card-portrait',
  templateUrl: './contact-card-portrait.component.html',
  // styleUrls: ['./contact-card-portrait.component.css']
})
export class ContactCardPortraitComponent implements OnInit {
  constructor() { }

  @Input() viewContactRoute: string;
  @Input() contact: ContactDto;
  @Input() options: ContactCardPortraitOption[] = [];
  @Output() selectView: EventEmitter<void> = new EventEmitter();
  @Output() selectOption: EventEmitter<ContactCardPortraitOption> = new EventEmitter();

  inputOptions: ContactCardPortraitOption[]

  ngOnInit() {
    this.inputOptions = this.options;
  }

  didClickView() {
    this.selectView.emit();
  }

  didSelectOption(option: ContactCardPortraitOption) {
    this.selectOption.emit(option);
  }
}


export class ContactCardPortraitOption {

  id: string;
  name: string;
  warn: boolean;

  constructor(id: string, name: string, warn: boolean) {
    this.id = id;
    this.name = name;
    this.warn = warn;
  }
}