import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from 'app/core/user/user.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'environments/environment';
import { AuthService, User } from '@auth0/auth0-angular';
import { AdministratorDto } from 'app/models/dtos';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: AdministratorDto;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private http: HttpClient,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        @Inject(DOCUMENT) private doc: Document,
        public auth: AuthService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user changes

        this.tryGetUser();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        // this._userService.update({
        //     ...this.user,
        //     status
        // }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        // this._router.navigate(['/sign-out']);
        this.auth.logout({ returnTo: this.doc.location.origin, client_id: environment.auth0_client_id });
    }

    signOutUrl(): string {
        return `https://${environment.auth0_domain}/v2/logout?client_id=${environment.auth0_client_id}&returnTo=` + encodeURIComponent(`${this.doc.location.origin}`);
    }

    tryGetUser() {
        //  this.auth.getUser().subscribe(user =>{
        //     this.user = user;
        //  });

        this.http
            .get(`${environment.services_tdusa_admin}/v1/accounts/me`)
            .subscribe((result: AdministratorDto) => {
                this.user = result;
                this._changeDetectorRef.detectChanges();
            });
    }
}
