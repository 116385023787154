<div class="flex items-center mt-4 sm:mt-0 md:mt-4">
  <!-- Search -->
  <div class="flex-auto">
    <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
      <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
      <input matInput [(ngModel)]="searchText" (ngModelChange)="seachTextDidChange(searchText)" [autocomplete]="'off'"
        [placeholder]="'Filter domains'">
    </mat-form-field>
  </div>
  <!-- Add Domain  -->
  <div class="flex-auto">
    <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50 ml-4 mr-2">
      <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:plus'"></mat-icon>
      <input matInput [(ngModel)]="addDomainText" [autocomplete]="'off'"
        [placeholder]="'Add domain'">
    </mat-form-field>
  </div>
  <!-- Add domain button -->
  <button class="ml-4" mat-flat-button [color]="'primary'" (click)="addBannedDomain()" [disabled]="this.addDomainText.length == 0">
    <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
    <span class="ml-2 mr-1">Add</span>
  </button>
</div>


<!--list-->
<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<!-- no content -->
<div *ngIf="viewState == viewStates.content && filteredDomains().length === 0" class="mt-8">
  <app-generic-no-content title="No Domains Found"
    subtitle="No domains were found please refresh or update your search criteria."
    imageUrl="https://img.icons8.com/color/256/000000/domain--v1.png" class="ml-auto mr-auto mt-5">
  </app-generic-no-content>
</div>

<!-- content -->
<div [hidden]="viewState != viewStates.content || filteredDomains().length === 0" class="w-full mt-8">
  <fuse-card class="w-full p-4">
    <div class="w-full overflow-x-auto mx-6">
      <table class="w-full bg-transparent" mat-table matSort [dataSource]="domainsDataSource"
        [trackBy]="trackByFn" #domainsTable>

        <!-- Name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Name
          </th>
          <td mat-cell *matCellDef="let domain">
            <a routerLink="/domains/{{domain.id}}"
              class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
              <mat-icon class="mr-3 text-primary" style="vertical-align: bottom;"
                [svgIcon]="'heroicons_solid:globe-alt'"></mat-icon>
              <b class="text-slate-600">{{domain.name}}</b>
            </a>
          </td>
        </ng-container>

        <!-- timestamp -->
        <ng-container matColumnDef="created">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Created
          </th>
          <td mat-cell *matCellDef="let domain">
            <span class="whitespace-nowrap">
              {{moment(domain.timestampCreated).format('lll')}}
            </span>
          </td>
        </ng-container>

        <!-- delete -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Delete
          </th>
          <td mat-cell *matCellDef="let domain">
            <span class="whitespace-nowrap">
              <button class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
              [swal]="{ title: 'Delete Banned Domain', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
              (confirm)="deleteBannedDomain(domain)">
              Delete
            </button>
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="domainsTableColumns"></tr>
        <tr class="order-row h-16" mat-row *matRowDef="let row; columns: domainsTableColumns;"></tr>
        <!-- <tr
              class="h-16 border-0"
              mat-footer-row
              *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
      </table>
    </div>
  </fuse-card>
</div>