import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { ApplicationDto, LeadDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import moment from 'moment';
import * as mime from 'mime';

@Component({
  selector: 'app-lead-matches',
  templateUrl: './lead-matches.component.html',
  // styleUrls: ['./lead-matches.component.css']
})
export class LeadMatchesComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  //inputs
  @Input() application: ApplicationDto;

  //view children
  @ViewChild('leadsTable', { read: MatSort, static: false }) leadsTableMatSort: MatSort;

  //vars
  leads: LeadDto[] = []
  filteredLeads: LeadDto[] = []
  searchText: string = '';
  leadsDataSource: MatTableDataSource<LeadDto> = new MatTableDataSource();
  leadsTableColumns: string[] = ['company', 'type', 'created', 'sends'];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addLeadViewState = ViewState.content;

  ngOnInit() {
    this.titleService.setTitle('Leads | Admin');

  }

  ngAfterViewInit() {
    this.getLeads();
  }

  //api
  getLeads(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/applications/${this.application.id}/leads`)
      .subscribe((result: LeadDto[]) => {
        this.leads = result;
        this.filteredLeads = this.leads;
        this.viewState = ViewState.content;
        this.leadsDataSource = new MatTableDataSource(this.filteredLeads);
        this.updateSorting(this.filteredLeads);
      });
  }

  seachTextDidChange(text: string) {
    this.filteredLeads = this.leads.filter(r =>
      true
    )
    this.updateSorting(this.filteredLeads);
  }

  updateSorting(leads: LeadDto[]) {
    this.leadsDataSource = new MatTableDataSource(leads);
    this.leadsDataSource.sort = this.leadsTableMatSort;
    this.leadsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
      return item[property];
   };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  fileIconType(mimeType: string): string {
    return mime.getExtension(mimeType);
  }

  formattedDocSize(size: number): string {
    if (size > 1000000) {
      return `${(size / 1000000).toFixed(2)} MB`
    }
    else if (size > 1000) {
      return `${(size / 1000).toFixed(2)} KB`
    }
    else {
      return `${size.toFixed(2)} bytes`
    }
  }
}
