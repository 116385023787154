import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AgencyDto, ContactDto } from 'app/models/dtos';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-contact-agencies',
  templateUrl: './contact-agencies.component.html',
  // styleUrls: ['./contact-agencies.component.css']
})
export class ContactAgenciesComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  //inputs
  @Input() contact: ContactDto;

  //vars
  agencies: AgencyDto[] = []
  allAgencies: AgencyDto[] = []
  searchText: string = '';

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  ngOnInit() {
    this.getAgencies();
  }

  getAgencies(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/contacts/${this.contact.id}/agencies`)
      .subscribe((result: AgencyDto[]) => {
        this.agencies = result;
        this.viewState = ViewState.content;
      });
  }

  filteredAgencies() : AgencyDto[] {
    return this.agencies.filter(r =>
      r.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }
}
