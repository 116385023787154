import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AdministratorDto, EditAdministratorDto, FileResponseDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  // styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  account: AdministratorDto;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  //file upload
  @ViewChild('image') imageElement: ElementRef;

  ngOnInit() {
    //update title
    this.titleService.setTitle(`My Profile | Admin`);

    this.getAccount();
  }

  //api
  getAccount(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/accounts/me`)
      .subscribe((result: AdministratorDto) => {
       this.account = result;
       this.viewState = ViewState.content;
      });
  }

  editAccount(): void {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/accounts/me`, EditAdministratorDto.fromAdministrator(this.account))
      .subscribe((result: AdministratorDto) => {
        this.account = result;
        this.viewState = ViewState.content;
      });
  }

  async uploadFile(file: File) : Promise<FileResponseDto> {
    //package form data
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    //upload
    return (await firstValueFrom(this.http.post(`${environment.services_tdusa_admin}/v1/files`, formData))) as FileResponseDto;
  }

  async editImage(e: Event) {
    //get file
    const file: File = (<HTMLInputElement>e.target).files.item(0);

    //upload
    this.viewState = ViewState.loading;
    const uploadedFile = await this.uploadFile(file);
    this.account.imageUrl = uploadedFile.src;
    if(this.accountIsValid()) {
      this.editAccount();
    }
  }

  //validations
  accountIsValid() : Boolean {
    return this.isValidString(this.account.name);
  }

  isValidString(test: string) : Boolean {
    if(test == null) { return false; }
    return test.trim() != '';
  }
}
