import { IdentifiableDto } from "./identifiable.dto";
import { JobPostingTypeDto } from "./lookup.dto";

export class JobPostingDto extends IdentifiableDto {
    timestampCreated!: Date;
    referenceId: string;
    read: boolean;
    jobPosting: any;

    //relations
    jobPostingTypes: JobPostingTypeDto;
}