
import { AdministratorDto } from "./administrator.dto";
import { AgencyDto } from "./agency.dto";
import { CollectiveDto } from "./collective.dto";
import { CompanyDto } from "./company.dto";
import { ContactDto } from "./contact.dto";
import { IdentifiableDto } from "./identifiable.dto";
import { LogEntryTypeDto, ServiceDto } from "./lookup.dto";
import { ProposalDto } from "./proposal.dto";

export class LogEntryDto extends IdentifiableDto {
    timestampCreated!: Date;
    timestampUpdated!: Date;
    timestampResolved!: Date;
    narrative!: string;
    needsResolution: boolean;
    resolutionNarrative: string;

    //relations
    logEntryType: LogEntryTypeDto;
    generatingAdministrator: AdministratorDto;
    generatingService: ServiceDto;
    agency: AgencyDto;
    company: CompanyDto;
    collective: CollectiveDto;
    administrator: AdministratorDto;
    resolvingAdministrator: AdministratorDto;
    contact: ContactDto;
    proposal: ProposalDto;
    service: ServiceDto;
}