import { ContactDto } from "./contact.dto";
import { IdentifiableDto } from "./identifiable.dto";

export class AgencyDto extends IdentifiableDto {
    id!: string;
    name!: string;
    imageUrl: string;
    timestampCreated!: Date;
    timestampUpdated!: Date;

    //relations
    repContact: ContactDto;
}