<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="Add Company"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <div class="grid grid-cols-12">
      <div class="col-span-12 md:col-span-6 lg:col-span-4 p-2">
        <fuse-card class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
          <!--loading-->
          <div *ngIf="viewState == viewStates.loading">
            <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
          </div>

          <div *ngIf="viewState == viewStates.content">
            <div class="text-small text-center text-secondary mb-4">
              <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
              </mat-icon>
              <b>Company Info</b>
            </div>

            <div class="form-group">
              <input type="file" style="display: none;" id="resource-image" accept="image/*"
                (change)="editImage($event)" #image>
            </div>
            <img *ngIf="company?.imageUrl == null" class="object-contain w-32 h-32 ml-auto mr-auto cursor-pointer"
              src="https://img.icons8.com/color/256/000000/add-image.png"
              (click)="imageElement.nativeElement.click()" />
            <img *ngIf="company?.imageUrl != null"
              class="object-cover w-32 h-32 ml-auto mr-auto border rounded cursor-pointer" src="{{company.imageUrl}}"
              alt="Card cover image" (click)="imageElement.nativeElement.click()">

            <div class="text-center text-small text-secondary mt-2">Click image to change</div>
            <div>
              <div class="flex-auto">
                <!--name-->
                <mat-form-field class="w-full">
                  <mat-label>Name</mat-label>
                  <input matInput [(ngModel)]="company.name" [autocomplete]="'off'" placeholder="Company Name">
                  <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>
                <!--phone-->
                <mat-form-field class="w-full">
                  <mat-label>Phone</mat-label>
                  <input matInput [(ngModel)]="company.phone" [autocomplete]="'off'" placeholder="Company Phone">
                  <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
                </mat-form-field>
              </div>
            </div>
          </div>
        </fuse-card>
      </div>
      <div class="col-span-12 md:col-span-6 lg:col-span-8 p-2">

        <fuse-card class="pt-5 pb-5 pl-8 pr-8 w-full grid grid-cols-12 gap-3">
          <div class="text-2xl col-span-12 mb-2"><b>Staff Assignments</b></div>
          <div class="col-span-12 md:col-span-6">
            <div class="text-md text-accent"><b>Sales Rep</b></div>
            <mat-form-field appearance="fill" class="w-full mt-1">
              <mat-select [(value)]="company.leadSalesAdministratorId">
                <mat-option [value]="undefined">Unassigned</mat-option>
                <mat-option *ngFor="let administrator of administrators" [value]="administrator.id">
                  {{administrator.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-span-12 md:col-span-6">
            <div class="text-md text-accent"><b>Ops Rep</b></div>
            <mat-form-field appearance="fill" class="w-full mt-1">
              <mat-select [(value)]="company.leadOpsAdministratorId">
                <mat-option [value]="undefined">Unassigned</mat-option>
                <mat-option *ngFor="let administrator of administrators" [value]="administrator.id">
                  {{administrator.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </fuse-card>

        <fuse-card class="pt-5 pb-5 pl-8 pr-8 w-full grid grid-cols-12 gap-3 mt-4">
          <div class="text-2xl col-span-12 mb-2"><b>Associations</b></div>
          <div class="col-span-12">
            <div class="text-md text-accent"><b>Agency</b></div>
            <mat-form-field appearance="fill" class="w-full mt-1">
              <mat-select [(value)]="company.agencyId">
                <mat-option [value]="undefined">Unassigned</mat-option>
                <mat-option *ngFor="let agency of agencies" [value]="agency.id">{{agency.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div class="col-span-12 md:col-span-6">
            <div class="text-md text-accent"><b>Collective</b></div>
            <mat-form-field appearance="fill" class="w-full mt-1">
              <mat-select [(value)]="company.collectiveId">
                <mat-option [value]="undefined">Unassigned</mat-option>
                <mat-option *ngFor="let collective of collectives" [value]="collective.id">{{collective.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
        </fuse-card>
      </div>
      <div class="col-span-12">
        <button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="addCompany()"
          [disabled]="!companyIsValid()">
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="ml-2 mr-1">Add</span>
        </button>
      </div>
    </div>

  </div>

</div>