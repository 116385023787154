import { AppAnalyticsDto, ApplicationDto } from "app/models/dtos";
import { AppAnalyticDateContainer, AppAnalytics2Dto } from "app/models/dtos/appAnalytics2.dto";

export class StackedChartAppSet {

    label: string;
    apps: AppAnalyticDateContainer[] = [];
    
    constructor(label: string, apps: AppAnalyticDateContainer[]) {
        this.label = label;
        this.apps = apps;
    }
}