import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { PieChartDataPair } from 'app/models/app/charts/pieChartDataPair';
import { ProductSliceTypes } from 'app/models/app/productSliceTypes';
import { AdministratorDto, CompanyDto, MatchedLeadsProductDto, ProductStatusDto } from 'app/models/dtos';
import { PermissionGroupsLookup, ProductStatusTypesLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import { Map } from 'mapbox-gl';
import moment from 'moment';
import { ApexAxisChartSeries, ApexOptions } from 'ng-apexcharts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  // styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  ngOnInit() {
    //update title
    this.titleService.setTitle('Dashboard | Admin');

    this.permissionsService.setContent(PermissionGroupsLookup.GLOBAL, '');
    this.viewState = this.viewStates.content;
  }
}
