import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AdCampaignDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  // styleUrls: ['./campaigns.component.css']
})
export class CampaignsComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  //view children
  @ViewChild('campaignsTable', { read: MatSort, static: false }) campaignsTableMatSort: MatSort;

  //vars
  campaigns: AdCampaignDto[] = []
  filteredCampaigns: AdCampaignDto[] = []
  searchText: string = '';
  campaignsDataSource: MatTableDataSource<AdCampaignDto> = new MatTableDataSource();
  campaignsTableColumns: string[] = ['name', 'updated', 'adCount'];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addCampaignViewState = ViewState.content;

  ngOnInit() {
    this.titleService.setTitle('Campaigns | Admin');

  }

  ngAfterViewInit() {
    this.getCampaigns();
  }

  //api
  getCampaigns(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/campaigns`)
      .subscribe((result: AdCampaignDto[]) => {
        this.campaigns = result;
        this.filteredCampaigns = this.campaigns;
        this.viewState = ViewState.content;
        this.campaignsDataSource = new MatTableDataSource(this.filteredCampaigns);
        this.updateSorting(this.filteredCampaigns);
      });
  }

  seachTextDidChange(text: string) {
    this.filteredCampaigns = this.campaigns.filter(r =>
      r.name.toLowerCase().includes(text.toLowerCase())
    )
    this.updateSorting(this.filteredCampaigns);
  }

  updateSorting(campaigns: AdCampaignDto[]) {
    this.campaignsDataSource = new MatTableDataSource(campaigns);
    this.campaignsDataSource.sort = this.campaignsTableMatSort;
    this.campaignsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
      return item[property];
   };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

}
