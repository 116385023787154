import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AdministratorDto, CompanyDto, EditCompanyDto, FileResponseDto } from 'app/models/dtos';
import { GenericBreadcrumb } from 'app/modules/admin/common/generic-breadcrumbs/genericBreadcrumb';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  // styleUrls: ['./company-info.component.css']
})
export class CompanyInfoComponent implements OnInit {
  constructor(private http: HttpClient, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  //inputs
  @Input() company: CompanyDto;

  //outputs
  @Output() didEditCompany: EventEmitter<CompanyDto> = new EventEmitter();

  //vars
  administrators: AdministratorDto[] = []
  moment = moment;
  selectedSalesRepId: string;
  selectedOpsRepId: string;

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;
  infoViewState = ViewState.content;
  repsViewState = ViewState.loading;

  //file upload
  @ViewChild('image') imageElement: ElementRef;

  ngOnInit() {
    this.buildUI();
    this.getReps();
  }

  buildUI() {
    this.selectedSalesRepId = this.company.salesRep?.id;
    this.selectedOpsRepId = this.company.opsRep?.id;
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | Admin`);
  }

  //api
  getCompany(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}`)
      .subscribe((result: CompanyDto) => {
        this.company = result;

        this.viewState = ViewState.content;
        this.infoViewState = ViewState.content;
        this.setTitle(this.company.name);
      });
  }

  getReps() {
    this.repsViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/administrators`)
      .subscribe((result: AdministratorDto[]) => {
        this.administrators = result;
        this.repsViewState = ViewState.content;
      });
  }

  editCompany(): void {
    this.infoViewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}`, EditCompanyDto.fromCompany(this.company, this.selectedSalesRepId, this.selectedOpsRepId, this.company.repContact?.id, this.company.billingContact?.id))
      .subscribe((result: CompanyDto) => {
        this.company = result;
        this.infoViewState = ViewState.content;
        this.repsViewState = ViewState.content;
        this.setTitle(this.company.name);
        this.didEditCompany.emit(this.company);
      });
  }

  editSalesRep(): void {
    this.viewState = ViewState.loading;

    const editDto = EditCompanyDto.fromCompany(this.company, this.selectedSalesRepId, this.selectedOpsRepId, this.company.repContact?.id, this.company.billingContact?.id);
    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/salesRep`, editDto)
      .subscribe((result: CompanyDto) => {
        this.company.salesRep = result.salesRep;
        this.viewState = ViewState.content;
      });
  }

  editOpsRep(): void {
    this.viewState = ViewState.loading;

    const editDto = EditCompanyDto.fromCompany(this.company, this.selectedSalesRepId, this.selectedOpsRepId, this.company.repContact?.id, this.company.billingContact?.id);
    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/opsRep`, editDto)
      .subscribe((result: CompanyDto) => {
        this.company.opsRep = result.opsRep;
        this.viewState = ViewState.content;
      });
  }

  deleteCompany(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}`)
      .subscribe(() => {
        this._router.navigateByUrl('/companies')
      });
  }

  async uploadFile(file: File) : Promise<FileResponseDto> {
    //package form data
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    //upload
    return (await firstValueFrom(this.http.post(`${environment.services_tdusa_admin}/v1/files`, formData))) as FileResponseDto;
  }

  async editImage(e: Event) {
    //get file
    const file: File = (<HTMLInputElement>e.target).files.item(0);

    //upload
    this.infoViewState = ViewState.loading;
    const uploadedFile = await this.uploadFile(file);
    this.company.imageUrl = uploadedFile.src;
    this.editCompany();
  }
}
