import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { ApplicationDto, CompanyDto, MatchedLeadsProductDto, ReportDto } from 'app/models/dtos';
import { AppAnalyticDateContainer } from 'app/models/dtos/appAnalytics2.dto';
import { AppAnalytics3Dto } from 'app/models/dtos/appAnalytics3.dto';
import { ApplicationLocationDto } from 'app/models/dtos/applicationLocation.dto';
import { CdlClassesLookup, DriverTypesLookup, ExperienceTypesLookup, FreightTypesLookup, PermissionGroupsLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import mapboxgl, { AnySourceData, Map } from 'mapbox-gl';
import * as moment from 'moment';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';
import { TdusaMapElements } from '../../maps/tdusa-map-elements';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  // styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  public id: string = '0';
  public productId: string = '0';
  public reportId: string = '0';
  report: ReportDto;
  company: CompanyDto;
  product: MatchedLeadsProductDto;
  breadcrumbs(): GenericBreadcrumb[] {
    return [
      new GenericBreadcrumb(`${this.company?.name ?? 'Company'}`, `/companies/${this.id}`),
      new GenericBreadcrumb(`${this.product?.name ?? 'Product'}`, `/companies/${this.id}/matchedLeadsProducts/${this.productId}`)
    ];
  }
  analytics: AppAnalytics3Dto = new AppAnalytics3Dto();
  locations: ApplicationLocationDto[] = [];

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  moment = moment;

  //lookups
  driverTypes = DriverTypesLookup;
  freightTypes = FreightTypesLookup;
  experienceTypes = ExperienceTypesLookup;

  //map
  mapHeight: string = '400px';
  mapZoom: number = 3;
  scrollZoom: boolean = false;
  dragPan: boolean = false;
  map: mapboxgl.Map;
  hasLoadedZipsSource: boolean = false;
  mapImageUrl: string;

  async ngOnInit() {
    //update title
    this.titleService.setTitle('Report | Admin');

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';
    this.productId = this.route.snapshot.paramMap.get('productId') ?? '0';
    this.reportId = this.route.snapshot.paramMap.get('reportId') ?? '0';

    await this.permissionsService.setContent(PermissionGroupsLookup.COMPANY, this.id);
    this.getCompany();
    this.getProduct();
    this.getReport();
  }

  //api
  getCompany(): void {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}`)
      .subscribe((result: CompanyDto) => {
        this.company = result;
      });
  }

  getProduct(): void {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}/matchedLeadsProducts/${this.productId}`)
      .subscribe((result: MatchedLeadsProductDto) => {
        this.product = result;
      });
  }

  getReport(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}/matchedLeadsProducts/${this.productId}/reports/${this.reportId}`)
      .subscribe((result: ReportDto) => {
        this.report = result;

        //update title
        this.titleService.setTitle(`${this.report.name ?? 'Report'} | Admin`);
        this.getAnalytics();
      });
  }

  getAnalytics(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}/matchedLeadsProducts/${this.productId}/reports/${this.reportId}/applications-analytics`)
      .subscribe((result: AppAnalytics3Dto) => {
        this.analytics = result;
        this.viewState = ViewState.content;

        this.getLocations();
      });
  }

  getLocations(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}/matchedLeadsProducts/${this.productId}/reports/${this.reportId}/applications-locations`)
      .subscribe((result: ApplicationLocationDto[]) => {
        this.locations = result;
        this.viewState = ViewState.content;
        setTimeout(() => {
          this.loadMap();
        }, 0.3);
      });
  }

  //map
  loadMap() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/light-v11',
      center: {
        lat: 37,
        lng: -95.7
      },
      zoom: this.mapZoom,
      preserveDrawingBuffer: true,
      accessToken: environment.mapbox.accessToken
    });

    this.map.once('load', map => {
      //map render
      this.updateMapZips();
    });
  }

  onMapLoad(event) {
    this.map = event;

    //map render
    this.updateMapZips();
  }

  updateMapZips() {
    //remove old source
    if (this.hasLoadedZipsSource) {
      this.map.removeLayer(TdusaMapElements.zipsLayerTag);
      this.map.removeSource(TdusaMapElements.zipsSourceTag);
    }

    this.map.addSource(TdusaMapElements.zipsSourceTag, this.zipsMapSource(this.locations));
    this.map.addLayer(TdusaMapElements.reportZipsLayer());
    this.map.on('render', ev => {
      this.map.setZoom(this.mapZoom - 1);
      this.mapImageUrl = this.map.getCanvas().toDataURL();
    });

    this.hasLoadedZipsSource = true;
  }

  zipsMapSource(positions: ApplicationLocationDto[]): AnySourceData {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: positions.map(z => TdusaMapElements.circleMarkerForLatLng(z.lat, z.lng, 10))
      }
    }
  }


  //math / counts
  periodSpend(): number {
    return this.report.spendPeriod.spendInCents / 100
  }

  costPerLead(): number {
    return this.periodSpend() / this.leadsCount();
  }

  estimatedLeadsRemaining(): number {
    return Math.floor(this.report.spendPeriod.spendInCents / this.report.matchedLeadsProduct.targetCplInCents) - this.report.spendPeriod.sentLeads;
  }

  leadsCount(): number {
    return this.analytics.total + this.report.spendPeriod.calls;
  }

  callsCount(): number {
    return this.report.spendPeriod.calls;
  }

  leadCountWithAddOn(addOnAmountInDollars: number) : number {
    const adjustedSpendInDollars = this.periodSpend() + addOnAmountInDollars;
    const adjustedSpendInCents = adjustedSpendInDollars*100;

    return adjustedSpendInCents / this.report.matchedLeadsProduct.targetCplInCents;
  }

  applicationsCount(): number {
    return this.analytics.total
  }

  countForDriverType(driverType: DriverTypesLookup): number {
    return this.analytics.driverTypes
      .filter(a => a.id === driverType.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  soloDriverCount(): number {
    return this.analytics.total - this.analytics.teamDriver;
  }

  teamDriverCount(): number {
    return this.analytics.teamDriver;
  }

  countForFreightType(freightType: FreightTypesLookup): number {
    return this.analytics.freightTypes
      .filter(a => a.id === freightType.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  countForExperienceType(experienceType: ExperienceTypesLookup): number {
    return this.analytics.experienceTypes
      .filter(a => a.id === experienceType.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  cdlACount(): number {
    // return this.analytics.filter(a => a.cdlClasses.find(c => c === CdlClassesLookup.CLASS_A.id) != null).length;
    return this.analytics.cdlClasses
      .filter(a => a.id === CdlClassesLookup.CLASS_A.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  violationsCount(): number {
    return this.analytics.violations;
  }

  accidentsCount(): number {
    return this.analytics.accidents;
  }

  militaryCount(): number {
    return this.analytics.military;
  }

  interestInOOCount(): number {
    return this.analytics.interestInOoLp;
  }

  interestInTeamDrivingCount(): number {
    return this.analytics.interestInTeams;
  }
}
