import { IdentifiableDto } from "./identifiable.dto";

export class CandidateDto extends IdentifiableDto {
    email!: string;
    name!: string;
    phone: string;
    timestampCreated!: Date;
    timestampUpdated!: Date;
    imageUrl: string;
    banned: boolean;
}