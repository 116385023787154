import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExternalJobDto } from 'app/models/dtos';
import { JobCriteriaFormComponent } from 'app/modules/admin/jobs/job/job-criteria/job-criteria-form/job-criteria-form.component';

export class EditJobModalComponentInput {
  job: ExternalJobDto;
  title: string;
  subtitle: string;
}

@Component({
  selector: 'app-edit-job-modal',
  templateUrl: './edit-job-modal.component.html',
  // styleUrls: ['./fallback-job-modal.component.css']
})
export class EditJobModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EditJobModalComponentInput>,
    @Inject(MAT_DIALOG_DATA) public input: EditJobModalComponentInput
  ) { }

  //view children
  @ViewChild(JobCriteriaFormComponent /* #name or Type*/, {static: false}) jobCriteriaForm: JobCriteriaFormComponent;

  ngOnInit() {

  }

  //actions
  didClickDismiss(action: string = 'dismiss') {
    this.dialogRef.close({
      action: action,
      data: this.input
    });
  }

  didClickSave() {
    this.jobCriteriaForm.editMatching();
    this.didClickDismiss();
  }
}
