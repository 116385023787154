export class MatchedLeadsProductListingDto {
    heroImage: string;
    biography: string;
    profileDetails: string;
    name: string;
    notes: string;

    //social
    twitterHandle: string;
    instagramHandle: string;
    youtubePlaylistId: string;
    tiktokHandle: string;
    facebookHandle: string;

    defaultSocial: string;
}