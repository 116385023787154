<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="Collectives" fallbackIcon="heroicons_solid:collection"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
      <!-- Search -->
      <div class="flex-auto">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [(ngModel)]="searchText" [autocomplete]="'off'"
            (ngModelChange)="seachTextDidChange(searchText)" [placeholder]="'Filter collectives'">
        </mat-form-field>
      </div>
      <!-- Add collective button -->
      <a routerLink="/add-collective">
        <button class="ml-4" mat-flat-button [color]="'primary'">
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="ml-2 mr-1">Add</span>
        </button>
      </a>
    </div>


    <!--list-->
    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- no content -->
    <div *ngIf="viewState == viewStates.content && filteredCollectives.length === 0" class="mt-8">
      <app-generic-no-content title="No Collectives Found"
        subtitle="No collectives were found please refresh or update your search criteria."
        imageUrl="https://img.icons8.com/color/256/000000/layers.png" class="ml-auto mr-auto mt-5">
      </app-generic-no-content>
    </div>

    <!-- content -->
    <div [hidden]="viewState != viewStates.content || filteredCollectives.length === 0" class="w-full mt-8">
      <fuse-card class="w-full p-4">
        <div class="w-full overflow-x-auto mx-6">
          <table class="w-full bg-transparent" mat-table matSort [dataSource]="collectivesDataSource"
            [trackBy]="trackByFn" #collectivesTable>

            <!-- Name -->
            <ng-container matColumnDef="name">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Name
              </th>
              <td mat-cell *matCellDef="let collective">
                <a routerLink="/collectives/{{collective.id}}"
                  class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                  <mat-icon class="mr-3 text-primary" style="vertical-align: bottom;"
                    [svgIcon]="'heroicons_solid:collection'"></mat-icon>
                  <b class="text-slate-600">{{collective.name}}</b>
                </a>
              </td>
            </ng-container>

            <!-- Companies -->
            <ng-container matColumnDef="companies">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Companies
              </th>
              <td mat-cell *matCellDef="let collective" style="vertical-align: bottom;">
                <a *ngFor="let company of collective.companies" routerLink="/companies/{{company.id}}"
                  class="pr-6 font-medium text-sm text-secondary whitespace-nowrap"
                  [matTooltip]="company.name">
                  <img class="w-8 h-8 rounded" *ngIf="company.imageUrl != null" [src]="company.imageUrl"
                    alt="Card cover image">
                  <mat-icon *ngIf="company.imageUrl == null" class="text-primary w-5 h-5"
                    style="vertical-align: bottom;" [svgIcon]="'heroicons_solid:office-building'"></mat-icon>
                </a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="collectivesTableColumns"></tr>
            <tr class="order-row h-16" mat-row *matRowDef="let row; columns: collectivesTableColumns;"></tr>
            <!-- <tr
                  class="h-16 border-0"
                  mat-footer-row
                  *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
          </table>
        </div>
      </fuse-card>
    </div>
  </div>

</div>