import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AddLogNotificationRequestDto, AdministratorDto, LogNotificationRequestDto } from 'app/models/dtos';
import { LogEntryTypesLookup, LogNotificationTypesLookup } from 'app/models/lookups';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-log-notifications',
  templateUrl: './log-notifications.component.html',
  // styleUrls: ['./log-notifications.component.css']
})
export class LogNotificationsComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  //inputs
  @Input() logRoute: string;

  //view children
  @ViewChild('logsTable', { read: MatSort, static: false }) logsTableMatSort: MatSort;

  //vars
  logs: LogNotificationRequestDto[] = []
  searchText: string = '';
  logsDataSource: MatTableDataSource<LogNotificationRequestDto> = new MatTableDataSource();
  logsTableColumns: string[] = ['type', 'receiving', 'via', 'delete'];
  moment = moment;
  addNotificationRecipient = new AddLogNotificationRequestDto();
  administrators: AdministratorDto[] = [];

  //types
  notificationTypes = structuredClone(LogNotificationTypesLookup.values);
  entryTypes = structuredClone(LogEntryTypesLookup.values);
  rawEntryTypes = LogEntryTypesLookup;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addLogViewState = ViewState.content;

  ngOnInit() {
    this.setInitialValues();
    this.getLogs();
    this.getAdministrators();
  }

  setInitialValues() {
    this.addNotificationRecipient.logEntryTypeId = LogEntryTypesLookup.ERROR.id;
    this.addNotificationRecipient.logNotificationTypeId = LogNotificationTypesLookup.EMAIL.id;
    this.addNotificationRecipient.receivingAdministratorId = null;
  }

  //api
  getAdministrators() {
    this.addLogViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/administrators`)
      .subscribe((result: AdministratorDto[]) => {
        this.administrators = result;
        this.addLogViewState = ViewState.content;
      });
  }

  getLogs(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/${this.logRoute ?? 'v1/logs/notifications'}`)
      .subscribe((result: LogNotificationRequestDto[]) => {
        this.logs = result;
        this.viewState = ViewState.content;
        this.logsDataSource = new MatTableDataSource(this.filteredLogs());
        this.updateSorting(this.filteredLogs());
      });
  }

  addNotificationRequest() {
    this.addLogViewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/${this.logRoute ?? 'v1/logs/notifications'}`, this.addNotificationRecipient)
      .subscribe((result: LogNotificationRequestDto) => {
        this.logs.push(result);
        this.setInitialValues();
        this.addLogViewState = ViewState.content;
        this.logsDataSource = new MatTableDataSource(this.filteredLogs());
        this.updateSorting(this.filteredLogs());
      });
  }

  deleteNotificationRequest(request: LogNotificationRequestDto) {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/${this.logRoute ?? 'v1/logs/notifications'}/${request.id}`)
      .subscribe(() => {
        this.logs = this.logs.filter(l => l.id != request.id);
        this.viewState = ViewState.content;
        this.logsDataSource = new MatTableDataSource(this.filteredLogs());
        this.updateSorting(this.filteredLogs());
      });
  }

  //list
  seachTextDidChange(text: string) {
    this.updateSorting(this.filteredLogs());
  }

  updateSorting(logs: LogNotificationRequestDto[]) {
    this.logsDataSource = new MatTableDataSource(logs);
    this.logsDataSource.sort = this.logsTableMatSort;
    this.logsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
      return item[property];
    };
  }

  filteredLogs(): LogNotificationRequestDto[] {
    return this.logs
    .filter(r =>
      (r.logEntryType?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.logNotificationType?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.receivingAdministrator?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  addRecipientIsValid() : boolean {
    return this.addNotificationRecipient.logEntryTypeId != null
    && this.addNotificationRecipient.logNotificationTypeId != null
    && this.addNotificationRecipient.receivingAdministratorId != null;
  }
}
