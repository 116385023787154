export class AddPermissionDto {
    objectId: string;
    permissionTypeId: string;
    administratorId: string;

    static fromIds(permissionTypeId: string, administratorId: string, objectId: string = null) {
        const dto = new AddPermissionDto();
        dto.permissionTypeId = permissionTypeId;
        dto.objectId = objectId;
        dto.administratorId = administratorId;

        return dto;
    }
}