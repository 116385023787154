

import { AdCampaignDto } from "./adCampaign.dto";
import { CompanyDto } from "./company.dto";
import { IdentifiableDto } from "./identifiable.dto";
import { AdTypeDto, HiringStateDto, HiringZipDto } from "./lookup.dto";

export class AdDto extends IdentifiableDto {
    name!: string;
    timestampCreated!: Date;
    timestampUpdated!: Date;
    externalId!: string;
    clientCount!: number;
    spendPercentage!: number;
    applicationsCount: number;
    companiesCount: number;
    productsCount: number;
    leadsPerApp: number;

    //relations
    hiringState: HiringStateDto;
    hiringZip: HiringZipDto;
    adCampaign: AdCampaignDto;
    adType: AdTypeDto;
    companies: CompanyDto[] = [];

    externalUrl: string;

    //external
    fbCampaign: any;

    //virtual, for sorting support
    dailyBudget: number;
    adSpendInRange: number;
    costPerApp: number;
    costPerLead: number;
    expectedCpl: number;
    showCompanies: boolean = false;
}