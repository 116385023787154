import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AddContactDto, AgencyDto, CompanyDto, ContactDto, FileResponseDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  // styleUrls: ['./add-contact.component.css']
})
export class AddContactComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  ngOnInit() {
    this.titleService.setTitle(`Add Contact | Admin`);
  }

}
