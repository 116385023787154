<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [breadcrumbs]="breadcrumbs()" [terminal]="this.proposal?.name ?? 'Proposal'"
    fallbackIcon="heroicons_solid:presentation-chart-bar"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <div *ngIf="viewState == viewStates.content" class="grid grid-cols-12">
      <div class="col-span-12">
        <mat-tab-group animationDuration="0ms">
          <!--Info-->
          <mat-tab label="Info">
            <ng-template matTabContent>
              <app-proposal-info [proposal]="proposal" (didEditProposal)="didEditProposal($event)"></app-proposal-info>
            </ng-template>
          </mat-tab>
          <!--Criteria-->
          <mat-tab label="Criteria">
            <ng-template matTabContent>
              <app-proposal-criteria [proposal]="proposal"></app-proposal-criteria>
            </ng-template>
          </mat-tab>
          <!--Logs-->
          <!-- <mat-tab label="Logs">
            <ng-template matTabContent>
              <app-log-analytics logRoute="v1/proposals/{{proposal.id}}/logs"></app-log-analytics>
            </ng-template>
          </mat-tab> -->
          <!--Generate-->
          <mat-tab label="Generate / Review">
            <ng-template matTabContent>
              <app-proposal-generate [proposal]="proposal" (didEditProposal)="didEditProposal($event)"></app-proposal-generate>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>


  </div>

</div>