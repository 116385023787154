import { AdDto } from "./ad.dto";


export class EditAdDto {
    name!: string;
    externalId!: string;

    static fromAd(ad: AdDto): EditAdDto {
        const dto = new EditAdDto();
        dto.name = ad.name;
        dto.externalId = ad.externalId;

        return dto;
    }
}