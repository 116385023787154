import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { OwnAuthorityTypes } from 'app/models/app/ownAuthorityTypes';
import { TeamDriverTypes } from 'app/models/app/teamDriverTypes';
import { ExternalJobDto, MatchedLeadsProductMatchingDto } from 'app/models/dtos';
import { CdlClassesLookup, DriverTypesLookup, EndorsementTypesLookup, ExperienceTypesLookup, FreightTypesLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-job-criteria-form',
  templateUrl: './job-criteria-form.component.html',
  // styleUrls: ['./matched-leads-product-jobs-edit-form.component.css']
})
export class JobCriteriaFormComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  //inputs
  @Input() job: ExternalJobDto;

  public companyId: string;
  public productId: string;
  public jobId: string;
  public proposalId: string

  //vars
  matching: MatchedLeadsProductMatchingDto;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  //values
  cdlClasses = structuredClone(CdlClassesLookup.values);
  experience = structuredClone(ExperienceTypesLookup.values);
  endorsements = structuredClone(EndorsementTypesLookup.values);
  driverTypes = structuredClone(DriverTypesLookup.values);
  freightTypes = structuredClone(FreightTypesLookup.values);
  ownAuthorityTypes = structuredClone(OwnAuthorityTypes.values);
  teamDriverTypes = structuredClone(TeamDriverTypes.values);
  customExperience = structuredClone(ExperienceTypesLookup.values);

  ngOnInit() {
    //parse query params
    this.companyId = this.route.snapshot.paramMap.get('id');
    this.productId = this.route.snapshot.paramMap.get('productId');
    this.jobId = this.route.snapshot.paramMap.get('jobId');
    this.proposalId = this.route.snapshot.paramMap.get('proposalId');

    this.getMatching();
  }

  setDefaultSelections() {
    this.driverTypes.forEach(i => i.checked = (this.matching.driverTypes.find(cl => cl == i.id) != null));
    this.cdlClasses.forEach(i => i.checked = (this.matching.cdlClasses.find(cl => cl == i.id) != null));
    this.endorsements.forEach(i => i.checked = (this.matching.endorsements.find(e => e == i.id) != null));
    this.freightTypes.forEach(i => i.checked = (this.matching.freightTypes.find(f => f == i.id) != null));
    this.customExperience.forEach(i => i.checked = (this.matching.customExperienceTypes.find(f => f == i.id) != null));

    if(this.matching.experienceTypeId == null) {
      this.matching.experienceTypeId = `0`;
    }
  }

  

  //api
  getMatching() {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}${this.proposalId ? `/proposals/${this.proposalId}` : ''}/jobs/${this.jobId ?? this.job.id}/matching`)
      .subscribe((result: MatchedLeadsProductMatchingDto) => {
        this.matching = result;
        this.viewState = ViewState.content;
        this.setDefaultSelections();
      });
  }

  editMatching() {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}${this.proposalId ? `/proposals/${this.proposalId}` : ''}/jobs/${this.jobId ?? this.job.id}/matching`, this.matching)
      .subscribe((result: MatchedLeadsProductMatchingDto) => {
        this.matching = result;
        this.viewState = ViewState.content;
      });
  }

  //selections
  setDriverType(driverType: DriverTypesLookup) {
    this.matching.driverTypes = this.driverTypes.filter(c => c.checked).map(c => c.id);
  }

  setLicenseType(cdlClass: CdlClassesLookup) {
    this.matching.cdlClasses = this.cdlClasses.filter(c => c.checked).map(c => c.id);
  }

  setFreightType(freightType: FreightTypesLookup) {
    this.matching.freightTypes = this.freightTypes.filter(c => c.checked).map(c => c.id);
  }

  setEndorsementType(endorsementType: EndorsementTypesLookup) {
    this.matching.endorsements = this.endorsements.filter(c => c.checked).map(c => c.id);
  }

  setCustomExperienceType(experienceType: ExperienceTypesLookup) {
    this.matching.customExperienceTypes = this.customExperience.filter(c => c.checked).map(c => c.id);
  }
}
