<!-- Error -->
<fuse-alert
class="mb-4"
*ngIf="application.blocked"
    [type]="'error'"
    [appearance]="'outline'">
    <span fuseAlertTitle>Application Blocked</span>
    This application was blocked as the result of a domain or direct ban
</fuse-alert>

<fuse-card>
  <mat-horizontal-stepper [linear]="true" #stepper class="w-full">
    <mat-step #stepApplied>
      <ng-template matStepLabel>Applied</ng-template>
    </mat-step>
    <mat-step *ngIf="application.blocked" #stepBlocked>
      <ng-template matStepLabel>Blocked</ng-template>
    </mat-step>
    <mat-step *ngIf="!application.blocked" #stepMatched>
      <ng-template matStepLabel>Matched</ng-template>
    </mat-step>
    <mat-step *ngIf="!application.blocked" #stepSent>
      <ng-template matStepLabel>Sent</ng-template>
    </mat-step>
  </mat-horizontal-stepper>
</fuse-card>