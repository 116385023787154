import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ViewState } from 'app/models/app';
import { AdministratorDto, CompanyDto } from 'app/models/dtos';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-staff-member-assignments',
  templateUrl: './staff-member-assignments.component.html',
  // styleUrls: ['./staff-member-assignments.component.css']
})
export class StaffMemberAssignmentsComponent implements OnInit {
  constructor(private http: HttpClient) { }

  //inputs
  @Input() administrator: AdministratorDto;

  //vars
  salesRepCompanies: CompanyDto[] = [];
  opsRepCompanies: CompanyDto[] = [];

  //view states
  viewStates = ViewState;
  salesRepsViewState = ViewState.loading;
  opsRepsViewState = ViewState.loading;

  ngOnInit() {
    this.getSalesRepCompanies();
    this.getOpsRepCompanies();
  }

  //api
  getSalesRepCompanies(): void {
    this.salesRepsViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/administrators/${this.administrator.id}/salesRep/companies`)
      .subscribe((result: CompanyDto[]) => {
        this.salesRepCompanies = result;
        this.salesRepsViewState = ViewState.content;
      });
  }

  getOpsRepCompanies(): void {
    this.opsRepsViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/administrators/${this.administrator.id}/opsRep/companies`)
      .subscribe((result: CompanyDto[]) => {
        this.opsRepCompanies = result;
        this.opsRepsViewState = ViewState.content;
      });
  }
}
