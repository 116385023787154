import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewState } from 'app/models/app';
import { LogEntryDto, ResolveLogEntryDto } from 'app/models/dtos';
import { LogEntryTypesLookup } from 'app/models/lookups';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-log-entry',
  templateUrl: './log-entry.component.html',
  // styleUrls: ['./log-entry.component.css']
})
export class LogEntryComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LogEntryComponent>,
    @Inject(MAT_DIALOG_DATA) public logEntry: LogEntryDto,
    private http: HttpClient
  ) { }

  //vars
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;

  //lookups
  rawEntryTypes = LogEntryTypesLookup;

  ngOnInit() {

  }

  //api
  markResolved(): void {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/logs/${this.logEntry.id}/resolution`, ResolveLogEntryDto.fromNarrative(this.logEntry.resolutionNarrative))
      .subscribe((result: LogEntryDto) => {
        this.logEntry = result;
        this.viewState = ViewState.content;

        this.didClickDismiss('resolve');
      });
  }

  markUnresolved(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/logs/${this.logEntry.id}/resolution`)
      .subscribe(() => {
        this.logEntry.resolutionNarrative = null;
        this.logEntry.resolvingAdministrator = null;
        this.logEntry.timestampResolved = null;
        this.viewState = ViewState.content;

        this.didClickDismiss('unresolve');
      });
  }

  deleteLogEntry() {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/logs/${this.logEntry.id}`)
      .subscribe(() => {
        this.didClickDismiss('delete');
      });
  }

  //actions
  didClickDismiss(action: string = 'dismiss') {
    this.dialogRef.close({
      action: action,
      data: this.logEntry
    });
  }

  toggleResolved() {
    if (this.logEntry.resolvingAdministrator == null) {
      this.markResolved();
    }
    else {
      this.markUnresolved();
    }
  }
}
