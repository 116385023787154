import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CompanyDto, MatchedLeadsProductDto } from 'app/models/dtos';
import { PermissionGroupsLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-matched-leads-product',
  templateUrl: './matched-leads-product.component.html',
  // styleUrls: ['./matched-leads-product.component.css']
})
export class MatchedLeadsProductComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  //vars
  public id: string = '0';
  public productId: string = '0';
  breadcrumbs() : GenericBreadcrumb[] {
    return [
      new GenericBreadcrumb('Companies', '/companies'),
      new GenericBreadcrumb(`${this.company?.name ?? 'Company'}`, `/companies/${this.id}`)
    ];
  }
  terminal: string = 'Matched Lead Product';
  moment = moment;
  company: CompanyDto;
  product: MatchedLeadsProductDto;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  infoViewState = ViewState.loading;
  

  async ngOnInit() {
    //update title
    this.titleService.setTitle(`Matched Leads Product | Admin`);

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';
    this.productId = this.route.snapshot.paramMap.get('productId') ?? '0';

    //get data
    await this.permissionsService.setContent(PermissionGroupsLookup.COMPANY, this.id);
    this.getCompany();
  }

  //api
  getCompany(): void {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}`)
      .subscribe((result: CompanyDto) => {
        this.company = result;
        this.titleService.setTitle(`Matched Leads Product (${this.company.name}) | Admin`);
        this.getProduct();
      });
  }

  getProduct() {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}/matchedLeadsProducts/${this.productId}`)
      .subscribe((result: MatchedLeadsProductDto) => {
        this.product = result;
        this.viewState = ViewState.content;
        this.infoViewState = ViewState.content;
      });
  }

  //callbacks
  didEditProduct(product: MatchedLeadsProductDto) {
    this.product = product;
  }
}
