import { AdministratorDto } from "./administrator.dto";
import { AgencyDto } from "./agency.dto";
import { CollectiveDto } from "./collective.dto";
import { CompanyDto } from "./company.dto";
import { IdentifiableDto } from "./identifiable.dto";
import { LogEntryTypeDto, LogNotificationTypeDto } from "./lookup.dto";

export class LogNotificationRequestDto extends IdentifiableDto {
    timestampCreated!: Date;
    receivingAdministrator!: AdministratorDto;
    logEntryType!: LogEntryTypeDto;
    logNotificationType!: LogNotificationTypeDto;
    agency: AgencyDto;
    company: CompanyDto;
    collective: CollectiveDto;
    administrator: AdministratorDto;
}