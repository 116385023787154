import { IdentifiableDto } from "./identifiable.dto";
import { LeadGenSettingsDto } from "./leadGenSettings.dto";

export class EditLeadGenSettingsDto extends IdentifiableDto {
    maxSendsPerApplication: number;
    percentageAllocation: number;
    pocketingSpend: boolean;
    matrixProductWeights: number;
    minDailySpendCampaignInCents!: number;

    static fromSettings(settings: LeadGenSettingsDto) : EditLeadGenSettingsDto {
        const dto = new EditLeadGenSettingsDto();
        dto.matrixProductWeights = settings.matrixProductWeights;
        dto.maxSendsPerApplication = settings.maxSendsPerApplication;
        dto.pocketingSpend = settings.pocketingSpend;
        dto.percentageAllocation = settings.percentageAllocation;
        dto.minDailySpendCampaignInCents = settings.minDailySpendCampaignInCents;

        return dto;
    }
}