import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CandidateDto, FileResponseDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  // styleUrls: ['./candidate.component.css']
})
export class CandidateComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  public id: string = '0';
  candidate: CandidateDto = null;
  breadcrumbs: GenericBreadcrumb[] = [
    new GenericBreadcrumb('Candidates', '/candidates')
  ];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  infoViewState = ViewState.loading;

  //file upload
  @ViewChild('image') imageElement: ElementRef;

  ngOnInit() {
    //update title
    this.titleService.setTitle('Candidate | Admin');

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';

    this.getCandidate();
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | Admin`);
  }

  //api
  getCandidate(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/candidates/${this.id}`)
      .subscribe((result: CandidateDto) => {
        this.candidate = result;
        this.infoViewState = ViewState.content;
        this.viewState = ViewState.content;
        this.setTitle(this.candidate.name);
      });
  }

  banCandidate(): void {
    this.infoViewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/bans/candidates/${this.id}`, null)
      .subscribe(() => {
        this.candidate.banned = true;
        this.infoViewState = ViewState.content;
      });
  }

  unbanCandidate(): void {
    this.infoViewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/bans/candidates/${this.id}`)
      .subscribe(() => {
        this.candidate.banned = false;
        this.infoViewState = ViewState.content;
      });
  }

  deleteCandidate(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/candidates/${this.id}`)
      .subscribe(() => {
        this._router.navigateByUrl('/candidates')
      });
  }
}
