import { AdCampaignDto } from "./adCampaign.dto";


export class EditAdCampaignDto {
    name!: string;
    externalId!: string;

    static fromCampaign(campaign: AdCampaignDto) : EditAdCampaignDto {
        var dto = new EditAdCampaignDto();
        dto.name = campaign.name;
        dto.externalId = campaign.externalId;

        return dto;
    }
}