import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { DocumentDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import moment from 'moment';
import * as mime from 'mime';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  // styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('Documents | Admin');

  }
}
