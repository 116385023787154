import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AgencyDto, CompanyDto, ContactDto, EditAgencyDto, FileResponseDto } from 'app/models/dtos';
import { PermissionGroupsLookup, PermissionTypesLookup } from 'app/models/lookups';
import { AccountsService } from 'app/services/accounts/accounts.service';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-agency-info',
  templateUrl: './agency-info.component.html',
  // styleUrls: ['./agency-info.component.css']
})
export class AgencyInfoComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, private accountsService: AccountsService, public permissionsService: PermissionsService) { }

  @Input() agency: AgencyDto;

  public id: string = '0';
  
  moment = moment;
  companies: CompanyDto[] = [];
  allCompanies: CompanyDto[] = [];;
  searchText: string = '';
  addSearchText: string = '';
  companyToAdd: CompanyDto = null;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  infoViewState = ViewState.loading;
  companiesViewState = ViewState.loading;
  addViewState = ViewState.initial;

  //file upload
  @ViewChild('image') imageElement: ElementRef;

  async ngOnInit() {
    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';

    await this.permissionsService.setContent(PermissionGroupsLookup.AGENCY, this.id);
    this.getAgency();
    this.getCompanies();
    if(this.permissionsService.canAdmin()) { this.getAvailableCompanies(); }
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | Admin`);
  }

  //api
  getAgency(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/agencies/${this.id}`)
      .subscribe((result: AgencyDto) => {
        this.agency = result;
        this.viewState = ViewState.content;
        this.infoViewState = ViewState.content;
        this.setTitle(this.agency.name);
      });
  }

  editAgency(): void {
    this.infoViewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/agencies/${this.id}`, EditAgencyDto.fromAgency(this.agency, this.agency.repContact?.id))
      .subscribe((result: AgencyDto) => {
        this.agency = result;
        this.infoViewState = ViewState.content;
        this.setTitle(this.agency.name);
      });
  }

  deleteAgency(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/agencies/${this.id}`)
      .subscribe(() => {
        this._router.navigateByUrl('/agencies')
      });
  }

  async uploadFile(file: File) : Promise<FileResponseDto> {
    //package form data
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    //upload
    return (await firstValueFrom(this.http.post(`${environment.services_tdusa_admin}/v1/files`, formData))) as FileResponseDto;
  }

  async editImage(e: Event) {
    //get file
    const file: File = (<HTMLInputElement>e.target).files.item(0);

    //upload
    this.infoViewState = ViewState.loading;
    const uploadedFile = await this.uploadFile(file);
    this.agency.imageUrl = uploadedFile.src;
    this.editAgency();
  }


  didEditAgency(agency: AgencyDto) {
    this.agency = agency;
  }

  getCompanies(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/agencies/${this.agency.id}/companies`)
      .subscribe((result: CompanyDto[]) => {
        this.companies = result;
        this.viewState = ViewState.content;
      });
  }

  getAvailableCompanies(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/agencies/${this.agency.id}/companies/available`)
      .subscribe((result: CompanyDto[]) => {
        this.allCompanies = result;
        this.viewState = ViewState.content;
      });
  }

  addCompany(company: CompanyDto): void {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/agencies/${this.agency.id}/companies/${company.id}`, null)
      .subscribe(() => {
        this.companies.push(company);
        this.allCompanies = this.allCompanies.filter(c => c.id != company.id);
        
        //reset add state
        this.companyToAdd = null;
        this.addViewState = ViewState.initial;
        this.addSearchText = "";

        this.viewState = ViewState.content;
      });
  }

  deleteCompany(company: CompanyDto): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/agencies/${this.agency.id}/companies/${company.id}`)
      .subscribe(() => {
        this.companies = this.companies.filter(c => c.id != company.id);
        this.allCompanies.push(company);
        this.searchText = '';
        this.viewState = ViewState.content;
      });
  }

  availableCompanies() : CompanyDto[] {
    return this.allCompanies.filter(c => this.companies.find(ec => ec.id === c.id) == null)
  }

  filteredCompanies() : CompanyDto[] {
    return this.companies.filter(r =>
      r.name.toLowerCase().includes(this.searchText.toLowerCase())
      || (r.agency?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.salesRep?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.salesRep?.email ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.opsRep?.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.opsRep?.email ?? '').toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  filteredAvailableCompanies() : CompanyDto[] {
    return this.availableCompanies().filter(r =>
      r.name.toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.agency?.name ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.salesRep?.name ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.salesRep?.email ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.opsRep?.name ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.opsRep?.email ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
    );
  }
}
