import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewState } from 'app/models/app';
import { LeadRadius } from 'app/models/app/leadRadius';
import { LocationSearchResponseDto } from 'app/models/dtos/locationSearchResponse.dto';
import { TdusaMapElements } from 'app/modules/admin/maps/tdusa-map-elements';
import { environment } from 'environments/environment';
import { AnySourceData, LngLat, Map, Marker } from 'mapbox-gl';

@Component({
  selector: 'app-lead-radius',
  templateUrl: './lead-radius.component.html',
  // styleUrls: ['./lead-radius.component.css']
})
export class LeadRadiusComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LeadRadiusComponent>,
    @Inject(MAT_DIALOG_DATA) public leadRadius: LeadRadius,
    private http: HttpClient
  ) { }

  map: Map;
  hasLoadedSource: boolean = false;

  //search
  searchText = '';
  results: LocationSearchResponseDto[] = [];

  //view states
  viewStates = ViewState;
  viewState = ViewState.initial;

  ngOnInit() {
    this.leadRadius = this.leadRadius ?? new LeadRadius(37, -95.7);
  }

  didClickDismiss(action: string = 'dismiss') {
    this.dialogRef.close({
      action: action,
      data: this.leadRadius
    });
  }

  //map
  onMapLoad(event) {
    this.map = event;

    //get locations
    this.updateMapRadius();
  }

  onMapClick(e: mapboxgl.MapMouseEvent) {
    // this.leadRadius = new LeadRadius(e.lngLat.lat, e.lngLat.lng);
  }

  onMarkerDragEnd(e: any) {
    this.leadRadius = new LeadRadius(e.getLngLat().lat, e.getLngLat().lng, this.leadRadius.radius ?? 50);
    this.updateMapRadius();
  }

  didClickClearRadius() {

  }

  updateMapRadius() {
    //remove old source
    if (this.hasLoadedSource) {
      this.map.removeLayer(TdusaMapElements.zipsLayerTag);
      this.map.removeSource(TdusaMapElements.zipsSourceTag);
    }

    this.map.addSource(TdusaMapElements.zipsSourceTag, this.mapSource());
    this.map.addLayer(TdusaMapElements.zipsLayer());

    this.hasLoadedSource = true;
  }

  mapSource(): AnySourceData {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [TdusaMapElements.circleMarkerForLatLng(this.leadRadius.lngLat.lat, this.leadRadius.lngLat.lng, this.leadRadius.radius)]
      }
    }
  }

  //search

  seachTextDidChange(searchText: string) {
    if(searchText.trim() === '') {
      this.viewState = ViewState.initial;
      return;
    }

    this.http
      .get(`${environment.services_tdusa_admin}/v1/location-search?q=${searchText.trim()}`)
      .subscribe((results: LocationSearchResponseDto[]) => {
        this.results = results;
        this.viewState = ViewState.content;
      });
  }

  didSelectResult(result: LocationSearchResponseDto) {
    this.searchText = '';
    this.seachTextDidChange(this.searchText);

    this.leadRadius = new LeadRadius(result.lat, result.lng, this.leadRadius.radius ?? 50, result.name);
    this.updateMapRadius();
  }
}
