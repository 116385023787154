import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CompanyDto, DocumentDto, DocumentTypeDto, EditDocumentDto } from 'app/models/dtos';
import { PermissionGroupsLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as mime from 'mime';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  // styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  public id: string = '0';
  public documentId: string = '0';
  company: CompanyDto;
  document: DocumentDto;
  documentTypes: DocumentTypeDto[];
  breadcrumbs(): GenericBreadcrumb[] {
    return [
      new GenericBreadcrumb(`Companies`, `/companies`),
      new GenericBreadcrumb(`${this.company?.name ?? 'Company'}`, `/companies/${this.id}`),
    ];
  };

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  async ngOnInit() {
    //update title
    this.titleService.setTitle('Document | Admin');

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';
    this.documentId = this.route.snapshot.paramMap.get('documentId') ?? '0';

    await this.permissionsService.setContent(PermissionGroupsLookup.COMPANY, this.id);
    this.getCompany();
    this.getDocumentTypes();
  }

  //api
  getCompany(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}`)
      .subscribe((result: CompanyDto) => {
        this.company = result;
        this.getDocument();
      });
  }

  getDocument(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}/documents/${this.documentId}`)
      .subscribe((result: DocumentDto) => {
        this.document = result;
        this.viewState = ViewState.content;
      });
  }

  getDocumentTypes(): void {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/documents/types`)
      .subscribe((result: DocumentTypeDto[]) => {
        this.documentTypes = result;
      });
  }

  editDocument(): void {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.id}/documents/${this.documentId}`, EditDocumentDto.fromDocument(this.document))
      .subscribe((result: DocumentDto) => {
        this.document = result;
        this.viewState = ViewState.content;
      });
  }

  deleteDocument(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.id}/documents/${this.documentId}`)
      .subscribe(() => {
        this._router.navigateByUrl('/documents')
      });
  }


  //validations
  documentIsValid(): Boolean {
    return this.isValidString(this.document.name)
      && this.isValidString(this.document.url);
  }

  isValidString(test: string): Boolean {
    if (test == null) { return false; }
    return test.trim() != '';
  }

  formattedDocSize(size: number): string {
    if (size > 1000000) {
      return `${(size / 1000000).toFixed(2)} MB`
    }
    else if (size > 1000) {
      return `${(size / 1000).toFixed(2)} KB`
    }
    else {
      return `${size.toFixed(2)} bytes`
    }
  }

  fileIconType(): string {
    return mime.getExtension(this.document.mimeType)
  }
}
