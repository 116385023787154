<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [breadcrumbs]="breadcrumbs()" [terminal]="document?.name ?? ''" fallbackIcon="heroicons_solid:document-text"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

     <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <fuse-card *ngIf="viewState == viewStates.content"
      class="flex flex-col max-w-xl ml-auto mr-auto filter-article pt-5 pb-5 pl-8 pr-8">
      <div>

        <!--company-->
        <div class="py-2">
          <div><b>For company...</b></div>
          <app-company-card [company]="document.company"></app-company-card>
          <hr class="my-4" />
        </div>

        <div class="text-small text-center text-secondary mb-4">
          <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
          </mat-icon>
          <b>Document Info</b>
        </div>

        <!--document-->
        <div class="text-center">
          <a [href]="document.url" target="_blank">
            <file-icon class="ml-auto mr-auto cursor-pointer" set="square-o" [type]="fileIconType()" size="xl"></file-icon>
          </a>
          
          <div class="mt-2 text-sm">{{document.name}} ({{formattedDocSize(document.size)}})</div>
          <div class="text-center text-small text-secondary mt-2">Click icon to open document</div>
        </div>

        <div>
          <div class="flex-auto">
            <!--document type-->
            <mat-label>Document Type</mat-label>
            <mat-form-field appearance="fill" class="w-full mt-1">
              <mat-select [(value)]="document.documentType.id" [disabled]="!permissionsService.canManage()">
                <mat-option *ngFor="let type of documentTypes" [value]="type.id">{{type.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!--name-->
            <mat-form-field class="w-full">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="document.name" [autocomplete]="'off'" placeholder="Document Name" [disabled]="!permissionsService.canManage()">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </mat-form-field>
          </div>

        </div>
      </div>

      <button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="editDocument()"
        [disabled]="!documentIsValid() || !permissionsService.canManage()">
        <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
        <span class="ml-2 mr-1">Save</span>
      </button>

      <!--delete-->
      <div class="border rounded p-3 mt-4 border-rose-500" *ngIf="permissionsService.canAdmin()">
        <div class="text-warn"><b>Danger Zone</b></div>
        <div><small>These actions are destructive. Proceed with care.</small></div>
        <button class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
          [swal]="{ title: 'Delete Document', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
          (confirm)="deleteDocument()">

          Delete
        </button>
      </div>
    </fuse-card>

  </div>

</div>