<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [terminal]="job?.title ?? ''" [breadcrumbs]="breadcrumbs()" [profile]="true"
    fallbackIcon="heroicons_solid:clipboard-list"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- content -->
    <!--tabs-->
    <div *ngIf="viewState == viewStates.content">
      <mat-tab-group animationDuration="0ms">
        <!--Info-->
        <mat-tab label="Info">
          <ng-template matTabContent>
            <app-job-info [company]="company" [product]="product" [job]="job"></app-job-info>
          </ng-template>
        </mat-tab>

        <!--Criteria-->
        <mat-tab label="Criteria">
          <ng-template matTabContent>
            <!--non-map criteria-->
            <app-job-criteria-form [job]="job"></app-job-criteria-form>

            <!--map criteria-->
            <app-job-criteria-area [job]="job"></app-job-criteria-area>
          </ng-template>
        </mat-tab>

        <!--Advertising-->
        <!-- <mat-tab label="Advertising">
          <ng-template matTabContent>
            <app-job-advertising [job]="job"></app-job-advertising>
          </ng-template>
        </mat-tab> -->
      </mat-tab-group>

    </div>
  </div>
</div>