<!--Import Jobs-->
<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
    <!--header-->
    <div class="flex items-center justify-between">
        <div class="text-2xl font-semibold leading-tight">Import Existing Jobs</div>
        <div class="-mr-3">
            <button class="ml-2" mat-flat-button [color]="'primary'" (click)="didClickSelectAll()">
                <span class="ml-2 mr-1">Select All</span>
              </button>
        </div>
    </div>
    <div class="mb-4 mt-2 text-sm">
        These jobs are actively being utilized for matched leads. Click on a job to include it's match criteria in ther
        report, then click import to aply the criteria.
    </div>

    <!--loading-->
    <div *ngIf="importJobsViewState == viewStates.loading">
        <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>
    <div *ngIf="importJobsViewState == viewStates.content">
        <div class="grid grid-cols-12 gap-3 mt-4">
            <div class="col-span-12 md:col-span-3" *ngFor="let job of importJobs" (click)="toggleJobInclusion(job)">
                <app-job-card-proposal [proposal]="proposal" [job]="job" [included]="false" [removable]="false"></app-job-card-proposal>
            </div>
        </div>
    </div>
</fuse-card>

<!--Proposal Jobs-->
<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
    <!--header-->
    <div class="flex items-center justify-between">
        <div class="text-2xl font-semibold leading-tight">Proposal Jobs</div>
        <div class="-mr-3">
            <button mat-flat-button [color]="'primary'" (click)="didTapAddJob()">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add Custom Job</span>
            </button>
        </div>
    </div>
    <div class="mb-4 mt-2 text-sm">
        These jobs are the ones that should be included in the proposal.
    </div>

    <!--loading-->
    <div *ngIf="jobsViewState == viewStates.loading">
        <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>
    <div *ngIf="jobsViewState == viewStates.content">

        <!-- no content -->
        <div *ngIf="jobs.length === 0" class="mt-8">
            <app-generic-no-content title="No Proposal Jobs Found" subtitle="No jobs were found please add some via import or using the add button above."
                imageUrl="https://img.icons8.com/color/256/null/find-matching-job.png" class="ml-auto mr-auto mt-5">
            </app-generic-no-content>
        </div>

        <!-- content -->
        <div class="grid grid-cols-12 gap-3 mt-4">
            <div class="col-span-12 md:col-span-3" *ngFor="let job of jobs">
                <app-job-card-proposal [proposal]="proposal" [job]="job" [included]="false" [removable]="true" [editable]="jobIsEditable(job)" (removeHandler)="didClickRemoveJob($event, job)"></app-job-card-proposal>
            </div>

        </div>
    </div>
</fuse-card>