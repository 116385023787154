import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CompanyDto, WorklistSettingsDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import * as DocumentEditor from '@ckeditor/ckeditor5-build-decoupled-document';

@Component({
  selector: 'app-company-worklist',
  templateUrl: './company-worklist.component.html',
  // styleUrls: ['./company-worklist.component.css']
})
export class CompanyWorklistComponent implements OnInit {
  constructor(private http: HttpClient, private _router: Router) { }

  //inputs
  @Input() company: CompanyDto;

  //outputs
  @Output() didEditCompany: EventEmitter<CompanyDto> = new EventEmitter();

  //vars
  moment = moment;
  settings: WorklistSettingsDto;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  //editors
  public WorklistEditor: DocumentEditor = DocumentEditor;
  public SuggestedWorklistEditor: DocumentEditor = DocumentEditor;

  ngOnInit() {
    this.getWorklistSettings();
  }

  //api
  getWorklistSettings(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/worklist/settings`)
      .subscribe((result: WorklistSettingsDto) => {
        this.settings = result;

        this.viewState = ViewState.content;
      });
  }

  editWorklistSettings(): void {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/worklist/settings`, this.settings)
      .subscribe((result: WorklistSettingsDto) => {
        this.settings = result;
        this.viewState = ViewState.content;
      });
  }

  //document editor
  public onReady( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
  }
}
