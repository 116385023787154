import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AddPermissionDto, AdministratorDto, PermissionDto } from 'app/models/dtos';
import { PermissionGroupsLookup, PermissionTypesLookup } from 'app/models/lookups';
import { environment } from 'environments/environment';
import moment from 'moment';

@Component({
  selector: 'app-manage-permissions',
  templateUrl: './manage-permissions.component.html',
  // styleUrls: ['./manage-permissions.component.css']
})
export class ManagePermissionsComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private _router: Router) { }

  //inputs
  @Input() objectId: string;
  @Input() group: PermissionGroupsLookup;
  @Input() permissionsRoute: string;

  //vars
  permittedAdmins: AdministratorDto[] = [];
  moment = moment;
  administrators: AdministratorDto[] = [];
  selectedAdministrator: AdministratorDto;
  permissionToAdd: AddPermissionDto;
  userSearchText: string = '';

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addPermissionViewState = ViewState.initial;


  ngOnInit() {
    this.getPermissions();
  }

  //api
  getAdministrators() {
    this.addPermissionViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/administrators`)
      .subscribe((result: AdministratorDto[]) => {
        this.administrators = result;
        this.addPermissionViewState = ViewState.content;
      });
  }

  getPermissions(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/${this.permissionsRoute}`)
      .subscribe((result: AdministratorDto[]) => {
        this.permittedAdmins = result;
        this.viewState = ViewState.content;
      });
  }

  addPermission(permissionTypeId: string, administratorId: string): void {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/${this.permissionsRoute}`, AddPermissionDto.fromIds(permissionTypeId, administratorId, this.objectId))
      .subscribe((result: PermissionDto) => {
        this.permittedAdmins.find(pa => pa.id == administratorId)?.permissions?.push(result);
        this.viewState = ViewState.content;
      });
  }

  deletePermission(permissionId: string, administratorId: string): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/${this.permissionsRoute}/${permissionId}`)
      .subscribe(() => {
        const targetAdmin = this.permittedAdmins.find(pa => pa.id == administratorId);
        targetAdmin.permissions = targetAdmin.permissions.filter(p => p.id !== permissionId);
        this.permittedAdmins = this.permittedAdmins.filter(pa => pa.permissions.length > 0);
        this.viewState = ViewState.content;
      });
  }


  //user management
  didClickAddUser() {
    this.getAdministrators();
  }

  didClickDismissAddPermission() {
    this.addPermissionViewState = ViewState.initial;
  }

  seachTextDidChange(userSearchText: string) {
    console.log(userSearchText);
  }

  availableAdmins(): AdministratorDto[] {
    return this.administrators
    .filter(a => this.permittedAdmins.find(pa => pa.id == a.id) == null)
    .filter(a =>
      a.name.toLowerCase().includes(this.userSearchText.toLowerCase())
      || (a.email ?? '').toLowerCase().includes(this.userSearchText.toLowerCase())
      || (a.phone ?? '').toLowerCase().includes(this.userSearchText.toLowerCase())
    );
  }

  //permissions
  hasPermission(administrator: AdministratorDto, permissionType: PermissionTypesLookup): Boolean {
    return administrator.permissions.find(p => p.permissionType.id === permissionType.id) != null;
  }

  toggleChange(administrator: AdministratorDto, permissionType: PermissionTypesLookup) {
    if (this.hasPermission(administrator, permissionType)) {
      const permission = administrator.permissions.find(p => p.permissionType.id == permissionType.id);
      this.deletePermission(permission.id, administrator.id);
    }
    else {
      this.addPermission(permissionType.id, administrator.id);
    }
  }

  didClickAddAdmin(administrator: AdministratorDto) {
    this.permittedAdmins.push(administrator);
    this.addPermission(this.group.types[0].id, administrator.id);
    this.userSearchText = '';
    this.addPermissionViewState = this.viewStates.initial;
  }
}
