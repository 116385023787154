

import { IdentifiableDto } from "./identifiable.dto";

export class AdCampaignDto extends IdentifiableDto {
    name!: string;
    timestampCreated!: Date;
    timestampUpdated!: Date;
    adCount: number;
    externalId!: string;
    locked: boolean;
    grossPocketedSpend: number;
    addBacks: number;
    spent: number;
}