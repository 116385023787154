<div mat-dialog-title>
  <b class="text-xl">Select States</b>
</div>

<div mat-dialog-content>
  <div class="flex items-center mt-2">
    <!-- Search -->
    <div class="flex-auto">
      <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
        <input matInput [(ngModel)]="stateSearchText" [autocomplete]="'off'" [placeholder]="'Filter states'">
      </mat-form-field>
    </div>
  </div>

  <div class="flex flex-col mt-4" style="overflow-y: scroll; height: 572px; min-width: 320px;">
    <div class="grid grid-cols-12" *ngFor="let state of filteredStates()">
      <div class="col-span-8">{{state.name}}</div>
      <div class="col-span-4 text-right pr-2">
        <ng-switchery onLabel="" [(ngModel)]="state.checked" name="" [matTooltip]="'Toggle {{state}}} filter on/off'">
        </ng-switchery>
      </div>
      <div class="col-span-12">
        <hr class="mt-2 mb-2" />
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <div class="grid grid-cols-12 gap-3">
    <button mat-stroked-button class="col-span-6" color="primary" (click)="onDismiss()">Cancel</button>
    <button mat-flat-button class="col-span-6" color="primary" (click)="onConfirm()">Confirm</button>
  </div>
  
  
</div>