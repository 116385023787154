<fuse-card class="flex flex-col w-full filter-article">
  <app-external-jobs-map [job]="job" mapHeight="200px" [mapZoom]="1"></app-external-jobs-map>
  <div class="m-8 mb-4 text-center">
    <div class="text-lg font-semibold leading-tight">{{job.title}}</div>
    <div class="text-secondary text-md mt-1">{{job.positions.length}} locations</div>
    <div class="mt-4 text-sm">
      {{strippedDesc}}...
    </div>
    <div *ngIf="included" class="mt-3 -mx-3 grid grid-cols-12 gap-3">
      <div class="col-span-12 text-emerald-500">
        <mat-icon class="icon-size-5 mr-2 text-emerald-500" matPrefix [svgIcon]="'check_circle'"
          style="vertical-align: middle;"></mat-icon>
        <b>Include</b>
      </div>
    </div>
    <div *ngIf="removable || editable" class="mt-3 -mx-3 grid grid-cols-12 gap-3">
      <a *ngIf="editable"
        routerLink="/companies/{{proposal.matchedLeadsProduct.company.id}}/matchedLeadsProducts/{{proposal.matchedLeadsProduct.id}}/proposals/{{proposal.id}}/jobs/{{job.id}}"
        class="col-span-12" mat-flat-button [color]="'primary'">
        Edit Job
      </a>
      <button *ngIf="!editable" class="col-span-12" mat-flat-button [color]="'primary'" [disabled]="true">
        View Job
      </button>
      <button class="col-span-12" mat-flat-button [color]="'warn'" [disabled]="!removable"
        [swal]="{ title: 'Remove Job From Proposal?', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
        (confirm)="didClickRemoveJob()">
        Remove Job
      </button>
    </div>
  </div>
</fuse-card>