import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagePermissionsComponent } from './manage-permissions/manage-permissions.component';
import { FuseModule } from '@fuse';
import { FuseCardModule } from '@fuse/components/card';
import { MatButtonModule } from '@angular/material/button';
import { NgSwitcheryModule } from 'angular-switchery-ios';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { IconsModule } from 'app/core/icons/icons.module';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FuseCardModule,
    MatButtonModule,
    NgSwitcheryModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatInputModule,
    IconsModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule
  ],
  declarations: [
    ManagePermissionsComponent
  ],
  exports: [
    ManagePermissionsComponent
  ]
})
export class PermissionsModule { }
