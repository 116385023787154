import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AdministratorDto } from "app/models/dtos";
import { PermissionTypesLookup } from "app/models/lookups";
import { environment } from "environments/environment";

@Injectable({
    providedIn: 'root',
  })
export class AccountsService {
    constructor(private http: HttpClient) { }

    private _account: AdministratorDto = null;

    async getAccount(): Promise<AdministratorDto> {
        if (this._account != null) { return this._account; }

        this._account = await this.http
            .get<AdministratorDto>(`${environment.services_tdusa_admin}/v1/accounts/me`).toPromise();

        return this._account;
    }

    async hasPermission(checkPermission: PermissionTypesLookup, objectId: string) : Promise<boolean> {
        const account = await this.getAccount();
        if(checkPermission.id === PermissionTypesLookup.SUPER_ADMIN.id) { return account.permissions.find(p => p.permissionType.id === PermissionTypesLookup.SUPER_ADMIN.id) != null; }
        if(checkPermission.id === PermissionTypesLookup.ADMIN.id) { return account.permissions.find(p => p.permissionType.id === PermissionTypesLookup.ADMIN.id) != null; }

        //content permission check
        return account.permissions.find(p => p.permissionType.id == checkPermission.id && p.objectId == objectId) != null;
    }

    async isSuperAdmin() : Promise<boolean> {
        return await this.hasPermission(PermissionTypesLookup.SUPER_ADMIN, '');
    }

    async isAdmin() : Promise<boolean> {
        return await this.hasPermission(PermissionTypesLookup.ADMIN, '');
    }
}