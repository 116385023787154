import { ExternalJobDto } from "./externalJob.dto";

export class EditExternalJobDto {
    //core
    title!: string;
    notes: string;
    descriptionHtml!: string;
    inquiryWebsite: string;
    customApplyUrl: string;
    customSeoTitle: string;
    visible: boolean;
    twilioNumber: string;
    twilioForwardNumber: string;
    tenstreetReferrer: string;
    jobcastReferenceId: string;
    tenstreetJobPostingReferenceId: string;
    salary: string;

    static fromJob(job: ExternalJobDto) : EditExternalJobDto {
        var edit = new EditExternalJobDto();
        edit.title = job.title;
        edit.notes = job.notes;
        edit.descriptionHtml = job.descriptionHtml;
        edit.inquiryWebsite = job.inquiryWebsite;
        edit.customApplyUrl = job.customApplyUrl;
        edit.customSeoTitle = job.customSeoTitle;
        edit.visible = job.visible;
        edit.twilioNumber = job.twilioNumber;
        edit.twilioForwardNumber = job.twilioForwardNumber;
        edit.tenstreetReferrer = job.tenstreetReferrer;
        edit.jobcastReferenceId = job.jobcastReferenceId;
        edit.salary = job.salary;

        if((job.tenstreetJobPostingReferenceId ?? '').trim().length > 0) {
            edit.tenstreetJobPostingReferenceId = job.tenstreetJobPostingReferenceId;
        }
        else {
            edit.tenstreetJobPostingReferenceId = null;
        }

        return edit;
    }
}