export class MatchedLeadsProductMatchingDto {
    violations!: number;
    accidents!: number;
    interestInOoLp!: boolean;
    interestInTeamDriving!: boolean;
    teamDriver!: boolean;
    freightMatching: boolean;
    ownAuthority: boolean;

    //singles
    experienceTypeId: string;

    //multis
    cdlClasses: string[] = [];
    driverTypes: string[] = [];
    endorsements: string[] = [];
    freightTypes: string[] = [];

    customExperienceTypes: string[] = [];
}