import { AdministratorDto } from ".";

export class EditAdministratorDto {
    email!: string;
    name!: string;
    phone: string;
    imageUrl: string;
    position: string;
    canLogin: boolean;

    static fromAdministrator(administrator: AdministratorDto) : EditAdministratorDto {
        var edit = new EditAdministratorDto();
        edit.name = administrator.name;
        edit.imageUrl = administrator.imageUrl;
        edit.phone = administrator.phone;
        edit.position = administrator.position;
        edit.canLogin = administrator.canLogin;

        return edit;
    }
}