import { DocumentDto } from "./document.dto";

export class EditDocumentDto {
    name!: string;
    documentTypeId!: string;

    static fromDocument(document: DocumentDto) : EditDocumentDto {
        var edit = new EditDocumentDto();
        edit.name = document.name;
        edit.documentTypeId = document.documentType.id;

        return edit;
    }
}