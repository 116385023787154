import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewState } from 'app/models/app';
import { JobPostingDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-job-board-posting-modal',
  templateUrl: './job-board-posting-modal.component.html',
  // styleUrls: ['./tenstreet-job-modal.component.css']
})
export class JobBoardPostingModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<JobBoardPostingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public jobPosting: JobPostingDto,
    private http: HttpClient
  ) { }

  //vars
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;


  ngOnInit() {

  }

  //api
  markResolved(): void {
    this.viewState = ViewState.loading;

    this.jobPosting.read = true;
    this.http
      .put(`${environment.services_tdusa_admin}/v1/jobBoards/${this.jobPosting.id}`, this.jobPosting)
      .subscribe(() => {
        this.viewState = ViewState.content;

        this.didClickDismiss('unresolve');
      });
  }

  markUnresolved(): void {
    this.viewState = ViewState.loading;

    this.jobPosting.read = false;
    this.http
      .put(`${environment.services_tdusa_admin}/v1/jobBoards/${this.jobPosting.id}`, this.jobPosting)
      .subscribe(() => {
        this.viewState = ViewState.content;

        this.didClickDismiss('unresolve');
      });
  }

  //actions
  didClickDismiss(action: string = 'dismiss') {
    this.dialogRef.close({
      action: action,
      data: this.jobPosting
    });
  }

  toggleResolved() {
    if (!this.jobPosting.read) {
      this.markResolved();
    }
    else {
      this.markUnresolved();
    }
  }

  jobPostingSerialized(): string {
    return JSON.stringify(this.jobPosting.jobPosting, null, 2);
  }
}
