import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ViewState } from 'app/models/app';
import { CompanyDto, MatchedLeadsProductDto, WebhookDto, WebhookFieldDto } from 'app/models/dtos';
import { WebhookMethodTypesLookup } from 'app/models/lookups';
import { WebhookAtsFieldsLookup } from 'app/models/lookups/webhookAtsFields.lookup';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-matched-leads-product-webhook',
  templateUrl: './matched-leads-product-webhook.component.html',
  // styleUrls: ['./matched-leads-product-webhook.component.css']
})
export class MatchedLeadsProductWebhookComponent implements OnInit {
  constructor(private http: HttpClient) { }

  //inputs
  @Input() company: CompanyDto;
  @Input() product: MatchedLeadsProductDto;
  @Input() webhook: WebhookDto;

  //outputs
  @Output() didDeleteWebhook: EventEmitter<WebhookDto> = new EventEmitter();

  //vars
  moment = moment;
  webhookFields: WebhookFieldDto[] = [];

  webhookFieldTypes = structuredClone(WebhookAtsFieldsLookup.values);
  webhookMethodTypes = structuredClone(WebhookMethodTypesLookup.values);

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;
  webhookFieldsViewState = ViewState.content;

  ngOnInit() {
    this.getFields();
  }

  //api
  editWebhook() {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/webhooks/${this.webhook.id}`, this.webhook)
      .subscribe((result: WebhookDto) => {
        this.webhook = result;
        this.viewState = ViewState.content;
      });
  }

  deleteWebhook() {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/webhooks/${this.webhook.id}`)
      .subscribe(() => {
        this.didDeleteWebhook?.emit(this.webhook);
      });
  }

  testWebhook() {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/webhooks/${this.webhook.id}/test`)
      .subscribe(() => {
        this.viewState = ViewState.content;
      });
  }

  getFields() {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/webhooks/${this.webhook.id}/fields`)
      .subscribe((result: WebhookFieldDto[]) => {
        this.webhookFields = result;
        this.viewState = ViewState.content;
      });
  }

  addField() {
    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/webhooks/${this.webhook.id}/fields`, null)
      .subscribe((result: WebhookFieldDto) => {
        //add
        this.webhookFields.push(result);
      });
  }

  editField(field: WebhookFieldDto) {
    field.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/webhooks/${this.webhook.id}/fields/${field.id}`, field)
      .subscribe((result: WebhookFieldDto) => {
        //replace
        field.viewState = ViewState.content;
      });
  }

  deleteField(field: WebhookFieldDto) {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/webhooks/${this.webhook.id}/fields/${field.id}`)
      .subscribe(() => {
        //remove
        this.webhookFields = this.webhookFields.filter(f => f.id != field.id);
        this.viewState = ViewState.content;
      });
  }


  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  isValidWebhook(webhook: WebhookDto) {
    return (webhook.name ?? '').trim() != '';
  }

  fieldTypeForField(field: string) : WebhookAtsFieldsLookup {
    return this.webhookFieldTypes.find(t => t.field == field);
  }
}
