import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { EditLeadGenSettingsDto } from 'app/models/dtos/editLeadGenSettings.dto';
import { LeadGenSettingsDto } from 'app/models/dtos/leadGenSettings.dto';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-settings-lead-gen',
  templateUrl: './settings-lead-gen.component.html',
  // styleUrls: ['./settings-lead-gen.component.css']
})
export class SettingsLeadGenComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  settings: LeadGenSettingsDto;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  ngOnInit() {
    //set title
    this.titleService.setTitle(`Settings | Admin`);

    this.getSettings();
  }

  //api
  getSettings(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/settings`)
      .subscribe((result: LeadGenSettingsDto) => {
        this.settings = result;
        this.processTempVars();

        this.viewState = ViewState.content;
      });
  }

  processTempVars() {
    this.settings.minDailySpendCampaign = (this.settings.minDailySpendCampaignInCents / 100);
  }

  editSettings(): void {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/settings`, EditLeadGenSettingsDto.fromSettings(this.settings))
      .subscribe((result: LeadGenSettingsDto) => {
        this.settings = result;
        this.processTempVars();
        this.viewState = ViewState.content;
      });
  }

  //validations
  settingsIsValid(): Boolean {
    return this.settings.matrixProductWeights > 0
      && this.settings.maxSendsPerApplication > 0
      && this.settings.percentageAllocation > 0;
  }

  minDailySpendInCentsDidChange() {
    this.settings.minDailySpendCampaignInCents = Math.floor((this.settings.minDailySpendCampaign * 100));

    try {
      if (`${this.settings.minDailySpendCampaign}`.split(".")[1].length > 2) {
        setTimeout(() => {
          this.settings.minDailySpendCampaign = (this.settings.minDailySpendCampaignInCents / 100);
        }, 100);
      }
    } catch (error) {

    }
  }
}
