
import { AdCampaignDto } from "./adCampaign.dto";
import { IdentifiableDto } from "./identifiable.dto";
import { AdSpendTypeDto } from "./lookup.dto";

export class AdSpendDto extends IdentifiableDto {
    timestampCreated!: Date;
    amount!: number;

    //relations
    adSpendType: AdSpendTypeDto;
}