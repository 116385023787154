import { CompanyDto } from "./company.dto";

export class EditCompanyDto {
    name!: string;
    phone: string;
    notes!: string;
    imageUrl: string;

    //relations
    repContactId: string;
    billingContactId: string;
    collectiveId: string;
    currentStatusId: string;
    currentSpendPeriodId: string;
    leadOpsAdministratorId: string;
    leadSalesAdministratorId: string;

    static fromCompany(company: CompanyDto, salesRepId: string, opsRepId: string, repContactId: string, billingContactId: string) : EditCompanyDto {
        var edit = new EditCompanyDto();
        edit.name = company.name;
        edit.notes = company.notes
        edit.phone = company.phone;
        edit.imageUrl = company.imageUrl;

        //reps
        edit.leadSalesAdministratorId = salesRepId;
        edit.leadOpsAdministratorId =  opsRepId;

        //contacts
        edit.repContactId = repContactId;
        edit.billingContactId = billingContactId;

        return edit;
    }
}