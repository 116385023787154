import { MatchedLeadsProductEmailGroupDto } from "./matchedLeadsProductEmailGroup.dto";

export class MatchedLeadsProductEmailSettingsDto {
    name: string
    emailModeId: string;
    emailFormatId: string;
    batchLeads: boolean;

    static fromGroup(group: MatchedLeadsProductEmailGroupDto) : MatchedLeadsProductEmailSettingsDto {
        var dto = new MatchedLeadsProductEmailSettingsDto();
        dto.name = group.name;
        dto.emailFormatId = group.emailFormat.id;
        dto.emailModeId = group.emailMode.id;
        dto.batchLeads = group.batchLeads;

        return dto;
    }
}