<div>
  <div class="mb-4 text-center">
    <div class="text-2xl"><b>Define Your Hiring Requirements</b></div>
    <div>
      <small>Please tell us the minimum hiring requirements that your company is looking for. This includes your
        companies' overall hiring area, the type of driver you are looking for, minimum experience & endorsements. You
        will be able to change these requirements at any time. We will use these initial settings to configure our
        application filtering so that we are not sending you applications that do not meet your minimum hiring
        guidelines.</small>
    </div>
  </div>

  <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article text-sm">
    <!--header-->
    <div class="flex items-center justify-between">
      <div class="text-2xl font-semibold leading-tight">Experience & Requirement</div>
      <div class="-mr-3">
        <button mat-flat-button [color]="'primary'" (click)="editMatching()"
          [disabled]="(!permissionsService.canManage() && !proposalId)">
          <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
          <span class="ml-2 mr-1">Save</span>
        </button>
      </div>
    </div>

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5 mb-5"></mat-spinner>
    </div>

    <div *ngIf="viewState == viewStates.content">
      <!-- driver types -->
      <div class="grid grid-cols-12 gap-3 mb-2 mt-4 ">
        <div class="col-span-12 md:col-span-8">
          <div class="text-primary"><b>Desired Driver Types (1 or more)</b></div>
          <div class="flex flex-row flex-wrap">
            <mat-checkbox *ngFor="let item of driverTypes" class="p-2" [color]="'primary'" [(ngModel)]="item.checked"
              (change)="setDriverType(item)"
              [disabled]="formLocked || (!permissionsService.canManage() && !proposalId)">
              {{item.name}}
            </mat-checkbox>

            <mat-checkbox class="p-2" [color]="'primary'" [(ngModel)]="matching.interestInOoLp"
              matTooltip="When enabled, this option will include those who select they are interested in OO & LP. This is only relevant when OO or LP is also checked."
              [disabled]="formLocked || (!permissionsService.canManage() && !proposalId)">
              Interest in OO & LP
            </mat-checkbox>
          </div>
          <div class="flex flex-row flex-wrap">
            <div class="col-span-12 md:col-span-4">
              <mat-form-field appearance="fill" class="w-full">
                <mat-label class="text-primary text-sm font-bold">Team Driver</mat-label>
                <mat-select [(value)]="matching.teamDriver"
                  matTooltip="Team Driver: Empty will allow both team and solo drivers. Team takes only team drivers. Solo takes only solo drivers."
                  [disabled]="formLocked || (!permissionsService.canManage() && !proposalId)">
                  <mat-option *ngFor="let type of teamDriverTypes" [value]="type.value">
                    {{type.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="pt-8 pl-2">
              <mat-checkbox class="p-2" [color]="'primary'" [(ngModel)]="matching.interestInTeamDriving"
                matTooltip="Note: This checkbox requires that Team Driver be checked! When enabled, this option will match those interested in team driving in addition to those who are already team drivers, effectively widening the pool of potential candidates. When disabled, only team drivers will match (assuming Team Drivers is enabled)."
                [disabled]="formLocked || (!permissionsService.canManage() && !proposalId)">
                Solo drivers interested
                in becoming Team Drivers
              </mat-checkbox>
            </div>

          </div>
        </div>
        <div class="col-span-12 md:col-span-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label class="text-primary text-sm font-bold">Own Authority (empty matches both)</mat-label>
            <mat-select [(value)]="matching.ownAuthority"
              matTooltip="Own Authority: When enabled, this option will further filter down Owner Operator applications to those that answer the 'Own Authority' as the selected option below. Defaults to empty which matches both."
              [disabled]="formLocked || (!permissionsService.canManage() && !proposalId)">
              <mat-option *ngFor="let type of ownAuthorityTypes" [value]="type.value">
                {{type.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <!-- license types -->
      <div class="mb-2">
        <div class="text-primary"><b>Desired License Types (1 or more)</b></div>
        <div class="flex flex-row flex-wrap">
          <mat-checkbox *ngFor="let item of cdlClasses" class="p-2" [color]="'primary'" [(ngModel)]="item.checked"
            (change)="setLicenseType(item)" [disabled]="formLocked || (!permissionsService.canManage() && !proposalId)">
            {{item.name}}
          </mat-checkbox>
        </div>
      </div>

      <!-- freight types -->
      <div class="mb-2">
        <div class="text-primary"><b>Desired Freight Types (1 or more)</b></div>
        <div class="flex flex-row flex-wrap">
          <mat-checkbox *ngFor="let item of freightTypes" class="p-2" [color]="'primary'" [(ngModel)]="item.checked"
            (change)="setFreightType(item)" [disabled]="formLocked || (!permissionsService.canManage() && !proposalId)">
            {{item.name}}
          </mat-checkbox>
          <mat-checkbox class="p-2" [color]="'primary'" [(ngModel)]="matching.freightMatching"
            style="border-left: 1px solid grey;"
            matTooltip="Freight matching: When enabled, this option restricts the matching of drivers to only those who commonly haul or are interested in the types of freight checked above. When disabled, freight type is disregarded for lead matching."
            [disabled]="formLocked || (!permissionsService.canManage() && !proposalId)">
            Freight Matching
          </mat-checkbox>
        </div>
      </div>

      <!-- endorsements -->
      <div class="mb-2">
        <div class="text-primary"><b>Desired Endorsement Types (candidates must satisfy ALL selected)</b></div>
        <div class="flex flex-row flex-wrap">
          <mat-checkbox *ngFor="let item of endorsements" class="p-2" [color]="'primary'" [(ngModel)]="item.checked"
            (change)="setEndorsementType(item)"
            [disabled]="formLocked || (!permissionsService.canManage() && !proposalId)">
            {{item.name}}
          </mat-checkbox>
        </div>
      </div>


      <div class="text-lg font-semibold leading-tight w-full mt-4 mb-2">Driving History</div>
      <div class="grid grid-cols-12 gap-3">
        <!-- experience -->
        <div class="col-span-12 md:col-span-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label class="text-primary text-sm font-bold">Minimum Experience</mat-label>
            <mat-select [(value)]="matching.experienceTypeId"
              [disabled]="formLocked || (!permissionsService.canManage() && !proposalId)">
              <mat-option *ngFor="let experienceLevel of experience" [value]="experienceLevel.id">
                {{experienceLevel.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <!--custom experience levels-->
          <ng-switchery onLabel="Custom Experience Levels" [(ngModel)]="matching.customExperienceLevels"
            name="custom-experience-level" size="switchery-sm"
            matTooltip="Instead of using the default minimum experience, this options allows you to manually select all desired experience levels when matching">
          </ng-switchery>

          <div *ngIf="matching.customExperienceLevels">
            <mat-checkbox *ngFor="let item of customExperience" class="p-2" [color]="'primary'" [(ngModel)]="item.checked"
              (change)="setCustomExperienceType(item)"
              [disabled]="formLocked || (!permissionsService.canManage() && !proposalId)">
              {{item.name}}
            </mat-checkbox>
          </div>
        </div>

        <!-- violations -->
        <div class="col-span-12 md:col-span-4">
          <mat-form-field class="w-full">
            <mat-label class="text-primary text-sm font-bold"># of Violations</mat-label>
            <input matInput type="number" [(ngModel)]="matching.violations" [autocomplete]="'off'"
              [disabled]="formLocked || (!permissionsService.canManage() && !proposalId)" placeholder="# of Violations">
            <mat-icon class="icon-size-5" matSuffix [svgIcon]="'warning'"></mat-icon>
          </mat-form-field>
        </div>

        <!-- accidents -->
        <div class="col-span-12 md:col-span-4">
          <mat-form-field class="w-full">
            <mat-label class="text-primary text-sm font-bold"># of Accidents</mat-label>
            <input matInput type="number" [(ngModel)]="matching.accidents" [autocomplete]="'off'"
              [disabled]="formLocked || (!permissionsService.canManage() && !proposalId)" placeholder="# of Accidents">
            <mat-icon class="icon-size-5" matSuffix [svgIcon]="'directions_car'"></mat-icon>
          </mat-form-field>
        </div>
      </div>
    </div>
  </fuse-card>

  <mat-divider class="mt-4 mb-4"></mat-divider>


</div>