import { AgencyDto } from "./agency.dto";

export class EditAgencyDto {
    name!: string;
    imageUrl: string;
    repContactId: string;

    static fromAgency(agency: AgencyDto, repContactId: string) : EditAgencyDto {
        var edit = new EditAgencyDto();
        edit.name = agency.name;
        edit.imageUrl = agency.imageUrl;
        edit.repContactId = repContactId;

        return edit;
    }
}