export class ApplicationViewTypes {

    id!: string
    name!: string

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    // Values
    static readonly LEADS = new ApplicationViewTypes('1', 'Sent Leads');
    static readonly APPS = new ApplicationViewTypes('2', 'Applications');
    static readonly UNIQUE_APPS = new ApplicationViewTypes('3', 'Unique Applications (slow)');
    static readonly BLOCKED_APPS = new ApplicationViewTypes('4', 'Blocked Applications');
    static readonly SUSPISCIOUS_APPS = new ApplicationViewTypes('5', 'Suspiscious Applications');
    // static readonly MATCHES = new ApplicationViewTypes('6', 'Matches');

    static readonly values = [
        ApplicationViewTypes.LEADS,
        ApplicationViewTypes.APPS,
        ApplicationViewTypes.UNIQUE_APPS,
        ApplicationViewTypes.BLOCKED_APPS,
        ApplicationViewTypes.SUSPISCIOUS_APPS,
        // ApplicationViewTypes.MATCHES
    ];

    static readonly companyValues = [
        ApplicationViewTypes.LEADS
    ];
}