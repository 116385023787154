<!--content-->
<div class="grid grid-cols-12 gap-3 text-left">
  <!--header-->
  <div class="col-span-12">
    <div class="font-bold text-primary text-xl">{{input.title}}</div>
    <div>
      <small>{{input.subtitle}}</small>
    </div>
  </div>

  <div class="col-span-12">
    <hr class="mt-0 mb-0" />
  </div>

  <!--results-->
  <div class="col-span-12 grid grid-cols-12 gap-3 " style="max-height: 480px; overflow-y: scroll;">
    <div class="col-span-12 flex flex-col flex-auto">
      <div class="flex flex-auto">
        <app-job-criteria-form #jobCriteriaForm [job]="input.job"></app-job-criteria-form>
      </div>
    </div>
  </div>


  <!--actions-->
  <div class="col-span-12">
    <hr class="mt-0 mb-0" />
  </div>

  <div class="col-span-12 md:col-span-6">
    <button mat-flat-button color="warn" class="w-full" (click)="didClickDismiss()">Dismiss</button>
  </div>
  <div class="col-span-12 md:col-span-6">
    <button mat-flat-button color="primary" class="w-full" (click)="didClickSave()">Save</button>
  </div>
</div>