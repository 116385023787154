<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="Candidates" fallbackIcon="heroicons_solid:user-circle"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
      <!-- Search -->
      <div class="flex-auto">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [(ngModel)]="searchText" [autocomplete]="'off'"
            (ngModelChange)="seachTextDidChange(searchText)" [placeholder]="'Filter candidates'">
        </mat-form-field>
      </div>
    </div>
     

    <!--list-->
    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- no content -->
    <div *ngIf="viewState == viewStates.content && filteredCandidates.length === 0" class="mt-8">
      <app-generic-no-content 
      title="No Candidates Found"
      subtitle="No candidates were found please refresh or update your search criteria."
      imageUrl="https://img.icons8.com/color/256/000000/group.png"
      class="ml-auto mr-auto mt-5"></app-generic-no-content>
    </div>

    <!-- content -->
    <div [hidden]="viewState != viewStates.content || filteredCandidates.length === 0" class="w-full mt-8">
      <fuse-card class="w-full p-4">
        <div class="w-full overflow-x-auto mx-6">
          <table
              class="w-full bg-transparent"
              mat-table
              matSort
              [dataSource]="candidatesDataSource"
              [trackBy]="trackByFn"
              #candidatesTable>
  
              <!-- Name -->
              <ng-container matColumnDef="name">
                  <th
                      mat-header-cell
                      mat-sort-header
                      *matHeaderCellDef>
                      Name
                  </th>
                  <td
                      mat-cell
                      *matCellDef="let candidate">
                      <a routerLink="/candidates/{{candidate.id}}" class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                        <img class="inline mr-3 rounded-full object-cover w-6 h-6"*ngIf="candidate.imageUrl != null" [src]="candidate.imageUrl" />
                        <mat-icon  *ngIf="candidate.imageUrl == null" class="mr-3 text-primary" style="vertical-align: bottom;"
                        [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
                          <b class="text-slate-600">{{candidate.name}}</b>
                      </a>
                  </td>
              </ng-container>

              <!-- Email -->
            <ng-container matColumnDef="email">
              <th
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef>
                  Email
              </th>
              <td
                  mat-cell
                  *matCellDef="let candidate">
                  <span class="pr-6 text-secondary whitespace-nowrap">
                      {{candidate.email}}
                  </span>
              </td>
          </ng-container>

          <!-- Phone -->
          <ng-container matColumnDef="phone">
            <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef>
                Phone
            </th>
            <td
                mat-cell
                *matCellDef="let candidate">
                <span class="pr-6 text-secondary whitespace-nowrap">
                    {{candidate.phone}}
                </span>
            </td>
        </ng-container>

              <tr
                  mat-header-row
                  *matHeaderRowDef="candidatesTableColumns"></tr>
              <tr
                  class="order-row h-16"
                  mat-row
                  *matRowDef="let row; columns: candidatesTableColumns;"></tr>
              <!-- <tr
                  class="h-16 border-0"
                  mat-footer-row
                  *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
          </table>
      </div>
      </fuse-card>
    </div>
     

  </div>

</div>