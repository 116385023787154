<!--Import Jobs-->
<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
  <!--header-->
  <div class="flex items-center justify-between">
    <div class="text-2xl font-semibold leading-tight">Manage Permissions</div>
    <div class="-mr-3">
      <button class="ml-2" mat-flat-button [color]="'primary'" (click)="didClickAddUser()">
        <span class="ml-2 mr-1">Add User</span>
      </button>
    </div>
  </div>
  <div class="mb-4 mt-2 text-sm">
    Manage specific permissiosn for a given administrator below
  </div>

  <!--Add Permission-->
  <fuse-card *ngIf="addPermissionViewState != viewStates.initial"
    class="flex flex-col w-full mb-4 p-8 filter-article mt-4">
    <div class="text-2xl font-semibold leading-tight">Add Staff Member</div>
    <div class="text-secondary text-md mt-1">Provide a staff member to grant them view access.</div>

    <mat-form-field class="w-full mt-4">
      <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
      <input matInput [(ngModel)]="userSearchText" [autocomplete]="'off'" placeholder="Staff member name"
        (ngModelChange)="seachTextDidChange(userSearchText)">
    </mat-form-field>

    <!--loading-->
    <div *ngIf="addPermissionViewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!--content-->
    <div *ngIf="addPermissionViewState == viewStates.content" class="mt-4">
      <div style="max-height: 300px; overflow-y: scroll;">
        <fuse-card class="mt-3 p-4" *ngFor="let administrator of availableAdmins()" (click)="didClickAddAdmin(administrator)" style="cursor: pointer;">
          <mat-icon *ngIf="administrator.imageUrl == null" class="w-5 h-5 mr-3 text-primary"
            style="vertical-align: bottom;" [svgIcon]="'heroicons_outline:identification'"></mat-icon>
          <img *ngIf="administrator.imageUrl != null" src="{{administrator.imageUrl}}"
            class="w-5 h-5 mr-3 rounded-full object-cover inline-block" style="vertical-align: middle;" />
          <span class="whitespace-nowrap font-bold">
            {{administrator.name}}
          </span>
        </fuse-card>
      </div>
      
      <div class="grid grid-cols-12 gap-3 mt-3">
        <div class="col-span-12">
          <button class="w-full" mat-flat-button [color]="'warn'" (click)="didClickDismissAddPermission()">
            <span class="ml-2 mr-1">Cancel</span>
          </button>
        </div>
      </div>
    </div>
  </fuse-card>

  <!--loading-->
  <div *ngIf="viewState == viewStates.loading">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
  </div>
  <div *ngIf="viewState == viewStates.content">
    <fuse-card class="grid grid-cols-12 gap-3 mt-4 p-4" *ngFor="let administrator of permittedAdmins">
      <div class="col-span-12 md:col-span-3">
        <mat-icon *ngIf="administrator.imageUrl == null" class="w-10 h-10 mr-3 text-primary"
          style="vertical-align: bottom;" [svgIcon]="'heroicons_outline:identification'"></mat-icon>
        <img *ngIf="administrator.imageUrl != null" src="{{administrator.imageUrl}}"
          class="w-10 h-10 mr-3 rounded-full object-cover inline-block" style="vertical-align: middle;" />
        <span class="whitespace-nowrap font-bold">
          {{administrator.name}}
        </span>
      </div>
      <div class="col-span-12 md:col-span-3 text-center" *ngFor="let permissionType of group.types">
        <!-- <ng-switchery *ngIf="administrator?.id != user?.id" name="super-admin" size="switchery-md"
          [(ngModel)]="" (ngModelChange)="toggleChange(administrator, permissionType)"></ng-switchery> -->

          <mat-checkbox class="p-2" [color]="'primary'" [checked]="hasPermission(administrator, permissionType)"
          (change)="toggleChange(administrator, permissionType)">
          {{permissionType.name}}
        </mat-checkbox>

        <!-- <div class="text-md font-bold">{{permissionType.name}}</div> -->
        <!-- <div>{{permissionType.description}}</div> -->
      </div>
    </fuse-card>
  </div>
</fuse-card>