import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyDto, ExternalJobDto, MatchedLeadsProductDto } from 'app/models/dtos';
import { stripHtml } from "string-strip-html";

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  // styleUrls: ['./job-card.component.css']
})
export class JobCardComponent implements OnInit {
  constructor() { }

  //inputs
  @Input() editUrl: CompanyDto;
  @Input() job: ExternalJobDto;
  @Input() editable: boolean = false;

  //outputs
  @Output() viewJobHandler: EventEmitter<ExternalJobDto> = new EventEmitter<ExternalJobDto>();
  @Output() editJobHandler: EventEmitter<ExternalJobDto> = new EventEmitter<ExternalJobDto>();

  //vars
  strippedDesc: string = '';

  ngOnInit() {
    this.strippedDesc = stripHtml(this.job.descriptionHtml ?? '').result.substring(0, 100);
  }

  didClickViewJob() {
    this.viewJobHandler?.emit();
  }

  didClickEditJob() {
    this.editJobHandler?.emit();
  }
}
