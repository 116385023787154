<div class="flex items-center mt-4 sm:mt-0 md:mt-4">
  <!-- Search -->
  <div class="flex-auto">
    <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
      <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
      <input matInput [(ngModel)]="searchText" [autocomplete]="'off'" (ngModelChange)="seachTextDidChange(searchText)"
        [placeholder]="'Filter proposals'">
    </mat-form-field>
  </div>
  <!-- Add proposal button -->
  <button *ngIf="product != null && addProposalViewState == viewStates.initial" class="ml-4" mat-flat-button
    [color]="'primary'" (click)="didClickAddProposal()">
    <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
    <span class="ml-2 mr-1">Add</span>
  </button>
</div>


<!--Add Proposal-->
<fuse-card *ngIf="addProposalViewState != viewStates.initial" class="flex flex-col w-full mb-4 p-8 filter-article mt-4">
  <div class="text-2xl font-semibold leading-tight">Add Proposal</div>
  <div class="text-secondary text-md mt-1">Provide a name for your new proposal below</div>

  <!--loading-->
  <div *ngIf="addProposalViewState == viewStates.loading">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
  </div>

  <!--content-->
  <div *ngIf="addProposalViewState == viewStates.content" class="mt-4">
    <mat-form-field class="w-full mt-4">
      <input matInput [(ngModel)]="proposalToAdd.name" [autocomplete]="'off'"
        placeholder="e.g. 2022-01-01 Company Name">
      <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:presentation-c'"></mat-icon>
    </mat-form-field>

    <div class="grid grid-cols-12 gap-3">
      <div class="col-span-12 md:col-span-6">
        <button class="w-full" mat-flat-button [color]="'warn'" (click)="didClickDismissAddProposal()">
          <span class="ml-2 mr-1">Cancel</span>
        </button>
      </div>
      <div class="col-span-12 md:col-span-6">
        <button class="w-full" mat-flat-button [color]="'primary'" (click)="didClickConfirmAddProposal()"
          [disabled]="!isValidProposal(addProposal)">
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="ml-2 mr-1">Add</span>
        </button>
      </div>
    </div>
  </div>
</fuse-card>


<!--list-->
<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<!-- no content -->
<div *ngIf="viewState == viewStates.content && filteredProposals.length === 0" class="mt-8">
  <app-generic-no-content title="No Proposals Found"
    subtitle="No proposals were found please refresh or update your search criteria."
    imageUrl="https://img.icons8.com/color/256/000000/training.png" class="ml-auto mr-auto mt-5">
  </app-generic-no-content>
</div>

<!-- content -->
<div [hidden]="viewState != viewStates.content || filteredProposals.length === 0" class="w-full mt-8">
  <fuse-card class="w-full p-4">
    <div class="w-full overflow-x-auto mx-6">
      <table class="w-full bg-transparent" mat-table matSort [dataSource]="proposalsDataSource" [trackBy]="trackByFn"
        #proposalsTable>

        <!-- Name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Name
          </th>
          <td mat-cell *matCellDef="let proposal">
            <a routerLink="/companies/{{company?.id ?? proposal.matchedLeadsProduct?.company?.id}}/matchedLeadsProducts/{{product?.id ?? proposal.matchedLeadsProduct?.id}}/proposals/{{proposal.id}}"
              class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
              <mat-icon class="mr-3 text-primary" style="vertical-align: bottom;"
                [svgIcon]="'heroicons_solid:presentation-chart-bar'"></mat-icon>
              <b class="text-slate-600">{{proposal.name}}</b>
            </a>
          </td>
        </ng-container>

        <!-- Product -->
        <ng-container matColumnDef="product">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Product
          </th>
          <td mat-cell *matCellDef="let proposal">
            <span class="whitespace-nowrap">
              {{proposal.matchedLeadsProduct.name}}
            </span>
          </td>
        </ng-container>

        <!-- company -->
        <ng-container matColumnDef="company">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Company
          </th>
          <td mat-cell *matCellDef="let proposal">
            <img src="{{proposal.matchedLeadsProduct.company.imageUrl}}" class="w-4 h-4 rounded inline-block"
              style="vertical-align: middle;" />
            <span class="whitespace-nowrap">
              {{proposal.matchedLeadsProduct.company.name}}
            </span>
          </td>
        </ng-container>

        <!-- timestamp -->
        <ng-container matColumnDef="created">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Created
          </th>
          <td mat-cell *matCellDef="let proposal">
            <span class="whitespace-nowrap">
              {{moment(proposal.timestampCreated).format('lll')}}
            </span>
          </td>
        </ng-container>

        <!-- actions -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Created
          </th>
          <td mat-cell *matCellDef="let proposal">
            <span class="whitespace-nowrap">
              <a mat-icon-button routerLink="/companies/{{proposal.matchedLeadsProduct.company.id}}/matchedLeadsProducts/{{proposal.matchedLeadsProduct.id}}/proposals/{{proposal.id}}">
                <mat-icon svgIcon="edit" matTooltip="Edit"></mat-icon>
            </a>
              <button mat-icon-button
                [swal]="{ title: 'Copy Proposal', text: 'Would you like to duplicate this proposal and all its settings?', confirmButtonText: 'Copy', icon: 'info', confirmButtonColor: '#EF4444', showCancelButton: true }"
                (confirm)="duplicateProposal(proposal)">
                <mat-icon svgIcon="content_copy" matTooltip="Copy"></mat-icon>
              </button>
              <button mat-icon-button
                [swal]="{ title: 'Delete Proposal', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
                (confirm)="deleteProposal(proposal)">
                <mat-icon svgIcon="delete" matTooltip="Delete"></mat-icon>
              </button>
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="proposalsTableColumns"></tr>
        <tr class="order-row h-16" mat-row *matRowDef="let row; columns: proposalsTableColumns;"></tr>
        <!-- <tr
              class="h-16 border-0"
              mat-footer-row
              *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
      </table>
    </div>
  </fuse-card>
</div>