import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-html-snippet',
  templateUrl: './html-snippet.component.html',
  styleUrls: ['./html-snippet.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class HtmlSnippetComponent implements OnInit {
  constructor() { }

  //Inputs
  @Input() html: string = '';

  ngOnInit() {
  }

}
