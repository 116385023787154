import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { BannedDomainDto } from 'app/models/dtos';
import { AddBannedDomainDto } from 'app/models/dtos/addBannedDomain.dto';
import { environment } from 'environments/environment';
import moment from 'moment';

@Component({
  selector: 'app-settings-banned-domains',
  templateUrl: './settings-banned-domains.component.html',
  // styleUrls: ['./settings-banned-domains.component.css']
})
export class SettingsBannedDomainsComponent implements OnInit, AfterViewInit {
  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  //view children
  @ViewChild('domainsTable', { read: MatSort, static: false }) domainsTableMatSort: MatSort;

  //vars
  domains: BannedDomainDto[] = [];
  searchText: string = '';
  addDomainText: string = '';
  domainsDataSource: MatTableDataSource<BannedDomainDto> = new MatTableDataSource();
  domainsTableColumns: string[] = ['name', 'created', 'delete'];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addDomainViewState = ViewState.content;

  ngOnInit() {
    // this.getBannedDomains();
  }

  ngAfterViewInit(): void {
    this.getBannedDomains();
  }

  //api
  getBannedDomains(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/bans/domains`)
      .subscribe((result: BannedDomainDto[]) => {
        this.domains = result;
        this.updateSorting(this.filteredDomains());
        this.viewState = ViewState.content;
      });
  }

  addBannedDomain(): void {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/bans/domains`, new AddBannedDomainDto(this.addDomainText))
      .subscribe((result: BannedDomainDto) => {
        this.domains.push(result);
        this.updateSorting(this.filteredDomains());
        this.addDomainText = '';
        this.viewState = ViewState.content;
      }, (error: Error) => { 

      });
  }

  deleteBannedDomain(domain: BannedDomainDto): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/bans/domains/${domain.id}`)
      .subscribe(() => {
        this.domains = this.domains.filter(d => d.id != domain.id);
        this.updateSorting(this.filteredDomains());
        this.viewState = ViewState.content;
      }, (error: Error) => { 

      });
  }

  seachTextDidChange(text: string) {
    this.updateSorting(this.filteredDomains());
  }

  filteredDomains() : BannedDomainDto[] {
    return this.domains.filter(r =>
      r.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  updateSorting(domains: BannedDomainDto[]) {
    this.domainsDataSource = new MatTableDataSource(domains);
    this.domainsDataSource.sort = this.domainsTableMatSort;
    this.domainsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
      return item[property];
   };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

}
