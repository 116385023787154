<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="Job Board Sources" fallbackIcon="play_circle_filled"
    [breadcrumbs]="breadcrumbs"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
      <!-- Search -->
      <div class="flex-auto">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [(ngModel)]="searchText" [autocomplete]="'off'" [placeholder]="'Filter job sources'">
        </mat-form-field>
      </div>

      <button class="ml-4" mat-flat-button [color]="'primary'" *ngIf="permissionsService.canAdmin()"
        (click)="didClickAddSource()">
        <mat-icon [svgIcon]="'mat_outline:add'"></mat-icon>
        <span class="ml-2 mr-1">Add Source</span>
      </button>
    </div>


    <!--list-->
    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- no content -->
    <div *ngIf="viewState == viewStates.content && filteredJobSources().length === 0" class="mt-8">
      <app-generic-no-content title="No Job Sources Found"
        subtitle="No job board sources were found please refresh or update your search criteria."
        imageUrl="https://img.icons8.com/color/256/000000/permanent-job.png"
        class="ml-auto mr-auto mt-5"></app-generic-no-content>
    </div>

    <!-- content -->
    <div [hidden]="viewState != viewStates.content || filteredJobSources().length === 0" class="w-full mt-8">
      <fuse-card class="w-full p-4 md:p-8 flex flex-col mb-4" *ngFor="let source of jobSources">
        <!--header-->
        <div class="flex items-center justify-between mb-4">
          <div class="text-2xl font-semibold leading-tight flex flex-row">
            <img *ngIf="source.jobPostingType.imageUrl" class="w-6 h-6 inline" src="{{source.jobPostingType.imageUrl}}" />
            <div class="ml-2">{{source.jobPostingType.name}} Source</div>
          </div>
          <div *ngIf="!(selectedViewState == viewStates.loading && selectedSource.id == source.id)" class="-mr-3">
            <!--edit-->
            <button *ngIf="source.id != ''" class="ml-2 mr-2" mat-stroked-button [color]="'warn'"
              [swal]="{ title: 'Delete Source', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
              (confirm)="deleteSource(source)">
              <mat-icon [svgIcon]="'delete'"></mat-icon>
            </button>
            <button *ngIf="source.id != ''" mat-flat-button [color]="'primary'" (click)="editSource(source)"
              [disabled]="!permissionsService.canManage() || !isValidSource(source)">
              <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
              <span class="ml-2 mr-1">Save</span>
            </button>

            <!--add-->
            <button *ngIf="source.id == ''" class="ml-2 mr-2" mat-stroked-button [color]="'warn'"
              (click)="cancelAddSource()">
              <mat-icon [svgIcon]="'delete'"></mat-icon>
            </button>
            <button *ngIf="source.id == ''" mat-flat-button [color]="'primary'" (click)="addSource(source)"
              [disabled]="!permissionsService.canManage() || !isValidSource(source)">
              <mat-icon [svgIcon]="'mat_outline:add'"></mat-icon>
              <span class="ml-2 mr-1">Add</span>
            </button>
          </div>
        </div>


        <!--loading-->
        <div *ngIf="selectedViewState == viewStates.loading && selectedSource.id == source.id">
          <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
        </div>


        <div *ngIf="!(selectedViewState == viewStates.loading && selectedSource.id == source.id)">
          <div>
            <mat-form-field class="w-full">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="source.name" [autocomplete]="'off'" placeholder="Feed Name">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </mat-form-field>
          </div>
          <div class="flex flex-row">
            <mat-form-field class="mr-2" appearance="fill" style="min-width: 200px;">
              <mat-label>Feed Type</mat-label>
              <mat-select [(value)]="source.jobPostingType.id">
                <mat-option *ngFor="let type of feedTypes" [value]="type.id">{{type.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="w-full">
              <mat-label>Feed URL</mat-label>
              <input matInput [(ngModel)]="source.feedUrl" [autocomplete]="'off'" placeholder="Feed URL">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'code'"></mat-icon>
            </mat-form-field>
          </div>
        </div>


      </fuse-card>
    </div>


  </div>

</div>