<div class="mb-4 text-center">
  <div class="text-2xl"><b>Email Integration</b></div>
  <div>
    <small>In addition to any ATS integrations configured above, by checking this box we will also email all
      applications to you. This means that applications will be sent twice (once to Tenstreet/EBE and again to
      email).</small>
  </div>
</div>

<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<!-- no content -->
<div *ngIf="viewState == viewStates.content && groups.length === 0" class="mt-8 text-center">
  <app-generic-no-content title="No Email Groups Found"
    subtitle="No email groups were found please refresh or add a group by clicking the button below"
    imageUrl="https://img.icons8.com/color/256/000000/message-group.png" class="ml-auto mr-auto mt-5">
  </app-generic-no-content>
  <button mat-flat-button color="primary" class="mt-3" (click)="addGroup()">
    <mat-icon class="icon-size-5" [svgIcon]="'add'"></mat-icon>
    Add Email Group
  </button>
</div>

<!--content-->
<div *ngIf="viewState == viewStates.content && groups.length > 0" class="grid grid-cols-12 gap-3">

  <!-- add group -->
  <button mat-flat-button color="primary" class="w-full col-span-12" (click)="addGroup()">
    <mat-icon class="icon-size-5" [svgIcon]="'add'"></mat-icon>
    Add Email Group
  </button>

  <!-- groups -->
  <div class="col-span-12" *ngFor="let group of groups">
    <app-matched-leads-product-email-group [company]="company" [product]="product" [group]="group" (didDeleteGroup)="didDeleteGroup($event)">
    </app-matched-leads-product-email-group>
  </div>
</div>