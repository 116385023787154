import { AnyLayer, AnySourceData } from "mapbox-gl";
import * as turf from '@turf/turf'
import { HiringCityDto, HiringZipDto, MatchedLeadsProductHiringAreaDto } from "app/models/dtos";

export class TdusaMapElements {
    //tags
    static readonly statesBordersLayerTag = 'state-borders';
    static readonly statesFillsLayerTag = 'state-fills';
    static readonly statesFillsHoverLayerTag = 'state-fills-hover';
    static readonly statesFillsActiveLayerTag = 'state-fills-active';
    static readonly zipsLayerTag = 'zips';
    static readonly citiesLayerTag = 'cities';
    static readonly areasLayerTag = 'areas';

    static readonly statesSourceTag = 'states';
    static readonly zipsSourceTag = 'zips';
    static readonly citiesSourceTag = 'cities';
    static readonly areasSourceTag = 'areas';

    //layers
    static zipsLayer(): AnyLayer {
        return {
            id: this.zipsLayerTag,
            type: 'fill',
            source: this.zipsSourceTag,
            layout: {},
            paint: {
                'fill-opacity': 0.5,
                'fill-color': '#ff8c00',
                'fill-outline-color': '#ff8c00',
            },
        }
    }

    static reportZipsLayer(): AnyLayer {
        return {
            id: this.zipsLayerTag,
            type: 'fill',
            source: this.zipsSourceTag,
            layout: {},
            paint: {
                'fill-opacity': 1,
                'fill-color': '#ff8c00',
                'fill-outline-color': '#ff8c00',
            },
        }
    }

    static stateBordersLayer(): AnyLayer {
        return {
            id: this.statesBordersLayerTag,
            type: 'line',
            source: this.statesSourceTag,
            layout: {},
            filter: ['all', ['!=', 'postal', 'AK'], ['!=', 'postal', 'HI']],
            paint: {
                // jscs:disable
                'line-color': '#0C5899',
                'line-width': 2,
                // jscs:enable
            },
        }
    }

    static citiesLayer(): AnyLayer {
        return {
            id: this.citiesLayerTag,
            type: 'fill',
            source: this.citiesSourceTag,
            layout: {},
            paint: {
                'fill-opacity': 0.5,
                'fill-color': '#ff007b',
                'fill-outline-color': '#ff007b',
            },
        }
    }

    static areasLayer(): AnyLayer {
        return {
            id: this.areasLayerTag,
            type: 'fill',
            source: this.areasSourceTag,
            layout: {},
            paint: {
                'fill-opacity': 0.5,
                'fill-color': '#8000ff',
                'fill-outline-color': '#8000ff',
            },
        }
    }

    static stateFillsLayer(): AnyLayer {
        return {
            id: this.statesFillsLayerTag,
            type: 'fill',
            source: this.statesSourceTag,
            layout: {},
            filter: ['all', ['!=', 'postal', 'AK'], ['!=', 'postal', 'HI']],
            paint: {
                // jscs:disable
                'fill-color': '#0C5899',
                'fill-opacity': 0.3,
                // jscs:enable
            },
        }
    }

    static stateFillsActiveLayer(): AnyLayer {
        return {
            id: this.statesFillsActiveLayerTag,
            type: 'fill',
            source: this.statesSourceTag,
            layout: {},
            filter: ['all', ['!=', 'postal', 'AK'], ['!=', 'postal', 'HI']],
            paint: {
                // jscs:disable
                'fill-color': '#0C5899',
                'fill-opacity': 0.7,
                // jscs:enable
            },
            // filter: stateFilter(opts.states),
        }
    }

    static stateFillsHoverLayer(): AnyLayer {
        return {
            id: this.statesFillsHoverLayerTag,
            type: 'line',
            source: this.statesSourceTag,
            layout: {},
            paint: {
                // jscs:disable
                'line-color': '#02A161',
                'line-width': 3,
                // jscs:enable
            },
            filter: ['any'],
        }
    }

    //#region markers
    static circleMarkerForHiringZip(hiringZip: HiringZipDto, radius: number): any {
        return TdusaMapElements.circleMarker([hiringZip.lng, hiringZip.lat], radius);
    }

    static circleMarkerForHiringCity(hiringCity: HiringCityDto, radius: number): any {
        return TdusaMapElements.circleMarker([hiringCity.lng, hiringCity.lat], radius);
    }

    static circleMarkerForHiringArea(hiringArea: MatchedLeadsProductHiringAreaDto): any {
        return TdusaMapElements.circleMarker([hiringArea.lng, hiringArea.lat], hiringArea.radius);
    }

    static circleMarkerForLatLng(lat: number, lng: number, radius: number): any {
        return TdusaMapElements.circleMarker([lng, lat], radius);
    }

    static circleMarker(point: number[], radius: number) {
        return turf.circle(point, radius, {
            steps: 50,
            units: 'miles'
        });
    }

    //sources
    static statesMapSource(): AnySourceData {
        return {
            type: 'geojson',
            data: '/assets/geojson/us-states.json'
        };
    }
}