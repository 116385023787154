
import { AppAnalyticPair } from "./appAnalytics2.dto";

export class AppAnalytics3Dto {
    total: number = 0;
    direct: number = 0;
    matched: number = 0;

    percentMaxSendsUsed: number;
    cdlHolders: number;
    states: AppAnalyticPair[] = [];

    driverTypes: AppAnalyticPair[] = [];
    cdlClasses: AppAnalyticPair[] = [];
    experienceTypes: AppAnalyticPair[] = [];
    freightTypes: AppAnalyticPair[] = [];

    violations: number = 0;
    accidents: number = 0;
    military: number = 0;
    interestInOoLp: number = 0;
    interestInTeams: number = 0;
    teamDriver: number = 0;

    utmSources: AppAnalyticPair[] = [];
    utmMediums: AppAnalyticPair[] = [];
    utmCampaigns: AppAnalyticPair[] = [];
}