<fuse-card class="flex flex-col w-full filter-article">
  <app-external-jobs-map [job]="job" mapHeight="400px" [mapZoom]="3" [scrollZoom]="false" [dragPan]="true">
  </app-external-jobs-map>
  <div class="m-8 mb-4 text-center">
    <div class="text-4xl font-bold leading-tight">{{job.title}}</div>
    <div class="text-secondary text-md mt-1">{{job.positions.length}} locations</div>

    <hr />
    <!-- constraints -->
    <div class="grid grid-cols-12 gap-3">
      <div class="col-span-12">
        <div class="text-xl font-bold">Matching Criteria</div>
      </div>

      <!--driver types-->
      <div class="col-span-12 md:col-span-3">
        <div class="font-bold text-primary">Driver Types</div>
        <div>
          <span>{{lookupList(job.driverTypes)}}</span>
          <span *ngIf="job.teamDriver" class="ml-2">(team)</span>
          <span *ngIf="!job.teamDriver" class="ml-2">(solo)</span>
        </div>
      </div>

      <!--driver types-->
      <div class="col-span-12 md:col-span-3">
        <div class="font-bold text-primary">CDL Classes</div>
        <div>
          <span>{{lookupList(job.cdlClasses)}}</span>
        </div>
      </div>

      <!--freight types-->
      <div class="col-span-12 md:col-span-3">
        <div class="font-bold text-primary">Freight Types</div>
        <div>{{lookupList(job.freightTypes)}}</div>
      </div>

      <!--endorsements-->
      <div class="col-span-12 md:col-span-3">
        <div class="font-bold text-primary">Endorsements</div>
        <div *ngIf="job.experienceType != null">{{lookupList(job.endorsements)}}</div>
      </div>

      <!--experience-->
      <div class="col-span-12 md:col-span-3">
        <div class="font-bold text-primary">Experience</div>
        <div *ngIf="!job.customExperienceLevels">
          <div *ngIf="job.experienceType != null">{{job.experienceType.name}}</div>
          <div *ngIf="job.experienceType == null">N/A</div>
        </div>
        <div *ngIf="job.customExperienceLevels">
          <div>Custom Experience Levels</div>
          <div>{{lookupList(job.customExperienceTypes)}}</div>
        </div>
      </div>

      <!--violations-->
      <div class="col-span-12 md:col-span-3">
        <div class="font-bold text-primary"># of Violations</div>
        <div *ngIf="job.experienceType != null">{{job.violations}}</div>
      </div>

      <!--accidents-->
      <div class="col-span-12 md:col-span-3">
        <div class="font-bold text-primary"># of Accidents</div>
        <div *ngIf="job.experienceType != null">{{job.accidents}}</div>
      </div>

      <!--solo interested-->
      <div class="col-span-12 md:col-span-3">
        <div class="font-bold text-primary">Solo Interested in Team</div>
        <div *ngIf="job.experienceType != null">{{job.interestInTeamDriving}}</div>
      </div>
    </div>
    <hr />

    <div class="mt-4 text-sm">
      <small>
        <!-- description -->
        <app-html-snippet [html]="job.descriptionHtml"></app-html-snippet>
      </small>
    </div>

    <hr />

    <!-- states list -->
    <div>
      <div class="font-bold text-primary">Hiring States ({{statePositions().length}})</div>
      <div>{{statesList()}}</div>
    </div>

    <!-- cities list -->
    <div class="mt-4">
      <div class="font-bold text-primary">Hiring Cities ({{cityPositions().length}})</div>
      <div class="grid grid-cols-12">
        <div class="col-span-6 md:col-span-4 lg:col-span-2" *ngFor="let position of cityPositions()">[{{position.hiringCity.name}}]</div>
      </div>
    </div>

    <!-- zips list -->
    <div class="mt-4">
      <div class="font-bold text-primary">Hiring Zips ({{zipPositions().length}})</div>
      <div class="grid grid-cols-12">
        <div 
        class="col-span-6 md:col-span-4 lg:col-span-2" 
        matTooltip="{{position.hiringZip.city}}, {{position.hiringState.abbreviation}}"
        *ngFor="let position of zipPositions()">[{{position.hiringZip.name}}]</div>
      </div>
    </div>

    <div class="mt-3 -mx-3">
      <!-- <a
              class="px-3"
              mat-button
              [color]="'primary'"
              [routerLink]="['./']">
              Read More
          </a> -->
    </div>
  </div>
</fuse-card>