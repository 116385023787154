<div class="mb-4 text-center">
  <div class="text-2xl"><b>Hiring Area Requirements</b></div>
  <div>
    <small>We will filter out all leads that do not meet your requirements. Please indicate your hiring criteria
      below:</small>
  </div>
</div>

<div class="w-full grid grid-cols-12 gap-3 mt-4">
  <div class="col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-7">
    <div style="border-radius: 16px; overflow: hidden;">
      <mgl-map [style]="'mapbox://styles/mapbox/streets-v11'" [style.width]="'100%'" [style.height]="'800px'"
        [style.borderRadius]="'16px'" [zoom]="[3]" [center]="[-95.7, 37]" (mapCreate)="map = $event"
        (mapLoad)="onMapLoad($event)"
        (mapClick)="(permissionsService.canManage() || proposalId) ? onMapClick($event) : null"
        (mouseMove)="(permissionsService.canManage() || proposalId) ? onMouseMove($event) : null">
        <mgl-control mglNavigation></mgl-control>
        <mgl-control mglFullscreen></mgl-control>
      </mgl-map>
    </div>

  </div>
  <div class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-5">
    <fuse-card class="flex flex-col w-full mb-4 p-0 filter-article">
      <!--header-->
      <div class="flex items-center justify-between m-4">
        <div class="text-2xl font-semibold leading-tight">Hiring Areas</div>
        <div class="-mr-3">
          <mat-icon *ngIf="selectedTab == 'States'" class="states-color" [svgIcon]="'flag'"
            style="vertical-align: top;"></mat-icon>
          <mat-icon *ngIf="selectedTab == 'Zip Codes'" class="zips-color" [svgIcon]="'circle'"
            style="vertical-align: top;"></mat-icon>
          <mat-icon *ngIf="selectedTab == 'Cities'" class="cities-color" [svgIcon]="'circle'"
            style="vertical-align: top;"></mat-icon>
          <mat-icon *ngIf="selectedTab == 'Areas'" class="pins-color" [svgIcon]="'circle'" style="vertical-align: top;">
          </mat-icon>
          <span class="ml-2">{{selectedTab}}</span>
          <!-- <button mat-flat-button [color]="'primary'" (click)="editAreas()">
            <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span class="ml-2 mr-1">Save</span>
          </button> -->
        </div>
      </div>

      <!--tabs-->
      <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabClick($event)">
        <!--States-->
        <mat-tab label="States">
          <div class="flex items-center">
            <!-- Search -->
            <div class="flex-auto">
              <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                <input matInput [(ngModel)]="stateSearchText" [autocomplete]="'off'" [placeholder]="'Filter states'">
              </mat-form-field>
            </div>
            <!-- Select/Deselect all button -->
            <button class="ml-2" mat-flat-button [color]="'primary'" (click)="didClickSelectAll()"
              [disabled]="(!permissionsService.canManage() && !proposalId)">
              <span *ngIf="!allStatesSelected()" class="ml-2 mr-1">Select All</span>
              <span *ngIf="allStatesSelected()" class="ml-2 mr-1">Deselect All</span>
            </button>
          </div>


          <!--loading-->
          <div *ngIf="statesViewState == viewStates.loading">
            <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
          </div>

          <!--list-->
          <div *ngIf="statesViewState == viewStates.content" class="flex flex-col mt-4" style="overflow-y: scroll;
          height: 572px;">
            <div class="grid grid-cols-12" *ngFor="let state of filteredStates()">
              <div class="col-span-8">{{state.name}}</div>
              <div class="col-span-4 text-right pr-2">
                <ng-switchery onLabel="" [(ngModel)]="state.checked" name="" (ngModelChange)="didCheckState(state)"
                  [disabled]="(!permissionsService.canManage() && !proposalId)"
                  [matTooltip]="'Toggle this {{state}}} on/off for matching'">
                </ng-switchery>
              </div>
              <div class="col-span-12">
                <hr class="mt-2 mb-2" />
              </div>
            </div>
          </div>
        </mat-tab>
        <!--Zip Codes-->
        <mat-tab label="Zip Codes">
          <div class="flex items-center">
            <!-- states -->
            <mat-form-field appearance="fill" class="mr-2" style="margin-bottom: -1.25em">
              <mat-select [(value)]="zipHiringState" (valueChange)="zipSearchTextDidChange(zipSearchText);">
                <mat-option *ngFor="let state of hiringStates" [value]="state.id">{{state.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Search -->
            <div class="flex-auto">
              <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                <input matInput [(ngModel)]="zipSearchText" (ngModelChange)="zipSearchTextDidChange(zipSearchText)"
                  [autocomplete]="'off'" [placeholder]="'Filter zips'">
              </mat-form-field>
            </div>
            <!-- Add button -->
            <button class="ml-2" mat-flat-button [color]="'primary'" (click)="didClickAddZips()"
              [disabled]="(!permissionsService.canManage() && !proposalId)">
              <mat-icon [svgIcon]="'add'"></mat-icon>
            </button>
            <!-- Delete button -->
            <button class="ml-2" mat-flat-button [color]="'warn'" 
            [swal]="{ title: 'Delete Zip Codes?', text: 'This will delete all the zip codes in your filtered list. Are you sure you would like to continue?', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
                          (confirm)="deleteZipCodes()"
              [disabled]="(!permissionsService.canManage() && !proposalId)">
              <mat-icon [svgIcon]="'delete'"></mat-icon>
            </button>
          </div>

          <!-- add -->
          <div *ngIf="addZipsViewState == viewStates.content" class="border rounded p-4 mt-4 border-primary-500">
            <div class="grid grid-cols-2">
              <b class="text-primary col-span-1">Add Zips</b>
              <button class="col-span-1 text-right text-warn" (click)="addZipsViewState = viewStates.initial">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <div><small>Add zip codes below, one per line</small></div>
            <!-- Radius -->
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50 mt-2">
              <span matPrefix>Radius (mi)</span>
              <input type="number" matInput [(ngModel)]="rawZipsRadiusToAdd" [autocomplete]="'off'"
                [placeholder]="'50'" />
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'radio_button_unchecked'"></mat-icon>
            </mat-form-field>
            <!-- Add Text Area -->
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50 mt-2">
              <textarea matInput [(ngModel)]="rawZipsToAdd" [autocomplete]="'off'"
                [placeholder]="'One zip code per line'"></textarea>
            </mat-form-field>

            <!-- Add button -->
            <button class="mt-2 w-full" mat-flat-button [color]="'primary'" (click)="didClickAddRawZips(rawZipsToAdd)"
              [disabled]="rawZipsToAdd == ''">
              <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
              <span class="ml-2 mr-1">Add</span>
            </button>
          </div>

          <!--loading-->
          <div *ngIf="zipsViewState == viewStates.loading">
            <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
          </div>

          <!-- no content -->
          <div *ngIf="zipsViewState == viewStates.content && filteredZips().length === 0" class="mt-8">
            <app-generic-no-content title="No Zip Codes Found"
              subtitle="No zip codes were found please refresh or update your search criteria."
              imageUrl="https://img.icons8.com/color-glass/256/zip-code.png" class="ml-auto mr-auto mt-5">
            </app-generic-no-content>
          </div>

          <!--list-->
          <div class="flex flex-col mt-4" style="height: 572px;">
            <cdk-virtual-scroll-viewport tvsItemSize class="wrapper" #zipsScrollViewport>
              <table class="w-full bg-transparent" mat-table [dataSource]="zipsDataSource" [trackBy]="trackByFn">
                <ng-container matColumnDef="main">
                  <td mat-cell *matCellDef="let position" class="px-0">
                    <div class="grid grid-cols-12 pt-2 pb-2">
                      <div class="col-span-8 text-sm">
                        <div style="display:inline-block;">
                          <!-- Radius -->
                          <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded"
                            style="width: 90px; vertical-align: bottom;">
                            <input type="number" matInput [(ngModel)]="position.radius"
                              (ngModelChange)="editZip(position)" [autocomplete]="'off'" [placeholder]="'50'" />
                          </mat-form-field>
                        </div>
                        <div class="pt-1 pl-3" style="display:inline-block;">
                          <div class="font-bold">{{position.hiringZip.name}} ({{position.radius}} mi)</div>
                          <div>{{position.hiringZip.city}}, {{position.hiringZip.county}}</div>
                        </div>
                      </div>
                      <div class="col-span-4 text-right pr-2">
                        <button class="ml-2" mat-stroked-button [color]="'warn'"
                          [swal]="{ title: 'Delete Zip Code?', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
                          (confirm)="didClickDeleteZip(position)">
                          Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr class="order-row h-16" mat-row *matRowDef="let row; columns: columns();"></tr>
              </table>
            </cdk-virtual-scroll-viewport>
          </div>
        </mat-tab>
        <mat-tab label="Cities">
          <div class="flex items-center">
            <!-- states -->
            <mat-form-field appearance="fill" class="mr-2" style="margin-bottom: -1.25em">
              <mat-select [(value)]="cityHiringState" (valueChange)="citySearchTextDidChange(citySearchText);">
                <mat-option *ngFor="let state of hiringStates" [value]="state.id">{{state.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Search -->
            <div class="flex-auto">
              <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                <input matInput [(ngModel)]="citySearchText" (ngModelChange)="citySearchTextDidChange(citySearchText)"
                  [autocomplete]="'off'" [placeholder]="'Filter cities'">
              </mat-form-field>
            </div>
            <!-- Add button -->
            <button class="ml-2" mat-flat-button [color]="'primary'" (click)="didClickAddCities()"
              [disabled]="(!permissionsService.canManage() && !proposalId)">
              <mat-icon [svgIcon]="'add'"></mat-icon>
            </button>
            <!-- Delete button -->
            <button class="ml-2" mat-flat-button [color]="'warn'" 
            [swal]="{ title: 'Delete Cities?', text: 'This will delete all the cities in your filtered list. Are you sure you would like to continue?', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
                          (confirm)="deleteCities()"
              [disabled]="(!permissionsService.canManage() && !proposalId)">
              <mat-icon [svgIcon]="'delete'"></mat-icon>
            </button>
          </div>

          <!-- add -->
          <div *ngIf="addCitiesViewState == viewStates.content" class="border rounded p-4 mt-4 border-primary-500">
            <div class="grid grid-cols-2">
              <b class="text-primary col-span-1">Add Cities</b>
              <button class="col-span-1 text-right text-warn" (click)="addCitiesViewState = viewStates.initial">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <div><small>Add cites below, one per line (e.g. Denver, CO)</small></div>
            <!-- Radius -->
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50 mt-2">
              <span matPrefix>Radius (mi)</span>
              <input type="number" matInput [(ngModel)]="rawCitiesRadiusToAdd" [autocomplete]="'off'"
                [placeholder]="'50'" />
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'radio_button_unchecked'"></mat-icon>
            </mat-form-field>
            <!-- Add Text Area -->
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50 mt-2">
              <textarea matInput [(ngModel)]="rawCitiesToAdd" [autocomplete]="'off'"
                [placeholder]="'One city per line'"></textarea>
            </mat-form-field>

            <!-- Add button -->
            <button class="mt-2 w-full" mat-flat-button [color]="'primary'"
              (click)="didClickAddRawCities(rawCitiesToAdd)" [disabled]="rawCitiesToAdd == ''">
              <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
              <span class="ml-2 mr-1">Add</span>
            </button>
          </div>

          <!--loading-->
          <div *ngIf="citiesViewState == viewStates.loading">
            <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
          </div>

          <!-- no content -->
          <div *ngIf="citiesViewState == viewStates.content && filteredCities().length === 0" class="mt-8">
            <app-generic-no-content title="No Cities Found"
              subtitle="No cities were found please refresh or update your search criteria."
              imageUrl="https://img.icons8.com/color/256/000000/city-buildings.png" class="ml-auto mr-auto mt-5">
            </app-generic-no-content>
          </div>

          <!--list-->
          <div class="flex flex-col mt-4" style="height: 572px;">
            <cdk-virtual-scroll-viewport tvsItemSize class="wrapper" #citiesScrollViewport>
              <table class="w-full bg-transparent" mat-table [dataSource]="citiesDataSource" [trackBy]="trackByFn">


                <ng-container matColumnDef="main">
                  <td mat-cell *matCellDef="let position" class="px-0">
                    <div class="grid grid-cols-12 pt-2 pb-2">
                      <div class="col-span-8 text-sm">
                        <div style="display:inline-block;">
                          <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded"
                            style="width: 90px; vertical-align: bottom;">
                            <input type="number" matInput [(ngModel)]="position.radius"
                              (ngModelChange)="editCity(position)" [autocomplete]="'off'" [placeholder]="'50'" />
                          </mat-form-field>
                        </div>
                        <div class="pt-1 pl-3" style="display:inline-block;">
                          <div class="font-bold">{{position.hiringCity.name}} ({{position.radius}} mi)</div>
                          <div>{{position.hiringCity.state.name}}</div>
                        </div>

                      </div>
                      <div class="col-span-4 text-right pr-2">
                        <button class="ml-2" mat-stroked-button [color]="'warn'"
                          [swal]="{ title: 'Delete City?', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
                          (confirm)="didClickDeleteCity(position)">
                          Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr class="order-row h-16" mat-row *matRowDef="let row; columns: columns();"></tr>
              </table>
            </cdk-virtual-scroll-viewport>
          </div>
        </mat-tab>
        <mat-tab label="Areas">
          <div class="flex items-center">
            <!-- Search -->
            <div class="flex-auto">
              <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                <input matInput [(ngModel)]="areasSearchText" [autocomplete]="'off'" [placeholder]="'Filter areas'">
              </mat-form-field>
            </div>
            <!-- Add button -->
            <button class="ml-2" mat-flat-button [color]="'primary'" (click)="didClickAddArea()"
              [disabled]="(!permissionsService.canManage() && !proposalId)">
              <mat-icon [svgIcon]="'add'"></mat-icon>
            </button>
          </div>

          <!-- add -->
          <div *ngIf="addAreasViewState == viewStates.content" class="border rounded p-4 mt-4 border-primary-500">
            <div class="grid grid-cols-2">
              <b class="text-primary col-span-1">Add Area</b>
              <button class="col-span-1 text-right text-warn" (click)="addAreasViewState = viewStates.initial">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <!-- <div><small>Add cites below, one per line (e.g. Denver, CO)</small></div> -->
            <!-- Radius -->
            <!-- <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50 mt-2">
              <span matPrefix>Radius (mi)</span>
              <input type="number" matInput [(ngModel)]="rawCitiesRadiusToAdd" [autocomplete]="'off'"
                [placeholder]="'50'" />
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'radio_button_unchecked'"></mat-icon>
            </mat-form-field> -->
            <!-- Add Text Area -->
            <!-- <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50 mt-2">
              <textarea matInput [(ngModel)]="rawCitiesToAdd" [autocomplete]="'off'"
                [placeholder]="'One zip code per line'"></textarea>
            </mat-form-field> -->

            <!-- Add button -->
            <!-- <button class="mt-2 w-full" mat-flat-button [color]="'primary'"
              (click)="didClickAddRawCities(rawCitiesToAdd)" [disabled]="rawCitiesToAdd == ''">
              <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
              <span class="ml-2 mr-1">Add</span>
            </button> -->
          </div>

          <!--loading-->
          <div *ngIf="areasViewState == viewStates.loading">
            <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
          </div>

          <!--list-->
          <div *ngIf="areasViewState == viewStates.content" class="flex flex-col mt-4" style="overflow-y: scroll;
          height: 572px;">
            <div class="grid grid-cols-12" *ngFor="let hiringArea of filteredAreas()">
              <div class="col-span-8 text-sm">
                <div style="display:inline-block;">
                  <!-- Radius -->
                  <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded"
                    style="width: 90px; vertical-align: bottom;">
                    <input type="number" matInput [(ngModel)]="hiringArea.radius" (ngModelChange)="editArea(hiringArea)"
                      [autocomplete]="'off'" [placeholder]="'50'" />
                  </mat-form-field>
                </div>
                <div class="pt-1 pl-3" style="display:inline-block;">
                  <div class="font-bold">{{hiringArea.name}} ({{hiringArea.radius}} mi)</div>
                  <div>({{hiringArea.lat.toFixed(4)}}, {{hiringArea.lng.toFixed(4)}})</div>
                </div>

              </div>
              <div class="col-span-4 text-right pr-2">
                <button class="ml-2" mat-stroked-button [color]="'warn'"
                  [swal]="{ title: 'Delete Area?', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
                  (confirm)="didClickDeleteArea(hiringArea)">
                  Delete
                </button>
              </div>
              <div class="col-span-12">
                <hr class="mt-2 mb-2" />
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </fuse-card>
  </div>
</div>