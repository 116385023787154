<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [terminal]="contact?.name ?? ''" [breadcrumbs]="breadcrumbs" [imageUrl]="contact?.imageUrl"
    [profile]="true" fallbackIcon="contacts"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- content -->
    <div *ngIf="viewState == viewStates.content" class="grid grid-cols-12">
      <div class="col-span-12 md:col-span-6 lg:col-span-4 mb-4 p-2">
        <!--basic info-->
        <app-contact-form [contact]="contact"></app-contact-form>
      </div>

      <div class="col-span-12 md:col-span-6 lg:col-span-8 p-2">
        <!--tabs-->
        <mat-tab-group animationDuration="0ms">

          <!--Companies-->
          <mat-tab label="Companies" *ngIf="permissionsService.canAdmin()">
            <ng-template matTabContent>
              <app-contact-companies [contact]="contact"></app-contact-companies>
            </ng-template>
          </mat-tab>

          <!--Agencies-->
          <mat-tab label="Agencies" *ngIf="permissionsService.canAdmin()">
            <ng-template matTabContent>
              <app-contact-agencies [contact]="contact"></app-contact-agencies>
            </ng-template>
          </mat-tab>

          <!--Logs-->
          <mat-tab label="Logs" *ngIf="permissionsService.isSuperAdmin()">
            <ng-template matTabContent>
              <app-log-analytics logRoute="v1/contacts/{{contact.id}}/logs"></app-log-analytics>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>