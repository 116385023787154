import { IdentifiableDto } from "./identifiable.dto";

export class AppAnalyticsDto extends IdentifiableDto  {

    //all below are IDs
    s: string; //state
    c: string[]; //cdl class
    d: string; //driver type
    e: string; //experience class
    f: string; //common freight
    if: string[]; //interested freight
    t: boolean; //team driver
    oa: boolean; //own authority
    ca: string; //candidate
    b: boolean; //blocked
    su: boolean; //suspicious
    di: boolean; //direct
    v: number; //violations
    a: number; //accidents
    mi: boolean; //military
    io: boolean; //interest owner op lp
    it: boolean; //interest team
    ti: Date; //timestamp
    la: number; //latitude
    lo: number; //longitude

    //except these which are full
    us: string;
    um: string;
    uc: string;

    //misc
    m: number; //matched
    l: number; //sent leads
}