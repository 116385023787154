import { CompanyDto } from "./company.dto";
import { IdentifiableDto } from "./identifiable.dto";
import { ProductSpendPeriodDto } from "./productSpendPeriod.dto";
import { ProductStatusDto } from "./productStatus.dto";

export class MatchedLeadsProductDto extends IdentifiableDto {
    name!: string;
    notes!: string;
    timestampCreated!: Date;
    timestampUpdated!: Date;
    
    targetCplInCents: number;

    //relations
    company: CompanyDto;
    productStatus: ProductStatusDto;
    currentSpendPeriod: ProductSpendPeriodDto;
    futureSpendPeriod: ProductSpendPeriodDto;

    //internal
    targetCpl: number;
    sentLeads: number;
    costPerSentLead: number;
    priorityScore: number;
    allocatedPercentage: number;
    spend: number;
    spent: number;
    remaining: number;
}