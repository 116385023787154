import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CompanyDto, EditMatchedLeadsProductDto, MatchedLeadsProductAtsDto, MatchedLeadsProductDto } from 'app/models/dtos';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-matched-leads-product-integrations',
  templateUrl: './matched-leads-product-integrations.component.html',
  // styleUrls: ['./matched-leads-product-integrations.component.css']
})
export class MatchedLeadsProductIntegrationsComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  //inputs
  @Input() company: CompanyDto;
  @Input() product: MatchedLeadsProductDto;

  //outputs
  @Output() didEditProduct: EventEmitter<MatchedLeadsProductDto> = new EventEmitter();

  //vars
  ats: MatchedLeadsProductAtsDto

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  ngOnInit() {
    this.getAts();
  }

  //api
  getAts() {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/ats`)
      .subscribe((result: MatchedLeadsProductAtsDto) => {
        this.ats = result;
        this.viewState = ViewState.content;
        this.didEditProduct.emit(this.product);
      });
  }

  editAts(): void {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/ats`, this.ats)
      .subscribe((result: MatchedLeadsProductAtsDto) => {
        this.ats = result;
        this.viewState = ViewState.content;
        this.didEditProduct.emit(this.product);
      });
  }
}
