import { BaseLookup } from "../lookups";

export class ApplicationSliceTypes extends BaseLookup {
    propName!: string;

    constructor(id: string, name: string, propName: string) {
        super(id, name);
        this.propName = propName;
    }

    // Values
    static readonly SOURCE = new ApplicationSliceTypes('1', 'UTM Source', 'utmSources');
    static readonly MEDIUM = new ApplicationSliceTypes('2', 'UTM Medium', 'utmMediums');
    static readonly CAMPAIGN = new ApplicationSliceTypes('3', 'UTM Campaign', 'utmCampaigns');
    static readonly STATE = new ApplicationSliceTypes('4', 'State', 'states');
    static readonly DRIVER_TYPE = new ApplicationSliceTypes('5', 'Driver Type', 'driverTypes');
    static readonly CDL_CLASSES = new ApplicationSliceTypes('6', 'CDL Classes', 'cdlClasses');
    static readonly FREIGHT_TYPE = new ApplicationSliceTypes('7', 'Freight Type', 'freightTypes');
    static readonly EXPERIENCE_TYPE = new ApplicationSliceTypes('8', 'Experience Type', 'experienceTypes');
    static readonly OWN_AUTHORITY = new ApplicationSliceTypes('9', 'Has Own Authority', 'oa');
    static readonly TEAM_DRIVER = new ApplicationSliceTypes('910', 'Team Driver', 't');

    static readonly values = [
        ApplicationSliceTypes.SOURCE,
        ApplicationSliceTypes.MEDIUM,
        ApplicationSliceTypes.CAMPAIGN,
        ApplicationSliceTypes.STATE,
        ApplicationSliceTypes.DRIVER_TYPE,
        ApplicationSliceTypes.CDL_CLASSES,
        ApplicationSliceTypes.FREIGHT_TYPE,
        ApplicationSliceTypes.EXPERIENCE_TYPE,
        // ApplicationSliceTypes.OWN_AUTHORITY,
        // ApplicationSliceTypes.TEAM_DRIVER
    ]

    static override fromId(id: string) : ApplicationSliceTypes {
        return this.values.find(v => v.id == id);
    }
}