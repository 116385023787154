<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [breadcrumbs]="breadcrumbs" [terminal]="agency?.name ?? ''" [imageUrl]="agency?.imageUrl"
    fallbackIcon="heroicons_solid:office-building" imgRenderMode="object-contain"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- content -->
    <div *ngIf="viewState == viewStates.content">
      <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabClick($event)">
        <mat-tab label="Dashboard">
          <ng-template matTabContent>
            <app-dashboard-analytics applicationRoute="v1/agencies/{{agency.id}}/matchedLeadsProducts" [permissionsService]="permissionsService">
            </app-dashboard-analytics>
          </ng-template>
        </mat-tab>
        <mat-tab label="Info">
          <ng-template matTabContent>
            <app-agency-info [agency]="agency"></app-agency-info>
          </ng-template>
        </mat-tab>
        <mat-tab label="Contacts">
          <ng-template matTabContent>
            <app-agency-contacts [agency]="agency" *ngIf="agency != null" (didEditAgency)="didEditAgency($event)">
            </app-agency-contacts>
          </ng-template>
        </mat-tab>
        <mat-tab label="Permissions" *ngIf="permissionsService.canAdmin()">
          <ng-template matTabContent>
            <app-manage-permissions [objectId]="agency.id" [group]="permissionGroup"
              permissionsRoute="v1/agencies/{{agency.id}}/permissions"></app-manage-permissions>
          </ng-template>
        </mat-tab>
        <!--Logs-->
        <mat-tab label="Logs" *ngIf="permissionsService.canAdmin()">
          <ng-template matTabContent>
            <app-log-analytics logRoute="v1/agencies/{{agency.id}}/logs"></app-log-analytics>
          </ng-template>
        </mat-tab>
        <!--Notifications-->
        <mat-tab label="Notifications" *ngIf="permissionsService.canAdmin()">
          <ng-template matTabContent>
            <app-log-notifications logRoute="v1/agencies/{{agency.id}}/logs/notifications"></app-log-notifications>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>

  </div>

</div>