<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article" *ngIf="permissionsService.canManage()">
  <!--header-->
  <div class="flex items-center justify-between">
    <div class="text-2xl font-semibold leading-tight">Call Forwarding</div>
    <div class="-mr-3">
      <button mat-flat-button [color]="'primary'" (click)="editJobsInfo()">
        <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
        <span class="ml-2 mr-1">Save</span>
      </button>
    </div>
  </div>

  <!--loading-->
  <div *ngIf="viewState == viewStates.loading">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
  </div>

  <div *ngIf="viewState == viewStates.content" class="grid grid-cols-12 gap-3 mt-4">
    <div *ngIf="twilioViewState == viewStates.loading" class="col-span-12">
      <div>
        <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
      </div>
    </div>
    <!--twilio number-->
    <div *ngIf="twilioViewState == viewStates.content" class="col-span-12 md:col-span-6">
      <mat-form-field class="w-full">
        <mat-label>Twilio Number</mat-label>
        <input matInput [(ngModel)]="jobsInfo.twilioNumber" [autocomplete]="'off'" placeholder="Twilio Number"
          [disabled]="true">
        <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
      </mat-form-field>
      <button *ngIf="jobsInfo.twilioNumber == null" mat-stroked-button color="primary" class="w-full"
        (click)="requestNumber()">Request</button>
      <button *ngIf="jobsInfo.twilioNumber != null" mat-stroked-button color="warn" class="w-full"
        [swal]="{ title: 'Release Number?', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
        (confirm)="releaseNumber()">Release</button>
    </div>

    <div *ngIf="twilioViewState == viewStates.content" class="col-span-12 md:col-span-6">
      <mat-form-field class="w-full">
        <mat-label>Forward Number</mat-label>
        <input matInput [(ngModel)]="jobsInfo.twilioForwardNumber" [autocomplete]="'off'"
          placeholder="Twilio Forward Number">
        <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
      </mat-form-field>
    </div>
  </div>
</fuse-card>



<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article" *ngIf="permissionsService.canManage()">
  <!--header-->
  <div class="flex items-center justify-between">
    <div class="text-2xl font-semibold leading-tight">Jobs & Feeds</div>
    <div class="-mr-3">
      <button mat-flat-button [color]="'primary'" (click)="editJobsInfo()">
        <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
        <span class="ml-2 mr-1">Save</span>
      </button>
    </div>
  </div>

  <!--loading-->
  <div *ngIf="viewState == viewStates.loading">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
  </div>

  <div *ngIf="viewState == viewStates.content" class="grid grid-cols-12 gap-3 mt-4">


    <!--xml feed-->
    <div class="col-span-12 flex flex-row">
      <!-- feed type -->
    <mat-form-field class="mr-2" appearance="fill" style="min-width: 200px;" >
      <mat-label>Feed Type</mat-label>
      <mat-select [(value)]="jobsInfo.externalJobFeedTypeId">
        <mat-option *ngFor="let type of feedTypes" [value]="type.id">{{type.name}}</mat-option>
      </mat-select>
    </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Feed URL</mat-label>
        <input matInput [(ngModel)]="proposedXmlFeed" [autocomplete]="'off'" placeholder="Feed URL">
        <mat-icon class="icon-size-5" matSuffix [svgIcon]="'code'"></mat-icon>
      </mat-form-field>
    </div>

    <div *ngIf="recommendDefineFallbackJob()" class="col-span-12 flex flex-row text-rose-500">
      It is recommended for this feed type that you define a fallback job by clicking the button below.
    </div>

  </div>

  <!--XML feed check-->
  <hr />
  <div *ngIf="viewState == viewStates.content && jobsInfo.xmlFeed != null">
    <!--XML Tools-->
    <div class="flex items-center justify-between">
      <div class="text-lg font-semibold leading-tight">XML Feed Tools</div>
      <div class="-mr-3" *ngIf="jobsInfo.xmlFeed != null && jobsInfo.xmlFeed != ''">
        <button mat-flat-button [color]="'primary'" class="ml-2 mr-2" (click)="showFallbackJob()"
          *ngIf="jobsInfo.externalJobFeedFallbackJob != null"
          matTooltip="Define a fallback job for missing fields in the job feed.">
          <mat-icon [svgIcon]="'mat_outline:business_center'"></mat-icon>
          <span class="ml-2 mr-1">Fallback Job</span>
        </button>
        <a mat-flat-button [color]="'primary'" target="_blank" [href]="jobsInfo.xmlFeed"
          matTooltip="Opens the XML feed url in a new tab for viewing the raw XML.">
          <mat-icon [svgIcon]="'heroicons_outline:external-link'"></mat-icon>
          <span class="ml-2 mr-1">View</span>
        </a>
        <button mat-flat-button [color]="'primary'" class="ml-2" (click)="checkXmlFeed()"
          [disabled]="jobsInfo.xmlFeed == null || jobsInfo.xmlFeed == ''"
          matTooltip="Parses through the XML feed and makes sure all entries are valid. Checking the feed does NOT import and save jobs to the database.">
          <mat-icon [svgIcon]="'checklist'"></mat-icon>
          <span class="ml-2 mr-1">Check</span>
        </button>
        <button mat-flat-button [color]="'primary'" class="ml-2" (click)="importXmlFeed()"
          [disabled]="jobsInfo.xmlFeed == null || jobsInfo.xmlFeed == ''"
          matTooltip="Parses through the XML feed, making sure all entries are valid. Importing the jobs will save them into the databse for population on the jobs website. It will also form the basis of the product\'s matching criteria.">
          <mat-icon [svgIcon]="'download'"></mat-icon>
          <span class="ml-2 mr-1">Import</span>
        </button>
      </div>
    </div>

    <!--no content-->
    <div *ngIf="jobsInfo.xmlFeed == null || jobsInfo.xmlFeed == ''" class="text-center mt-4 text-secondary">
      Please provide and save a link to an XML feed above.
    </div>

    <!-- content -->
    <div *ngIf="jobsInfo.xmlFeed != null && jobsInfo.xmlFeed != ''">

      <!--loading-->
      <div *ngIf="xmlFeedCheckViewState == viewStates.loading">
        <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
      </div>

      <!--xml feed results-->
      <div *ngIf="xmlFeedCheckViewState == viewStates.content" class="mt-4">
        <!--failure-->
        <div *ngIf="!xmlFeedCheckResult.success">
          <app-generic-no-content title="Failure" [subtitle]="xmlFeedCheckResult.explanation"
            imageUrl="https://img.icons8.com/color/256/null/error--v1.png" class="ml-auto mr-auto mt-5">
          </app-generic-no-content>
        </div>

        <!--succes-->
        <div *ngIf="xmlFeedCheckResult.success">
          <app-generic-no-content title="Success" [subtitle]="xmlFeedCheckResult.explanation"
            imageUrl="https://img.icons8.com/color/256/null/checked-radio-button--v1.png" class="ml-auto mr-auto mt-5">
          </app-generic-no-content>

          <div class="text-lg font-bold text-center">Parsed Jobs ({{filteredCheckedJobs().length}})</div>
          <app-generic-no-content *ngIf="filteredCheckedJobs().length == 0" title="No Jobs Found"
            subtitle="No jobs were found in this XML feed"
            imageUrl="https://img.icons8.com/color/256/null/find-matching-job.png" class="ml-auto mr-auto mt-5">
          </app-generic-no-content>

          <div class="grid grid-cols-12 gap-3 mt-4">
            <div class="col-span-12 md:col-span-3" *ngFor="let job of checkedJobs">
              <app-job-card [job]="job" (viewJobHandler)="didClickViewJob($event, job)"></app-job-card>
            </div>

          </div>
        </div>

      </div>
    </div>

    <!--XML Filter-->
    <hr />
    <div class="flex items-center justify-between mt-8">
      <div class="text-lg font-semibold leading-tight">XML Feed Filter</div>
      <div class="-mr-3" *ngIf="jobsInfo.xmlFeed != null && jobsInfo.xmlFeed != ''">
        <ng-switchery onLabel="Enable Feed Filter" [(ngModel)]="jobsInfo.xmlFeedFilter" (ngModelChange)="editJobsInfo()"
          [disabled]="!permissionsService.canManage()" name="Enable Feed Filter"></ng-switchery>
      </div>
    </div>

    <!--xml feed filter content-->
    <div class="grid grid-cols-12 gap-3">
      <div class="col-span-12 text-center text-sm">
        The XML feed filter is useful when you have 1 XML feed for a client, but need to break that feed up into smaller
        groups of jobs (with matching criteria), usually in order to separate out their spend.
        Applying a filter will only allow jobs to pass through that pass the filter criteria you define below.
      </div>

      <div class="col-span-12" *ngIf="jobsInfo.xmlFeed && jobsInfo.xmlFeedExternalJob != null && jobsInfo.xmlFeedFilter">
        <!--non-map criteria-->
        <app-job-criteria-form [job]="jobsInfo.xmlFeedExternalJob"></app-job-criteria-form>

        <!--map criteria-->
        <app-job-criteria-area [job]="jobsInfo.xmlFeedExternalJob"></app-job-criteria-area>
      </div>

      <!--keyword filter-->
      <div class="col-span-12 flex flex-col" *ngIf="jobsInfo.xmlFeed && jobsInfo.xmlFeedExternalJob != null && jobsInfo.xmlFeedFilter">
        <hr class="mt-2 mb-2" />
        <div class="flex flex-row">
          <mat-form-field class="w-full">
            <mat-label>Job Title Keyword Filter</mat-label>
            <input matInput [(ngModel)]="jobsInfo.xmlFeedFilterKeywords" [autocomplete]="'off'"
              placeholder="(e.g. cdl-a)">
          </mat-form-field>
          <button class="mt-6 ml-3" mat-flat-button [color]="'primary'" (click)="editJobsInfo()">
            <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span class="ml-2">Save</span>
          </button>
        </div>
        <div class="text-sm">
          <div>- Inclusion: "term1, term2" will include jobs with titles that include term1 or term2</div>
          <div>- Exclusion: "!term1, !term2" will exclude jobs with titles that include term1 or term2</div>
          <div>- Matches are case insensitive</div>
        </div>
      </div>
    </div>

  </div>
</fuse-card>

<!--Active Jobs-->
<fuse-card id="active-jobs" class="flex flex-col w-full mb-4 p-8 filter-article">
  <!--header-->
  <div class="flex items-center justify-between">
    <div class="text-2xl font-semibold leading-tight">Active Jobs</div>
    <div class="-mr-3">
      <button mat-stroked-button class="ml-2" *ngIf="productIsActive()"
        [disabled]="jobsViewState == viewStates.loading || !permissionsService.canManage()"
        [swal]="{ title: 'Synchronize Jobs?', text:'For products with many jobs, this can take several minutes... Please do not refresh the page while jobs are syncronizing.', confirmButtonText: 'Synchronize', icon: 'info', showCancelButton: true }"
        (confirm)="syncJobs()"
        matTooltip="Synchronizes the jobs below with the jobs site. For products with many jobs, this can take several minutes...">
        <mat-icon svgIcon="refresh"></mat-icon>
        <span class="ml-2 mr-1">Sync Jobs</span>
      </button>
      <button mat-flat-button [color]="'primary'" class="ml-2" (click)="didClickAddJob()"
        [disabled]="(jobsInfo?.xmlFeed != null && jobsInfo?.xmlFeed != '') || !permissionsService.canManage()">
        <mat-icon [svgIcon]="'add'"></mat-icon>
        <span class="ml-2 mr-1">Add Job</span>
      </button>
    </div>
  </div>
  <div class="mb-4 mt-2 text-sm">
    These jobs are actively being utilized for matched leads.
  </div>

  <fuse-alert *ngIf="isXmlMode()" [type]="'info'" [appearance]="'outline'">
    <span fuseAlertTitle>XML Mode Enabled</span>
    XML mode is enabled because an XML source was provided above. All hiring criteria will be sources from the XML feed
    and is NOT editable manually below.
  </fuse-alert>

  <!--loading-->
  <div *ngIf="jobsViewState == viewStates.loading">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
  </div>
  <div *ngIf="jobsViewState == viewStates.content">

    <div class="grid grid-cols-12 gap-3 mt-4">
      <div class="col-span-12">
        <!-- Search -->
        <div class="flex-auto">
          <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
            <input matInput [(ngModel)]="searchText" [autocomplete]="'off'" [placeholder]="'Filter jobs'">
          </mat-form-field>
        </div>
      </div>

      <!-- no content -->
      <div class="col-span-12" *ngIf="viewState == viewStates.content && filteredJobs().length === 0">
        <div  class="mt-8">
          <app-generic-no-content title="No Jobs Found"
            subtitle="No jobs were found please refresh or update your search criteria."
            imageUrl="https://img.icons8.com/color/256/null/find-matching-job.png" class="ml-auto mr-auto mt-5">
          </app-generic-no-content>
        </div>
      </div>

      <div class="col-span-12 md:col-span-3" *ngFor="let job of filteredJobs()"
        [matTooltip]="job.notes"
      >
        <app-job-card [job]="job" [editable]="!isXmlMode() && permissionsService.canManage()"
          (viewJobHandler)="didClickViewJob($event, job)"
          editUrl="/companies/{{this.company.id}}/matchedLeadsProducts/{{this.product.id}}/jobs/{{job.id}}"
          (editJobHandler)="didClickEditJob($event, job)">
        </app-job-card>
      </div>

    </div>
  </div>
</fuse-card>


<!--job details-->
<div id="job-details" *ngIf="selectedJob != null">
  <div class="mb-4 mt-4 text-center">
    <div class="text-2xl"><b>Job Details</b></div>
    <div>
      <small>Below is a summary of all information about this job</small>
    </div>
  </div>

  <!--loading-->
  <div *ngIf="jobDetailsViewState == viewStates.loading">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
  </div>
  <!--content-->
  <div *ngIf="jobDetailsViewState == viewStates.content">
    <app-job-details-card [job]="selectedJob"></app-job-details-card>
  </div>

</div>