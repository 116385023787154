import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  // styleUrls: ['./log.component.css']
})
export class LogComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('System Logs | Admin');
  }
}
