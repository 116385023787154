<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [breadcrumbs]="breadcrumbs()" [terminal]="this.report?.name ?? 'Report'"
    fallbackIcon="heroicons_solid:presentation-chart-bar"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!--print preview-->
    <fuse-card *ngIf="viewState == viewStates.content"
      class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8 mt-4">
      <!--header-->
      <div class="flex items-center justify-between">
        <div class="text-2xl font-semibold leading-tight">Print Preview</div>
        <div class="-mr-3">
          <button mat-flat-button printSectionId="print-section" ngxPrint 
          [printStyle]="{'#lead-heatmap' : {'margin-top': '180px'}}"
          [printTitle]="report.name"
            [useExistingCss]="true" [color]="'primary'">
            <mat-icon [svgIcon]="'print'"></mat-icon>
            <span class="ml-2 mr-1">Print</span>
          </button>
        </div>
      </div>

      <hr />

      <!--print section-->
      <div id="print-section" class="mt-4">
        <!--header-->
        <div class="grid grid-cols-12 gap-3">
          <div class="col-span-6 text-left">
            <!--tdusa-->
            <img class="h-15 rounded object-contain" src="../../../../../../assets/images/logo/company-wordmark.png"
              alt="Card cover image">
          </div>
          <div class="col-span-6">
            <img class="h-15 ml-auto rounded object-contain w-auto"
              *ngIf="report.matchedLeadsProduct.company.imageUrl != null"
              [src]="report.matchedLeadsProduct.company.imageUrl" alt="Card cover image">
          </div>

          <div class="col-span-12 text-center mt-4">
            <div class="text-4xl font-bold">{{report.name}}</div>
            <div>
              <span class="font-bold">for {{report.matchedLeadsProduct.name}}</span>
              <span> | </span>
              <span>{{moment(report.spendPeriod.timestampStart).format('LL')}}</span>
              <span> to </span>
              <span>{{moment(report.spendPeriod.timestampEnd).format('LL')}}</span>
            </div>
            <div></div>
          </div>
        </div>

        <!--content-->
        <div class="grid grid-cols-12 gap-3 mt-8 text-center">
          <!--period results-->
          <div class="col-span-6">
            <div class="text-center text-xl font-bold">Period Results</div>
            <div class="bg-slate-100 rounded w-full p-4 grid grid-cols-12">
              <div class="col-span-6 font-bold text-left">Budget</div>
              <div class="col-span-6 font-bold text-right">{{periodSpend() | currency:'USD'}}</div>
              <div class="col-span-6 text-left pl-2">Spend to Date</div>
              <div class="col-span-6 text-right">{{periodSpend() | currency:'USD'}}</div>

              <div class="col-span-6 font-bold text-left">Leads</div>
              <div class="col-span-6 font-bold text-right">{{leadsCount()}}</div>

              <div class="col-span-6 font-bold text-left">Cost Per Lead</div>
              <div class="col-span-6 font-bold text-right">{{costPerLead() | currency:'USD'}}</div>

              <div class="col-span-12">
                <hr class="mt-2 mb-2" />
              </div>

              <div class="col-span-6 font-bold text-left">Budget Remaining</div>
              <div class="col-span-6 font-bold text-right">{{periodSpend() | currency:'USD'}}</div>

              <div class="col-span-6 font-bold text-left">Estimated Leads Remaining</div>
              <div class="col-span-6 font-bold text-right">{{estimatedLeadsRemaining()}}</div>
            </div>
          </div>

          <!--Need More Leads?-->
          <div class="col-span-6">
            <div class="text-center text-xl font-bold">Need More Leads?</div>
            <div class="bg-slate-100 rounded w-full p-4 grid grid-cols-12 gap-1">
              <div class="col-span-6 font-bold text-left">Budget Add-On</div>
              <div class="col-span-6 font-bold text-right">Leads*</div>

              <div class="col-span-6 text-left pl-2">$400.00 add-on</div>
              <div class="col-span-6 text-right">{{leadCountWithAddOn(400) | number:'1.0-0'}}</div>

              <div class="col-span-6 text-left pl-2">$1,000.00 add-on</div>
              <div class="col-span-6 text-right">{{leadCountWithAddOn(1000) | number:'1.0-0'}}</div>

              <div class="col-span-6 text-left pl-2">$2,000.00 add-on</div>
              <div class="col-span-6 text-right">{{leadCountWithAddOn(2000) | number:'1.0-0'}}</div>

              <div class="col-span-6 text-left pl-2">$4,000.00 add-on</div>
              <div class="col-span-6 text-right">{{leadCountWithAddOn(4000) | number:'1.0-0'}}</div>
            </div>
            <div class="text-center">
              <small>* Budget add-ons projections are based on your current cost per lead.</small>
            </div>
          </div>

          <!--Lead breakdown-->
          <div class="col-span-12 text-2xl font-bold mt-4">Lead Breakdown</div>
          <div class="col-span-6">
            <div class="bg-slate-100 rounded w-full p-4 grid grid-cols-12 gap-1">
              <!--leads section-->
              <div class="col-span-6 font-bold text-left">Leads</div>
              <div class="col-span-6 font-bold text-right">{{leadsCount()}}</div>

              <div class="col-span-6 text-left pl-2">Calls</div>
              <div class="col-span-6 text-right">{{callsCount()}}</div>

              <div class="col-span-6 text-left pl-2">Applications</div>
              <div class="col-span-6 text-right">{{applicationsCount()}}</div>

              <div class="col-span-6 text-left pl-2">Direct</div>
              <div class="col-span-6 text-right">{{analytics.direct}}</div>

              <div class="col-span-6 text-left pl-2">Matched</div>
              <div class="col-span-6 text-right">{{analytics.matched}}</div>

              <!--driver types section-->
              <div class="col-span-12 font-bold text-left mt-3">Driver Types</div>
              <div class="col-span-6 text-left pl-2">Company Drivers</div>
              <div class="col-span-6 text-right">{{countForDriverType(driverTypes.COMPANY_DRIVER)}}</div>

              <div class="col-span-6 text-left pl-2">Owner Operators</div>
              <div class="col-span-6 text-right">{{countForDriverType(driverTypes.OWNER_OPERATOR)}}</div>

              <div class="col-span-6 text-left pl-2">Lease Purchase</div>
              <div class="col-span-6 text-right">{{countForDriverType(driverTypes.LEASE_PURCHASE)}}</div>

              <div class="col-span-6 text-left pl-2">Solo Driver</div>
              <div class="col-span-6 text-right">{{soloDriverCount()}}</div>

              <div class="col-span-6 text-left pl-2">Team Driver</div>
              <div class="col-span-6 text-right">{{teamDriverCount()}}</div>

              <!--freight types section-->
              <div class="col-span-12 font-bold text-left mt-3">Freight Types</div>
              <div class="col-span-6 text-left pl-2">Dry Van</div>
              <div class="col-span-6 text-right">{{countForFreightType(freightTypes.DRY_VAN)}}</div>

              <div class="col-span-6 text-left pl-2">Refrigerated (Reefer)</div>
              <div class="col-span-6 text-right">{{countForFreightType(freightTypes.REFRIGERATED)}}</div>

              <div class="col-span-6 text-left pl-2">Flatbed</div>
              <div class="col-span-6 text-right">{{countForFreightType(freightTypes.FLATBED)}}</div>

              <div class="col-span-6 text-left pl-2">Tanker</div>
              <div class="col-span-6 text-right">{{countForFreightType(freightTypes.TANKER)}}</div>

              <div class="col-span-6 text-left pl-2">Doubles / Triples</div>
              <div class="col-span-6 text-right">{{countForFreightType(freightTypes.DOUBLES_TRIPLES)}}</div>

              <div class="col-span-6 text-left pl-2">Intermodal</div>
              <div class="col-span-6 text-right">{{countForFreightType(freightTypes.INTERMODAL)}}</div>

              <div class="col-span-6 text-left pl-2">Household Goods</div>
              <div class="col-span-6 text-right">{{countForFreightType(freightTypes.HOUSEHOLD_GOODS)}}</div>

              <div class="col-span-6 text-left pl-2">Specialized / Heavy Haul</div>
              <div class="col-span-6 text-right">{{countForFreightType(freightTypes.SPECIALIZED)}}</div>

              <div class="col-span-6 text-left pl-2">Auto Hauler</div>
              <div class="col-span-6 text-right">{{countForFreightType(freightTypes.AUTO_HAULER)}}</div>

              <div class="col-span-6 text-left pl-2">Double Pups</div>
              <div class="col-span-6 text-right">{{countForFreightType(freightTypes.DOUBLE_PUPS)}}</div>

              <div class="col-span-6 text-left pl-2">Dry Bulk & Pneumatic</div>
              <div class="col-span-6 text-right">{{countForFreightType(freightTypes.DRY_BULK_AND_PNEUMATIC)}}</div>

              <div class="col-span-6 text-left pl-2">Other</div>
              <div class="col-span-6 text-right">{{countForFreightType(freightTypes.OTHER)}}</div>
            </div>
          </div>
          <div class="col-span-6">
            <div class="bg-slate-100 rounded w-full p-4 grid grid-cols-12 gap-1">
              <!--driver types section-->
              <div class="col-span-12 font-bold text-left">Experience</div>
              <div class="col-span-6 text-left pl-2">Need CDL Training</div>
              <div class="col-span-6 text-right">{{countForExperienceType(experienceTypes.NEED_CDL_TRAINING)}}</div>

              <div class="col-span-6 text-left pl-2">In CDL School</div>
              <div class="col-span-6 text-right">{{countForExperienceType(experienceTypes.IN_CDL_SCHOOL_NOW)}}</div>

              <div class="col-span-6 text-left pl-2">CDL Grad, No Experience Yet</div>
              <div class="col-span-6 text-right">{{countForExperienceType(experienceTypes.CDL_GRAD_NO_EXPERIENCE)}}
              </div>

              <div class="col-span-6 text-left pl-2">1-5 Months</div>
              <div class="col-span-6 text-right">{{countForExperienceType(experienceTypes.ONE_TO_FIVE_MONTHS)}}</div>

              <div class="col-span-6 text-left pl-2">6-11 Months</div>
              <div class="col-span-6 text-right">{{countForExperienceType(experienceTypes.SIX_TO_ELEVEN_MONTHS)}}</div>

              <div class="col-span-6 text-left pl-2">12-23 Months</div>
              <div class="col-span-6 text-right">
                {{countForExperienceType(experienceTypes.TWELVE_TO_TWENTY_THREE_MONTHS)}}</div>

              <div class="col-span-6 text-left pl-2">2 Years</div>
              <div class="col-span-6 text-right">{{countForExperienceType(experienceTypes.TWO_YEARS)}}</div>

              <div class="col-span-6 text-left pl-2">3 Years</div>
              <div class="col-span-6 text-right">{{countForExperienceType(experienceTypes.THREE_YEARS)}}</div>

              <div class="col-span-6 text-left pl-2">4 Years</div>
              <div class="col-span-6 text-right">{{countForExperienceType(experienceTypes.FOUR_YEARS)}}</div>

              <div class="col-span-6 text-left pl-2">5+ Years</div>
              <div class="col-span-6 text-right">{{countForExperienceType(experienceTypes.FIVE_YEARS)}}</div>

              <!--driver types section-->
              <div class="col-span-12 font-bold text-left mt-3">Leads with...</div>
              <div class="col-span-6 text-left pl-2">Class A CDL</div>
              <div class="col-span-6 text-right">{{cdlACount()}}</div>

              <div class="col-span-6 text-left pl-2">Moving Violations</div>
              <div class="col-span-6 text-right">{{violationsCount()}}</div>

              <div class="col-span-6 text-left pl-2">Preventable Accidents</div>
              <div class="col-span-6 text-right">{{accidentsCount()}}</div>

              <div class="col-span-6 text-left pl-2">Military Experience</div>
              <div class="col-span-6 text-right">{{militaryCount()}}</div>

              <div class="col-span-6 text-left pl-2">Interest in Owner Operator</div>
              <div class="col-span-6 text-right">{{interestInOOCount()}}</div>

              <div class="col-span-6 text-left pl-2">Interest in Teaming Up</div>
              <div class="col-span-6 text-right">{{interestInTeamDrivingCount()}}</div>
            </div>
          </div>


          <!--Lead breakdown-->
          <div id="lead-heatmap"></div>
          <div class="col-span-12 text-2xl font-bold mt-4">Lead Heatmap</div>
          <div class="col-span-12">
            <!-- <mgl-map [style]="'mapbox://styles/mapbox/light-v11'" [style.width]="'100%'" [style.height]="mapHeight"
              [style.borderRadius]="'16px'" [zoom]="[mapZoom]" [center]="[-95.7, 37]" (mapCreate)="map = $event"
              (mapLoad)="onMapLoad($event)" [scrollZoom]="scrollZoom" [dragPan]="dragPan" [attributionControl]="false" [preserveDrawingBuffer]="true">
            </mgl-map> -->
            <div id="map" hidden style="width: 100%; height: 400px;"></div>
            <img id="map-screenshot" src="{{mapImageUrl}}" style="width: 100%; height: 400px; object-fit: contain;" />
          </div>

          <!--Lead breakdown-->
          <div class="col-span-12 text-2xl font-bold mt-4">Performance History (Last 3 Periods)</div>
          <div class="bg-slate-100 rounded w-full p-4 col-span-12 grid grid-cols-12 gap-1 text-left">
            <div class="col-span-12 grid grid-cols-12 font-bold">
              <div class="col-span-3">Period</div>
              <div class="col-span-3">Sent Leads</div>
              <div class="col-span-3">Budget</div>
              <div class="col-span-3">Cost Per Sent Lead</div>
            </div>
            <div class="col-span-12 grid grid-cols-12" *ngFor="let period of report.previousSpendPeriods">
              <div class="col-span-3">
                <span>{{moment(period.timestampStart).format('LL')}}</span>
                <span> to </span>
                <span>{{moment(period.timestampEnd).format('LL')}}</span>
              </div>
              <div class="col-span-3">{{period.sentLeads}}</div>
              <div class="col-span-3">{{period.spendInCents / 100 | currency:'USD'}}</div>
              <div class="col-span-3">{{(period.spendInCents / 100 / period.sentLeads) | currency:'USD'}}</div>
            </div>
          </div>

        </div>


      </div>
    </fuse-card>
  </div>