

import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AddContactDto, AgencyDto, CompanyDto, ContactDto, FileResponseDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-add-contact-form',
  templateUrl: './add-contact-form.component.html',
  // styleUrls: ['./add-contact-form.component.css']
})
export class AddContactFormComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  //inputs
  @Input() company: CompanyDto;
  @Input() agency: AgencyDto;

  //outputs
  @Output() didCreateContact: EventEmitter<ContactDto> = new EventEmitter();

  //vars
  contact: AddContactDto = new AddContactDto();

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;

  //file upload
  @ViewChild('image') imageElement: ElementRef;

  ngOnInit() {
    
  }


  //api
  addContact(): void {
    this.viewState = ViewState.loading;

    this.http
      .post(this.addContactRoute(), this.contact)
      .subscribe((result: ContactDto) => {
        this.contact = result;

        //return to previous page
        if (this.company != null || this.agency != null) {
          this.didCreateContact.emit(result)
        }
        else {
          this._router.navigateByUrl(`/contacts/${result.id}`);
        }
      });
  }

  addContactRoute() : string {
    if (this.company != null) {
      return `${environment.services_tdusa_admin}/v1/companies/${this.company.id}/contacts`;
    }
    else if (this.agency != null) {
      return `${environment.services_tdusa_admin}/v1/agencies/${this.agency.id}/contacts`;
    }
    else {
      return `${environment.services_tdusa_admin}/v1/contacts`;
    }
    
  }

  async uploadFile(file: File): Promise<FileResponseDto> {
    //package form data
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    //upload
    return (await firstValueFrom(this.http.post(`${environment.services_tdusa_admin}/v1/files`, formData))) as FileResponseDto;
  }

  async editImage(e: Event) {
    //get file
    const file: File = (<HTMLInputElement>e.target).files.item(0);

    //upload
    this.viewState = ViewState.loading;
    const uploadedFile = await this.uploadFile(file);
    this.contact.imageUrl = uploadedFile.src;
    this.viewState = ViewState.content;
  }

  //validations
  contactIsValid(): Boolean {
    return this.isValidString(this.contact.name) && this.isValidString(this.contact.email);
  }

  isValidString(test: string): Boolean {
    if (test == null) { return false; }
    return test.trim() != '';
  }
}
