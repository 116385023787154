import { IdentifiableDto } from "./identifiable.dto";
import { ProductStatusTypeDto, ProductSuspensionReasonDto, ProspectReasonDto } from "./lookup.dto";

export class ProductStatusDto extends IdentifiableDto {
    reason: string;
    timestampCreated!: Date;

    //relations
    productStatusType: ProductStatusTypeDto;
    suspensionReason: ProductSuspensionReasonDto;
    prospectReason: ProspectReasonDto;
}