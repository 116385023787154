<div class="grid grid-cols-12 gap-3">
  <div *ngIf="permissionsService.canManage()" class="col-span-12 md:col-span-6 grid grid-cols-12 gap-3">
    <fuse-card class="col-span-12 p-4 grid grid-cols-12 gap-3">
      <div class="col-span-12 md:col-span-6">
        <div class="text-lg font-medium tracking-tight leading-6 truncate">Period Ad Spend</div>
        <div class="flex flex-col mx-6 mt-3 mb-3">
          <div class="text-lg font-bold racking-tighter leading-tight">
            <span class="mr-3">Total:</span>
            <span>{{periodAdSpendTotalRaw() | currency:'USD'}}</span>
          </div>
          <div class="text-lg font-bold racking-tighter leading-tight mt-2">
            <span class="mr-3 text-rose-500">Trial:</span>
            <span>({{periodAdSpendTrialRaw() | currency:'USD'}})</span>
          </div>
          <div class="text-lg font-bold racking-tighter leading-tight mt-2" matTooltip="Allocated money filters out free trials and then multiplies spend amounts first by their allocated percentage and then by the global percentage setting. This is the actual amount available to the system to spend ads for MLPs.">
            <span class="mr-3 text-emerald-500">Alloc:</span>
            <span>{{periodAdSpendAllocRaw() | currency:'USD'}}</span>
          </div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-6">
        <div class="text-lg font-medium tracking-tight leading-6 truncate">Amount Remaining</div>
        <div class="flex flex-col lg:flex-row lg:items-center mx-6 mt-3 mb-3">
          <div class="text-3xl font-bold tracking-tighter leading-tight">
            <span class="mr-3 text-emerald-500">Alloc:</span>
            <span>{{(periodAdSpendAllocRaw() - periodAdSpent()) | currency:'USD'}}</span>
          </div>
        </div>
      </div>

      <!--spend breakdown-->
      <div class="col-span-12">
        <div class="flex flex-col">
          <div class="flex items-center">
            <div class="flex items-center justify-center w-14 h-14 rounded bg-slate-100 text-primary">
              <mat-icon class="text-current" [svgIcon]="'heroicons_outline:credit-card'"></mat-icon>
            </div>
            <div class="flex-auto ml-4 leading-none">
              <div class="text-sm font-medium text-secondary">Amount Spent</div>
              <div class="mt-2 font-medium text-2xl">{{periodAdSpent() | currency:'USD'}}</div>
              <mat-progress-bar class="mt-3 rounded-full" [color]="'primary'" [mode]="'determinate'"
                [value]="(periodAdSpent()  * 100) / periodAdSpend()"></mat-progress-bar>
            </div>
            <div class="flex items-end justify-end min-w-18 mt-auto ml-6">
              <div class="text-lg leading-none">{{periodAdSpent() / periodAdSpend() | percent:'2.2'}}</div>
              <mat-icon class="text-green-600 icon-size-4 ml-1" [svgIcon]="'heroicons_solid:arrow-narrow-up'">
              </mat-icon>
            </div>
          </div>
        </div>
      </div>
    </fuse-card>

    <div class="col-span-12 grid grid-cols-12 gap-3 text-center">
      <fuse-card class="col-span-12 md:col-span-4 p-4 flex flex-col">
        <div class="text-lg font-bold text-primary ">
          <mat-icon [svgIcon]="'account_tree'" class="w-1 h-1 text-primary mr-2" style="vertical-align: middle;">
          </mat-icon>
          <span>Total Products</span>
        </div>
        <div class="font-bold text-7xl">{{filteredCompanyPerformances().length}}</div>
      </fuse-card>
      <fuse-card class="col-span-12 md:col-span-4 p-4 flex flex-col">
        <div class="text-lg font-bold text-emerald-400">
          <mat-icon [svgIcon]="'check'" class="w-1 h-1 text-emerald-400 mr-2" style="vertical-align: middle;">
          </mat-icon>
          <span>Active Products</span>
        </div>
        <div class="font-bold text-7xl">{{statusTypeTotal(rawStatusTypes.ACTIVE)}}</div>
      </fuse-card>
      <fuse-card class="col-span-12 md:col-span-4 p-4 flex flex-col">
        <div class="text-lg font-bold text-orange-400">
          <mat-icon [svgIcon]="'refresh'" class="w-1 h-1 text-orange-400 mr-2" style="vertical-align: middle;">
          </mat-icon>
          <span>Renewal Required</span>
        </div>
        <div class="font-bold text-7xl">{{statusTypeTotal(rawStatusTypes.RENEWAL_REQUIRED)}}</div>
      </fuse-card>
    </div>
  </div>
  <div *ngIf="permissionsService.canManage()" class="col-span-12 md:col-span-6">
    <fuse-card class="p-4" style="min-height: 450px;">
      <div *ngIf="chartTypeSlices != null" class="flex flex-col flex-auto rounded-2xl overflow-hidden p-6"
        style="min-height: 450px;">
        <div class="flex items-start justify-between">
          <div class="text-lg font-medium tracking-tight leading-6 truncate">Total Percentage</div>
          <div class="ml-2">
            <button class="h-6 min-h-6 px-2 rounded-full bg-hover" mat-button [matMenuTriggerFor]="conversionMenu">
              <span class="font-medium text-sm text-secondary">{{selectedSliceType.name}}</span>
            </button>
            <mat-menu #conversionMenu="matMenu">
              <button mat-menu-item *ngFor="let type of applicationSliceTypes" (click)="selectSliceType(type)">
                <span *ngIf="type.id == selectedSliceType.id">
                  <mat-icon>check</mat-icon>
                </span>
                <span>{{type.name}}</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="flex flex-col flex-auto mt-6">
          <apx-chart class="flex flex-auto items-center justify-center w-full h-full" [chart]="chartTypeSlices.chart"
            [colors]="chartTypeSlices.colors" [labels]="chartTypeSlices.labels"
            [plotOptions]="chartTypeSlices.plotOptions" [series]="chartTypeSlices.series"
            [states]="chartTypeSlices.states" [tooltip]="chartTypeSlices.tooltip"></apx-chart>
        </div>
      </div>
    </fuse-card>
  </div>
  <!--map-->
  <!-- <div class="col-span-12">
    <mgl-map [style]="'mapbox://styles/mapbox/streets-v11'" [style.width]="'100%'" [style.height]="'400px'"
      [style.borderRadius]="'16px'" [zoom]="[3]" [center]="[-95.7, 37]" (mapCreate)="map = $event"
      (mapLoad)="onMapLoad($event)">
      <mgl-control mglNavigation></mgl-control>
      <mgl-control mglScale></mgl-control>
    </mgl-map>
  </div> -->

  <!--product performance-->
  <div class="col-span-12">
    <div class="grid grid-cols-12 gap-3">
      <!--Stas & Filters-->
      <fuse-card class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 mb-4 p-4 grid grid-cols-12"
        style="height: fit-content;">
        <div class="text-2xl col-span-12"><b>Stats & Filters</b></div>

        <!-- sales reps -->
        <div class="text-primary col-span-12"><b>Sales Rep</b></div>
        <mat-form-field appearance="fill" class="w-full mt-1 col-span-12">
          <mat-select [(value)]="selectedSalesRepId" (valueChange)="invalidateStats()">
            <mat-option [value]="undefined">All</mat-option>
            <mat-option *ngFor="let administrator of administrators" [value]="administrator.id">
              {{administrator.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--ops rep-->
        <div class="text-primary col-span-12"><b>Ops Rep</b></div>
        <mat-form-field appearance="fill" class="w-full mt-1 col-span-12">
          <mat-select [(value)]="selectedOpsRepId" (valueChange)="invalidateStats()">
            <mat-option [value]="undefined">All</mat-option>
            <mat-option *ngFor="let administrator of administrators" [value]="administrator.id">
              {{administrator.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--status types-->
        <div class="text-primary col-span-12 mt-2 mb-2"><b>Status Types</b></div>
        <div class="col-span-12 grid grid-cols-12" *ngFor="let type of statusTypes">
          <div class="col-span-8">
            <ng-switchery onLabel="{{type.name}}" [(ngModel)]="type.checked" (ngModelChange)="invalidateStats()"
              name="{{type.id}}-{{type.name}}" size="switchery-xs"></ng-switchery>
          </div>
          <div class="col-span-4 text-right pb-1">{{statusTypeTotal(type)}}</div>
        </div>
        <div class="col-span-12">
          <hr class="my-2" />
        </div>

        <!--status types-->
        <div class="text-primary col-span-12 mt-2 mb-2"><b>Renewal Types</b></div>
        <div class="col-span-12 grid grid-cols-12">
          <div class="col-span-8">
            <ng-switchery onLabel="{{renewedType.name}}" [(ngModel)]="renewedType.checked" (ngModelChange)="invalidateStats()"
              name="{{renewedType.id}}-{{renewedType.name}}" size="switchery-xs"></ng-switchery>
          </div>
          <div class="col-span-4 text-right pb-1">{{renewalTypeTotal(renewedType)}}</div>
        </div>
        <div class="col-span-12 grid grid-cols-12">
          <div class="col-span-8">
            <ng-switchery onLabel="{{notRenewedType.name}}" [(ngModel)]="notRenewedType.checked" (ngModelChange)="invalidateStats()"
              name="{{notRenewedType.id}}-{{notRenewedType.name}}" size="switchery-xs"></ng-switchery>
          </div>
          <div class="col-span-4 text-right pb-1">{{renewalTypeTotal(notRenewedType)}}</div>
        </div>
        <div class="col-span-12">
          <hr class="my-2" />
        </div>

        <!--apps-->
        <div class="text-primary col-span-12 mt-2 mb-2"><b>Totals</b>
        </div>
        <div class="col-span-8">Companies</div>
        <div class="col-span-4 text-right pb-1">{{filteredCompanyPerformances().length}}</div>
        <div class="col-span-8">Sent Leads</div>
        <div class="col-span-4 text-right pb-1">{{sentLeadsTotal()}}</div>
        <div class="col-span-8">Period Ad Spend</div>
        <div class="col-span-4 text-right pb-1">{{periodAdSpendTotal()}}</div>
        <div class="col-span-8">Period Amnt. Spent</div>
        <div class="col-span-4 text-right pb-1">{{periodAmountSpentTotal()}}</div>
        <div class="col-span-8">Period Amnt. Rem.</div>
        <div class="col-span-4 text-right pb-1">{{periodAmountSpendRemainingTotal()}}</div>
        <div class="col-span-12">
          <hr class="my-2" />
        </div>

      </fuse-card>
      <div class="col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-9">
        <div class="flex items-center">
          <!-- Search -->
          <div class="flex-auto">
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
              <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
              <input matInput [(ngModel)]="searchText" (ngModelChange)="seachTextDidChange(searchText)"
                [autocomplete]="'off'" [placeholder]="'Filter matched leads products'">
            </mat-form-field>
          </div>
        </div>


        <!--list-->
        <!--loading-->
        <div *ngIf="viewState == viewStates.loading">
          <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
        </div>

        <!-- no content -->
        <div *ngIf="viewState == viewStates.content && filteredCompanyPerformances().length === 0" class="mt-8">
          <app-generic-no-content title="No Products Found"
            subtitle="No products were found please refresh or update your search criteria."
            imageUrl="https://img.icons8.com/color/256/000000/briefcase.png" class="ml-auto mr-auto mt-5">
          </app-generic-no-content>
        </div>

        <!-- content -->
        <div [hidden]="viewState != viewStates.content || filteredCompanyPerformances().length === 0"
          class="w-full mt-3">
          <fuse-card class="w-full p-4" style="max-height: 724px;">
            <div class="w-full overflow-x-auto mx-6">
              <table class="w-full bg-transparent" mat-table matSort [dataSource]="productsDataSource"
                [trackBy]="trackByFn" #productsTable>

                <!-- Product -->
                <ng-container matColumnDef="name" sticky>
                  <th mat-header-cell mat-sort-header *matHeaderCellDef class="bg-white">
                    Product
                  </th>
                  <td mat-cell *matCellDef="let product" class="{{productSuccessColorClass(product)}}">
                    <a routerLink="/companies/{{product.company.id}}/matchedLeadsProducts/{{product.id}}"
                      class="pr-6 font-medium whitespace-nowrap"
                      [matTooltip]="product.notes">
                      <small><b>{{product.name ?? 'MLP'}}</b></small>
                      <mat-icon *ngIf="product.currentSpendPeriod?.freeTrial == true" class="icon-size-5 ml-2"
                        style="vertical-align: bottom;" svgIcon="sell" matTooltip="Free Trial"></mat-icon>
                    </a>
                    
                  </td>
                </ng-container>


                <!-- Company -->
                <ng-container matColumnDef="company">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Company
                  </th>
                  <td mat-cell *matCellDef="let product" class="pr-6">
                    <a routerLink="/companies/{{product.company.id}}"
                      class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                      <img class="inline mr-3 rounded" style="width: 24px;" *ngIf="product.company.imageUrl != null"
                        [src]="product.company.imageUrl" />
                      <mat-icon *ngIf="product.company.imageUrl == null" class="mr-3 text-primary"
                        style="vertical-align: bottom;" [svgIcon]="'heroicons_solid:briefcase'"></mat-icon>
                      <span class="text-slate-600">{{product.company.name}}</span>
                    </a>
                  </td>
                </ng-container>

                <!-- Agency -->
                <ng-container matColumnDef="agency">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Agency
                  </th>
                  <td mat-cell *matCellDef="let product">
                    <span *ngIf="product.company.agency == null" class="pr-6 font-medium whitespace-nowrap"></span>
                    <a *ngIf="product.company.agency != null" routerLink="/agencies/{{product.company.agency.id}}"
                      class="pr-6 font-medium whitespace-nowrap" matTooltip="{{product.company.name}} Agency">
                      <small> {{product.company.agency?.name ?? 'N/A'}}</small>
                    </a>
                  </td>
                </ng-container>

                <!-- Status -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Status
                  </th>
                  <td mat-cell *matCellDef="let product">
                    <span class="pr-6 font-medium whitespace-nowrap" matTooltip="{{product.company.name}} Status">
                      <span *ngIf="product.productStatus == null" class="text-slate-500"><small>Unknown</small></span>
                      <span *ngIf="product.productStatus != null">
                        <small
                          class="{{rawStatusTypes.valueForId(product.productStatus?.productStatusType?.id).textColor}}">{{product.productStatus.productStatusType.name}}</small>
                      </span>
                    </span>
                  </td>
                </ng-container>

                <!-- Future Renewal -->
                <ng-container matColumnDef="futureRenewal">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Future Renewal
                  </th>
                  <td mat-cell *matCellDef="let product">
                    <span class="pr-6 font-medium whitespace-nowrap"
                      matTooltip="{{product.company.name}} Future Renewal">
                      <mat-icon *ngIf="product.futureSpendPeriod != null" class="icon-size-5 text-emerald-500"
                        style="vertical-align: bottom;" svgIcon="check_box"></mat-icon>
                      <mat-icon *ngIf="product.futureSpendPeriod == null" class="icon-size-5"
                        style="vertical-align: bottom;" svgIcon="check_box"></mat-icon>
                    </span>
                  </td>
                </ng-container>

                <!-- Period Ad Sepnd -->
                <ng-container matColumnDef="spend">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Period Ad Sepnd
                  </th>
                  <td mat-cell *matCellDef="let product">
                    <span class="pr-6 font-medium whitespace-nowrap"
                      matTooltip="{{product.company.name}} Period Ad Sepnd">
                      <small *ngIf="product.currentSpendPeriod == null">N/A</small>
                      <small *ngIf="product.currentSpendPeriod != null">{{(product.currentSpendPeriod.spendInCents/100)
                        | currency:'USD'}}</small>
                    </span>
                  </td>
                </ng-container>

                <!-- Amount Spent -->
                <ng-container matColumnDef="spent">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Amount Spent
                  </th>
                  <td mat-cell *matCellDef="let product">
                    <span class="pr-6 font-medium whitespace-nowrap" matTooltip="{{product.company.name}} Amount Spent">
                      <small *ngIf="product.currentSpendPeriod == null">N/A</small>
                      <small *ngIf="product.currentSpendPeriod != null">{{product.currentSpendPeriod.spentInCents/100 |
                        currency:'USD'}}</small>
                    </span>
                  </td>
                </ng-container>

                <!-- Spend Remaining -->
                <ng-container matColumnDef="remaining">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Spend Remaining
                  </th>
                  <td mat-cell *matCellDef="let product">
                    <span class="pr-6 font-medium whitespace-nowrap"
                      matTooltip="{{product.company.name}} Spend Remaining">
                      <small *ngIf="product.currentSpendPeriod == null">N/A</small>
                      <small
                        *ngIf="product.currentSpendPeriod != null">{{(product.currentSpendPeriod.spendInCents-product.currentSpendPeriod.spentInCents)/100
                        | currency:'USD'}}</small>
                    </span>
                  </td>
                </ng-container>

                <!-- Allocated Percentage -->
                <ng-container matColumnDef="allocatedPercentage">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Allocated Percentage
                  </th>
                  <td mat-cell *matCellDef="let product">
                    <span class="pr-6 font-medium whitespace-nowrap">
                      <small>{{product.allocatedPercentage}}%</small>
                    </span>
                  </td>
                </ng-container>

                <!-- Last Daily Allocated Spend -->
                <ng-container matColumnDef="lastDailyAllocatedSpend">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Last Daily Allocated Spend
                  </th>
                  <td mat-cell *matCellDef="let product">
                    <span class="pr-6 font-medium whitespace-nowrap"
                      matTooltip="{{product.company.name}} Last Daily Allocated Spend">
                      <small>{{product.lastDailyAllocatedSpend}}</small>
                    </span>
                  </td>
                </ng-container>

                <!-- Sent Leads -->
                <ng-container matColumnDef="sentLeads">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Sent Leads
                  </th>
                  <td mat-cell *matCellDef="let product" class="text-center">
                    <span class="pr-6 font-medium whitespace-nowrap" matTooltip="{{product.company.name}} Sent Leads">
                      <small>{{product.sentLeads}}</small>
                    </span>
                  </td>
                </ng-container>

                <!-- Sent Pattern -->
                <ng-container matColumnDef="sentPattern">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Sent Pattern
                  </th>
                  <td mat-cell *matCellDef="let product">
                    <span class="pr-6 font-medium whitespace-nowrap" matTooltip="{{product.company.name}} Sent Pattern">
                      <small>{{product.sentPattern}}</small>
                    </span>
                  </td>
                </ng-container>

                <!-- Cost Per Sent Lead -->
                <ng-container matColumnDef="costPerSentLead">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Cost Per Sent Lead
                  </th>
                  <td mat-cell *matCellDef="let product">
                    <span class="pr-6 font-medium whitespace-nowrap text-sm"
                      matTooltip="{{product.company.name}} Cost Per Sent Lead">
                      {{product.costPerSentLead/100 | currency:'USD'}}
                    </span>
                  </td>
                </ng-container>

                <!-- Target CPL -->
                <ng-container matColumnDef="targetCpl">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Target CPL
                  </th>
                  <td mat-cell *matCellDef="let product">
                    <span class="pr-6 font-medium whitespace-nowrap" matTooltip="{{product.company.name}} Target CPL">
                      <small>{{product.targetCplInCents/100 | currency:'USD'}}</small>
                    </span>
                  </td>
                </ng-container>

                <!-- Priority Score -->
                <ng-container matColumnDef="priorityScore">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Priority Score
                  </th>
                  <td mat-cell *matCellDef="let product">
                    <span class="pr-6 font-medium whitespace-nowrap"
                      matTooltip="{{product.company.name}} Priority Score">
                      <small>{{product.priorityScore | number:'1.2-2'}}</small>
                    </span>
                  </td>
                </ng-container>

                <!-- Add Backs -->
                <ng-container matColumnDef="addBacks">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Add Backs
                  </th>
                  <td mat-cell *matCellDef="let product">
                    <span class="pr-6 font-medium whitespace-nowrap" matTooltip="{{product.company.name}} Add Backs">
                      <small *ngIf="product.currentSpendPeriod != null">{{product.currentSpendPeriod.addBacksInCents/100
                        | currency:'USD'}}</small>
                    </span>
                  </td>
                </ng-container>

                <!-- Sends Per Lead -->
                <ng-container matColumnDef="sendsPerLead">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    Sends Per Lead
                  </th>
                  <td mat-cell *matCellDef="let product">
                    <span class="pr-6 font-medium whitespace-nowrap"
                      matTooltip="{{product.company.name}} Sends Per Lead">
                      <small
                        *ngIf="product.currentSpendPeriod != null">{{sendsPerLead(product.currentSpendPeriod)}}</small>
                    </span>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="productsTableColumns(); sticky: true" class="bg-white"></tr>
                <tr class="order-row h-16" mat-row *matRowDef="let row; columns: productsTableColumns();"></tr>
                <!-- <tr
                      class="h-16 border-0"
                      mat-footer-row
                      *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
              </table>
            </div>
          </fuse-card>
        </div>
      </div>
    </div>
  </div>
</div>