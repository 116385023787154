import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { ApplicationDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  // styleUrls: ['./application.component.css']
})
export class LeadComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  public id: string = '0';
  application: ApplicationDto;
  breadcrumbs: GenericBreadcrumb[] = [
    new GenericBreadcrumb('Leads', '/leads')
  ];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  infoViewState = ViewState.loading;

  ngOnInit() {
    //update title
    this.titleService.setTitle('Lead | Admin');

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';

    this.getApplication();
  }

  //api
  getApplication() {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/applications/${this.id}`)
      .subscribe((result: ApplicationDto) => {
        this.application = result;
        this.viewState = ViewState.content;
        this.infoViewState = ViewState.content;
      });
  }

  deleteApplication() {

  }
}
