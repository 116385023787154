import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AdministratorDto, EditAdministratorDto, FileResponseDto } from 'app/models/dtos';
import { PermissionGroupsLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-staff-member',
  templateUrl: './staff-member.component.html',
  // styleUrls: ['./staff-member.component.css']
})
export class StaffMemberComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  public id: string = '0';
  administrator: AdministratorDto = null;
  breadcrumbs: GenericBreadcrumb[] = [
    new GenericBreadcrumb('Staff', '/staff')
  ];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  infoViewState = ViewState.loading;

  //file upload
  @ViewChild('image') imageElement: ElementRef;

  async ngOnInit() {
    //update title
    this.titleService.setTitle('Staff Member | Admin');

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';

    await this.permissionsService.setContent(PermissionGroupsLookup.GLOBAL, '');
    this.getAdministrator();
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | Admin`);
  }

  //api
  getAdministrator(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/administrators/${this.id}`)
      .subscribe((result: AdministratorDto) => {
        this.administrator = result;
        this.infoViewState = ViewState.content;
        this.viewState = ViewState.content;
        this.setTitle(this.administrator.name);
      });
  }

  editAdministrator(): void {
    this.infoViewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/administrators/${this.id}`, EditAdministratorDto.fromAdministrator(this.administrator))
      .subscribe((result: AdministratorDto) => {
        this.administrator = result;
        this.infoViewState = ViewState.content;
        this.setTitle(this.administrator.name);
      });
  }

  deleteAdministrator(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/administrators/${this.id}`)
      .subscribe(() => {
        this._router.navigateByUrl('/staff')
      });
  }

  async uploadFile(file: File) : Promise<FileResponseDto> {
    //package form data
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    //upload
    return (await firstValueFrom(this.http.post(`${environment.services_tdusa_admin}/v1/files`, formData))) as FileResponseDto;
  }

  async editImage(e: Event) {
    //get file
    const file: File = (<HTMLInputElement>e.target).files.item(0);

    //upload
    this.infoViewState = ViewState.loading;
    const uploadedFile = await this.uploadFile(file);
    this.administrator.imageUrl = uploadedFile.src;
    this.editAdministrator();
  }
}
