import { IdentifiableDto } from "./identifiable.dto";
import { MatchedLeadsProductDto } from "./matchedLeadsProduct.dto";
import { ProductSpendPeriodDto } from "./productSpendPeriod.dto";

export class ReportDto extends IdentifiableDto {
   timestampCreated!: Date;
   timestampUpdated!: Date;
   name!: string;

   //relations
   spendPeriod: ProductSpendPeriodDto;
   matchedLeadsProduct: MatchedLeadsProductDto;
   previousSpendPeriods: ProductSpendPeriodDto[] = [];
}