<fuse-card class="flex flex-col items-center w-full p-8 pb-6 filter-info">
  <img *ngIf="contact.imageUrl == null" class="w-16 h-16 border rounded-full"
    src="https://img.icons8.com/color/128/000000/test-account.png" alt="Card cover image">
  <img *ngIf="contact.imageUrl != null" class="w-16 h-16 border rounded-full object-cover" src="{{contact.imageUrl}}"
    alt="Card cover image">
  <div class="text-xl font-bold leading-tight text-center mt-8">{{contact.name}}</div>
  <div class="text-primary leading-tight text-center mt-1">{{contact.position}}</div>
  <div class="text-secondary leading-tight text-center mt-1">{{contact.email}}</div>
  <div class="text-secondary leading-tight text-center mt-1">{{contact.phone}}</div>
  <div class="mt-2">
    <button
        mat-stroked-button
        [matMenuTriggerFor]="contactCardMenu">
        <small><b>Menu</b></small>
    </button>
    <mat-menu #contactCardMenu="matMenu">
        <button mat-menu-item (click)="didClickView()">View Contact</button>
        <button mat-menu-item *ngFor="let option of inputOptions" (click)="didSelectOption(option)" [ngClass]="option.warn ? 'text-warn' : ''">
          {{option.name}}
        </button>
    </mat-menu>
</div>
</fuse-card>