import { IdentifiableDto } from "./identifiable.dto"

export class WebhookDto extends IdentifiableDto {
    name: string;
    timestampCreated!: Date;
    timestampUpdated!: Date;
    endpoint: string;
    url!: string;
    verb!: string;
    dateFormat!: string;
}