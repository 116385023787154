import { IdentifiableDto } from "./identifiable.dto"

export class WebhookFieldDto extends IdentifiableDto {
    key: string;
    fieldName: string;
    customValue: string;
    serialized: boolean;

    //internal
    viewState: number;
}