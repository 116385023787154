import { Component, Input, OnInit } from '@angular/core';
import { CompanyDto } from 'app/models/dtos';

@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  // styleUrls: ['./company-card.component.css']
})
export class CompanyCardComponent implements OnInit {
  constructor() { }

  @Input() company: CompanyDto;

  ngOnInit() {
  }

}
