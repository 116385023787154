import { ContactDto } from ".";

export class EditContactDto {
    email!: string;
    name!: string;
    phone: string;
    imageUrl: string;
    position: string;
    notes: string;
    canLogin: boolean;

    static fromContact(contact: ContactDto) : EditContactDto {
        var edit = new EditContactDto();
        edit.name = contact.name;
        edit.phone = contact.phone;
        edit.email = contact.email;
        edit.position = contact.position;
        edit.imageUrl = contact.imageUrl;
        edit.notes = contact.notes;
        edit.canLogin = contact.canLogin;

        return edit;
    }
}