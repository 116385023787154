import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewState } from 'app/models/app';
import { CompanyDto, ContactDto, EditCompanyDto } from 'app/models/dtos';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { ContactCardPortraitOption } from '../../../cards/contact-card-portrait/contact-card-portrait.component';

@Component({
  selector: 'app-company-contacts',
  templateUrl: './company-contacts.component.html',
  // styleUrls: ['./company-contacts.component.css']
})
export class CompanyContactsComponent implements OnInit {
  constructor(private http: HttpClient, public permissionsService: PermissionsService) { }

  //inputs
  @Input() company: CompanyDto;

  //outputs
  @Output() didEditCompany: EventEmitter<CompanyDto> = new EventEmitter();

  //vars
  //vars
  contacts: ContactDto[] = [];
  allContacts: ContactDto[] = [];
  searchText: string = '';
  contactsTableColumns: string[] = ['name'];
  moment = moment;
  addSearchText: string = '';
  contactToAdd: ContactDto = null;

  selectedContact: ContactDto;
  selectedRepContactId: string;
  selectedBillingContactId: string;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addNewViewState = ViewState.initial;
  addViewState = ViewState.initial;
  viewContactViewState = ViewState.initial;

  ngOnInit() {
    this.getContacts();

    this.selectedRepContactId = this.company.repContact?.id;
    this.selectedBillingContactId = this.company.billingContact?.id;
  }

  //api
  getContacts(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/contacts`)
      .subscribe((result: ContactDto[]) => {
        this.contacts = result;
        this.viewState = ViewState.content;
      });
  }

  editCompany(): void {
    this.viewState = ViewState.loading;

    const editDto = EditCompanyDto.fromCompany(this.company, this.company.salesRep?.id, this.company.opsRep?.id, this.selectedRepContactId, this.selectedBillingContactId);
    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}`, editDto)
      .subscribe((result: CompanyDto) => {
        this.company = result;
        this.viewState = ViewState.content;
        this.didEditCompany.emit(this.company);
      });
  }

  editBillingContact(): void {
    this.viewState = ViewState.loading;

    const editDto = EditCompanyDto.fromCompany(this.company, this.company.salesRep?.id, this.company.opsRep?.id, this.selectedRepContactId, this.selectedBillingContactId);
    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/billingContact`, editDto)
      .subscribe((result: CompanyDto) => {
        this.company.billingContact = result.billingContact;
        this.viewState = ViewState.content;
      });
  }

  editRepContact(): void {
    this.viewState = ViewState.loading;

    const editDto = EditCompanyDto.fromCompany(this.company, this.company.salesRep?.id, this.company.opsRep?.id, this.selectedRepContactId, this.selectedBillingContactId);
    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/repContact`, editDto)
      .subscribe((result: CompanyDto) => {
        this.company.repContact = result.repContact;
        this.viewState = ViewState.content;
      });
  }

  getAvailableContacts(): void {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/contacts/available`)
      .subscribe((result: ContactDto[]) => {
        this.allContacts = result;
      });
  }

  removeContact(contact: ContactDto): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/contacts/${contact.id}`)
      .subscribe(() => {
        this.contacts = this.contacts.filter(c => c.id != contact.id);
        this.viewState = ViewState.content;
      });
  }

  addContact(contact: ContactDto): void {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/contacts/${contact.id}`, null)
      .subscribe(() => {
        this.contacts.push(contact);
        this.allContacts = this.allContacts.filter(c => c.id != contact.id);

        //reset add state
        this.contactToAdd = null;
        this.addViewState = ViewState.initial;
        this.addSearchText = "";

        this.viewState = ViewState.content;
      });
  }

  deleteContact(contact: ContactDto): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/contacts/${contact.id}`)
      .subscribe(() => {
        this.contacts = this.contacts.filter(c => c.id != contact.id);
        this.allContacts.push(contact);
        this.searchText = '';
        this.viewState = ViewState.content;
      });
  }

  availableContacts(): ContactDto[] {
    return this.allContacts.filter(c => this.contacts.find(ec => ec.id === c.id) == null)
  }

  //search
  seachTextDidChange(text: string) {

  }

  filteredContacts(): ContactDto[] {
    return this.contacts.filter(r =>
      (r.name ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.email ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.phone ?? '').toLowerCase().includes(this.searchText.toLowerCase())
      || (r.position ?? '').toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  filteredAvailableContacts(): ContactDto[] {
    return this.allContacts.filter(r =>
      (r.name ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.email ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.phone ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
      || (r.position ?? '').toLowerCase().includes(this.addSearchText.toLowerCase())
    );
  }

  //contacts
  optionsForContact(): ContactCardPortraitOption[] {
    if (this.permissionsService.canManage()) {
      return [
        new ContactCardPortraitOption('1', 'Set as company rep', false),
        new ContactCardPortraitOption('2', 'Set as billing contact', false),
        new ContactCardPortraitOption('3', 'Remove as company contact', true),
      ];
    }
    else {
      return [];
    }
  }

  optionsForRepContact(): ContactCardPortraitOption[] {
    if (this.permissionsService.canManage()) {
      return [
        new ContactCardPortraitOption('1', 'Remove as rep contact', true),
      ];
    }
    else {
      return [];
    }
  }

  optionsForBillingContact(): ContactCardPortraitOption[] {
    if (this.permissionsService.canManage()) {
      return [
        new ContactCardPortraitOption('1', 'Remove as billing contact', true),
      ];
    }
    else {
      return [];
    }
  }

  didSelectContactOption(option: ContactCardPortraitOption, contact: ContactDto) {
    switch (option.id) {
      case '1':
        return this.setCompanyRepContact(contact);
      case '2':
        return this.setCompanyBillingContact(contact);
      case '3':
        return this.confirmRemoveContact(contact);
      default:
        break;
    }
  }

  didSelectRepContactOption(option: ContactCardPortraitOption, contact: ContactDto) {
    switch (option.id) {
      case '1':
        return this.setCompanyRepContact(null);
      default:
        break;
    }
  }

  didSelectBillingContactOption(option: ContactCardPortraitOption, contact: ContactDto) {
    switch (option.id) {
      case '1':
        return this.setCompanyBillingContact(null);
      default:
        break;
    }
  }

  setCompanyRepContact(contact: ContactDto) {
    this.selectedRepContactId = contact?.id;
    this.editRepContact();
  }

  setCompanyBillingContact(contact: ContactDto) {
    this.selectedBillingContactId = contact?.id;
    this.editBillingContact();
  }

  confirmRemoveContact(contact: ContactDto) {
    Swal.fire({
      title: 'Remove Contact',
      confirmButtonText: 'Remove',
      icon: 'error',
      confirmButtonColor: '#EF4444',
      showCancelButton: true,

    }).then((result) => {
      if (result.value) {
        this.removeContact(contact);
      }
    });
  }

  beginAddExistingContact() {
    this.addViewState = ViewState.content;
    this.getAvailableContacts();
  }

  didCreateContact(contact: ContactDto) {
    this.contacts.push(contact);
    this.addNewViewState = this.viewStates.initial;
  }

  cancelAddNewContact() {
    this.addNewViewState = this.viewStates.initial;
  }

  didSelectViewContact(contact: ContactDto) {
    this.selectedContact = contact;
    
    //add delay for render
    setTimeout(() => {
      this.viewContactViewState = this.viewStates.content;

      //scroll the job details into view
      setTimeout(() => {
        document.getElementById("contact-form").scrollIntoView({
          behavior: 'smooth'
        });
      }, 250);
    }, 500);
  }

  didEditContact(contact: ContactDto) {
    this.viewContactViewState = this.viewStates.initial;
    this.selectedContact = null;
  }
}
