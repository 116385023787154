<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<div *ngIf="viewState == viewStates.content" class="grid grid-cols-12">
  <div class="col-span-12 md:col-span-6 lg:col-span-4 mb-4 p-2">
    <!--basic info-->
    <fuse-card class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
      <div>
        <div class="text-small text-center text-secondary mb-4">
          <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
          </mat-icon>
          <b>Job Info</b>
        </div>
        <div>
          <div class="flex-auto">
            <!-- can login -->
            <div class="mt-4 mb-4 font-bold text-center">
              <ng-switchery onLabel="Visible to public" [(ngModel)]="job.visible" name="Is visible to public"
                [matTooltip]="''"></ng-switchery>
            </div>
            <!--name-->
            <mat-form-field class="w-full">
              <mat-label>Title</mat-label>
              <input matInput [(ngModel)]="job.title" [autocomplete]="'off'" placeholder="Job Title">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'edit'"></mat-icon>
            </mat-form-field>
            <!--salary-->
            <mat-form-field class="w-full">
              <mat-label>Salary</mat-label>
              <input matInput [(ngModel)]="job.salary" [autocomplete]="'off'" placeholder="Salary">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'money'"></mat-icon>
            </mat-form-field>
            <!--inquiry-->
            <mat-form-field class="w-full">
              <mat-label>Inquiry Website</mat-label>
              <input matInput [(ngModel)]="job.inquiryWebsite" [autocomplete]="'off'" placeholder="Inquiry Website">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'edit'"></mat-icon>
            </mat-form-field>
            <!--referral code-->
            <div class="col-span-12 md:col-span-6">
              <mat-form-field class="w-full">
                <mat-label>Tenstreet Referrer (Override)</mat-label>
                <input matInput [(ngModel)]="job.tenstreetReferrer" [autocomplete]="'off'" placeholder="Referral Code">
                <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
              </mat-form-field>
            </div>
            <!--tenstreet job posting id-->
            <mat-form-field class="w-full">
              <mat-label>Tenstreet Job Posting Reference ID (Override)</mat-label>
              <input matInput [(ngModel)]="job.tenstreetJobPostingReferenceId" [autocomplete]="'off'" placeholder="Tenstreet Job Posting Reference ID">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'link'"></mat-icon>
            </mat-form-field>
            <div class="text-sm">
              *Note: This field should be populated for products generated from the Tenstreet Jobs Marketplace.
            </div>

            <!--reference id-->
            <div class="col-span-12 md:col-span-6">
              <mat-form-field class="w-full">
                <mat-label>Jobcast Reference ID (Override)</mat-label>
                <input matInput [(ngModel)]="job.jobcastReferenceId" [autocomplete]="'off'" placeholder="Reference ID">
                <mat-icon class="icon-size-5" matSuffix [svgIcon]="'badge'"></mat-icon>
              </mat-form-field>
            </div>
            <!--notes-->
            <div class="col-span-12 md:col-span-6">
              <mat-form-field class="w-full">
                <mat-label>Notes</mat-label>
                <textarea [rows]="3" matInput [(ngModel)]="job.notes" [autocomplete]="'off'"
                  placeholder="Job Notes"></textarea>
                <mat-icon class="icon-size-5" matSuffix [svgIcon]="'edit_note'"></mat-icon>
              </mat-form-field>
            </div>
          </div>
          <button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="editJob()">
            <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span class="ml-2 mr-1">Save</span>
          </button>
          <div class="mt-4 text-small text-center text-secondary">Updated
            {{moment(job.timestampUpdated).fromNow()}}</div>
        </div>
      </div>

      <!--delete-->
      <div class="border rounded p-3 mt-4 border-rose-500">
        <div class="text-warn"><b>Danger Zone</b></div>
        <div><small>These actions are destructive. Proceed with care.</small></div>
        <button class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
          [swal]="{ title: 'Delete Job', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
          (confirm)="deleteJob()">

          Delete
        </button>
      </div>
    </fuse-card>
  </div>

  <div class="col-span-12 md:col-span-6 lg:col-span-8 p-2">
    <!--twilio-->
    <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
      <!--header-->
      <div class="flex items-center justify-between">
        <div class="text-2xl font-semibold leading-tight">Call Forwarding</div>
        <div class="-mr-3">
          <button mat-flat-button [color]="'primary'" (click)="editJob()">
            <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span class="ml-2 mr-1">Save</span>
          </button>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-3">
        <div *ngIf="twilioViewState == viewStates.loading" class="col-span-12">
          <div>
            <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
          </div>
        </div>
        <!--twilio number-->
        <div *ngIf="twilioViewState == viewStates.content" class="col-span-12 md:col-span-6">
          <mat-form-field class="w-full">
            <mat-label>Twilio Number (Override)</mat-label>
            <input matInput [(ngModel)]="job.twilioNumber" [autocomplete]="'off'" placeholder="Twilio Number (Override)"
              [disabled]="true">
            <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
          </mat-form-field>
          <button *ngIf="job.twilioNumber == null" mat-stroked-button color="primary" class="w-full"
            (click)="requestNumber()">Request</button>
          <button *ngIf="job.twilioNumber != null" mat-stroked-button color="warn" class="w-full"
            [swal]="{ title: 'Release Number?', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
            (confirm)="releaseNumber()">Release</button>
        </div>

        <div *ngIf="twilioViewState == viewStates.content" class="col-span-12 md:col-span-6">
          <mat-form-field class="w-full">
            <mat-label>Forward Number (Override)</mat-label>
            <input matInput [(ngModel)]="job.twilioForwardNumber" [autocomplete]="'off'"
              placeholder="Twilio Forward Number (Override)">
            <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
          </mat-form-field>
        </div>
      </div>
    </fuse-card>

    <!--SEO-->
    <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
      <!--header-->
      <div class="flex items-center justify-between">
        <div class="text-2xl font-semibold leading-tight">Custom Properties</div>
        <div class="-mr-3">
          <button mat-flat-button [color]="'primary'" (click)="editJob()">
            <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span class="ml-2 mr-1">Save</span>
          </button>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12 md:col-span-6 p-2">
          <!--seo title-->
          <mat-form-field class="w-full">
            <mat-label>Custom SEO Title</mat-label>
            <input matInput [(ngModel)]="job.customSeoTitle" [autocomplete]="'off'" placeholder="Custom SEO Title">
            <mat-icon class="icon-size-5" matSuffix [svgIcon]="'edit'"></mat-icon>
          </mat-form-field>
        </div>
        <div class="col-span-12 md:col-span-6 p-2">
          <!--apply url-->
          <mat-form-field class="w-full">
            <mat-label>Custom Apply URL</mat-label>
            <input matInput [(ngModel)]="job.customApplyUrl" [autocomplete]="'off'" placeholder="Custom Apply URL">
            <mat-icon class="icon-size-5" matSuffix [svgIcon]="'edit'"></mat-icon>
          </mat-form-field>
        </div>
      </div>
    </fuse-card>
    <!--Job Description-->
    <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
      <!--header-->
      <div class="flex items-center justify-between">
        <div class="text-2xl font-semibold leading-tight">Job Description</div>
        <div class="-mr-3">
          <button mat-flat-button [color]="'primary'" (click)="editJob()">
            <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span class="ml-2 mr-1">Save</span>
          </button>
        </div>
      </div>
      <div class="mt-4 mb-4 text-sm text-center">
        This description will be shown to prospective applicants on the corresponding microsite to lend credability for
        why this client is one of the top employers for the particular type of microsite.
      </div>
      <ckeditor class="mt-2" [editor]="JobEditor" [(ngModel)]="job.descriptionHtml" (ready)="onReady($event)">
      </ckeditor>
    </fuse-card>
  </div>
</div>