import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AdministratorDto, AgencyDto, CompanyDto, ContactDto, EditAgencyDto, FileResponseDto } from 'app/models/dtos';
import { PermissionGroupsLookup, PermissionTypesLookup } from 'app/models/lookups';
import { AccountsService } from 'app/services/accounts/accounts.service';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  // styleUrls: ['./agency.component.css']
})
export class AgencyComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  public id: string = '0';
  agency: AgencyDto = null;
  breadcrumbs: GenericBreadcrumb[] = [
    new GenericBreadcrumb('Agencies', '/agencies')
  ];
  moment = moment;
  companies: CompanyDto[] = [];
  contacts: ContactDto[] = [];
  permissions: any[] = [];

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  infoViewState = ViewState.loading;
  dashboardViewState = ViewState.loading;
  companiesViewState = ViewState.loading;
  contactsViewState = ViewState.loading;
  permissionsViewState = ViewState.loading;

  //lookups
  permissionGroup = PermissionGroupsLookup.AGENCY;

  //file upload
  @ViewChild('image') imageElement: ElementRef;

  async ngOnInit() {
    //update title
    this.setTitle('Agency');

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';
    await this.permissionsService.setContent(PermissionGroupsLookup.AGENCY, this.id);
    this.getAgency();
    this.getDashboard();
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | Admin`);
  }

  //tabs
  tabClick(tabEvent: MatTabChangeEvent) {
    switch (tabEvent.tab.textLabel) {
      case 'Dashboard':
        this.getDashboard();
        break;
      case 'Companies':
        this.getCompanies();
        break;
      case 'Contacts':
        this.getContacts();
        break;
      case 'Permissions':
        this.getPermissions();
        break;
      default:
        break;
    }
  }

  //api
  getAgency(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/agencies/${this.id}`)
      .subscribe((result: AgencyDto) => {
        this.agency = result;
        this.viewState = ViewState.content;
        this.infoViewState = ViewState.content;
        this.setTitle(this.agency.name);
      });
  }

  editAgency(): void {
    this.infoViewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/agencies/${this.id}`, EditAgencyDto.fromAgency(this.agency, this.agency.repContact?.id))
      .subscribe((result: AgencyDto) => {
        this.agency = result;
        this.infoViewState = ViewState.content;
        this.setTitle(this.agency.name);
      });
  }

  deleteAgency(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/agencies/${this.id}`)
      .subscribe(() => {
        this._router.navigateByUrl('/agencies')
      });
  }

  async uploadFile(file: File) : Promise<FileResponseDto> {
    //package form data
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    //upload
    return (await firstValueFrom(this.http.post(`${environment.services_tdusa_admin}/v1/files`, formData))) as FileResponseDto;
  }

  async editImage(e: Event) {
    //get file
    const file: File = (<HTMLInputElement>e.target).files.item(0);

    //upload
    this.infoViewState = ViewState.loading;
    const uploadedFile = await this.uploadFile(file);
    this.agency.imageUrl = uploadedFile.src;
    this.editAgency();
  }


  getDashboard(): void {
    this.dashboardViewState = ViewState.loading;

    setTimeout(() => {
      this.dashboardViewState = ViewState.content;
    }, 1000);
  }

  getCompanies(): void {
    this.companiesViewState = ViewState.loading;

    setTimeout(() => {
      this.companiesViewState = ViewState.content;
    }, 1000);
  }

  getContacts(): void {
    this.contactsViewState = ViewState.loading;

    setTimeout(() => {
      this.contactsViewState = ViewState.content;
    }, 1000);
  }

  getPermissions(): void {
    this.permissionsViewState = ViewState.loading;

    setTimeout(() => {
      this.permissionsViewState = ViewState.content;
    }, 1000);
  }

  didEditAgency(agency: AgencyDto) {
    this.agency = agency;
  }
}
