<fuse-card class="p-8 grid grid-cols-12">
  <div class="col-span-12">
    <div class="text-4xl mb-0"><b>Companies</b></div>
    <div>View all companies associated with this contact</div>
  </div>
  <div class="w-full col-span-12">
    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
      <!-- Search -->
      <div class="flex-auto">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [(ngModel)]="searchText" [autocomplete]="'off'" [placeholder]="'Filter companies'">
        </mat-form-field>
      </div>
    </div>

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto "></mat-spinner>
    </div>

    <!-- no content -->
    <div *ngIf="viewState == viewStates.content && filteredCompanies().length === 0" class="mt-8 text-center">
      <app-generic-no-content title="No Companies Found"
        subtitle="No company associations were found for this contact. If search, please update your search criteria."
        imageUrl="https://img.icons8.com/color/256/000000/briefcase.png" class="ml-auto mr-auto mt-5">
      </app-generic-no-content>
    </div>

    <!--content-->
    <div *ngIf="viewState == viewStates.content && filteredCompanies().length > 0">
      <div class="mt-4" *ngFor="let company of filteredCompanies()">
        <app-company-card [company]="company"></app-company-card>
      </div>
    </div>
  </div>

</fuse-card>