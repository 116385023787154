export class AddCompanyDto {
    name: string = '';
    phone: string;
    imageUrl: string;

    //relations
    agencyId: string;
    collectiveId: string;
    // currentStatusId: string;
    // currentSpendPeriodId: string;
    leadOpsAdministratorId: string;
    leadSalesAdministratorId: string;
}