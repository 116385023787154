<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="Lead" [breadcrumbs]="breadcrumbs"
    fallbackIcon="heroicons_solid:chevron-double-right"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!--main grid-->
    <div *ngIf="viewState == viewStates.content" class="grid grid-cols-12 gap-3">

      <!--lead header-->
      <div class="col-span-12">
        <app-lead-status [application]="application"></app-lead-status>
      </div>
      

      <div class="col-span-12 md:col-span-6 lg:col-span-4 mb-4 p-2">
        <!--basic info-->
        <fuse-card class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
          <!--loading-->
          <div *ngIf="infoViewState == viewStates.loading">
            <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
          </div>
  
          <div *ngIf="infoViewState == viewStates.content">
            <div class="text-small text-center text-secondary mb-4">
              <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
              </mat-icon>
              <b>Lead Info</b>
            </div>
            <img *ngIf="contact?.imageUrl == null" class="object-contain w-32 h-32 ml-auto mr-auto cursor-pointer"
              src="https://img.icons8.com/color/256/000000/test-account.png"
               />
            <img *ngIf="contact?.imageUrl != null"
              class="object-cover rounded-full w-32 h-32 ml-auto mr-auto border rounded-full cursor-pointer"
              src="{{contact.imageUrl}}" alt="Card cover image" />
  
            <div>
              <div class="flex-auto flex-col text-center pt-4">
                <div class="text-xl font-bold">{{application.firstName}} {{application.lastName}}</div>
                <div>{{application.email}}</div>
              </div>
              
              <a routerLink="/candidates/{{application.candidate.id}}">
                <button class="mt-4 w-full" mat-flat-button [color]="'primary'">
                  View Candidate Page
                </button>
              </a>

              <div class="mt-4 text-small text-center text-secondary">Created
                {{moment(application.timestampCreated).fromNow()}}</div>
            </div>
          </div>
  
          <!--delete-->
          <div class="border rounded p-3 mt-4 border-rose-500">
            <div class="text-warn"><b>Danger Zone</b></div>
            <div><small>These actions are destructive. Proceed with care.</small></div>
            <button class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
              [swal]="{ title: 'Delete Application', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
              (confirm)="deleteApplication()">
  
              Delete
            </button>
          </div>
        </fuse-card>
      </div>
  
      <div class="col-span-12 md:col-span-6 lg:col-span-8 p-2">
        <!--tabs-->
        <mat-tab-group animationDuration="0ms">
  
          <!--Application-->
          <mat-tab label="Application">
            <app-lead-application [application]="application"></app-lead-application>
          </mat-tab>
  
          <!--Matches-->
          <mat-tab label="Matches">
            <app-lead-matches [application]="application"></app-lead-matches>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    
  </div>

</div>