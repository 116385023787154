<fuse-card class="flex flex-col p-8">
  <div class="flex flex-col w-full filter-article text-sm">
    <!--header-->
    <div class="flex items-center justify-between">
      <div class="text-2xl font-semibold leading-tight">{{group.name ?? 'Email Group'}}</div>
      <div class="-mr-3">
        <button mat-flat-button [color]="'primary'" (click)="editGroup()" [disabled]="!isValidGroup(group)">
          <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
          <span class="ml-2 mr-1">Save</span>
        </button>
        <button class="ml-2" mat-flat-button [color]="'warn'"
        [swal]="{ title: 'Delete Group', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
          (confirm)="deleteGroup()">
          <mat-icon [svgIcon]="'delete'"></mat-icon>
          <span class="ml-2 mr-1">Delete</span>
        </button>
      </div>
    </div>
  
    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>
  
    <div *ngIf="viewState == viewStates.content">
      <div class="grid grid-cols-12 gap-3 mt-4">
        <div class="col-span-12">
          <!--name-->
          <mat-form-field class="w-full">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="group.name" [autocomplete]="'off'" placeholder="Group Name">
            <mat-icon class="icon-size-5" matSuffix [svgIcon]="'text_fields'"></mat-icon>
          </mat-form-field>
        </div>
        <!--  mode  -->
        <div class="col-span-12 md:col-span-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label class="text-primary text-sm font-bold">Email Mode</mat-label>
            <mat-select [(value)]="group.emailMode.id">
              <mat-option *ngFor="let mode of emailModes" [value]="mode.id">{{mode.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- format -->
        <div class="col-span-12 md:col-span-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label class="text-primary text-sm font-bold">Email Format</mat-label>
            <mat-select [(value)]="group.emailFormat.id">
              <mat-option *ngFor="let format of emailFormats" [value]="format.id">{{format.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!--batch-->
        <div class="col-span-12 md:col-span-4">
          <mat-label class="text-primary text-sm font-bold">&nbsp;</mat-label>
          <div class="text-center pt-3">
            <mat-checkbox class="p-2" [color]="'primary'" [(ngModel)]="group.batchLeads"
          matTooltip="Batch Leads (defaults to enabled): When enabled, this will bundle all leads into a single email and send in the selected mode/format to recipients. When disabled, each lead will generate an email and be sent in accordance with the chosen mode/format. Note: Lead2Hire / LeadAssist usually want this DISABLED."
            >
            Batch Leads
          </mat-checkbox>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  
  <div class="flex flex-col w-full pt-3 filter-article text-sm">
    <div class="text-sm text-primary font-semibold leading-tight">Recipients</div>
  
  
    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
      <!-- Search -->
      <div class="flex-auto">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [(ngModel)]="searchText" (ngModelChange)="seachTextDidChange(searchText)" [autocomplete]="'off'"
            [placeholder]="'Filter recipients'">
        </mat-form-field>
      </div>
      <!-- Add Recipient  -->
      <div class="flex-auto">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50 ml-4 mr-2">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:plus'"></mat-icon>
          <input matInput [(ngModel)]="addRecipientText" [autocomplete]="'off'" [placeholder]="'Add recipient'"
            [formControl]="emailFormControl">
        </mat-form-field>
      </div>
      <!-- Add recipient button -->
      <button class="ml-4" mat-flat-button [color]="'primary'" (click)="addRecipient()"
        [disabled]="!emailFormControl.valid">
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="ml-2 mr-1">Add</span>
      </button>
    </div>
  
  
    <!--list-->
    <!--loading-->
    <div *ngIf="recipientsViewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>
  
    <!-- no content -->
    <div *ngIf="recipientsViewState == viewStates.content && filteredRecipients().length === 0" class="mt-8">
      <app-generic-no-content title="No Recipients Found"
        subtitle="No recipients were found please refresh or update your search criteria."
        imageUrl="https://img.icons8.com/color/256/000000/message-group.png" class="ml-auto mr-auto mt-5">
      </app-generic-no-content>
    </div>
  
    <!-- content -->
    <div [hidden]="recipientsViewState != viewStates.content || filteredRecipients().length === 0" class="w-full mt-8">
      <fuse-card class="w-full p-4">
        <div class="w-full overflow-x-auto mx-6">
          <table class="w-full bg-transparent" mat-table matSort [dataSource]="recipientsDataSource" [trackBy]="trackByFn"
            #recipientsTable>
  
            <!-- Name -->
            <ng-container matColumnDef="email">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Email
              </th>
              <td mat-cell *matCellDef="let recipient">
                <mat-icon class="mr-3 text-primary" style="vertical-align: bottom;" [svgIcon]="'email'"></mat-icon>
                <b class="text-slate-600">{{recipient.email}}</b>
              </td>
            </ng-container>
  
            <!-- delete -->
            <ng-container matColumnDef="delete">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Delete
              </th>
              <td mat-cell *matCellDef="let recipient">
                <span class="whitespace-nowrap">
                  <button class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
                    [swal]="{ title: 'Delete Recipient', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
                    (confirm)="deleteRecipient(recipient)">
                    Delete
                  </button>
                </span>
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="recipientsTableColumns"></tr>
            <tr class="order-row h-16" mat-row *matRowDef="let row; columns: recipientsTableColumns;"></tr>
            <!-- <tr
                  class="h-16 border-0"
                  mat-footer-row
                  *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
          </table>
        </div>
      </fuse-card>
    </div>
  </div>
</fuse-card>
