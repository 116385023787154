import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CandidateDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import moment from 'moment';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  // styleUrls: ['./candidates.component.css']
})
export class CandidatesComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  //view children
  @ViewChild('candidatesTable', { read: MatSort, static: false }) candidatesTableMatSort: MatSort;

  //vars
  candidates: CandidateDto[] = []
  filteredCandidates: CandidateDto[] = []
  searchText: string = '';
  candidatesDataSource: MatTableDataSource<CandidateDto> = new MatTableDataSource();
  candidatesTableColumns: string[] = ['name', 'email', 'phone'];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addCandidateViewState = ViewState.content;

  ngOnInit() {
    this.titleService.setTitle('Candidates | Admin');

  }

  ngAfterViewInit() {
    this.getCandidates();
  }

  //api
  getCandidates(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/candidates`, {
        params: {
          q: this.searchText
        }
      })
      .subscribe((result: CandidateDto[]) => {
        this.candidates = result;
        this.filteredCandidates = this.candidates;
        this.viewState = ViewState.content;
        this.candidatesDataSource = new MatTableDataSource(this.filteredCandidates);
        this.updateSorting(this.filteredCandidates);
      });
  }

  seachTextDidChange(text: string) {
    this.getCandidates();
  }

  updateSorting(candidates: CandidateDto[]) {
    this.candidatesDataSource = new MatTableDataSource(candidates);
    this.candidatesDataSource.sort = this.candidatesTableMatSort;
    this.candidatesDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
      return item[property];
   };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}