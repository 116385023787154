<div class="flex items-center mt-4 sm:mt-0 md:mt-4">
  <!-- Search -->
  <div class="flex-auto">
    <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
      <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
      <input matInput [(ngModel)]="searchText" [autocomplete]="'off'"
        (ngModelChange)="seachTextDidChange(searchText)" [placeholder]="'Filter documents'">
    </mat-form-field>
  </div>
  <!-- Add document button -->
  <a 
  routerLink="/companies/{{companyId}}/add-document"
  *ngIf="companyId != null && (permissionsService?.canManage() ?? false)">
    <button class="ml-4" mat-flat-button [color]="'primary'">
    <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
    <span class="ml-2 mr-1">Add</span>
  </button>
  </a>
</div>
 

<!--list-->
<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<!-- no content -->
<div *ngIf="viewState == viewStates.content && filteredDocuments.length === 0" class="mt-8">
  <app-generic-no-content 
  title="No Documents Found"
  subtitle="No documents were found please refresh or update your search criteria."
  imageUrl="https://img.icons8.com/color/256/000000/documents.png"
  class="ml-auto mr-auto mt-5"></app-generic-no-content>
</div>

<!-- content -->
<div [hidden]="viewState != viewStates.content || filteredDocuments.length === 0" class="w-full mt-8">
  <fuse-card class="w-full p-4">
    <div class="w-full overflow-x-auto mx-6">
      <table
          class="w-full bg-transparent"
          mat-table
          matSort
          [dataSource]="documentsDataSource"
          [trackBy]="trackByFn"
          #documentsTable>

          <!-- Name -->
          <ng-container matColumnDef="name">
              <th
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef>
                  Name
              </th>
              <td
                  mat-cell
                  *matCellDef="let document">
                  <a [href]="document.url" target="_blank" class="mr-3" style="vertical-align: sub;">
                    <mat-icon svgIcon="heroicons_solid:external-link"></mat-icon>
                  </a>
                  <a routerLink="/companies/{{this.document.company?.id}}/documents/{{document.id}}" class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                    <file-icon class="mr-3 cursor-pointer" set="square-o" [type]="fileIconType(document.mimeType)" size="md"
            (click)="imageElement.nativeElement.click()"></file-icon>
                      <b class="text-slate-600" style="vertical-align: super;">{{document.name}}</b>
                  </a>
              </td>
          </ng-container>

          <!-- company -->
        <ng-container matColumnDef="company">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Company
          </th>
          <td mat-cell *matCellDef="let document">
            <img src="{{document.company.imageUrl}}" class="w-4 h-4 rounded inline-block" style="vertical-align: middle;" />
            <span class="whitespace-nowrap">
              {{document.company.name}}
            </span>
          </td>
        </ng-container>

          <!-- timestamp -->
        <ng-container matColumnDef="created">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Created
          </th>
          <td mat-cell *matCellDef="let document">
            <span class="whitespace-nowrap">
              {{moment(document.timestampCreated).format('lll')}}
            </span>
          </td>
        </ng-container>

        <!-- type -->
        <ng-container matColumnDef="type">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Type
          </th>
          <td mat-cell *matCellDef="let document">
            <span class="whitespace-nowrap">
              {{document.documentType.name}}
            </span>
          </td>
        </ng-container>

        <!-- size -->
        <ng-container matColumnDef="size">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Size
          </th>
          <td mat-cell *matCellDef="let document">
            <span class="whitespace-nowrap">
              {{formattedDocSize(document.size)}}
            </span>
          </td>
        </ng-container>

          <tr
              mat-header-row
              *matHeaderRowDef="documentsTableColumns"></tr>
          <tr
              class="order-row h-16"
              mat-row
              *matRowDef="let row; columns: documentsTableColumns;"></tr>
          <!-- <tr
              class="h-16 border-0"
              mat-footer-row
              *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
      </table>
  </div>
  </fuse-card>
</div>