import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AddDocumentDto, CompanyDto, DocumentDto, DocumentTypeDto, FileResponseDto } from 'app/models/dtos';
import { PermissionGroupsLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as mime from 'mime';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  // styleUrls: ['./add-document.component.css']
})
export class AddDocumentComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  public id: string = '0';

  document: AddDocumentDto = new AddDocumentDto();
  company: CompanyDto;
  documentTypes: DocumentTypeDto[];
  file: File;

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;
  companyViewState = ViewState.loading;
  documentViewState = ViewState.content;

  //file upload
  @ViewChild('image') imageElement: ElementRef;

  async ngOnInit() {
    //update title
    this.titleService.setTitle(`Add Document | Admin`);

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';

    await this.permissionsService.setContent(PermissionGroupsLookup.COMPANY, this.id);
    this.getCompany();
    this.getDocumentTypes();
  }

  //api
  getCompany(): void {
    this.companyViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}`)
      .subscribe((result: CompanyDto) => {
        this.company = result;
        this.document.companyId = this.company.id;
        this.companyViewState = this.viewStates.content
      });
  }

  getDocumentTypes(): void {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/documents/types`)
      .subscribe((result: DocumentTypeDto[]) => {
        this.documentTypes = result;
        this.document.documentTypeId = this.documentTypes[0].id;
      });
  }

  addDocument(): void {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.id}/documents`, this.document)
      .subscribe((result: DocumentDto) => {
        this._router.navigateByUrl(`/companies/${this.company.id}/documents/${result.id}`);
      });
  }

  async uploadFile(file: File): Promise<FileResponseDto> {
    //snapshot file name for presentation
    this.file = file;

    //package form data
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    //upload
    return (await firstValueFrom(this.http.post(`${environment.services_tdusa_admin}/v1/files`, formData))) as FileResponseDto;
  }

  async editFile(e: Event) {
    //get file
    const file: File = (<HTMLInputElement>e.target).files.item(0);

    //upload
    this.documentViewState = ViewState.loading;
    const uploadedFile = await this.uploadFile(file);
    this.document.url = uploadedFile.src;
    this.document.size = this.file.size;
    this.document.mimeType = this.file.type;
    this.documentViewState = ViewState.content;
  }

  //validations
  documentIsValid(): Boolean {
    return this.isValidString(this.document.name)
      && this.isValidString(this.document.url);
  }

  isValidString(test: string): Boolean {
    if (test == null) { return false; }
    return test.trim() != '';
  }

  formattedDocSize(size: number): string {
    if (size > 1000000) {
      return `${(size / 1000000).toFixed(2)} MB`
    }
    else if (size > 1000) {
      return `${(size / 1000).toFixed(2)} KB`
    }
    else {
      return `${size.toFixed(2)} bytes`
    }
  }

  fileIconType(): string {
    return mime.getExtension(this.document.mimeType)
  }
}
