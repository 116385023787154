<!--Sales Reps-->
<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
  <!--header-->
  <div class="flex items-center justify-between">
    <div class="text-2xl font-semibold leading-tight">Sales Rep Assignments</div>
  </div>

  <!--loading-->
  <div *ngIf="salesRepsViewState == viewStates.loading">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
  </div>

  <!-- no content -->
  <div *ngIf="salesRepsViewState == viewStates.content && salesRepCompanies.length === 0" class="mt-8 text-center">
    <app-generic-no-content title="No Companies Found"
      subtitle="No company associations were found."
      imageUrl="https://img.icons8.com/color/256/000000/briefcase.png" class="ml-auto mr-auto mt-5">
    </app-generic-no-content>
  </div>

  <!--content-->
  <div *ngIf="salesRepsViewState == viewStates.content && salesRepCompanies.length > 0">
    <app-company-card *ngFor="let company of salesRepCompanies" [company]="company"></app-company-card>
  </div>
</fuse-card>

<!--Ops Reps-->
<fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
  <!--header-->
  <div class="flex items-center justify-between">
    <div class="text-2xl font-semibold leading-tight">Ops Rep Assignments</div>
  </div>

  <!--loading-->
  <div *ngIf="opsRepsViewState == viewStates.loading">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
  </div>

  <!-- no content -->
  <div *ngIf="opsRepsViewState == viewStates.content && opsRepCompanies.length === 0" class="mt-8 text-center">
    <app-generic-no-content title="No Companies Found"
      subtitle="No company associations were found."
      imageUrl="https://img.icons8.com/color/256/000000/briefcase.png" class="ml-auto mr-auto mt-5">
    </app-generic-no-content>
  </div>

  <!--content-->
  <div *ngIf="opsRepsViewState == viewStates.content && opsRepCompanies.length > 0">
    <app-company-card *ngFor="let company of opsRepCompanies" [company]="company"></app-company-card>
  </div>
</fuse-card>