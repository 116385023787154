<fuse-card 
  class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8 mt-4">
  <!--header-->
  <div class="flex items-center justify-between">
    <div class="text-2xl font-semibold leading-tight">Print Preview</div>
    <div class="-mr-3">
      <button mat-flat-button printSectionId="print-section" ngxPrint [printTitle]="proposal.name"
        [useExistingCss]="true" [color]="'primary'">
        <mat-icon [svgIcon]="'print'"></mat-icon>
        <span class="ml-2 mr-1">Print</span>
      </button>
    </div>
  </div>

  <hr />

  <!--print section-->
  <div id="print-section" class="mt-4">
    <!--header-->
    <div class="grid grid-cols-12 gap-3">
      <div class="col-span-6 text-left">
        <!--tdusa-->
        <img class="h-15 rounded object-contain" src="../../../../../../assets/images/logo/company-wordmark.png"
          alt="Card cover image">
      </div>
      <div class="col-span-6">
        <img class="h-15 ml-auto rounded object-contain w-auto"
          *ngIf="proposal.matchedLeadsProduct.company.imageUrl != null"
          [src]="proposal.matchedLeadsProduct.company.imageUrl" alt="Card cover image">
      </div>

      <div class="col-span-12 text-center mt-4">
        <div class="text-4xl font-bold">{{proposal.name}}</div>
        <div>
          <span class="font-bold">for {{proposal.matchedLeadsProduct.company.name}}</span>
          <span> | {{moment(proposal.timestampUpdated).format('lll')}}</span>
        </div>
        <div></div>
      </div>
    </div>

    <!--summary-->
    <div class="grid grid-cols-12 gap-3 mt-8 text-center">
      <div class="col-span-12 text-xl font-bold">Summary</div>
      <div class="col-span-12 grid grid-cols-12 gap-3">
        <div class="col-span-4">
          <div class="bg-slate-100 rounded w-full p-4">
            <div class="text-xl">Total Spend</div>
            <div class="text-2xl font-bold">{{proposal.spendInCents/100 | currency:'USD'}}</div>
            <div class="font-bold mt-2">Lead gen spend: {{proposal.spendInCents/100 | currency:'USD'}}</div>
          </div>
        </div>
        <div class="col-span-4">
          <div class="bg-slate-100 rounded w-full p-4">
            <div class="text-xl">Total Leads</div>
            <div class="text-2xl font-bold mb-7">{{estimatedNumberOfLeads() | number:'1.0-0'}}</div>
          </div>
        </div>
        <div class="col-span-4">
          <div class="bg-slate-100 rounded w-full p-4">
            <div class="text-xl">Cost Per Lead</div>
            <div class="text-2xl font-bold mb-7">{{estimatedCpl() | currency:'USD'}}</div>
          </div>
        </div>
      </div>
    </div>

    <!--tdusa-->
    <!-- <div class="grid grid-cols-12 gap-3 mt-8 text-center">
      <div class="col-span-12 text-xl font-bold">TDUSA</div>
      <div class="col-span-12 grid grid-cols-12 gap-3">
        <div class="col-span-6">
          <div class="bg-slate-100 rounded w-full p-4">
            <div class="text-xl">Estimated Number of Leads</div>
            <div class="text-2xl font-bold mb-7">{{estimatedNumberOfLeads() | number:'1.0-0'}}</div>
          </div>
        </div>
        <div class="col-span-6">
          <div class="bg-slate-100 rounded w-full p-4">
            <div class="text-xl">Estimated Cost Per Lead</div>
            <div class="text-2xl font-bold mb-7">$0.00</div>
          </div>
        </div>
      </div>
    </div> -->

    <!--historical stats-->
    <div class="grid grid-cols-12 gap-3 mt-8 text-center">
      <div class="col-span-12 text-xl font-bold">Historical Stats</div>

      <!-- content -->
      <div class="col-span-12">
        <div class="w-full overflow-x-auto mx-6">
          <table class="w-full bg-transparent text-left" mat-table matSort [dataSource]="proposalsDataSource"
            [trackBy]="trackByFn" #proposalsTable>

            <!-- period -->
            <ng-container matColumnDef="period">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Period
              </th>
              <td mat-cell *matCellDef="let spendPeriod">
                {{moment(spendPeriod.timestampStart).format('LL')}} - {{moment(spendPeriod.timestampEnd).format('LL')}}
              </td>
            </ng-container>

            <!-- totalSpend -->
            <ng-container matColumnDef="totalSpend">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Total Spend
              </th>
              <td mat-cell *matCellDef="let spendPeriod">
                {{spendPeriod.spendInCents/100 | currency:'USD'}}
              </td>
            </ng-container>

            <!-- avgDailySpend -->
            <!-- <ng-container matColumnDef="avgDailySpend">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Average Daily Spend
              </th>
              <td mat-cell *matCellDef="let spendPeriod">
                {{spendPeriod.avgDailySpend}}
              </td>
            </ng-container> -->

            <!-- totalSentLeads -->
            <ng-container matColumnDef="totalSentLeads">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Total Sent Leads
              </th>
              <td mat-cell *matCellDef="let spendPeriod">
                {{spendPeriod.sentLeads | number:'1.0-0'}}
              </td>
            </ng-container>

            <!-- costPerSentLead -->
            <ng-container matColumnDef="costPerSentLead">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Cost Per Sent Lead
              </th>
              <td mat-cell *matCellDef="let spendPeriod">
                {{(spendPeriod.spendInCents/100/spendPeriod.sentLeads) | currency:'USD'}}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="proposalsTableColumns"></tr>
            <tr class="order-row h-16" mat-row *matRowDef="let row; columns: proposalsTableColumns;"></tr>
            <!-- <tr
            class="h-16 border-0"
            mat-footer-row
            *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
          </table>
        </div>
      </div>
    </div>

  </div>
</fuse-card>