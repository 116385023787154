<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<!--content-->
<div *ngIf="viewState == viewStates.content" class="grid grid-cols-12 gap-3 text-center" style="min-width: 480px;">
  <div class="col-span-12">
    <div class="font-bold text-primary text-xl">#{{logEntry.id}}</div>
    <div>{{moment(logEntry.timestampCreated).format('lll')}}</div>
    <div>
      <small class="font-bold">
        <span *ngIf="logEntry.logEntryType.id == rawEntryTypes.ERROR.id"
          style="color: #dc3545;"><small>{{logEntry.logEntryType.name.toUpperCase()}}</small></span>
        <span *ngIf="logEntry.logEntryType.id == rawEntryTypes.WARNING.id"
          style="color: #ffc107;"><small>{{logEntry.logEntryType.name.toUpperCase()}}</small></span>
        <span *ngIf="logEntry.logEntryType.id == rawEntryTypes.INFO.id"
          style="color: #6c757d;"><small>{{logEntry.logEntryType.name.toUpperCase()}}</small></span>
        <span *ngIf="logEntry.logEntryType.id == rawEntryTypes.USERS.id"
          style="color: #007bff;"><small>{{logEntry.logEntryType.name.toUpperCase()}}</small></span>
      </small>
    </div>
  </div>

  <div class="col-span-12">
    <hr class="mt-0 mb-0" />
  </div>

  <!--generated-->
  <div class="col-span-12 md:col-span-6">
    <div class="text-sm text-primary font-bold">Generated by...</div>
    <small class="pr-6 whitespace-nowrap">
      <!--admin-->
      <span *ngIf="logEntry.generatingAdministrator != null">
        <mat-icon *ngIf="logEntry.generatingAdministrator.imageUrl == null" class="w-4 h-4 mr-3 text-primary"
          style="vertical-align: bottom;" [svgIcon]="'heroicons_outline:identification'"></mat-icon>
        <img *ngIf="logEntry.generatingAdministrator.imageUrl != null"
          src="{{logEntry.generatingAdministrator.imageUrl}}"
          class="w-4 h-4 mr-3 rounded-full object-cover inline-block" style="vertical-align: middle;" />
        <span class="whitespace-nowrap">
          <a routerLink="/staff/{{logEntry.generatingAdministrator.id}}">{{logEntry.generatingAdministrator.name}}</a>
        </span>
      </span>
      <!--service-->
      <span *ngIf="logEntry.generatingService != null">
        <img *ngIf="logEntry.generatingService.imageUrl != null" src="{{logEntry.generatingService.imageUrl}}"
          class="w-4 h-4 mr-3 rounded object-cover inline-block" style="vertical-align: middle;" />
        <span>{{logEntry.generatingService.name}}</span>
      </span>
    </small>
  </div>

  <!--target-->
  <div class="col-span-12 md:col-span-6">
    <div class="text-sm text-primary font-bold">On target...</div>
    <small class="pr-6 whitespace-nowrap">
      <!--admin-->
      <span *ngIf="logEntry.administrator != null">
        <mat-icon *ngIf="logEntry.administrator.imageUrl == null" class="w-4 h-4 mr-3 text-primary"
          style="vertical-align: bottom;" [svgIcon]="'heroicons_outline:identification'"></mat-icon>
        <img *ngIf="logEntry.administrator.imageUrl != null" src="{{logEntry.administrator.imageUrl}}"
          class="w-4 h-4 mr-3 rounded-full object-cover inline-block" style="vertical-align: middle;" />
        <span class="whitespace-nowrap">
          <a routerLink="/staff/{{logEntry.administrator.id}}">{{logEntry.administrator.name}}</a>
        </span>
      </span>
      <!--agency-->
      <span *ngIf="logEntry.agency != null">
        <mat-icon *ngIf="logEntry.agency.imageUrl == null" class="w-4 h-4 mr-3 text-primary"
          style="vertical-align: bottom;" [svgIcon]="'heroicons_solid:office-building'"></mat-icon>
        <img *ngIf="logEntry.agency.imageUrl != null" src="{{logEntry.agency.imageUrl}}"
          class="w-4 h-4 mr-3 rounded object-cover inline-block" style="vertical-align: middle;" />
        <span class="whitespace-nowrap">
          <a routerLink="/agencies/{{logEntry.agency.id}}">{{logEntry.agency.name}}</a>
        </span>
      </span>
      <!--collective-->
      <span *ngIf="logEntry.collective != null">
        <mat-icon class="w-4 h-4 mr-3 text-primary" style="vertical-align: bottom;"
          [svgIcon]="'heroicons_solid:collection'"></mat-icon>
        <span class="whitespace-nowrap">
          <a routerLink="/collectives/{{logEntry.collective.id}}">{{logEntry.collective.name}}</a>
        </span>
      </span>
      <!--company-->
      <span *ngIf="logEntry.company != null">
        <mat-icon *ngIf="logEntry.company.imageUrl == null" class="w-4 h-4 mr-3 text-primary"
          style="vertical-align: bottom;" [svgIcon]="'heroicons_solid:briefcase'"></mat-icon>
        <img *ngIf="logEntry.company.imageUrl != null" src="{{logEntry.company.imageUrl}}"
          class="w-4 h-4 mr-3 rounded object-cover inline-block" style="vertical-align: middle;" />
        <span class="whitespace-nowrap">
          <a routerLink="/companies/{{logEntry.company.id}}">{{logEntry.company.name}}</a>
        </span>
      </span>
      <!--contacts-->
      <span *ngIf="logEntry.contact != null">
        <mat-icon *ngIf="logEntry.contact.imageUrl == null" class="w-4 h-4 mr-3 text-primary"
          style="vertical-align: bottom;" [svgIcon]="'contacts'"></mat-icon>
        <img *ngIf="logEntry.contact.imageUrl != null" src="{{logEntry.contact.imageUrl}}"
          class="w-4 h-4 mr-3 rounded-full object-cover inline-block" style="vertical-align: middle;" />
        <span class="whitespace-nowrap">
          <a routerLink="/contacts/{{logEntry.contact.id}}">{{logEntry.contact.name}}</a>
        </span>
      </span>
      <!--proposal-->
      <span *ngIf="logEntry.proposal != null">
        <mat-icon class="w-4 h-4 mr-3 text-primary" style="vertical-align: bottom;"
          [svgIcon]="'heroicons_solid:presentation-chart-bar'"></mat-icon>
        <span class="whitespace-nowrap">
          <a
            routerLink="/matchedLeadsProducts/{{logEntry.proposal.matchedLeadsProduct.id}}/proposals/{{logEntry.proposal.id}}">{{logEntry.proposal.name}}</a>
        </span>
      </span>
      <!--service-->
      <span *ngIf="logEntry.service != null">
        <img *ngIf="logEntry.service.imageUrl != null" src="{{logEntry.service.imageUrl}}"
          class="w-4 h-4 mr-3 rounded object-cover inline-block" style="vertical-align: middle;" />
        <span>{{logEntry.service.name}}</span>
      </span>
    </small>
  </div>

  <!--narrative-->
  <div class="col-span-12">
    <div class="text-sm text-primary font-bold">Narrative</div>
    <div style="white-space: pre-wrap; max-height: 350px; overflow: scroll; text-align: left;">{{logEntry.narrative}}</div>
  </div>

  <!--resolution-->
  <div *ngIf="logEntry.needsResolution" class="col-span-12">
    <hr class="mt-0 mb-3" />
    <div *ngIf="logEntry.resolvingAdministrator == null" class="text-sm font-bold">Unresolved</div>
    <div *ngIf="logEntry.resolvingAdministrator != null" class="text-sm text-primary font-bold">
      <span>Resolved by {{logEntry.resolvingAdministrator.name}}</span>
      <span> on {{moment(logEntry.timestampResolved).format('lll')}}</span>
    </div>

    <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full mt-2">
      <textarea matInput [(ngModel)]="logEntry.resolutionNarrative" [autocomplete]="'off'"
        placeholder="Resolution notes are optional, but recommended."></textarea>
    </mat-form-field>
    <button *ngIf="logEntry.resolvingAdministrator != null" mat-flat-button class="w-full mt-3" (click)="markResolved()">
      Update Resolution
    </button>
    <button mat-flat-button color="primary" class="w-full mt-3" (click)="toggleResolved()">
      <span *ngIf="logEntry.resolvingAdministrator == null" class="text-sm font-bold">Mark Resolved</span>
      <span *ngIf="logEntry.resolvingAdministrator != null" class="text-sm font-bold">Mark
        Unresolved</span>
    </button>
  </div>

  <!--actions-->
  <div class="col-span-12">
    <hr class="mt-0 mb-0" />
  </div>

  <div class="col-span-6">
    <button mat-flat-button color="primary" class="w-full" (click)="didClickDismiss()">Dismiss</button>
  </div>
  <div class="col-span-6">
    <button mat-flat-button color="warn" class="w-full" (click)="deleteLogEntry()">Delete</button>
  </div>
</div>