<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [breadcrumbs]="breadcrumbs()" [terminal]="this.job?.title ?? 'Proposal Job'"
    fallbackIcon="heroicons_solid:presentation-chart-bar"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!--loading-->
    <div *ngIf="viewState == viewStates.content">

      <!--non-map criteria-->
      <app-job-criteria-form [job]="job"></app-job-criteria-form>

      <!--map criteria-->
      <app-job-criteria-area [job]="job"></app-job-criteria-area>
    </div>

  </div>
</div>