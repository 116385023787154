import { Component, Input, OnInit } from '@angular/core';
import { ExternalJobDto, ExternalJobPositionDto, HiringStateDto, LookupDto } from 'app/models/dtos';
import { stripHtml } from 'string-strip-html';

@Component({
  selector: 'app-job-details-card',
  templateUrl: './job-details-card.component.html',
  // styleUrls: ['./job-details-card.component.css']
})
export class JobDetailsCardComponent implements OnInit {
  constructor() { }

  //inputs
  @Input() job: ExternalJobDto;

  //vars

  ngOnInit() {
  }

  formattedDescription(): string {
    return stripHtml(this.job.descriptionHtml ?? '').result.substring(0, 100);
  }

  statePositions(): ExternalJobPositionDto[] {
    return this.job.positions.filter(p => p.hiringZip == null && p.hiringCity == null);
  }

  cityPositions(): ExternalJobPositionDto[] {
    return this.job.positions.filter(p => p.hiringZip == null && p.hiringCity != null);
  }

  zipPositions(): ExternalJobPositionDto[] {
    return this.job.positions.filter(p => p.hiringZip != null);
  }

  lookupList(type: LookupDto[]) : string {
    return type.map(t => t.name)
    .join(', ')
  }

  statesList(): string {
    return this.statePositions()
      .map(t => t.hiringState.abbreviation)
      .join(', ')
  }
}
