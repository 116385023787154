export class TeamDriverTypes {

    id!: string;
    name!: string;
    value: boolean;

    constructor(id: string, name: string, value: boolean) {
        this.id = id;
        this.name = name;
        this.value = value;
    }

    // Values
    static readonly BOTH = new TeamDriverTypes('1', '', null);
    static readonly TEAM = new TeamDriverTypes('2', 'Team', true);
    static readonly SOLO = new TeamDriverTypes('3', 'Solo', false);

    static readonly values = [
        TeamDriverTypes.BOTH,
        TeamDriverTypes.TEAM,
        TeamDriverTypes.SOLO,
    ];
}