import { LngLat } from "mapbox-gl";

export class LeadRadius {

    lngLat: LngLat;
    radius: number = 50;
    name: string;

    constructor(lat: number, lng: number, radius: number = 50, name: string = '') {
        this.lngLat = new LngLat(lng, lat);
        this.radius = radius;
        this.name = name;
    }
}