<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="Campaigns" fallbackIcon="campaign"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
      <!-- Search -->
      <div class="flex-auto">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [(ngModel)]="searchText" [autocomplete]="'off'"
            (ngModelChange)="seachTextDidChange(searchText)" [placeholder]="'Filter campaigns'">
        </mat-form-field>
      </div>
      <!-- Add campaign button -->
      <a routerLink="/add-campaign">
        <button class="ml-4" mat-flat-button [color]="'primary'">
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="ml-2 mr-1">Add</span>
        </button>
      </a>
    </div>


    <!--list-->
    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- no content -->
    <div *ngIf="viewState == viewStates.content && filteredCampaigns.length === 0" class="mt-8">
      <app-generic-no-content title="No Campaigns Found"
        subtitle="No campaigns were found please refresh or update your search criteria."
        imageUrl="https://img.icons8.com/color/256/000000/commercial.png" class="ml-auto mr-auto mt-5">
      </app-generic-no-content>
    </div>

    <!-- content -->
    <div [hidden]="viewState != viewStates.content || filteredCampaigns.length === 0" class="w-full mt-8">
      <fuse-card class="w-full p-4">
        <div class="w-full overflow-x-auto mx-6">
          <table class="w-full bg-transparent" mat-table matSort [dataSource]="campaignsDataSource"
            [trackBy]="trackByFn" #campaignsTable>

            <!-- Name -->
            <ng-container matColumnDef="name">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Name
              </th>
              <td mat-cell *matCellDef="let campaign">
                <a routerLink="/campaigns/{{campaign.id}}"
                  class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                  <mat-icon *ngIf="campaign.imageUrl == null" class="mr-3 text-primary" style="vertical-align: bottom;"
                    [svgIcon]="'campaign'"></mat-icon>
                  <b class="text-slate-600">{{campaign.name}}</b>
                  <mat-icon class="ml-4" 
                  style="vertical-align: bottom;"
                  svgIcon="heroicons_solid:lock-closed" matTooltip="This campaign is system essential and locked from editing"></mat-icon>
                </a>
              </td>
            </ng-container>

            <!-- timestamp -->
            <ng-container matColumnDef="updated">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Updated
              </th>
              <td mat-cell *matCellDef="let campaign">
                <span class="whitespace-nowrap">
                  {{moment(campaign.timestampUpdated).format('lll')}}
                </span>
              </td>
            </ng-container>

            <!-- Ad Count -->
            <ng-container matColumnDef="adCount">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Ad Count
              </th>
              <td mat-cell *matCellDef="let campaign">
                <span class="whitespace-nowrap">{{campaign.adCount}}</span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="campaignsTableColumns"></tr>
            <tr class="order-row h-16" mat-row *matRowDef="let row; columns: campaignsTableColumns;"></tr>
            <!-- <tr
                  class="h-16 border-0"
                  mat-footer-row
                  *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
          </table>
        </div>
      </fuse-card>
    </div>
  </div>

</div>