<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="Add Document"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <fuse-card *ngIf="viewState == viewStates.content"
      class="flex flex-col max-w-xl ml-auto mr-auto filter-article pt-5 pb-5 pl-8 pr-8">
      <div>


        <!--company-->
        <div class="py-2">
          <!--loading-->
          <div *ngIf="companyViewState == viewStates.loading">
            <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
          </div>
          <!--company card-->
          <div *ngIf="companyViewState == viewStates.content">
            <div><b>For company...</b></div>
            <app-company-card [company]="company"></app-company-card>
          </div>

          <hr class="my-4" />
        </div>

        <div class="text-small text-center text-secondary mb-4">
          <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
          </mat-icon>
          <b>Document Info</b>
        </div>

        <!--document-->
        <div>
          <!--loading-->
          <div *ngIf="documentViewState == viewStates.loading">
            <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
          </div>

          <!--document pre-or-post upload-->
          <div *ngIf="documentViewState == viewStates.content">
            <div class="form-group">
              <input type="file" style="display: none;" id="resource-image" accept="application/*"
                (change)="editFile($event)" #image>
            </div>
            <div *ngIf="document.url == null">
              <img *ngIf="document?.imageUrl == null" class="object-contain w-32 h-32 ml-auto mr-auto cursor-pointer"
                src="https://img.icons8.com/color/256/000000/new-by-copy.png"
                (click)="imageElement.nativeElement.click()" />
              <img *ngIf="document?.imageUrl != null"
                class="object-cover w-32 h-32 ml-auto mr-auto border rounded cursor-pointer" src="{{document.imageUrl}}"
                alt="Card cover image" (click)="imageElement.nativeElement.click()">
              <div class="text-center text-small text-secondary mt-2">Click image to upload document</div>
            </div>
            <div *ngIf="document.url != null" class="text-center">
              <file-icon class="ml-auto mr-auto cursor-pointer" set="square-o" [type]="fileIconType()" size="xl"
                (click)="imageElement.nativeElement.click()"></file-icon>
              <div class="mt-2 text-sm">{{file.name}} ({{formattedDocSize(file.size)}})</div>
            </div>
          </div>
        </div>

        <div>
          <div class="flex-auto">
            <!--document type-->
            <mat-label>Document Type</mat-label>
            <mat-form-field appearance="fill" class="w-full mt-1">
              <mat-select [(value)]="document.documentTypeId">
                <mat-option *ngFor="let type of documentTypes" [value]="type.id">{{type.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!--name-->
            <mat-form-field class="w-full">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="document.name" [autocomplete]="'off'" placeholder="Document Name">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </mat-form-field>
          </div>

        </div>
      </div>

      <button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="addDocument()"
        [disabled]="!documentIsValid()">
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="ml-2 mr-1">Add</span>
      </button>
    </fuse-card>
  </div>
</div>