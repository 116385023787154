import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CompanyDto, EditProductStatusDto, MatchedLeadsProductDto, ProductStatusDto } from 'app/models/dtos';
import { ProductStatusTypesLookup, ProductSuspensionReasonsLookup, ProspectReasonsLookup } from 'app/models/lookups';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-matched-leads-product-update-status',
  templateUrl: './matched-leads-product-update-status.component.html',
  // styleUrls: ['./matched-leads-product-update-status.component.css']
})
export class MatchedLeadsProductUpdateStatusComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  //vars
  public id: string = '0';
  public productId: string = '0';
  breadcrumbs(): GenericBreadcrumb[] {
    return [
      new GenericBreadcrumb('Companies', '/companies'),
      new GenericBreadcrumb(`${this.company?.name ?? 'Company'}`, `/companies/${this.id}`),
      new GenericBreadcrumb(`${this.product?.name ?? 'Product'}`, `/companies/${this.id}/matchedLeadsProducts/${this.productId}`)
    ];
  }
  terminal: string = 'Matched Lead Product';
  moment = moment;
  company: CompanyDto;
  product: MatchedLeadsProductDto;
  selectedProspectReason = ProspectReasonsLookup.NOT_SET.id;
  selectedSuspensionReason = ProductSuspensionReasonsLookup.NOT_SET.id;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  //lookups
  prospectReasons = structuredClone(ProspectReasonsLookup.values);
  suspensionReasons = structuredClone(ProductSuspensionReasonsLookup.values);
  rawStatusTypes = ProductStatusTypesLookup;
  rawProspectReasons = ProspectReasonsLookup;
  rawSuspensionReasons = ProductSuspensionReasonsLookup;


  ngOnInit() {
    //update title
    this.titleService.setTitle(`Update Product Status | Admin`);

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';
    this.productId = this.route.snapshot.paramMap.get('productId') ?? '0';

    this.getCompany();
  }


  //api
  getCompany(): void {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}`)
      .subscribe((result: CompanyDto) => {
        this.company = result;
        this.getProduct();
      });
  }

  getProduct() {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.id}/matchedLeadsProducts/${this.productId}`)
      .subscribe((result: MatchedLeadsProductDto) => {
        this.product = result;
        this.viewState = ViewState.content;
      });
  }

  editStatus(editDto: EditProductStatusDto) {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.id}/matchedLeadsProducts/${this.productId}/status`, editDto)
      .subscribe((result: ProductStatusDto) => {
        this.product.productStatus = result;
        this.viewState = ViewState.content;
        // this._router.navigateByUrl(`/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}`)
      });
  }

  editDto(type: ProductStatusTypesLookup, prospectReasonId: string, suspensionReasonId: string): EditProductStatusDto {
    const editProduct = new EditProductStatusDto();
    editProduct.productStatusTypeId = type.id;

    if (prospectReasonId !== '0' && type.id === this.rawStatusTypes.PROSPECT.id) {
      editProduct.prospectReasonId = prospectReasonId;
    }
    if (suspensionReasonId !== '0' && type.id === this.rawStatusTypes.SUSPENDED.id) {
      editProduct.suspensionReasonId = suspensionReasonId;
    }

    return editProduct
  }
}
