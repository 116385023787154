import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ProposalHistoricalStatLineItem, ViewState } from 'app/models/app';
import { DateRanges } from 'app/models/app/dateRanges';
import { ApplicationDto, EditProposalDto, ExternalJobDto, ProductSpendPeriodDto, ProposalDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import moment from 'moment';

@Component({
  selector: 'app-proposal-generate',
  templateUrl: './proposal-generate.component.html',
  // styleUrls: ['./proposal-generate.component.css']
})
export class ProposalGenerateComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  //inputs
  @Input() proposal: ProposalDto;

  //outputs
  @Output() didEditProposal: EventEmitter<ProposalDto> = new EventEmitter();

  //vars
  applications: ApplicationDto[] = [];

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;
  printViewState = ViewState.initial;
  moment = moment;

  //vars
  selectedRange = DateRanges.LAST_MONTH.id;

  //type lists
  dateRanges: DateRanges[] = structuredClone(DateRanges.values);

  ngOnInit() {
    
    if(this.proposal.maxLeads == null) {
      this.getMaxLeads();
    }
    else {
      this.processTempVars();
    }
  }

  processTempVars() {
    if (this.proposal.targetCplInCents != null) {
      this.proposal.targetCpl = (this.proposal.targetCplInCents / 100);
    }
    if (this.proposal.spendInCents != null) {
      this.proposal.spend = (this.proposal.spendInCents / 100);
    }
  }

  //api
  getMaxLeads() {
    this.viewState = this.viewStates.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.proposal.matchedLeadsProduct.company.id}/matchedLeadsProducts/${this.proposal.matchedLeadsProduct.id}/proposals/${this.proposal.id}/maxLeads`)
      .subscribe((result: ProposalDto) => {
        this.proposal = result;
        this.processTempVars();
        this.viewState = this.viewStates.content;
        this.didEditProposal.emit(this.proposal);
      });
  }

  editProposal(): void {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.proposal.matchedLeadsProduct.company.id}/matchedLeadsProducts/${this.proposal.matchedLeadsProduct.id}/proposals/${this.proposal.id}`, EditProposalDto.fromProposal(this.proposal))
      .subscribe((result: ProposalDto) => {
        this.proposal = result;
        this.processTempVars();
        this.viewState = ViewState.content;
        this.didEditProposal.emit(this.proposal);
      });
  }

  applicationsQueryString(): any {
    var range = this.dateRanges.find(range => range.id == this.selectedRange);

    if (range.id == '7') {
      //custom
      return {};
    }
    else {
      return range.params;
    }
  }

  didChangeDateRange() {

  }

  maxRecommendedBudget(): number {
    if (this.proposal.targetCplInCents == null) { return 0; }
    if (this.proposal.targetCplInCents == 0) { return 0; }

    return this.proposal.targetCplInCents/100 * this.proposal.maxLeads;
  }

  estimatedCpl(): number {
    return this.proposal.spendInCents/100/this.estimatedNumberOfLeads();
  }

  estimatedNumberOfLeads(): number {
    const calculatedEstimate = this.proposal.spendInCents/100 / this.maxRecommendedBudget() * this.proposal.maxLeads;
    return Math.min(calculatedEstimate, this.proposal.maxLeads);
  }

  spendInCentsDidChange() {
    this.proposal.spendInCents = Math.floor((this.proposal.spend * 100));

    try {
      if (`${this.proposal.spend}`.split(".")[1].length > 2) {
        setTimeout(() => {
          this.proposal.spend = (this.proposal.spendInCents / 100);
        }, 100);
      }
    } catch (error) {

    }
  }

  targetCplInCentsDidChange() {
    this.proposal.targetCplInCents = Math.floor((this.proposal.targetCpl * 100));

    try {
      if (`${this.proposal.targetCpl}`.split(".")[1].length > 2) {
        setTimeout(() => {
          this.proposal.targetCpl = (this.proposal.targetCplInCents / 100);
        }, 100);
      }
    } catch (error) {

    }
  }

  //validations
  proposalIsValid() : Boolean {
    return (this.proposal.targetCplInCents ?? 0) > 0
    && (this.proposal.spendInCents ?? 0) > 0;
  }
}
