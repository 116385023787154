<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="System Log" fallbackIcon="heroicons_solid:information-circle">
  </app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="System Log">
        <app-log-analytics></app-log-analytics>
      </mat-tab>
      <mat-tab label="Notifications">
        <app-log-notifications></app-log-notifications>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>