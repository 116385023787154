import { AdministratorDto, CandidateDto, ProposalDto, CollectiveDto, AgencyDto, CompanyDto, DocumentDto, ContactDto } from ".";

export class SearchResponseDto {
    //content
    agencies: AgencyDto[];
    companies: CompanyDto[];
    // collectives: CollectiveDto[];
    proposals: ProposalDto[];
    documents: DocumentDto[];

    //user groups
    administrators: AdministratorDto[];
    candidates: CandidateDto[];
    contacts: ContactDto[];
}