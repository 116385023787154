import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { PermissionGroupsLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  // styleUrls: ['./applications.component.css']
})
export class LeadsComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, public permissionsService: PermissionsService) { }

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  async ngOnInit() {
    //update title
    this.titleService.setTitle('Leads | Admin');

    await this.permissionsService.setContent(PermissionGroupsLookup.GLOBAL, '');
    this.viewState = this.viewStates.content;
  }
}