import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AdDto, EditAdDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-ad',
  templateUrl: './ad.component.html',
  // styleUrls: ['./ad.component.css']
})
export class AdComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  public companyId: string;
  public adId: string = '0';
  ad: AdDto = null;
  ads: AdDto[] = [];
  breadcrumbs: GenericBreadcrumb[] = [
    new GenericBreadcrumb('Ads', '/ads')
  ];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  infoViewState = ViewState.loading;
  adsViewState = ViewState.loading;

  ngOnInit() {
    //update title
    this.setTitle('Ad');

    //parse query params
    this.companyId = this.route.snapshot.paramMap.get('id');
    this.adId = this.route.snapshot.paramMap.get('adId') ?? '0';

    this.getAd();
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | Admin`);
  }

  //api
  getAd(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/ads/${this.adId}`)
      .subscribe((result: AdDto) => {
        this.ad = result;
        this.viewState = ViewState.content;
        this.infoViewState = ViewState.content;
        this.setTitle(this.ad.name);
      });
  }

  editAd(): void {
    this.infoViewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/ads/${this.adId}`, EditAdDto.fromAd(this.ad))
      .subscribe((result: AdDto) => {
        this.ad = result;
        this.infoViewState = ViewState.content;
        this.setTitle(this.ad.name);
      });
  }

  deleteAd(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/ads/${this.adId}`)
      .subscribe(() => {
        this._router.navigateByUrl('/ads')
      });
  }
}
