import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AgencyDto, CompanyDto, ContactDto, EditContactDto, FileResponseDto } from 'app/models/dtos';
import { PermissionGroupsLookup } from 'app/models/lookups';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  // styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  public contactId: string = '0';
  public id: string = '0';
  contact: ContactDto = null;
  breadcrumbs: GenericBreadcrumb[] = [
    new GenericBreadcrumb('Contacts', '/contacts')
  ];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  //file upload
  @ViewChild('image') imageElement: ElementRef;

  async ngOnInit() {
    //update title
    this.titleService.setTitle('Contact | Admin');

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id') ?? '0';
    this.contactId = this.route.snapshot.paramMap.get('contactId') ?? '0';

    //set content permissions
    if (this.id != '0') {
      if (this.route.toString().includes('companies/')) {
        await this.permissionsService.setContent(PermissionGroupsLookup.COMPANY, this.contactId);
      }
      else if (this.route.toString().includes('agencies/')) {
        await this.permissionsService.setContent(PermissionGroupsLookup.AGENCY, this.contactId);
      }
    }
    else {
      await this.permissionsService.setContent(PermissionGroupsLookup.GLOBAL, '');
    }

    this.getContact();
  }

  //api
  getContact(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/contacts/${this.contactId}`)
      .subscribe((result: ContactDto) => {
        this.contact = result;
        this.viewState = ViewState.content;
        this.setTitle(this.contact.name);
      });
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | Admin`);
  }


}
