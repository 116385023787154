<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="Proposals" fallbackIcon="heroicons_solid:presentation-chart-bar">
  </app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <app-proposals-list proposalsRoute="/v1/proposals"></app-proposals-list>
  </div>

</div>