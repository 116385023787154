import { Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { debounceTime, filter, map, Subject, takeUntil } from 'rxjs';
import { fuseAnimations } from '@fuse/animations/public-api';
import { environment } from 'environments/environment';
import { SearchResponseDto } from 'app/models/dtos';

@Component({
    selector     : 'search',
    templateUrl  : './search.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs     : 'fuseSearch',
    animations   : fuseAnimations
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy
{
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Input() debounce: number = 300;
    @Input() minLength: number = 2;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();

    opened: boolean = false;
    resultSets: any[];
    searchControl: UntypedFormControl = new UntypedFormControl();
    private _matAutocomplete: MatAutocomplete;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    private searchResponse: SearchResponseDto;

    /**
     * Constructor
     */
    constructor(
        private _elementRef: ElementRef,
        private _httpClient: HttpClient,
        private _renderer2: Renderer2
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any
    {
        return {
            'search-appearance-bar'  : this.appearance === 'bar',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened'          : this.opened
        };
    }

    /**
     * Setter for bar search input
     *
     * @param value
     */
    @ViewChild('barSearchInput')
    set barSearchInput(value: ElementRef)
    {
        // If the value exists, it means that the search input
        // is now in the DOM, and we can focus on the input..
        if ( value )
        {
            // Give Angular time to complete the change detection cycle
            setTimeout(() => {

                // Focus to the input element
                value.nativeElement.focus();
            });
        }
    }

    /**
     * Setter for mat-autocomplete element reference
     *
     * @param value
     */
    @ViewChild('matAutocomplete')
    set matAutocomplete(value: MatAutocomplete)
    {
        this._matAutocomplete = value;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void
    {
        // Appearance
        if ( 'appearance' in changes )
        {
            // To prevent any issues, close the
            // search after changing the appearance
            this.close();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to the search field value changes
        this.searchControl.valueChanges
            .pipe(
                debounceTime(this.debounce),
                takeUntil(this._unsubscribeAll),
                map((value) => {

                    // Set the resultSets to null if there is no value or
                    // the length of the value is smaller than the minLength
                    // so the autocomplete panel can be closed
                    if ( !value || value.length < this.minLength )
                    {
                        this.resultSets = null;
                    }

                    // Continue
                    return value;
                }),
                // Filter out undefined/null/false statements and also
                // filter out the values that are smaller than minLength
                filter(value => value && value.length >= this.minLength)
            )
            .subscribe((value) => {
                this._httpClient
                    .get(`${environment.services_tdusa_admin}/v1/search?q=${value}`)
                    .subscribe((result: SearchResponseDto) => {
                        this.searchResponse = result;

                        var resultSets = [];

                        //content
                        if (result.agencies.length > 0) {
                            resultSets.push({
                                id     : 'agencies',
                                label  : 'Agencies',
                                results: result.agencies.map(r => {
                                    const modR = r;
                                    modR['path'] = `/agencies/${r.id}`;
                                    modR['icon'] = 'heroicons_solid:office-building';

                                    return modR;
                                })
                            });
                        }
                        if (result.companies.length > 0) {
                            resultSets.push({
                                id     : 'companies',
                                label  : 'Companies',
                                results: result.companies.map(r => {
                                    const modR = r;
                                    modR['path'] = `/companies/${r.id}`;
                                    modR['icon'] = 'heroicons_solid:briefcase';
                                    return modR;
                                })
                            });
                        }
                        // if (result.collectives.length > 0) {
                        //     resultSets.push({
                        //         id     : 'collectives',
                        //         label  : 'Collectives',
                        //         results: result.collectives.map(r => {
                        //             const modR = r;
                        //             modR['path'] = `/collectives/${r.id}`;
                        //             modR['icon'] = 'heroicons_solid:collection';
                        //             return modR;
                        //         })
                        //     });
                        // }
                        if (result.proposals.length > 0) {
                            resultSets.push({
                                id     : 'proposals',
                                label  : 'Proposals',
                                results: result.proposals.map(r => {
                                    const modR = r;
                                    modR['path'] = `/companies/${r.matchedLeadsProduct.company.id}/matchedLeadsProducts/${r.matchedLeadsProduct.id}/proposals/${r.id}`;
                                    modR['icon'] = 'heroicons_solid:presentation-chart-bar';
                                    return modR;
                                })
                            });
                        }
                        if (result.documents.length > 0) {
                            resultSets.push({
                                id     : 'documents',
                                label  : 'Documents',
                                results: result.documents.map(r => {
                                    const modR = r;
                                    modR['path'] = `/companies/${r.company.id}/documents/${r.id}`;
                                    modR['icon'] = 'heroicons_solid:document-text';
                                    return modR;
                                })
                            });
                        }

                        //user groups
                        if (result.administrators.length > 0) {
                            resultSets.push({
                                id     : 'staff',
                                label  : 'Staff',
                                results: result.administrators.map(r => {
                                    const modR = r;
                                    modR['path'] = `/staff/${r.id}`;
                                    modR['icon'] = 'heroicons_solid:identification';
                                    return modR;
                                })
                            });
                        }
                        if (result.contacts.length > 0) {
                            resultSets.push({
                                id     : 'contacts',
                                label  : 'Contacts',
                                results: result.contacts.map(r => {
                                    const modR = r;
                                    modR['path'] = `/contacts/${r.id}`;
                                    modR['icon'] = 'contacts';
                                    return modR;
                                })
                            });
                        }
                        if (result.candidates.length > 0) {
                            resultSets.push({
                                id     : 'candidates',
                                label  : 'Candidates',
                                results: result.candidates.map(r => {
                                    const modR = r;
                                    modR['path'] = `/candidates/${r.id}`;
                                    modR['icon'] = 'heroicons_solid:user-circle';
                                    return modR;
                                })
                            });
                        }

                        this.resultSets = resultSets;
                        this.search.next(resultSets);
                        this._matAutocomplete.showPanel = true;
                    });
            });
    }



    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void
    {
        // Escape
        if ( event.code === 'Escape' )
        {
            // If the appearance is 'bar' and the mat-autocomplete is not open, close the search
            if ( this.appearance === 'bar' && !this._matAutocomplete.isOpen )
            {
                this.close();
            }
        }
    }

    /**
     * Open the search
     * Used in 'bar'
     */
    open(): void
    {
        // Return if it's already opened
        if ( this.opened )
        {
            return;
        }

        // Open the search
        this.opened = true;
    }

    /**
     * Close the search
     * * Used in 'bar'
     */
    close(): void
    {
        // Return if it's already closed
        if ( !this.opened )
        {
            return;
        }

        // Clear the search input
        this.searchControl.setValue('');

        // Close the search
        this.opened = false;
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }
}
