import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExternalJobDto, ExternalJobPositionDto, HiringStateDto, ProposalDto } from 'app/models/dtos';
import { stripHtml } from 'string-strip-html';

@Component({
  selector: 'app-job-card-proposal',
  templateUrl: './job-card-proposal.component.html',
  // styleUrls: ['./job-card-proposal.component.css']
})
export class JobCardProposalComponent implements OnInit {
  constructor() { }

  //inputs
  @Input() proposal: ProposalDto;
  @Input() job: ExternalJobDto;
  @Input() included: boolean = false;
  @Input() removable: boolean = false;
  @Input() editable: boolean = false;

  @Output() removeHandler: EventEmitter<ExternalJobDto> = new EventEmitter<ExternalJobDto>();

  //vars
  strippedDesc: string = '';

  ngOnInit() {
    this.strippedDesc = stripHtml(this.job.descriptionHtml ?? '').result.substring(0, 100);
  }

  didClickRemoveJob() {
    this.removeHandler?.emit();
  }
}
