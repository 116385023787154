export class OwnAuthorityTypes {

    id!: string;
    name!: string;
    value: boolean;

    constructor(id: string, name: string, value: boolean) {
        this.id = id;
        this.name = name;
        this.value = value;
    }

    // Values
    static readonly BOTH = new OwnAuthorityTypes('1', '', null);
    static readonly OWN_AUTHORITY = new OwnAuthorityTypes('2', 'Own Authority', true);
    static readonly POWER_UNITY_ONLY = new OwnAuthorityTypes('3', 'Power Unity Only', false);

    static readonly values = [
        OwnAuthorityTypes.BOTH,
        OwnAuthorityTypes.OWN_AUTHORITY,
        OwnAuthorityTypes.POWER_UNITY_ONLY,
    ];
}