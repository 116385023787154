<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [terminal]="administrator?.name ?? ''" [breadcrumbs]="breadcrumbs"
    [imageUrl]="administrator?.imageUrl" [profile]="true" fallbackIcon="heroicons_solid:identification">
  </app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- content -->
    <div *ngIf="viewState == viewStates.content" class="grid grid-cols-12">
      <div class="col-span-12 md:col-span-6 lg:col-span-4 mb-4 p-2">
        <!--basic info-->
        <fuse-card class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
          <!--loading-->
          <div *ngIf="infoViewState == viewStates.loading">
            <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
          </div>

          <div *ngIf="infoViewState == viewStates.content">
            <div class="text-small text-center text-secondary mb-4">
              <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
              </mat-icon>
              <b>Staff Info</b>
            </div>
            <div class="form-group">
              <input type="file" style="display: none;" id="resource-image" accept="image/*"
                (change)="editImage($event)" #image>
            </div>
            <img *ngIf="administrator?.imageUrl == null" class="object-contain w-32 h-32 ml-auto mr-auto cursor-pointer"
              src="https://img.icons8.com/color/256/000000/add-image.png"
              (click)="imageElement.nativeElement.click()" />
            <img *ngIf="administrator?.imageUrl != null"
              class="object-cover rounded-full w-32 h-32 ml-auto mr-auto border rounded-full cursor-pointer"
              src="{{administrator.imageUrl}}" alt="Card cover image" (click)="imageElement.nativeElement.click()">

            <div class="text-center text-small text-secondary mt-2">Tap image to change</div>
            <div>
              <div class="flex-auto">
                <!-- can login -->
                <div class="mt-4 mb-4 font-bold text-center" *ngIf="permissionsService.isSuperAdmin()">
                  <ng-switchery onLabel="Can login to admin portal" [(ngModel)]="administrator.canLogin"
                    name="Can login to admin portal"
                    [matTooltip]="'Enabling a staff member to login to the admin portal will generate a temporary password and send them an email with a link to the admin portal. The user will have basic read-access to the companies and agencies they are associated with.'">
                  </ng-switchery>
                </div>
                <!--name-->
                <mat-form-field class="w-full">
                  <mat-label>Name</mat-label>
                  <input matInput [(ngModel)]="administrator.name" [autocomplete]="'off'"
                    placeholder="Administrator Name">
                  <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>
                <!--email-->
                <mat-form-field class="w-full">
                  <mat-label>Email</mat-label>
                  <input type="email" matInput [(ngModel)]="administrator.email" [autocomplete]="'off'"
                    placeholder="Administrator Email" disabled>
                  <mat-icon class="icon-size-5" matSuffix [svgIcon]="'alternate_email'"></mat-icon>
                </mat-form-field>
                <!--phone-->
                <mat-form-field class="w-full">
                  <mat-label>Phone</mat-label>
                  <input matInput [(ngModel)]="administrator.phone" [autocomplete]="'off'"
                    placeholder="Administrator Phone">
                  <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
                </mat-form-field>
              </div>
              <button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="editAdministrator()">
                <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
                <span class="ml-2 mr-1">Save</span>
              </button>
              <div class="mt-4 text-small text-center text-secondary">Updated
                {{moment(administrator.timestampUpdated).fromNow()}}</div>
            </div>
          </div>

          <!--delete-->
          <div class="border rounded p-3 mt-4 border-rose-500" *ngIf="permissionsService.isSuperAdmin()">
            <div class="text-warn"><b>Danger Zone</b></div>
            <div><small>These actions are destructive. Proceed with care.</small></div>
            <button class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
              [swal]="{ title: 'Delete Administrator', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
              (confirm)="deleteAdministrator()">

              Delete
            </button>
          </div>
        </fuse-card>
      </div>

      <div class="col-span-12 md:col-span-6 lg:col-span-8 p-2">
        <!--tabs-->
        <mat-tab-group animationDuration="0ms">

          <!--Assignments-->
          <mat-tab label="Assignments">
            <ng-template matTabContent>
              <app-staff-member-assignments [administrator]="administrator"></app-staff-member-assignments>
            </ng-template>
          </mat-tab>

          <!--Permissions-->
          <mat-tab label="Permissions" *ngIf="permissionsService.isSuperAdmin()">
            <ng-template matTabContent>
              <app-staff-member-permissions [administrator]="administrator"></app-staff-member-permissions>
            </ng-template>
          </mat-tab>

          <!--Logs-->
          <mat-tab label="Logs" *ngIf="permissionsService.isSuperAdmin()">
            <ng-template matTabContent>
              <app-log-analytics logRoute="v1/administrators/{{administrator.id}}/logs"></app-log-analytics>
            </ng-template>
          </mat-tab>

          <!--Notifications-->
          <mat-tab label="Notifications" *ngIf="permissionsService.isSuperAdmin()">
            <ng-template matTabContent>
              <app-log-notifications logRoute="v1/administrators/{{administrator.id}}/logs/notifications">
              </app-log-notifications>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>