import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CompanyDto, EditExternalJobDto, ExternalJobDto, MatchedLeadsProductDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import * as DocumentEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { IncomingPhoneNumberInstance } from 'twilio/lib/rest/api/v2010/account/incomingPhoneNumber';
import { MyUploadAdapter } from 'app/adapters/my-upload-adapter';

@Component({
  selector: 'app-job-info',
  templateUrl: './job-info.component.html',
  styleUrls: ['./../../../css/bootstrap.css'],
  encapsulation: ViewEncapsulation.None
})
export class JobInfoComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  //inputs
  @Input() company: CompanyDto;
  @Input() product: MatchedLeadsProductDto;
  @Input() job: ExternalJobDto;

  public companyId: string = '0';
  public productId: string = '0';
  public jobId: string = '0';
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;
  twilioViewState = ViewState.content;

  //editor
  public JobEditor: DocumentEditor = DocumentEditor;

  ngOnInit() {
    //update title
    this.titleService.setTitle('Job | Admin');

    //parse query params
    this.companyId = this.route.snapshot.paramMap.get('id') ?? '0';
    this.productId = this.route.snapshot.paramMap.get('productId') ?? '0';
    this.jobId = this.route.snapshot.paramMap.get('jobId') ?? '0';
    // this.getJob();
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | Admin`);
  }

  //document editor
  public onReady( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );

    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader, this.http);
    };
}

  //api
  getJob(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}/jobs/${this.jobId}`)
      .subscribe((result: ExternalJobDto) => {
        this.job = result;
        this.viewState = ViewState.content;
        this.setTitle(this.job.title);
      });
  }

  editJob(): void {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}/jobs/${this.jobId}`, EditExternalJobDto.fromJob(this.job))
      .subscribe((result: ExternalJobDto) => {
        this.job = result;
        this.viewState = ViewState.content;
        this.setTitle(this.job.title);
      });
  }

  deleteJob(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}/jobs/${this.jobId}`)
      .subscribe(() => {
        this._router.navigateByUrl(`/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}`)
      });
  }

  requestNumber() {
    this.twilioViewState = ViewState.loading;

    //add job
    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}/jobs/${this.jobId}/requestNumber`, null)
      .subscribe((result: IncomingPhoneNumberInstance) => {
        this.job.twilioNumber = result.phoneNumber;
        this.twilioViewState = ViewState.content;
      });
  }

  releaseNumber() {
    this.twilioViewState = ViewState.loading;

    //release number
    this.http
      .delete(`${environment.services_tdusa_admin}/v1/companies/${this.companyId}/matchedLeadsProducts/${this.productId}/jobs/${this.jobId}/releaseNumber`)
      .subscribe(() => {
        this.job.twilioNumber = null;
        this.twilioViewState = ViewState.content;
      });
  }
}
