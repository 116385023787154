import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { ApplicationDto, CandidateDto, LookupDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

@Component({
  selector: 'app-candidate-leads',
  templateUrl: './candidate-leads.component.html',
  styleUrls: ['./candidate-leads.component.css']
})
export class CandidateLeadsComponent implements AfterViewInit {
  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  //inputs
  @Input() candidate: CandidateDto;

  //view children
  @ViewChild('applicationsTable', { read: MatSort, static: false }) applicationsTableMatSort: MatSort;

  //vars
  applications: ApplicationDto[] = []
  // _filteredApplications: ApplicationDto[] = null; //cache
  searchText: string = '';
  applicationsDataSource: TableVirtualScrollDataSource<ApplicationDto> = new TableVirtualScrollDataSource([]);
  applicationsTableColumns: string[] = [
    'status',
    'id',
    'created',
    'firstName',
    'lastName',
    'dob',
    'email',
    'phone',
    'zip',
    'city',
    'state',
    'cdl',
    'experience',
    'accidents',
    'violations',
    'endorsements',
    'driverType',
    'interestOOorLP',
    'hasOwnAuthority',
    'teamDriver',
    'interestInTeamDriving',
    'freight',
    'interestedFreightTypes',
    'militaryExperience',
    'applicationContactTimeId',
    'matchedCompanies',
    'utmSource',
    'utmMedium',
    'utmCampaign'
  ];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  ngAfterViewInit() {
    this.getApplications();
  }

  //api
  getApplications(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/candidates/${this.candidate.id}/applications`)
      .subscribe((result: ApplicationDto[]) => {
        this.applications = result;
        this.viewState = ViewState.content;
        // this.applicationsDataSource = new TableVirtualScrollDataSource(this.filteredApplications());
        this.updateSorting(this.filteredApplications());
      });
  }

  filteredApplications(): ApplicationDto[] {
    //if no cache hit, calculate based on free text and filters in place
    return this.applications
    .filter(r =>
      r.firstName.toLowerCase().includes(this.searchText.toLowerCase())
      || r.lastName.toLowerCase().includes(this.searchText.toLowerCase())
      || r.email.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  updateSorting(applications: ApplicationDto[]) {
    this.applicationsDataSource = new TableVirtualScrollDataSource(applications);
    this.applicationsDataSource.sort = this.applicationsTableMatSort;
    this.applicationsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
      return item[property];
    };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  serializedLookups(lookups: LookupDto[]): string {
    return lookups.map(l => l.name).join(', ');
  }
}
