<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="Staff" fallbackIcon="heroicons_solid:identification"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
      <!-- Search -->
      <div class="flex-auto">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [(ngModel)]="searchText" [autocomplete]="'off'"
            (ngModelChange)="seachTextDidChange(searchText)" [placeholder]="'Filter staff'">
        </mat-form-field>
      </div>
      <!-- Add administrator button -->
      <a routerLink="/add-staff" *ngIf="permissionsService?.isSuperAdmin() || permissionsService?.isAdmin()">
        <button class="ml-4" mat-flat-button [color]="'primary'">
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="ml-2 mr-1">Add</span>
      </button>
      </a>
    </div>
     

    <!--list-->
    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- no content -->
    <div *ngIf="viewState == viewStates.content && filteredAdministrators.length === 0" class="mt-8">
      <app-generic-no-content 
      title="No Staff Found"
      subtitle="No staff was found please refresh or update your search criteria."
      imageUrl="https://img.icons8.com/color/256/000000/conference-background-selected.png"
      class="ml-auto mr-auto mt-5"></app-generic-no-content>
    </div>

    <!-- content -->
    <div [hidden]="viewState != viewStates.content || filteredAdministrators.length === 0" class="w-full mt-8">
      <fuse-card class="w-full p-4">
        <div class="w-full overflow-x-auto mx-6">
          <table
              class="w-full bg-transparent"
              mat-table
              matSort
              [dataSource]="administratorsDataSource"
              [trackBy]="trackByFn"
              #administratorsTable>
  
              <!-- Name -->
              <ng-container matColumnDef="name">
                  <th
                      mat-header-cell
                      mat-sort-header
                      *matHeaderCellDef>
                      Name
                  </th>
                  <td
                      mat-cell
                      *matCellDef="let administrator">
                      <img class="inline mr-3 rounded-full object-cover w-6 h-6"*ngIf="administrator.imageUrl != null" [src]="administrator.imageUrl" />
                        <mat-icon  *ngIf="administrator.imageUrl == null" class="mr-3 text-primary" style="vertical-align: bottom;"
                        [svgIcon]="'heroicons_outline:identification'"></mat-icon>
                      <a *ngIf="permissionsService?.isSuperAdmin() || permissionsService?.isAdmin()"
                      routerLink="/staff/{{administrator.id}}" class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                          <b class="text-slate-600">{{administrator.name}}</b>
                      </a>
                      <b *ngIf="!(permissionsService?.isSuperAdmin() || permissionsService?.isAdmin())"
                      class="text-slate-600">{{administrator.name}}</b>
                  </td>
              </ng-container>

              <!-- Email -->
            <ng-container matColumnDef="email">
              <th
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef>
                  Email
              </th>
              <td
                  mat-cell
                  *matCellDef="let administrator">
                  <span class="pr-6 text-secondary whitespace-nowrap">
                      {{administrator.email}}
                  </span>
              </td>
          </ng-container>

          <!-- Phone -->
          <ng-container matColumnDef="phone">
            <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef>
                Phone
            </th>
            <td
                mat-cell
                *matCellDef="let administrator">
                <span class="pr-6 text-secondary whitespace-nowrap">
                    {{administrator.phone}}
                </span>
            </td>
        </ng-container>

              <tr
                  mat-header-row
                  *matHeaderRowDef="administratorsTableColumns"></tr>
              <tr
                  class="order-row h-16"
                  mat-row
                  *matRowDef="let row; columns: administratorsTableColumns;"></tr>
              <!-- <tr
                  class="h-16 border-0"
                  mat-footer-row
                  *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
          </table>
      </div>
      </fuse-card>
    </div>
     

  </div>

</div>