<div class="mb-4 mt-8 text-center">
  <div class="text-2xl"><b>Webhook Integration</b></div>
  <div>
    <small>In addition to any ATS integrations configured above, below we can configure webhooks to send lead information to custom endpoints over the internet.</small>
  </div>
</div>

<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<!-- no content -->
<div *ngIf="viewState == viewStates.content && webhooks.length === 0" class="mt-8 text-center">
  <app-generic-no-content title="No Webhooks Found"
    subtitle="No webhooks were found please refresh or add a webhook by clicking the button below"
    imageUrl="https://img.icons8.com/color/256/000000/webhook.png" class="ml-auto mr-auto mt-5">
  </app-generic-no-content>
  <button mat-flat-button color="primary" class="mt-3" (click)="addWebhook()">
    <mat-icon class="icon-size-5" [svgIcon]="'add'"></mat-icon>
    Add Webhook
  </button>
</div>

<!--content-->
<div *ngIf="viewState == viewStates.content && webhooks.length > 0" class="grid grid-cols-12 gap-3">

  <!-- add webhook -->
  <button mat-flat-button color="primary" class="w-full col-span-12" (click)="addWebhook()">
    <mat-icon class="icon-size-5" [svgIcon]="'add'"></mat-icon>
    Add Webhook
  </button>

  <!-- webhooks -->
  <div class="col-span-12" *ngFor="let webhook of webhooks">
    <app-matched-leads-product-webhook [company]="company" [product]="product" [webhook]="webhook" (didDeleteWebhook)="didDeleteWebhook($event)">
    </app-matched-leads-product-webhook>
  </div>
</div>