import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AdministratorDto } from "app/models/dtos";
import { PermissionGroupsLookup, PermissionTypesLookup } from "app/models/lookups";
import { environment } from "environments/environment";
import { AccountsService } from "../accounts/accounts.service";

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    constructor(private accountsService: AccountsService) { }

    //vars
    private group: PermissionGroupsLookup;
    private objectId: string;
    private account: AdministratorDto;

    async setContent(group: PermissionGroupsLookup, objectId: string) {
        this.group = group;
        this.objectId = objectId;

        this.account = await this.accountsService.getAccount();
    }

    isSuperAdmin(): boolean {
        return this.account?.permissions.find(p => p.permissionType.id === PermissionTypesLookup.SUPER_ADMIN.id) != null;
    }

    isAdmin(): boolean {
        return this.account?.permissions.find(p => p.permissionType.id === PermissionTypesLookup.ADMIN.id) != null;
    }

    canAdmin(): Boolean {
        if (this.account?.permissions.find(p => p.permissionType.id === PermissionTypesLookup.SUPER_ADMIN.id) != null) { return true; }
        if (this.account?.permissions.find(p => p.permissionType.id === PermissionTypesLookup.ADMIN.id) != null) { return true; }

        //content permission check
        if (this.group.id === PermissionGroupsLookup.AGENCY.id) {
            return this.account?.permissions.find(p => p.permissionType.id == PermissionTypesLookup.ADMIN_AGENCY.id && p.objectId == this.objectId) != null;
        }
        if (this.group.id === PermissionGroupsLookup.COMPANY.id) {
            return this.account?.permissions.find(p => p.permissionType.id == PermissionTypesLookup.ADMIN_COMPANY.id && p.objectId == this.objectId) != null;
        }
    }

    canManage(): Boolean {
        if (this.account?.permissions.find(p => p.permissionType.id === PermissionTypesLookup.SUPER_ADMIN.id) != null) { return true; }
        if (this.account?.permissions.find(p => p.permissionType.id === PermissionTypesLookup.ADMIN.id) != null) { return true; }

        //content permission check
        if (this.group.id === PermissionGroupsLookup.AGENCY.id) {
            return this.account?.permissions.find(p => p.permissionType.id == PermissionTypesLookup.ADMIN_AGENCY.id && p.objectId == this.objectId) != null
                || this.account?.permissions.find(p => p.permissionType.id == PermissionTypesLookup.MANAGE_AGENCY.id && p.objectId == this.objectId) != null;
        }
        if (this.group.id === PermissionGroupsLookup.COMPANY.id) {
            return this.account?.permissions.find(p => p.permissionType.id == PermissionTypesLookup.ADMIN_COMPANY.id && p.objectId == this.objectId) != null
                || this.account?.permissions.find(p => p.permissionType.id == PermissionTypesLookup.MANAGE_COMPANY.id && p.objectId == this.objectId) != null;
        }
    }
}