import { AgencyDto } from "./agency.dto";
import { CompanyDto } from "./company.dto";
import { IdentifiableDto } from "./identifiable.dto";

export class ContactDto extends IdentifiableDto {
    email!: string;
    name!: string;
    phone: string;
    timestampCreated!: Date;
    timestampUpdated!: Date;
    imageUrl: string;
    position: string;
    notes: string;
    canLogin: boolean;

    companies: CompanyDto[] = [];
    agencies: AgencyDto[] = [];
}