import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { CompanyDto, MatchedLeadsProductDto, WebhookDto } from 'app/models/dtos';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-matched-leads-product-webhooks',
  templateUrl: './matched-leads-product-webhooks.component.html',
  // styleUrls: ['./matched-leads-product-webhooks.component.css']
})
export class MatchedLeadsProductWebhooksComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  //inputs
  @Input() company: CompanyDto;
  @Input() product: MatchedLeadsProductDto;

  //vars
  webhooks: WebhookDto[] = [];

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  ngOnInit() {
    this.getWebhooks();
  }

  //api
  getWebhooks() {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/webhooks`)
      .subscribe((result: WebhookDto[]) => {
        this.webhooks = result;
        this.viewState = ViewState.content;
      });
  }

  addWebhook() {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.company.id}/matchedLeadsProducts/${this.product.id}/webhooks`, null)
      .subscribe((result: WebhookDto) => {
        this.webhooks.push(result);
        this.viewState = ViewState.content;
      });
  }

  didDeleteWebhook(webhook: WebhookDto) {
    this.webhooks = this.webhooks.filter(g => g.id !== webhook.id);
  }

}
