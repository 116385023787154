<fuse-card class="flex flex-col w-full filter-article">
  <!-- <app-external-jobs-map [job]="job" mapHeight="200px" [mapZoom]="1"></app-external-jobs-map> -->
  <div class="m-8 mb-4 text-center">
    <div class="text-lg font-semibold leading-tight">{{job.title}}</div>
    <div class="text-secondary text-md mt-1">{{job.positions.length}} locations</div>
    <div class="mt-4 text-sm">
      {{strippedDesc}}...
    </div>
    <div class="mt-3 -mx-3 grid grid-cols-12 gap-3">
      <button class="col-span-6" mat-flat-button [color]="'primary'" (click)="didClickViewJob()">
        View Job
      </button>
      <button *ngIf="!editable" class="col-span-6" mat-flat-button [disabled]="true">
        Edit Job
      </button>
      <a *ngIf="editable" routerLink="{{editUrl}}" class="col-span-6" mat-flat-button [disabled]="!editable">
        Edit Job
      </a>
    </div>
  </div>
</fuse-card>