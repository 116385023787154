import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { ExternalJobDto, IdListDto, ProposalDto } from 'app/models/dtos';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-proposal-criteria',
  templateUrl: './proposal-criteria.component.html',
  // styleUrls: ['./proposal-criteria.component.css']
})
export class ProposalCriteriaComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  //inputs
  @Input() proposal: ProposalDto;

  //vars
  importJobs: ExternalJobDto[] = [];
  jobs: ExternalJobDto[] = [];

  //view states
  viewStates = ViewState;
  importJobsViewState = ViewState.loading;
  jobsViewState = ViewState.loading;

  ngOnInit() {
    this.getJobs();
  }

  //api
  getImportJobs() {
    this.importJobsViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.proposal.matchedLeadsProduct.company.id}/matchedLeadsProducts/${this.proposal.matchedLeadsProduct.id}/jobs`)
      .subscribe((result: ExternalJobDto[]) => {
        this.importJobs = result;
        this.importJobsViewState = ViewState.content;

        //mark import jobs as checked when they exist in job list
        this.importJobs.forEach(j => j.checked = this.jobs.find(ij => ij.id == j.id) != null)
      });
  }

  getJobs() {
    this.jobsViewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.proposal.matchedLeadsProduct.company.id}/matchedLeadsProducts/${this.proposal.matchedLeadsProduct.id}/proposals/${this.proposal.id}/jobs`)
      .subscribe((result: ExternalJobDto[]) => {
        this.jobs = result;
        this.jobsViewState = ViewState.content;

        this.getImportJobs();
      });
  }

  includeJobsInProposal(jobsToInclude: ExternalJobDto[]) {
    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.proposal.matchedLeadsProduct.company.id}/matchedLeadsProducts/${this.proposal.matchedLeadsProduct.id}/proposals/${this.proposal.id}/jobs/include`, IdListDto.fromIds(jobsToInclude.map(j => j.id)))
      .subscribe(() => {
        this.getJobs();
      });
  }

  excludeJobsFromProposal(jobsToExclude: ExternalJobDto[]) {
    this.http
      .put(`${environment.services_tdusa_admin}/v1/companies/${this.proposal.matchedLeadsProduct.company.id}/matchedLeadsProducts/${this.proposal.matchedLeadsProduct.id}/proposals/${this.proposal.id}/jobs/exclude`, IdListDto.fromIds(jobsToExclude.map(j => j.id)))
      .subscribe(() => {
        this.jobs = this.jobs.filter(j => jobsToExclude.find(job => job.id == j.id) == null);
      });
  }

  addJob() {
    this.jobsViewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/companies/${this.proposal.matchedLeadsProduct.company.id}/matchedLeadsProducts/${this.proposal.matchedLeadsProduct.id}/proposals/${this.proposal.id}/jobs`, null)
      .subscribe((job: ExternalJobDto) => {
        this._router.navigateByUrl(`/companies/${this.proposal.matchedLeadsProduct.company.id}/matchedLeadsProducts/${this.proposal.matchedLeadsProduct.id}/proposals/${this.proposal.id}/jobs/${job.id}`)
      });
  }

  //jobs
  selectedJobs(): ExternalJobDto[] {
    return this.importJobs.filter(j => j.checked);
  }

  didClickSelectAll() {
    this.importJobs.forEach(j => j.checked = true);
    this.includeJobsInProposal(this.importJobs.filter(ij => this.jobs.find(j => j.id == ij.id) == null));
  }

  didTapAddJob() {
    this.addJob();
  }

  toggleJobInclusion(job: ExternalJobDto) {
    job.checked = !job.checked;

    if (job.checked) {
      this.includeJobsInProposal([job]);
    }
    else {
      this.excludeJobsFromProposal([job]);
    }
  }

  didClickRemoveJob(event: any, job: ExternalJobDto) {
    this.importJobs.forEach(ij => {
      if (ij.id === job.id) {
        ij.checked = false;
      }
    });

    this.excludeJobsFromProposal([job]);
  }

  jobIsEditable(job: ExternalJobDto): boolean {
    if (this.importJobsViewState !== this.viewStates.content) { return false; }
    return this.importJobs.find(ij => ij.id === job.id) == null
  }
}
