import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HiringStatesLookup } from 'app/models/lookups/hiringStates.lookup';

export class SelectStatesModalComponentInput {
  states: HiringStatesLookup[] = [];
}

@Component({
  selector: 'app-select-states-modal',
  templateUrl: './select-states-modal.component.html',
  // styleUrls: ['./select-states-modal.component.css']
})
export class SelectStatesModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SelectStatesModalComponentInput>,
    @Inject(MAT_DIALOG_DATA) public input: SelectStatesModalComponentInput
  ) { }

  //vars
  stateSearchText: string = '';
  states: HiringStatesLookup[] = structuredClone(HiringStatesLookup.values.filter(s => s.id > '0'));

  ngOnInit() {
    this.states.forEach(s => s.checked = this.input.states.find(is => is.id == s.id)?.checked ?? false);
  }

  filteredStates(): HiringStatesLookup[] {
    return this.states.filter(s =>
      s.name.toLowerCase().includes(this.stateSearchText.toLowerCase())
      || s.abbreviation.toLowerCase().includes(this.stateSearchText.toLowerCase())
    );
  }

  //actions
  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(this.states.filter(s => s.checked));
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(null);
  }
}
