import { ExternalJobDto } from "./externalJob.dto";

export class MatchedLeadsProductJobsDto {
    xmlFeed: string;
    twilioNumber: string;
    twilioForwardNumber: string;
    xmlFeedFilter: boolean;
    externalJobFeedTypeId: string;
    xmlFeedExternalJob: ExternalJobDto;
    externalJobFeedFallbackJob: ExternalJobDto;
    xmlFeedFilterKeywords: string;
}