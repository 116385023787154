import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { DateRanges } from 'app/models/app/dateRanges';
import { ApplicationDto, ProductSpendPeriodDto, ProposalDto, ProposalGenerationDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-proposal-preview',
  templateUrl: './proposal-preview.component.html',
  // styleUrls: ['./proposal-preview.component.css']
})
export class ProposalPreviewComponent implements OnInit {
  constructor(private http: HttpClient) { }

  //inputs
  @Input() proposal: ProposalDto;

  //view children
  @ViewChild('proposalsTable', { read: MatSort, static: false }) proposalsTableMatSort: MatSort;

  //vars
  applications: ApplicationDto[] = [];
  proposalsDataSource: MatTableDataSource<ProductSpendPeriodDto> = new MatTableDataSource();
  proposalsTableColumns: string[] = ['period', 'totalSpend'/*, 'avgDailySpend'*/, 'totalSentLeads', 'costPerSentLead'];
  periods: ProductSpendPeriodDto[] = [];

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;
  printViewState = ViewState.initial;
  moment = moment;

  //vars
  selectedRange = DateRanges.LAST_MONTH.id;

  //type lists
  dateRanges: DateRanges[] = structuredClone(DateRanges.values);

  ngOnInit() {
    this.getSpendPeriods();
  }

  //api
  getSpendPeriods(): void {
    this.http
      .get(`${environment.services_tdusa_admin}/v1/companies/${this.proposal.matchedLeadsProduct.company.id}/matchedLeadsProducts/${this.proposal.matchedLeadsProduct.id}/periods-spend-only`)
      .subscribe((result: ProductSpendPeriodDto[]) => {
        this.periods = result;
        this.updateSorting(this.periods);
      });
  }

  // recalculateProposal() {
  //   this.viewState = this.viewStates.loading;
  //   this.printViewState = this.viewStates.initial;

  //   //get leads
  //   setTimeout(() => {
  //     this.updateSorting([]);

  //     this.viewState = this.viewStates.content;
  //     this.printViewState = this.viewStates.content;
  //   }, 1000);

  //   this.http
  //     .get(`${environment.services_tdusa_admin}/v1/applications-analytics`, {
  //       params: this.applicationsQueryString()
  //     })
  //     .subscribe((result: ApplicationDto[]) => {
  //       this.applications = result;
  //       this.historicalStatLineItems = this.historicalLineItemsFromApps(this.applications);

  //       this.viewState = this.viewStates.content;
  //       this.printViewState = this.viewStates.content;

  //       //on delay to allow for render race condition
  //       setTimeout(() => {
  //         this.updateSorting(this.historicalStatLineItems);
  //       }, 1000);
  //     });
  // }

  applicationsQueryString(): any {
    var range = this.dateRanges.find(range => range.id == this.selectedRange);

    if (range.id == '7') {
      //custom
      return {};
    }
    else {
      return range.params;
    }
  }

  updateSorting(data: ProductSpendPeriodDto[]) {
    this.proposalsDataSource = new MatTableDataSource(data);
    this.proposalsDataSource.sort = this.proposalsTableMatSort;
    this.proposalsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
      return item[property];
    };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  didChangeDateRange() {

  }

  maxRecommendedBudget(): number {
    if (this.proposal.targetCplInCents == null) { return 0; }
    if (this.proposal.targetCplInCents == 0) { return 0; }

    return this.proposal.targetCplInCents/100 * this.proposal.maxLeads;
  }

  estimatedCpl(): number {
    return this.proposal.spendInCents/100/this.estimatedNumberOfLeads();
  }

  estimatedNumberOfLeads(): number {
    const calculatedEstimate = this.proposal.spendInCents/100 / this.maxRecommendedBudget() * this.proposal.maxLeads;
    return Math.min(calculatedEstimate, this.proposal.maxLeads);
  }

}
