import { CompanyDto } from "./company.dto";
import { IdentifiableDto } from "./identifiable.dto";
import { DocumentTypeDto } from "./lookup.dto";

export class DocumentDto extends IdentifiableDto {
   name!: string;
   timestampCreated!: Date;
   timestampUpdated!: Date;
   url!: string;
   size!: number;
   mimeType!: string;

   //relations
   company: CompanyDto;
   documentType: DocumentTypeDto;
}