import { HttpClient } from "@angular/common/http";
import { FileResponseDto } from "app/models/dtos";
import { environment } from "environments/environment";
import { firstValueFrom } from "rxjs";

export class MyUploadAdapter {
    loader: any;
    xhr: any;
    http: HttpClient;
    constructor( loader, http: HttpClient ) {
        this.loader = loader;
        this.http = http
    }
    upload() {
        return this.loader.file
            .then( file => new Promise( ( resolve, reject ) => {
                //package form data
                const formData: FormData = new FormData();
                formData.append('file', file, file.name);
            
                //upload
                this.http.post(`${environment.services_tdusa_admin}/v1/files`, formData)
                .subscribe((result: FileResponseDto) => {
                    resolve({
                        default: result.src
                    });
                  });
            } ) );
    }
    abort() {
        if ( this.xhr ) {
            this.xhr.abort();
        }
    }
}