import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AgencyDto, CompanyDto, ContactDto, EditContactDto, FileResponseDto } from 'app/models/dtos';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { GenericBreadcrumb } from '../../common/generic-breadcrumbs/genericBreadcrumb';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  // styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router, public permissionsService: PermissionsService) { }

  //Inputs
  @Input() company: CompanyDto;
  @Input() agency: AgencyDto;
  @Input() contact: ContactDto;

  //Outputs
  @Output() didEditContact: EventEmitter<ContactDto> = new EventEmitter();

  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;
  infoViewState = ViewState.content;

  //file upload
  @ViewChild('image') imageElement: ElementRef;

  ngOnInit() {
  }

  //api
  editContact(): void {
    this.infoViewState = ViewState.loading;

    this.http
      .put(`${this.editBaseRoute()}/contacts/${this.contact.id}`, EditContactDto.fromContact(this.contact))
      .subscribe((result: ContactDto) => {
        this.contact = result;
        this.infoViewState = ViewState.content;
        this.didEditContact.emit(result);
      });
  }

  editBaseRoute(): string {
    if (this.company != null) {
      return `${environment.services_tdusa_admin}/v1/companies/${this.company.id}`;
    }
    if (this.agency != null) {
      return `${environment.services_tdusa_admin}/v1/agencies/${this.agency.id}`;
    }
    return `${environment.services_tdusa_admin}/v1`;
  }

  deleteContact(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_tdusa_admin}/v1/contacts/${this.contact.id}`)
      .subscribe(() => {
        this._router.navigateByUrl('/contacts')
      });
  }

  async uploadFile(file: File): Promise<FileResponseDto> {
    //package form data
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    //upload
    return (await firstValueFrom(this.http.post(`${environment.services_tdusa_admin}/v1/files`, formData))) as FileResponseDto;
  }

  async editImage(e: Event) {
    //get file
    const file: File = (<HTMLInputElement>e.target).files.item(0);

    //upload
    this.infoViewState = ViewState.loading;
    const uploadedFile = await this.uploadFile(file);
    this.contact.imageUrl = uploadedFile.src;
    this.editContact();
  }
}
