<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [terminal]="candidate?.name ?? ''" [breadcrumbs]="breadcrumbs"
    [imageUrl]="candidate?.imageUrl" [profile]="true" fallbackIcon="heroicons_solid:user-circle">
  </app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- content -->
    <div *ngIf="viewState == viewStates.content" class="grid grid-cols-12">
      <div class="col-span-12 md:col-span-6 lg:col-span-4 mb-4 p-2">
        <!--basic info-->
        <fuse-card class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
          <!--loading-->
          <div *ngIf="infoViewState == viewStates.loading">
            <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
          </div>

          <div *ngIf="infoViewState == viewStates.content">
            <div class="text-small text-center text-secondary mb-4">
              <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
              </mat-icon>
              <b>Candidate Info</b>
            </div>
            <img *ngIf="candidate?.imageUrl == null" class="object-contain w-32 h-32 ml-auto mr-auto"
              src="https://img.icons8.com/color/128/000000/test-account.png" />
            <img *ngIf="candidate?.imageUrl != null"
              class="object-cover rounded-full w-32 h-32 ml-auto mr-auto border rounded-full"
              src="{{candidate.imageUrl}}" alt="Card cover image">

            <div>
              <div class="flex-auto">
                <!--name-->
                <mat-form-field class="w-full">
                  <mat-label>Name</mat-label>
                  <input matInput [(ngModel)]="candidate.name" [autocomplete]="'off'" placeholder="Candidate Name"
                    disabled>
                  <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>
                <!--email-->
                <mat-form-field class="w-full">
                  <mat-label>Email</mat-label>
                  <input type="email" matInput [(ngModel)]="candidate.email" [autocomplete]="'off'"
                    placeholder="Candidate Email" disabled>
                  <mat-icon class="icon-size-5" matSuffix [svgIcon]="'alternate_email'"></mat-icon>
                </mat-form-field>
                <!--phone-->
                <mat-form-field class="w-full">
                  <mat-label>Phone</mat-label>
                  <input matInput [(ngModel)]="candidate.phone" [autocomplete]="'off'" placeholder="Candidate Phone"
                    disabled>
                  <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
                </mat-form-field>
              </div>
              <!-- <button class="mt-4 w-full" mat-flat-button [color]="'primary'" (click)="editCandidate()">
                <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
                <span class="ml-2 mr-1">Save</span>
              </button> -->
              <div class="mt-4 text-small text-center text-secondary">Updated
                {{moment(candidate.timestampUpdated).fromNow()}}</div>
            </div>
          </div>

          <!--delete-->
          <div class="border rounded p-3 mt-4 border-rose-500">
            <div class="text-warn"><b>Danger Zone</b></div>
            <div><small>These actions are destructive. Proceed with care.</small></div>
            <button *ngIf="!candidate.banned" class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
              [swal]="{ title: 'Ban Candidate', confirmButtonText: 'Ban', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
              (confirm)="banCandidate()">
              Ban
            </button>
            <button *ngIf="candidate.banned" class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
              [swal]="{ title: 'Unban Candidate', confirmButtonText: 'Unban', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
              (confirm)="unbanCandidate()">
              Unban
            </button>
            <button class="mt-4 w-full text-rose-500" mat-stroked-button mat-button-base
              [swal]="{ title: 'Delete Candidate', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
              (confirm)="deleteCandidate()">
              Delete
            </button>
          </div>
        </fuse-card>
      </div>

      <div class="col-span-12 md:col-span-6 lg:col-span-8 p-2">
        <!--tabs-->
        <mat-tab-group animationDuration="0ms">

          <!--Leads-->
          <mat-tab label="Leads">
            <ng-template matTabContent>
              <app-candidate-leads [candidate]="candidate"></app-candidate-leads>
            </ng-template>
          </mat-tab>

          <!--Log-->
          <mat-tab label="Activity">
            <ng-template matTabContent>
              <app-candidate-log [candidate]="candidate"></app-candidate-log>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>