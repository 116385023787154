import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AddCollectiveDto, CollectiveDto, EditCollectiveDto, FileResponseDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-add-collective',
  templateUrl: './add-collective.component.html',
  // styleUrls: ['./add-collective.component.css']
})
export class AddCollectiveComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  public id: string = '0';
  collective: AddCollectiveDto = new AddCollectiveDto();

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;

  //file upload
  @ViewChild('image') imageElement: ElementRef;

  ngOnInit() {
    //update title
    this.titleService.setTitle(`Add Collective | Admin`);
  }

  //api
  addCollective(): void {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/collectives`, this.collective)
      .subscribe((result: CollectiveDto) => {
        this._router.navigateByUrl(`/collectives/${result.id}`);
      });
  }

  //validations
  collectiveIsValid() : Boolean {
    return this.isValidString(this.collective.name);
  }

  isValidString(test: string) : Boolean {
    if(test == null) { return false; }
    return test.trim() != '';
  }
}
