<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs [breadcrumbs]="breadcrumbs()" terminal="Update Product Status" [imageUrl]="company?.imageUrl"
    fallbackIcon="change_circle"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!--content-->
    <div *ngIf="viewState == viewStates.content">
      <div class="mb-4 mt-4 text-center col-span-12">
        <div class="text-2xl"><b>Current Product Status</b></div>
      </div>

      <!--status-->
      <fuse-card class="col-span-12 p-4 grid grid-cols-12 gap-3 text-center mb-8">
        <div class="col-span-12">
          <div class="mt-2">
            <span *ngIf="product.productStatus == null" class="text-slate-500">
              <mat-icon [svgIcon]="'account_tree'" class="text-slate-500 mr-2" style="vertical-align: top;">
              </mat-icon>
              <span class="font-bold text-lg">Status Not Set</span>
            </span>
            <div *ngIf="product.productStatus != null">
              <div>
                <span>
                  <mat-icon [svgIcon]="rawStatusTypes.valueForId(product.productStatus?.productStatusType?.id).matIcon"
                    class="w-1 h-1 {{rawStatusTypes.valueForId(product.productStatus?.productStatusType?.id).textColor}} mr-2"
                    style="vertical-align: top;"></mat-icon>
                  <span
                    class="font-bold text-lg {{rawStatusTypes.valueForId(product.productStatus?.productStatusType?.id).textColor}}">
                    <span>{{product.productStatus.productStatusType.name}}</span>
                    <span class="ml-2"
                      *ngIf="product.productStatus?.productStatusType?.id === rawStatusTypes.PROSPECT.id">({{product.productStatus.prospectReason?.name}})</span>
                    <span class="ml-2"
                      *ngIf="product.productStatus?.productStatusType?.id === rawStatusTypes.SUSPENDED.id">({{product.productStatus.suspensionReason?.name}})</span>
                  </span>

                </span>
              </div>
              <div>
                {{moment(product.productStatus.timestampUpdated).fromNow()}}
              </div>
            </div>

          </div>

        </div>
      </fuse-card>

      <div class="mb-4 mt-4 text-center col-span-12">
        <div class="text-2xl"><b>Choose a Product Status</b></div>
        <div>
          <small>Review the possible product statuses and choose the most accurate one below.</small>
        </div>
      </div>


      <fuse-card class="flex flex-col w-full mb-4 p-8 filter-article">
        <!--Prospect-->
        <div>
          <div class="text-2xl font-semibold leading-tight {{rawStatusTypes.PROSPECT.textColor}}">
            <mat-icon class="mr-2 {{rawStatusTypes.PROSPECT.textColor}}" svgIcon="{{rawStatusTypes.PROSPECT.matIcon}}"
              style="vertical-align: top;">
            </mat-icon>
            <span>Prospect</span>
          </div>
          <div class="grid grid-cols-12 gap-3">
            <div class="col-span-12 md:col-span-6">
              <div class="mt-4">
                This is a new company that needs to be contacted by sales to establish period dates, ad spends, job
                information, etc.
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <!--  prospect reason -->
              <mat-form-field appearance="fill" class="w-full">
                <mat-label class="text-primary text-sm font-bold">Prospect Reason</mat-label>
                <mat-select [(value)]="selectedProspectReason">
                  <mat-option *ngFor="let reason of prospectReasons" [value]="reason.id">{{reason.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              <button mat-flat-button color="primary" class="w-full mr-2"
                [disabled]="selectedProspectReason === rawProspectReasons.NOT_SET.id"
                (click)="editStatus(editDto(rawStatusTypes.PROSPECT, selectedProspectReason, null))">
                <mat-icon class="mr-2" svgIcon="{{rawStatusTypes.PROSPECT.matIcon}}">
                </mat-icon>
                <span>Prospect</span>
              </button>
            </div>
          </div>
        </div>
        <hr />

        <!--Active-->
        <div>
          <div class="text-2xl font-semibold leading-tight {{rawStatusTypes.ACTIVE.textColor}}">
            <mat-icon class="mr-2 {{rawStatusTypes.ACTIVE.textColor}}" svgIcon="{{rawStatusTypes.ACTIVE.matIcon}}"
              style="vertical-align: top;">
            </mat-icon>
            <span>{{rawStatusTypes.ACTIVE.name}}</span>
          </div>
          <div class="grid grid-cols-12 gap-3">
            <div class="col-span-12 md:col-span-6">
              <div class="mt-4">
                This is a client with established period dates, ad spends, and jobs. We should be active collecting
                leads for this company.
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <button *ngIf="product.productStatus?.productStatusType?.id !== rawStatusTypes.ACTIVE.id" mat-flat-button
                color="primary" class="w-full mr-2" (click)="editStatus(editDto(rawStatusTypes.ACTIVE, null, null))">
                <mat-icon class="mr-2" svgIcon="{{rawStatusTypes.ACTIVE.matIcon}}">
                </mat-icon>
                <span>{{rawStatusTypes.ACTIVE.name}}</span>
              </button>

              <div *ngIf="product.productStatus?.productStatusType?.id === rawStatusTypes.ACTIVE.id"
                class="text-center text-secondary">
                Currently Selected Status
              </div>
            </div>
          </div>
        </div>
        <hr />

        <!--Renewal Required-->
        <div>
          <div class="text-2xl font-semibold leading-tight {{rawStatusTypes.RENEWAL_REQUIRED.textColor}}">
            <mat-icon class="mr-2 {{rawStatusTypes.RENEWAL_REQUIRED.textColor}}"
              svgIcon="{{rawStatusTypes.RENEWAL_REQUIRED.matIcon}}" style="vertical-align: top;">
            </mat-icon>
            <span>{{rawStatusTypes.RENEWAL_REQUIRED.name}}</span>
          </div>
          <div class="grid grid-cols-12 gap-3">
            <div class="col-span-12 md:col-span-6">
              <div class="mt-4">
                This is a company that has completed a previous period. New periods, ad spends, and budget allocations
                need to be established for this company before it becomes Active again.
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <button *ngIf="product.productStatus?.productStatusType?.id !== rawStatusTypes.RENEWAL_REQUIRED.id"
                mat-flat-button color="primary" class="w-full mr-2"
                (click)="editStatus(editDto(rawStatusTypes.RENEWAL_REQUIRED, null, null))">
                <mat-icon class="mr-2" svgIcon="{{rawStatusTypes.RENEWAL_REQUIRED.matIcon}}">
                </mat-icon>
                <span>{{rawStatusTypes.RENEWAL_REQUIRED.name}}</span>
              </button>

              <div *ngIf="product.productStatus?.productStatusType?.id === rawStatusTypes.RENEWAL_REQUIRED.id"
                class="text-center text-secondary">
                Currently Selected Status
              </div>
            </div>
          </div>
        </div>
        <hr />

        <!--Suspended-->
        <div>
          <div class="text-2xl font-semibold leading-tight {{rawStatusTypes.SUSPENDED.textColor}}">
            <mat-icon class="mr-2 {{rawStatusTypes.SUSPENDED.textColor}}" svgIcon="{{rawStatusTypes.SUSPENDED.matIcon}}"
              style="vertical-align: top;">
            </mat-icon>
            <span>{{rawStatusTypes.SUSPENDED.name}}</span>
          </div>
          <div class="grid grid-cols-12 gap-3">
            <div class="col-span-12 md:col-span-6">
              <div class="mt-4 mb-4">
                Setting a company to "Suspended" will automatically archive all jobs, remove from the Directory, and
                will turn off all FB campaigns.
              </div>
              <small>
                <div><b>Paused</b> - Client is likely to come back.</div>
                <div><b>Performance</b> - Client is dissatisfied with results.</div>
                <div><b>Winback</b> - Prospecting client for returned business.</div>
                <div><b>Credit Hold</b> - Client has payments due.</div>
                <div><b>Terminated</b> - No longer pursuing client.</div>
              </small>
            </div>
            <div class="col-span-12 md:col-span-6">
              <!--  suspension reason -->
              <div class="col-span-12 md:col-span-4">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label class="text-primary text-sm font-bold">Suspension Reason</mat-label>
                  <mat-select [(value)]="selectedSuspensionReason">
                    <mat-option *ngFor="let reason of suspensionReasons" [value]="reason.id">{{reason.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <button mat-flat-button color="primary" class="w-full mr-2" [disabled]="selectedSuspensionReason === '0'"
                (click)="editStatus(editDto(rawStatusTypes.SUSPENDED, null, selectedSuspensionReason))">
                <mat-icon class="mr-2" svgIcon="{{rawStatusTypes.SUSPENDED.matIcon}}">
                </mat-icon>
                <span>{{rawStatusTypes.SUSPENDED.name}}</span>
              </button>
            </div>
          </div>
        </div>
        <hr />

        <!--Not Interested-->
        <div>
          <div class="text-2xl font-semibold leading-tight {{rawStatusTypes.NOT_INTERESTED.textColor}}">
            <mat-icon class="mr-2 {{rawStatusTypes.NOT_INTERESTED.textColor}}"
              svgIcon="{{rawStatusTypes.NOT_INTERESTED.matIcon}}" style="vertical-align: top;">
            </mat-icon>
            <span>{{rawStatusTypes.NOT_INTERESTED.name}}</span>
          </div>
          <div class="grid grid-cols-12 gap-3">
            <div class="col-span-12 md:col-span-6">
              <div class="mt-4">
                This company has been prospected, but never active.
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <button *ngIf="product.productStatus?.productStatusType?.id !== rawStatusTypes.NOT_INTERESTED.id"
                mat-flat-button color="primary" class="w-full mr-2"
                (click)="editStatus(editDto(rawStatusTypes.NOT_INTERESTED, null, null))">
                <mat-icon class="mr-2" svgIcon="{{rawStatusTypes.NOT_INTERESTED.matIcon}}">
                </mat-icon>
                <span>{{rawStatusTypes.NOT_INTERESTED.name}}</span>
              </button>

              <div *ngIf="product.productStatus?.productStatusType?.id === rawStatusTypes.NOT_INTERESTED.id"
                class="text-center text-secondary">
                Currently Selected Status
              </div>
            </div>
          </div>
        </div>
        <hr />

        <!--Archived-->
        <div>
          <div class="text-2xl font-semibold leading-tight {{rawStatusTypes.ARCHIVED.textColor}}">
            <mat-icon class="mr-2 {{rawStatusTypes.ARCHIVED.textColor}}" svgIcon="{{rawStatusTypes.ARCHIVED.matIcon}}"
              style="vertical-align: top;">
            </mat-icon>
            <span>{{rawStatusTypes.ARCHIVED.name}}</span>
          </div>
          <div class="grid grid-cols-12 gap-3">
            <div class="col-span-12 md:col-span-6">
              <div class="mt-4">
                This company has moved to a new agency or is now direct.
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <button *ngIf="product.productStatus?.productStatusType?.id !== rawStatusTypes.ARCHIVED.id" mat-flat-button
                color="primary" class="w-full mr-2" (click)="editStatus(editDto(rawStatusTypes.ARCHIVED, null, null))">
                <mat-icon class="mr-2" svgIcon="{{rawStatusTypes.ARCHIVED.matIcon}}">
                </mat-icon>
                <span>{{rawStatusTypes.ARCHIVED.name}}</span>
              </button>

              <div *ngIf="product.productStatus?.productStatusType?.id === rawStatusTypes.ARCHIVED.id"
                class="text-center text-secondary">
                Currently Selected Status
              </div>
            </div>
          </div>
        </div>
        <hr />
      </fuse-card>
    </div>
  </div>
</div>