<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="Contacts" fallbackIcon="contacts"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
      <!-- Search -->
      <div class="flex-auto">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [(ngModel)]="searchText" [autocomplete]="'off'" [placeholder]="'Filter contacts...'">
        </mat-form-field>
      </div>
      <!-- Add contact button -->
      <a routerLink="/add-contact" *ngIf="permissionsService.isAdmin() || permissionsService.isSuperAdmin()">
        <button class="ml-4" mat-flat-button [color]="'primary'">
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="ml-2 mr-1">Add</span>
      </button>
      </a>
    </div>
     

    <!--list-->
    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- no content -->
    <div *ngIf="viewState == viewStates.content && filteredContacts().length === 0" class="mt-8">
      <app-generic-no-content 
      title="No Contacts Found"
      subtitle="No contacts were found please refresh or update your search criteria."
      imageUrl="https://img.icons8.com/color/256/000000/business-contact.png"
      class="ml-auto mr-auto mt-5"></app-generic-no-content>
    </div>

    <!-- content -->
    <div *ngIf="viewState == viewStates.content || filteredContacts().length > 0" class="w-full mt-8 grid grid-cols-12 gap-3">
      <a routerLink="/contacts/{{contact.id}}" *ngFor="let contact of filteredContacts()" class="col-span-12 md:col-span-4">
        <app-contact-card-landscape [contact]="contact"></app-contact-card-landscape>
      </a>
    </div>
  </div>

</div>