import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AgencyDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import moment from 'moment';

@Component({
  selector: 'app-agencies',
  templateUrl: './agencies.component.html',
  // styleUrls: ['./agencies.component.css']
})
export class AgenciesComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  //view children
  @ViewChild('agenciesTable', { read: MatSort, static: false }) agenciesTableMatSort: MatSort;

  //vars
  agencies: AgencyDto[] = []
  filteredAgencies: AgencyDto[] = []
  searchText: string = '';
  agenciesDataSource: MatTableDataSource<AgencyDto> = new MatTableDataSource();
  agenciesTableColumns: string[] = ['name'];
  moment = moment;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addAgencyViewState = ViewState.content;

  ngOnInit() {
    this.titleService.setTitle('Agencies | Admin');

  }

  ngAfterViewInit() {
    this.getAgencies();
  }

  //api
  getAgencies(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/v1/agencies`)
      .subscribe((result: AgencyDto[]) => {
        this.agencies = result;
        this.filteredAgencies = this.agencies;
        this.viewState = ViewState.content;
        this.agenciesDataSource = new MatTableDataSource(this.filteredAgencies);
        this.updateSorting(this.filteredAgencies);
      });
  }

  seachTextDidChange(text: string) {
    this.filteredAgencies = this.agencies.filter(r =>
      r.name.toLowerCase().includes(text.toLowerCase())
    )
    this.updateSorting(this.filteredAgencies);
  }

  updateSorting(agencies: AgencyDto[]) {
    this.agenciesDataSource = new MatTableDataSource(agencies);
    this.agenciesDataSource.sort = this.agenciesTableMatSort;
    this.agenciesDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item)
      return item[property];
   };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

}
