import { IdentifiableDto } from "./identifiable.dto";

export class LookupDto extends IdentifiableDto {
    name!: string;
}

export class AbbreviatedLookupDto extends LookupDto {
    abbreviation!: string;
}
export class AdSpendTypeDto extends LookupDto { }
export class AdTypeDto extends LookupDto { }
export class ApplicationContactTimeDto extends LookupDto { }
export class BannedDomainDto extends LookupDto {
    timestampCreated!: Date;
}
export class CdlClassDto extends LookupDto { }
export class DocumentTypeDto extends LookupDto { }
export class DriverTypeDto extends LookupDto { }
export class EndorsementTypeDto extends AbbreviatedLookupDto { }
export class ExperienceTypeDto extends LookupDto { }
export class FreightTypeDto extends LookupDto { }
export class HiringStateDto extends AbbreviatedLookupDto { }
export class HiringZipDto extends LookupDto {
    city!: string;
    county!: string;
    lat!: number;
    lng!: number;
}
export class JobPostingTypeDto extends LookupDto {
    imageUrl!: string;
}
export class LeadTypeDto extends LookupDto { }
export class LeadSendServiceDto extends LookupDto {
    imageUrl!: string;
}
export class LogEntryTypeDto extends LookupDto { }
export class LogNotificationTypeDto extends LookupDto { }
export class MatchedLeadsProductEmailModeDto extends LookupDto { }
export class MatchedLeadsProductEmailFormatDto extends LookupDto { }
export class PermissionTypeDto extends LookupDto {
    description!: string;
}
export class ProductStatusTypeDto extends LookupDto { }
export class ProductSuspensionReasonDto extends LookupDto { }
export class ProspectReasonDto extends LookupDto { }
export class ServiceDto extends LookupDto { }