import { IdentifiableDto } from "./identifiable.dto";

export class ProductSpendPeriodDto extends IdentifiableDto {
    timestampStart!: Date;
    timestampEnd!: Date;
    spendInCents: number;
    spentInCents: number;
    allocationPercentage: number;
    freeTrial: boolean;

    sentLeads: number;
    calls: number;
    chosen: number;
    matched: number;
    addBacksInCents: number;

    //internal
    spend: number;
}

export class ProductSpendPeriodCalc {
    static sendsPerLead(period: ProductSpendPeriodDto): number {
        return period.sentLeads / period.matched;
    }

    static costPerSentLead(period: ProductSpendPeriodDto): number {
        return period.spendInCents / 100 / period.sentLeads;
    }

    static callsPlusSentLeads(period: ProductSpendPeriodDto): number {
        return period.calls + period.sentLeads;
    }

    static percentChosenPerMatched(period: ProductSpendPeriodDto): number {
        return (period.chosen/period.matched)*100;
    }
}