import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewState } from 'app/models/app';
import { AddAgencyDto, AgencyDto, EditAgencyDto, FileResponseDto } from 'app/models/dtos';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-add-agency',
  templateUrl: './add-agency.component.html',
  // styleUrls: ['./add-agency.component.css']
})
export class AddAgencyComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  public id: string = '0';
  agency: AddAgencyDto = new AddAgencyDto();

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;

  //file upload
  @ViewChild('image') imageElement: ElementRef;

  ngOnInit() {
    //update title
    this.titleService.setTitle(`Add Agency | Admin`);
  }

  //api
  addAgency(): void {
    this.viewState = ViewState.loading;

    this.http
      .post(`${environment.services_tdusa_admin}/v1/agencies`, this.agency)
      .subscribe((result: AgencyDto) => {
        this._router.navigateByUrl(`/agencies/${result.id}`);
      });
  }

  async uploadFile(file: File) : Promise<FileResponseDto> {
    //package form data
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    //upload
    return (await firstValueFrom(this.http.post(`${environment.services_tdusa_admin}/v1/files`, formData))) as FileResponseDto;
  }

  async editImage(e: Event) {
    //get file
    const file: File = (<HTMLInputElement>e.target).files.item(0);

    //upload
    this.viewState = ViewState.loading;
    const uploadedFile = await this.uploadFile(file);
    this.agency.imageUrl = uploadedFile.src;
    this.viewState = ViewState.content;
  }

  //validations
  agencyIsValid() : Boolean {
    return this.isValidString(this.agency.name);
  }

  isValidString(test: string) : Boolean {
    if(test == null) { return false; }
    return test.trim() != '';
  }
}
