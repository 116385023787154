<div class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
  <div class="text-2xl font-semibold leading-tight">Special Contacts</div>
  <div class="grid grid-cols-12 gap-4 mt-4">
    <div class="col-span-12">
      <div *ngIf="agency.repContact == null">
        <div
          class="flex flex-col items-center justify-center w-full rounded-lg border-2 border-gray-300 border-dashed bg-default"
          style="min-height: 285px;">
          <div class="text-secondary text-small text-center mt-2">
            <b>Rep Contact</b>
            <span *ngIf="agency.repContact == null"> (unassigned)</span>
          </div>
        </div>

      </div>
      <div *ngIf="agency.repContact != null">
        <app-contact-card-portrait [contact]="agency.repContact" [options]="optionsForRepContact()"
          (selectOption)="didSelectRepContactOption($event, contact)"
          (selectView)="didSelectViewContact(agency.repContact)">
        </app-contact-card-portrait>
        <div class="mt-2 font-bold text-center">Rep Contact</div>
      </div>

    </div>

    <div class="col-span-12 mt-5">
      <div class="text-2xl font-semibold w-full">All Contacts</div>
      <div *ngIf="addViewState == viewStates.initial" class="flex items-center mt-4 sm:mt-0 md:mt-4">
        <!-- Search -->
        <div class="flex-auto">
          <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
            <input matInput [(ngModel)]="searchText" [autocomplete]="'off'" [placeholder]="'Filter contacts...'">
          </mat-form-field>
        </div>
        <!-- Add existing contact button -->
        <button class="ml-4" mat-flat-button [color]="'primary'" (click)="beginAddExistingContact()" *ngIf="permissionsService.canManage()">
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="ml-2 mr-1">Existing</span>
        </button>
        <!-- Add new contact button -->
        <button class="ml-4" mat-flat-button [color]="'primary'" (click)="addNewViewState = viewStates.content" *ngIf="permissionsService.canManage()">
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="ml-2 mr-1">New</span>
        </button>
      </div>

      <!--loading-->
      <div *ngIf="viewState == viewStates.loading">
        <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
      </div>

      <!--add new-->
      <div class="mt-4" *ngIf="addNewViewState == viewStates.content">
        <app-add-contact-form [agency]="agency" (didCreateContact)="didCreateContact($event)"></app-add-contact-form>

        <button class="mt-4 w-full" mat-flat-button [color]="'warn'" (click)="cancelAddNewContact()">
          <span>Cancel Add New Contact</span>
        </button>
      </div>

      <!--add existing-->
      <div *ngIf="addViewState == viewStates.content" class="border rounded p-4 mt-4 border-primary-500">
        <div class="grid grid-cols-2">
          <b class="text-primary col-span-1">Add Contact</b>
          <button class="col-span-1 text-right text-warn" (click)="addViewState = viewStates.initial">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div><small>Search for a contact below to add them</small></div>
        <!-- Search -->
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50 mt-2">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [(ngModel)]="addSearchText" [autocomplete]="'off'" [placeholder]="'Search contacts to add'">
        </mat-form-field>

        <!--list-->
        <div *ngIf="contactToAdd == null" class="max-h-32 overflow-y-auto">
          <button *ngFor="let contact of filteredAvailableContacts()"
            class="flex items-center py-4 cursor-pointer border-b w-full" (click)="contactToAdd = contact">
            <div class="flex flex-0 items-center justify-center w-10 h-10 rounded overflow-hidden">
              <img *ngIf="contact.imageUrl" class="object-cover w-full h-full" [src]="contact.imageUrl"
                alt="Contact avatar" />
              <mat-icon *ngIf="contact.imageUrl == null" class="mr-3 text-primary" style="vertical-align: bottom;"
                [svgIcon]="'heroicons_solid:briefcase'"></mat-icon>
            </div>
            <div class="min-w-0 ml-2 text-left">
              <div class="font-medium leading-5 truncate">{{contact.name}}</div>
              <div class="leading-5 truncate text-secondary">{{contact.email}}</div>
            </div>
          </button>
        </div>


        <!--selected-->
        <div class="flex items-center mt-4 sm:mt-0 md:mt-4" *ngIf="contactToAdd != null">
          <div class="flex-auto">
            <div class="flex items-center py-4 cursor-pointer border-b">
              <div class="flex flex-0 items-center justify-center w-10 h-10 rounded overflow-hidden">
                <img *ngIf="contactToAdd.imageUrl" class="object-cover w-full h-full" [src]="contactToAdd.imageUrl"
                  alt="Contact avatar" />
                <mat-icon *ngIf="contactToAdd.imageUrl == null" class="mr-3 text-primary"
                  style="vertical-align: bottom;" [svgIcon]="'heroicons_solid:briefcase'"></mat-icon>
              </div>
              <div class="min-w-0 ml-2 text-left">
                <div class="font-medium leading-5 truncate">{{contactToAdd.name}}</div>
                <div class="leading-5 truncate text-secondary">{{contactToAdd.email}}</div>
              </div>
            </div>
          </div>
          <!--Clear contact-->
          <button class="ml-4" mat-flat-button (click)="contactToAdd = null">
            <mat-icon [svgIcon]="'close'"></mat-icon>
          </button>
          <!-- Add contact button -->
          <button class="ml-4" mat-flat-button [color]="'primary'" (click)="addContact(contactToAdd)">
            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
            <span class="ml-2 mr-1">Add</span>
          </button>
        </div>
        <!-- <button class="mt-2 w-full text-rose-500" mat-stroked-button mat-button-base
        [swal]="{ title: 'Delete Agency', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
        (confirm)="deleteAgency()">

        Delete
      </button> -->
      </div>

      <!-- no content -->
      <div *ngIf="viewState == viewStates.content && filteredContacts().length === 0" class="mt-8">
        <app-generic-no-content title="No Contacts Found"
          subtitle="No contacts were found please refresh or update your search criteria."
          imageUrl="https://img.icons8.com/color/256/000000/business-contact.png" class="ml-auto mr-auto mt-5">
        </app-generic-no-content>
      </div>

      <div class="col-span-12 grid grid-cols-12 gap-3 flex-auto pt-5"
        *ngIf="viewState == viewStates.content && filteredContacts().length > 0">
        <!--contact list-->
        <app-contact-card-portrait class="col-span-12 md:col-span-4 lg:col-span-3"
          *ngFor="let contact of filteredContacts()" [contact]="contact" [options]="optionsForContact()"
          (selectOption)="didSelectContactOption($event, contact)"
          (selectView)="didSelectViewContact(contact)"></app-contact-card-portrait>

      </div>
    </div>
  </div>

  <!--view/edit contact-->
  <div class="mt-4" *ngIf="viewContactViewState == viewStates.content">
    <hr />
    <app-contact-form [contact]="selectedContact" [agency]="agency" (didEditContact)="didEditContact($event)"></app-contact-form>

    <button class="mt-4 w-full" mat-flat-button [color]="'warn'" (click)="viewContactViewState = viewStates.initial">
      <span>Dismiss View Contact</span>
    </button>
  </div>
</div>