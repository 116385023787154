import { IdentifiableDto } from "./identifiable.dto";
import { LeadSendDto } from "./leadSend.dto";
import { LeadTypeDto } from "./lookup.dto";

export class LeadDto extends IdentifiableDto {
   timestampCreated!: Date;
   timestampUpdated!: Date;

   //relations
   leadType!: LeadTypeDto;
   companyId!: string;
   matchedLeadsProductId!: string;
   applicationId!: string;
   qualified!: boolean;
   sent!: boolean;
   sendFailureCount!: number;

   sends: LeadSendDto[] = [];
}