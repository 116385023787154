import { AdministratorDto } from "./administrator.dto";
import { AgencyDto } from "./agency.dto";
import { CollectiveDto } from "./collective.dto";
import { ContactDto } from "./contact.dto";
import { IdentifiableDto } from "./identifiable.dto";
import { MatchedLeadsProductDto } from "./matchedLeadsProduct.dto";

export class CompanyDto extends IdentifiableDto {
   name!: string;
   phone: string;
   notes!: string;
   imageUrl: string;
   timestampCreated!: Date;
   timestampUpdated!: Date;

   //relations
   repContact: ContactDto;
   billingContact: ContactDto;
   opsRep: AdministratorDto;
   salesRep: AdministratorDto;
   agency: AgencyDto;
   socialMediaProduct: MatchedLeadsProductDto;

   matchedLeadsProducts: MatchedLeadsProductDto[] = [];
}